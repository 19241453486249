import React, { Component } from 'react'

import Dropzone from 'react-dropzone'

import { Container, Message } from './styled'

export default class Upload extends Component {

  renderMessage = (isDragActive, isDragReject) =>  {
    if (!isDragActive) return <Message>Escolha uma imagem</Message>

    if (isDragReject) return <Message type='error'>Arquivo não suportado</Message>

    return <Message type='success'>Coloque a imagem aqui</Message>
  }

  render () {
    const { onUpload } = this.props

    return (
      <Dropzone accept="image/*" onDropAccepted={onUpload}>
        { ({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <Container
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} />
            {this.renderMessage(isDragActive, isDragReject)}
          </Container>
        )}
      </Dropzone>
    )
  }
}