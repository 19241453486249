import styled from 'styled-components'

import { getStatusColor } from 'utils/colors'

export const Container = styled.div`
  height: 82px;
  margin: 0 0 0 10px;
  background: #010101;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  grid-area: 1 / 1 / 2 / 3;
`

export const Dot = styled.span`
  margin: 0 4px;
  display: flex;
  color: #FFF;
  font-weight: bold;
  font-size: 16px;
  &:before{
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 8px;
    background-color: ${props => getStatusColor(props.status)}
  }
`

export const Register = styled.button`
  font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #fff;
  background-color: #000;
  height: 30px;
  width: '100%';
  padding: 0px 10px;
  border-radius: 15px;
  transition: all 100ms linear;

  &:hover {
    background-color: #222222;
  }
`
