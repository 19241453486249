import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import getRoutes from 'utils/getRoutes'

import { getSessionData, checkSession } from 'store/modules/auth/actions'
import { showLoading } from 'store/modules/alert/actions'
import { push } from 'connected-react-router'

import { Content, Aside, Container, Footer, Text } from './styled'
import SideBar from 'components/SideBar'
import NavBar from 'components/NavBar'

class AdminLayout extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isSidebarOpen: true
    }
  }

  componentDidMount () {
    const { user, getSessionData, checkSession } = this.props

    if (!user || !user.name) {
      getSessionData()
    }
    checkSession()
  }

  render () {
    const { user } = this.props
    const { isSidebarOpen } = this.state
    let routes = []
    if (user) {
      routes = getRoutes(user.level, user.customRoutes)
    }    

    return (
      <Content isSidebarOpen={isSidebarOpen} className={isSidebarOpen ? 'side-open' : 'side-closed'}>
        <NavBar onToggleClick={() => this.setState({ isSidebarOpen: !this.state.isSidebarOpen })}/>
        <Aside>
          <SideBar routes={routes} prefix='/admin' isOpen={isSidebarOpen} />
        </Aside>
        <Container>
          <Switch>
            {routes.map((route, index) => (
              <Route
                path={'/admin' + route.path}
                component={route.component}
                key={index}
              />
            ))}
          </Switch>
        </Container>
        <Footer>
          <Text>Feito com	<span role='img' aria-label='Coração'>&#128420;</span> por <strong>AGX Software</strong> - 2020</Text>
        </Footer>
      </Content>
    )
  }
}

AdminLayout.propTypes = {
  user: PropTypes.object,
  logged: PropTypes.bool,
  sessionTimeout: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getSessionData: PropTypes.func,
  push: PropTypes.func,
  checkSession: PropTypes.func
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  logged: state.auth.logged,
  sessionTimeout: state.auth.sessionTimeout
})

const mapDispatchToProps = (dispatch) => ({
  getSessionData: () => dispatch(getSessionData()),
  push: (url) => dispatch(push(url)),
  showLoading: (url) => dispatch(showLoading(url)),
  checkSession: () =>
    dispatch(checkSession())
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout)
