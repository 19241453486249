import React from 'react'
import PropTypes from 'prop-types'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'

export default function ScheduleCalendar ({ schedules, onDateSelect, size, ...rest }) {
  const schedulesToEvents = schedules => {
    if (schedules) {
      console.log('AGENDAS CARREGADAS NO CALENDARIO', schedules)
      return schedules.map((item) => {
        return {
          title: `${item.horarios_vagos} vagas em ${item.agendas} agendas`,
          date: moment(item.data).format('YYYY-MM-DD')
        }
      })
    }
  }

  const handleDateClick = (arg) => {
    console.log('ARGUMENTOS AO CLICAR', arg)
    onDateSelect(arg.dateStr)
  }

  return (
    <FullCalendar
      {...rest}
      locale='pt-br'
      buttonText={{
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        list: 'listar'
      }}
      contentHeight={size || 450}
      defaultView='dayGridMonth'
      plugins={[dayGridPlugin, interactionPlugin]}
      dateClick={handleDateClick}
      events={schedulesToEvents(schedules)}
    />
  )
}

ScheduleCalendar.propTypes = {
  schedules: PropTypes.array,
  onDateSelect: PropTypes.func,
  size: PropTypes.number

}
