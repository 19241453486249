import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Circle, Container } from './styled'
import { Icon } from 'semantic-ui-react'
import { formatPhone } from 'utils/formaters'
import { showConfirmation, showAlert, showDialling } from 'store/modules/alert/actions'
import telephony from 'consumers/telephony'

const ClickToCall = ({ label, to, whats, missed, showConfirmation, makeCall, showAlert, showDialling, token, status, confirmation }) => {
  if (!to) {
    return null
  }

  const call = () => {

    if (status !== 'available') {
      showAlert(400, 'Não foi possível realizar a ligação!', 'Você deve estar disponível para realizar uma ligação.')
      return
    }

    showConfirmation(20, 'Realizar ligação ?', `Deseja ligar para ${formatPhone(to)}`, async () => {
      try {
        if (confirmation) confirmation()
        const response = await telephony.makeCall(token, to, missed)
        if (response.data.status === 'ringing') showDialling(formatPhone(to))
      } catch (err) {
        showAlert(err.code, 'Algo deu errado', err.message, err.technical)
      }
    })

  }

  return (
    <Container whats={!!(whats)}>
      {
        whats && <Icon name='whatsapp' color='green'/>
      }
      {label}
      <Circle onClick={ call }>
        <Icon name='phone'/>
      </Circle>
    </Container>
  )
}

ClickToCall.propTypes = {
  label: PropTypes.string,
  to: PropTypes.string,
  showConfirmation: PropTypes.func
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  status: state.auth.user.status
})

const mapDispatchToProps = (dispatch) => ({
  showConfirmation: (type, title, message, func) =>
    dispatch(showConfirmation(type, title, message, func)),
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
  showDialling: (text) =>
    dispatch(showDialling(text))
})

export default connect(mapStateToProps, mapDispatchToProps)(ClickToCall)
