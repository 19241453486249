import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './styled'
import LinkSideBar from 'components/LinkSideBar'
import { connect } from 'react-redux'

export function SideBar ({ routes, prefix, isOpen, notifications, ...rest }) {
  return (
    <Container {...rest}>
      <ul className='navbar-item'>
        {routes.map((route) =>
          !route.hidden && (
            <li key={route.key}>
              <LinkSideBar
                notifications={notifications}
                sidebarOpen={isOpen}
                to={prefix + route.path}
                icon={route.icon}>
                {route.title}
              </LinkSideBar>
            </li>
          )
        )}
      </ul>
    </Container>
  )
}

SideBar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  prefix: PropTypes.string,
  isOpen: PropTypes.bool
}

const mapDispatchToProps = (dispatch) => ({

})

const mapStateToProps = (state) => {
  return {
    notifications: state.notification,
    token: state.auth.token
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
