import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { Grid } from 'semantic-ui-react'
import { showAlert, showConfirmation, closeAllSwal, setSwalLoading } from 'store/modules/alert/actions'

import CalendarHoursInput from 'components/CalendarHoursInput'

import Inputmask from 'inputmask'
import { Bounce } from 'react-activity'

import { validateDateHour } from 'utils/validators'

import Card from 'components/Card'
import PaginationShort from 'components/Pagination'
import ListRecord from 'components/ListRecord'

import moment from 'moment'
import Button from 'components/Button'
import telephony from 'consumers/telephony'
import Input from 'components/Input'
import { onlyDigits } from 'utils/onlyDigits'

class ListTabulations extends Component {
  constructor (props) {
    super(props)

    this.state = {
      recordings: undefined,

      // paginação
      totalPages: null,
      activePage: null,
      page: 1,
      ramal: '',
      phone: '',

      dateStart: '',
      dateEnd: '',

      dateHourStart: '',
      dateHourEnd: ''
    }

    this.consultRef = createRef()
    this.phoneRef = createRef()

    this.dateStartRef = createRef()
    this.dateEndRef = createRef()
  }

  componentDidMount () {
    this.getTabulations()
  }

  changePage = (event) => {
    if (event) {
      this.setState(
        {
          page: event.target.getAttribute('value')
        },
        () => {
          this.getTabulations()
        }
      )
    }
  }

  changeRamal = (event) => {
    if (event.target.value === '') {
      this.setState({ ramal: '' }, () => {
        this.getTabulations()
      })
    } else {
      if (event.target.value.length >= 4) {
        this.setState({ page: 1, ramal: `&ramal=${event.target.value}` }, () => {
          this.setState({ activePage: 1 })
          this.getTabulations()
        })
      }
    }
  }

  changePhone = (event) => {
    if (event.target.value === '') {
      this.setState({ phone: '' }, () => {
        this.getTabulations()
      })
    } else {
      if (onlyDigits(event.target.value).length >= 5) {
        this.setState(
          { page: 1, phone: `&phone=${onlyDigits(event.target.value)}` },
          () => {
            this.setState({ activePage: 1 })
            this.getTabulations()
          }
        )
      }
    }
  }

  changeStartDate = (event) => {
    const inputFormatDate = moment(event).format('DD/MM/YYYY HH:mm')
    this.setState({ dateStart: inputFormatDate })

    const searchFormatDate = moment(event).format('YYYY-MM-DDTHH:mm')
    this.concatDate(searchFormatDate, event, 'start')
  }

  changeEndDate = (event) => {
    const inputFormatDate = moment(event).format('DD/MM/YYYY HH:mm')
    this.setState({ dateEnd: inputFormatDate })

    const searchFormatDate = moment(event).format('YYYY-MM-DDTHH:mm')
    this.concatDate(searchFormatDate, event, 'end')
  }

  concatDate = (date, event, type) => {
    if (type === 'start') {
      if (validateDateHour(event)) {
        this.setState(
          { page: 1, dateHourStart: `&start=${date}` },
          () => {
            this.setState({ activePage: 1 })
            this.getTabulations()
          }
        )
      } else {
        this.setState({ dateHourStart: '' }, () => {
          this.getTabulations()
        })
      }
    }

    if (type === 'end') {
      if (validateDateHour(event)) {
        this.setState(
          { page: 1, dateHourEnd: `&end=${date}` },
          () => {
            this.setState({ activePage: 1 })
            this.getTabulations()
          }
        )
      } else {
        this.setState({ dateHourEnd: '' }, () => {
          this.getTabulations()
        })
      }
    }
  }

  clearFilters = () => {
    this.setState(
      {
        ramal: '',
        phone: '',
        dateHourStart: '',
        dateHourEnd: '',
        tempDate: '',
        tempDateFinal: '',
        page: 1,
        activePage: 1
      },
      () => {
        this.getTabulations()
      }
    )
  }

  getTabulations = async () => {
    const { token, showAlert, setSwalLoading, closeAllSwal } = this.props
    const { page, ramal, phone, dateHourStart, dateHourEnd } = this.state

    const data = {
      page,
      ramal,
      phone,
      dateHourStart,
      dateHourEnd
    }

    try {
      setSwalLoading()
      const response = await telephony.recordings(token, data)
      this.setState({
        recordings: response.docs,
        totalPages: response.totalPages,
        activePage: response.page
      })
      closeAllSwal()
    } catch (e) {
      if (!e.response) {
        return showAlert(
          500,
          'Atenção',
          'Não foi possível se comunicar com o servidor!'
        )
      }
      showAlert(400, 'Atenção', e.message, e.technical)
    }
  }

  searchPhone = async (phone) => {
    const { push, showLoading } = this.props

    showLoading()
    push(`/admin/pacientes?phone=${phone}`)
  }

  render () {
    const { recordings, totalPages, activePage } = this.state
    Inputmask().mask(document.querySelectorAll('input'))
    return (
      <Card title='Gravações'>
        {!recordings ? (
          <Grid centered>
            <Grid.Row>
              <Bounce
                className='bounce-margin'
                color='#212121'
                size={22}
                speed={1}
                animating={true}
              />
            </Grid.Row>
          </Grid>
        ) : (
          <>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Input label='Ramal' placeholder="Digite um ramal" onChange={(e) => this.changeRamal(e)} />
                </Grid.Column>
                <Grid.Column width={2}>
                  <Input
                    label='Fone'
                    placeholder="Digite o fone"
                    data-inputmask="'mask': '(99) 9999[9]-9999'"
                    onChange={(e) => this.changePhone(e)}
                  />
                </Grid.Column>

                <Grid.Column width={3}>
                  <CalendarHoursInput
                    label='Data inicial'
                    onSelect={(e) => { this.setState({ tempDate: e }); this.changeStartDate(e) }}
                    selected={this.state.tempDate}
                  />
                </Grid.Column>

                <Grid.Column width={3}>
                  <CalendarHoursInput
                    inputRef={this.dateStartRef}
                    label='Data Final'
                    onSelect={(e) => { this.setState({ tempDateFinal: e }); this.changeEndDate(e) }}
                    selected={this.state.tempDateFinal}
                  />
                </Grid.Column>

                <Grid.Column width={2} style={{ marginTop: 36 }}>
                  <Button filled onClick={this.clearFilters}>
                      Limpar
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <ListRecord recordings={recordings} />

            <PaginationShort
              onPageChange={(e) => this.changePage(e)}
              totalPages={totalPages}
              activePage={activePage}
            />
          </>
        )}
      </Card>
    )
  }
}

ListTabulations.propTypes = {
  token: PropTypes.string,
  showAlert: PropTypes.func,
  showConfirmation: PropTypes.func,
  push: PropTypes.func
}

const mapStateToProps = (state) => ({
  token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal()),
  showConfirmation: (type, title, message, func) =>
    dispatch(showConfirmation(type, title, message, func)),
  push: (value) => dispatch(push(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListTabulations)
