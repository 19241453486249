import React from 'react'
import PropTypes from 'prop-types'
import { Gradient, Content, Wrapper } from './styled'

const ENV = process.env.REACT_APP_ENV

export default function PatientLayout ({ signupWidth, children }) {
  return (
    <Content>
      <Gradient>
        <Wrapper signupWidth={signupWidth}>{children}</Wrapper>
      </Gradient>
    </Content>
  )
}

PatientLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
}
