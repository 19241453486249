import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Accordion, Icon } from 'semantic-ui-react'

// import { Btn } from './styled'

export default function AccordionButton ({ children, title, details, ...rest }) {
  const [activeIndex, setActiveIndex] = useState(-1)

  const handleClick = (e, titleProps) => {
    const { index } = titleProps

    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex)
  }
  
  return (
    <Accordion fluid styled>
      <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
        >
          <Icon name='dropdown' />
          {title}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <>
            <p>{details}</p>
            {children}
          </>
        </Accordion.Content>
    </Accordion>
  )
}

AccordionButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]).isRequired,
  title: PropTypes.string,
  details: PropTypes.string
}
