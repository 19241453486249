import moment from 'moment'
import 'moment/locale/pt-br'

export const formatDate = value => {
  if (value && typeof (value) === 'string') {
    const explode = value.split('/')
    const dateFormat = explode[2] + '-' + explode[1] + '-' + explode[0]
    return dateFormat
  }
}

export const formatDateUsBr = value => {
  const explode = moment(value).format('L').split('/')
  const stringDate = explode[1] + explode[0] + explode[2]
  return stringDate
}

export const formatDateUsBrBar = value => {
  const explode = moment(value).format('L').split('/')
  const stringDate = explode[1] + '/' + explode[0] + '/' + explode[2]
  return stringDate
}

export const FormatDateBr = value => {
  moment.locale('pt-br')
  return moment(value).format('L')
}

export const FormatDateBrPlus3Hours = value => {
  return moment(value).add(3, 'hours').format('L')
}

const brHolidays = [
  // january
  '01-01', // ano novo

  // april
  '04-02', // paixão de cristo
  '04-21', // tiradentes

  // may
  '05-01', // dia do trabalhador

  // june
  '06-11', // corpus christi

  // july
  '07-09', // revolução constitucionalista

  // august
  '08-15', // aniversario de sorocaba

  // september
  '09-07', // independencia

  // october
  '10-12', // nossa senhora

  // november
  '11-02', // finados
  '11-15', // proclamação da republica
  '11-20', // consciencia negra

  // december
  '12-25', // natal
  '12-31' // vespera de ano novo
]

export const validateHolidays = (date, weekday) => {
  return brHolidays.includes(date.day(weekday).format('MM-DD'))
}

export const getNameDay = (day) => {
  switch (moment(day).isoWeekday()) {
    case 0:
      return 'Domingo'
    case 1:
      return 'Segunda'
    case 2:
      return 'Terça'
    case 3:
      return 'Quarta'
    case 4:
      return 'Quinta'
    case 5:
      return 'Sexta'
    case 6:
      return 'Sabado'
  }
}

export const isSaturday = (day) => {
  return moment(day).weekday() === 0
}

export const difference6Hours = date => {
  const duration = moment.duration(moment().diff(date))

  return duration.asHours()
}

export const getHour = value => {
  if (value) {
    moment.locale('pt-br')
    return moment(value).format('LT')
  }
}

export const calendarInputToUsFormat = value => {
  return moment(value).format('YYYY-MM-DDTHH:mm')
}

export const ToBrDisplayWithHour = value => {
  return moment(value).format('DD/MM/YYYY HH:mm')
}

// 2020-08-26 16:10:00
export const ToUsDisplayWithHour = value => {
  return moment(value).format('YYYY-MM-DD HH:mm')
}

export const toBrDisplay = value => {
  moment.locale('pt-br')
  return moment(value).format('llll')
}

export const toUsDisplay = value => {
  value = moment(value).add(3, 'hours')
  return moment(value).format('llll')
}

export const diffInMs = (start, end) => {
  moment.locale('pt-br')
  return moment(start).diff(moment(end))
}

export const dateToBrSmall = value => {
  if (!value) return ''
  moment.locale('pt-br')
  if (moment().year === moment(value).year) { return `${moment(value).format('DD/MM')} ${moment(value).format('LT')}` }
  return `${moment(value).format('L')} ${moment(value).format('LT')}`
}

export const dateToUsSmall = value => {
  if (!value) return ''
  value = moment(value).add(3, 'hours')
  if (moment().year === moment(value).year) { return `${moment(value).format('DD/MM')} ${moment(value).format('LT')}` }
  return `${moment(value).format('L')} ${moment(value).format('LT')}`
}
