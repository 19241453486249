import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 10px;
  height: 100%;
  /* display: flex;
  align-items: center; */
  transition: all 300ms linear;
  border-radius: 6px;
  display: grid;
  &:hover {
    background-color: rgba(250,250,250,0.2);
    cursor: pointer;
  }
`

export const Text = styled.span`
  font-size: 11px;
  color: #FFF;
  font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
`

export const Title = styled.h1`
  font-size: 20px;
  color: #343434;
  align-self: center;
  margin-top: 15px;
  font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
`

export const Button = styled.button`
  background: none;
  border: none;
  color: #FFF;
`

export const CircleInfo = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: red;
  grid-column: 3;
  grid-row: 1;
  z-index: 1;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  padding-left: 7px;
`

export const CardInfo = styled.div`
  display: grid;
  background-color: #fff;
  margin-bottom: 4px;
  grid-template-columns: 1fr 45px;
  grid-template-rows: 1fr 1.5fr;
  grid-template-areas: "title advert"
                        "body advert";
  width: 100%;
  height: 60px;
  border-radius: 10px 0px 0px 10px;
  padding: 7px;
  overflow: hidden;
  transition: all 100ms linear;

  &:hover {
    background-color: rgba(0,0, 0,0.1);
    cursor: pointer;
  }
  
  h2 {
    margin: 0px;
    grid-area: title;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #343434;
    font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
  }

  p {
    grid-area: body;
    align-self: center;
    font-size: 11px;
    color: #545454;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
  }
`

export const Notify = styled.div`
  grid-area: advert;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background-color: black;
  }
`

export const ShowMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  transition: all 300ms linear;

 &:hover {
  background-color: rgba(0, 0, 0, 0.75);
 }
`
