import api from 'services/api'

const missedCalls = {

  list: async (token, query) => {
    try {
      const response = await api.get(`/missedcalls${query}`, 
        { headers: { Authorization: token } }
      )
      return response.data.data
    } catch(err) {
      if(!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },
  finish: async (token, id) => {
    try {
      const response = await api.patch(`/missedcalls/${id}`,
        { finished: true },
        { headers: { Authorization: token } }
      )
      return response.data
    } catch(err) {
      if(!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },
  fetch: async (token) => {
    try {
      const response = await api.post('/missedcalls',
        {},
        { headers: { Authorization: token } }
      )
      return response.data
    } catch(err) {
      if(!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },
  history: async (token, id) => {
    try {
      const response = await api.get(`/missedcalls/${id}`,
        { headers: { Authorization: token } }
      )
      return response.data
    } catch(err) {
      if(!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  }
}

export default missedCalls