import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { showAlert } from 'store/modules/alert/actions'
import PropTypes from 'prop-types'

import Input from 'components/Input'
import Button from 'components/Button'
import Select from 'components/Select'

import inputmask from 'inputmask'
import Toggle from 'react-toggle'
import { Bounce } from 'react-activity'
import operator from 'consumers/operator'
import { push } from 'connected-react-router'
import { Grid } from 'semantic-ui-react'
import { turnOptions } from 'utils/resources'

import { formatDate, FormatDateBr } from 'utils/formatDate'

class CreatePerson extends Component {
  constructor (props) {
    super(props)

    this.state = {
      id: '',
      name: undefined,
      email: undefined,
      password: undefined,
      identifier: undefined,
      isWhatsapp: false,
      cellphone: undefined,
      telephone: undefined,
      birthdate: undefined,
      period: undefined,
      ramal: 0,
      changed: false,
      userData: undefined,
      gettingUser: false
    }

    this.nameRef      = createRef()
    this.ramalRef     = createRef()
    this.emailRef     = createRef()
    this.periodRef    = createRef()
    this.birthdateRef = createRef()
    this.cellphoneRef = createRef()
    this.whatsappRef  = createRef()
  }

  redirUser = () => {
    const { push } = this.props
    push('/admin/operadores/listar')
  }

  submit = async () => {
    const { onSubmit, type, showAlert } = this.props
    const {
      id,
      name,
      identifier,
      password,
      isWhatsapp,
      cellphone,
      telephone,
      email,
      birthdate,
      ramal,
      changed,
      period
    } = this.state
    
    if (!changed) {
      showAlert(
        400,
        'Operador não atualizado!',
        'Redirecionando para listagem de operadores',
        null,
        this.redirUser
      )
      return
    }

    let rawCellphone
    let rawTelephone
    let newBirthdate
    if (cellphone) { rawCellphone = cellphone.replace(/\D/g, '') }
    if (telephone) { rawTelephone = telephone.replace(/\D/g, '') }
    if (birthdate) { newBirthdate = formatDate(birthdate) }
    const data = {
      identifier,
      name,
      birthdate: newBirthdate,
      email,
      isWhatsapp,
      cellphone: rawCellphone,
      telephone: rawTelephone,
      password,
      period
    }

    if (type === 'operator') data.ramal = ramal
    if (id && changed) {
      onSubmit(id, data)
    } else {
      onSubmit(data)
    }
  }

  getInputsConfig = (type) => {
    switch (type) {
      case 'operator':
        return new Set(['Operador', 'ramal', 'password', 'period'])
      default: break
    }
  }

  getUser = async (id) => {
    const { token, showAlert } = this.props
    this.setState({ gettingUser: true })
    try {
      const response = await operator.getById(token, id)
      const user = response.data
      this.setState({ userData: response.data })
      this.nameRef.current.value = user.name || ''
      this.ramalRef.current.value = user.ramal || ''
      this.emailRef.current.value = user.email || ''
      this.birthdateRef.current.value = FormatDateBr(user.birthdate) || ''
      this.cellphoneRef.current.value = user.cellphone || ''
      const periodStates = turnOptions.find(element => element.value === user.period)
      this.periodRef.current.state.value = periodStates || turnOptions[0]

      this.setState({ period: periodStates.value })
    } catch (e) {
      if (!e.response) {
        return showAlert(500, 'Atenção', 'Falha ao se comunicar com o servidor!')
      }
      showAlert(e.response, 'Atenção', e.response, e, e.technical)
    }
  }

  componentDidMount () {
    if (this.props.match.params) {
      const { id } = this.props.match.params
      if (id) {
        this.setState({ editMode: true, id })
        this.getUser(id)
      }
    }
  }

  render () {
    const { invalidFields, type, loading } = this.props
    const { editMode, userData, gettingUser } = this.state

    const config = this.getInputsConfig(type)
    inputmask().mask(document.querySelectorAll('input'))

    if (!userData && gettingUser) {
      return (
        <Grid centered>
          <Grid.Row>
            <Bounce
              className='bounce-margin'
              color='#212121'
              size={22}
              speed={1}
              animating={true}
            />
          </Grid.Row>
        </Grid>
      )
    }

    return (
      <>
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column width={6}>
              <Input
                label='Nome'
                placeholder='Nome'
                inputRef={this.nameRef}
                onChange={(e) =>
                  this.setState({ name: e.target.value, changed: true })
                }
                error={invalidFields.name}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Input
                label='Email'
                placeholder='Email'
                inputRef={this.emailRef}
                onChange={(e) =>
                  this.setState({ email: e.target.value, changed: true })
                }
                error={invalidFields.email}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Input
                data-inputmask="'mask': '99/99/9999', 'placeholder': 'dd/mm/aaaa'"
                label='Data de Nascimento'
                placeholder='Data de Nascimento'
                inputRef={this.birthdateRef}
                error={invalidFields.birthdate}
                onChange={(e) =>
                  this.setState({ birthdate: e.target.value, changed: true })
                }
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            {config.has('ramal') && (
              <Grid.Column width={4}>
                <Input
                  label='Ramal'
                  data-inputmask="'mask': '9', 'repeat': 10, 'greedy' : false"
                  placeholder='Ramal'
                  inputRef={this.ramalRef}
                  onChange={(e) =>
                    this.setState({ ramal: e.target.value, changed: true })
                  }
                  error={invalidFields.ramal}
                />
              </Grid.Column>
            )}

            {config.has('gender') && (
              <Grid.Column width={4}>
                <Select
                  label='Gênero'
                  inputRef={this.genderRef}
                  options={[
                    { value: 'M', label: 'Masculino' },
                    { value: 'F', label: 'Feminino' }
                  ]}
                  onChange={(e) =>
                    this.setState({ gender: e.target.value, changed: true })
                  }
                  error={invalidFields.gender}
                />
              </Grid.Column>
            )}
            {config.has('password') && (
              <Grid.Column width={4}>
                <Input
                  label='Senha'
                  placeholder='Senha'
                  type='password'
                  onChange={(e) =>
                    this.setState({ password: e.target.value, changed: true })
                  }
                  error={invalidFields.password}
                />
              </Grid.Column>
            )}
            {config.has('period') && (<Grid.Column width={3}>
              <Select
                label='Turno'
                inputRef={this.periodRef}
                options={turnOptions}
                onChange={(e) => this.setState({ period: e.value, changed: true })}
                error={invalidFields.period}
              />
            </Grid.Column>
            )}
            <Grid.Column width={3}>
              <Input
                label='Celular'
                placeholder='Celular'
                data-inputmask="'mask': '(99) 99999-9999'"
                inputRef={this.cellphoneRef}
                onChange={(e) =>
                  this.setState({ cellphone: e.target.value, changed: true })
                }
                error={invalidFields.cellphone}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <div className='align-toggle'>
                <Toggle
                  onChange={(e) =>
                    this.setState({
                      isWhatsapp: e.target.checked,
                      changed: true
                    })
                  }
                />
                <span>Whatsapp</span>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16} textAlign='right'>
              <Button filled onClick={this.submit} disabled={loading}>
                {loading ? (
                  <Bounce
                    color='#FCFCFC'
                    size={22}
                    speed={1}
                    animating={true}
                  />
                ) : editMode ? (
                  'Atualizar'
                ) : (
                  'Cadastrar'
                )}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }
}

CreatePerson.propTypes = {
  onSubmit: PropTypes.func,
  type: PropTypes.string,
  invalidFields: PropTypes.object,
  loading: PropTypes.bool,
  token: PropTypes.string
}

const mapStateToProps = (state) => ({
  token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
  push: (path) => dispatch(push(path))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatePerson)
