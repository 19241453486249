import axios from 'axios'
import { servers } from 'utils/config'

const ENV = process.env.REACT_APP_OMNI
const baseUrl = servers[ENV] || servers.local

const omniApi = axios.create({
  baseURL: baseUrl,
  timeout: 30000
})

omniApi.defaults.headers.post['Content-Type'] = 'application/json'

export default omniApi
