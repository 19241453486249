import styled from 'styled-components'

export const FullContainer = styled.div`  
  ${props => props.noPadding ? '' : 'padding: 15px;'}  
`

export const Container = styled.div`  
  height: auto;  
  border-radius: 20px;
  transition: all 0.65s ease;

  ${props => props.noPadding ? '' : `
    width: calc(100% - 13px);
    margin: 5px auto; 
    width: calc(100% - 13px);
    `}  
    margin-top: 0;
    ${props => props.noBg ? ''
    : `
    padding: 2px;
    background-color: #ffffff;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.18);    
  `
  };

${props => props.active ? `
    background-color: #010101;
    width: calc(100% + 5px) !important;
    height: calc(100% + 5px) !important;
    transform: translateY(-2px);
  ` : `
    width: calc(100% - 5px) !important;
    height: calc(100% - 5px) !important;
    `}
  
  ${props => props.center ? `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  ` : ''};

  &:hover {
    ${props => props.active ? '' : `
      background-color: #fafafa;
      cursor: pointer;
    `}
  }
`

export const Header = styled.div`  
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;    
  padding-left: 8px;
`

export const Title = styled.h1`
  color: #515151;
  font-size: ${props => props.titleSize ? props.titleSize : '2.3em'};    
  margin-bottom: 0;  
  
`
