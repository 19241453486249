import React, { Component, createRef } from 'react'
import { saveAs } from 'file-saver'

import { Grid, Modal } from 'semantic-ui-react'

import PropTypes from 'prop-types'
import swal from 'sweetalert'
import Button from 'components/Button'
import Select from 'components/Select'
import Inputmask from 'inputmask'
import Input from 'components/Input'
import Checkbox from 'components/Checkbox'
import { toDate } from 'utils/formaters'
import { connect } from 'react-redux'
import { Bounce } from 'react-activity'
import schedulesConsumer from 'consumers/schedule'
import indicationConsumer from 'consumers/indications'

import { push } from 'connected-react-router'
import Toggle from 'react-toggle'

import {
  showAlert,
  setSwalLoading,
  closeAllSwal,
  showLoading,
  showOptionSelect,
  showConfirmation,
  showDialling,
  showApproveNegate,
  showInput
} from 'store/modules/alert/actions'
import { FormatDateBr } from 'utils/formatDate'
import moment from 'moment'
import { validateData } from 'utils/validators'
import RichText from 'components/Richtext'
import RichTextEditor from 'react-rte'
import insurances from 'consumers/insurances'
import serviceTypes from 'consumers/serviceTypes'
import specialtiesConsumer from 'consumers/specialties'
import examsConsumer from 'consumers/exams'
import providersConsumer from 'consumers/providers'
import to from 'await-to-js'
import ScheduleCalendar from 'components/ScheduleCalendar'
import ModalScheduleTable from 'components/ModalScheduleTable'
import SelectedSchedulesTable from 'components/SelectedSchedulesTable'
import protocolConsumer from 'consumers/scheduleProtocol'
// import ScheduleDescriptionModal from 'components/ScheduleDescriptionModal'
import ProtocolViewModal from 'components/ProtocolViewModal'
class CreateScheduling extends Component {
  constructor (props) {
    super(props)

    this.state = {
      date: null,
      specialty: null,
      healthInsurance: null,
      healthInsuranceCode: null,
      healthInsurancePlan: null,
      wasInsuranceSet: false,
      type: null,
      description: RichTextEditor.createEmptyValue(),
      disabledButtonSave: true,
      errorsFront: {},
      scheduleCode: null,
      scheduleItemCode: null,
      mainScheduleCode: null,
      specialties: [],
      providers: [],
      filteredProviders: [],
      healthInsurances: [],
      healthInsurancePlans: [],
      scheduleTypes: [],
      availableSchedules: [],
      types: [],
      exams: [],
      schedules: [],
      schedule: '',
      loading: true,
      editSchedule: false,
      initialDataFilled: false,
      selectedDay: undefined,
      availableDys: [],
      fieldFilled: false,
      goBack: false,
      canGoOn: false,
      wasDateSelected: false,
      provider: null,
      calendarMonth: null,
      openSelectOneSchedule: false,
      openDescriptionModal: false,
      hourSchedules: [],
      totalModalPages: 0,
      modalPage: 0,
      selectedDate: null,
      mvUser: '',
      mvUserExists: false,
      selectedSchedules: [],
      isProtocolReady: null,
      protocolPdfFile: null,
      providerWhoIndidicatedCode: null,
      providerWhoIndidicatedName: null,
      wasIndicated: false,
      toggleIndicationSelect: false,
      toggleProviderIndication: false,
      isProviderIndication: false,
      allProviders: [],
      indicationTypeCode: null
    }

    this.dateRef = createRef()
    this.typeRef = createRef()
    this.especialityRef = createRef()
    this.providerRef = createRef()
    this.insuranceRef = createRef()
    this.insurancePlanRef = createRef()
    this.examRef = createRef()
    this.schedulesRef = createRef()
    this.scheduleRef = createRef()
    this.mvUserRef = createRef()
  }

  submit = async () => {
    const { mvId, showAlert, patient, patientId, setSwalLoading, closeAllSwal, showApproveNegate } = this.props

    const {
      date,
      scheduleCode,
      specialty,
      healthInsurance,
      healthInsurancePlan,
      type,
      exam,
      description,
      mainScheduleCode,
      mvUser,
      selectedSchedules,
      providerWhoIndidicatedCode,
      providerWhoIndidicatedName,
      indicationTypeCode
    } = this.state

    swal({
      title: 'Realizar o agendamento ?',
      icon: 'warning',
      buttons: {
        cancel: 'Não',
        yes: {
          text: 'Sim',
          value: 'Sim'
        }
      },
      dangerMode: false
    })
      .then(async (willDelete) => {
        if (willDelete) {
          const datesData = {
            date: FormatDateBr(date),
            healthInsurance,
            healthInsurancePlan,
            type
          }

          if (exam) datesData.exam = exam
          if (specialty) datesData.specialty = specialty

          const errorsFront = validateData(datesData)
          this.setState({ errorsFront })
          const operatorId = localStorage.getItem('@Agenda/userId')
          const updatedSelectedSchedules = selectedSchedules.map((schedule) => {
            return {
              ...schedule,
              operatorId: operatorId,
              attendanceCode: type,
              schedulingItemCode: exam,
              providerWhoIndidicatedCode: providerWhoIndidicatedCode,
              providerWhoIndidicatedName: providerWhoIndidicatedName,
              indicationTypeCode: indicationTypeCode
            }
          })
          if (selectedSchedules.length) {
            setSwalLoading()
            const [responseError, response] = await to(schedulesConsumer.insertPatient(updatedSelectedSchedules))

            if (responseError) {
              console.log('ERRO', responseError)
              return showAlert('error', 'Não foi possível agendar paciente')
            }
            return showAlert('success', 'Paciente agendado com sucesso', '', '', async () => {
              setSwalLoading()
              const [protcolResponseError, protocolResponse] = await to(protocolConsumer.create({
                scheduleItemCode: updatedSelectedSchedules[0].scheduleItemCode,
                patientCode: updatedSelectedSchedules[0].patientCode
              }))
              closeAllSwal()
              if (protcolResponseError || !protocolResponse.pdfUrl) {
                return showAlert('error', 'Erro ao gerar protocolo')
              }
              setSwalLoading()

              await this.setState({
                protocolPdfFile: protocolResponse.pdfUrl
              }, () => {
                this.setState({
                  isProtocolReady: true
                }, async () => {
                  closeAllSwal()
                  await this.getSchedules()
                })
              })
            })
          }
          return showAlert('error', 'Não há agenda selecionada')
        } else {

        }
      })
  }

  update = async () => {
    const { onUpdate, showAlert } = this.props

    const {
      initialState,
      date,
      schedule,
      specialty,
      exam,
      healthInsurance,
      type,
      description
    } = this.state

    const data = {}
    const changedData = {}

    if (date && date !== initialState.date) {
      data.date = FormatDateBr(date)
    }

    if (schedule && schedule !== initialState.schedule) {
      data.schedule = schedule
    }

    if (specialty && specialty !== initialState.specialty) {
      changedData.specialtyId = specialty
    }

    if (exam && exam !== initialState.exam) {
      changedData.examId = exam
    }

    if (healthInsurance && healthInsurance !== initialState.healthInsurance) {
      changedData.insuranceId = healthInsurance
    }

    if (type && type !== initialState.type) {
      changedData.consultType = type
    }

    if (description && description.toString('html') !== initialState.description.toString('html')) {
      changedData.description = description.toString('html')
    }

    if (data.date) {
      if (!data.schedule) {
        changedData.date = toDate(data.date, initialState.schedule)
      } else {
        changedData.date = toDate(data.date, data.schedule)
      }
    }

    if (data.schedule) {
      if (!data.date) {
        changedData.date = toDate(initialState.date, data.schedule)
      } else {
        changedData.date = toDate(data.date, data.schedule)
      }
    }

    const errorsFront = validateData(data)
    this.setState({ errorsFront })

    if (Object.keys(errorsFront).length <= 0) {
      this.setState({ loading: true, errorsFront: {} })
      if (Object.keys(changedData).length > 0) {
        await onUpdate(changedData)
      } else {
        showAlert(400, 'Atencão', 'Você não alterou nenhum dado')
      }
      this.setState({ loading: false })
    }
  }

  getProviders = async (specialtyCode) => {
    const providers = await providersConsumer.get({ specialtyCode: specialtyCode })
    this.setState({ providers })
  }

  getAllProviders = async () => {
    const { setSwalLoading, closeAllSwal } = this.props
    setSwalLoading()
    const response = await providersConsumer.get()
    this.setState({ allProviders: response }, () => {
      closeAllSwal()
    })
  }

  getSpecialties = async (token) => {
    const specialties = await specialtiesConsumer.get()
    this.setState({ specialties: specialties })
  }

  getHealthInsurances = async (token) => {
    const healthInsurances = await insurances.getHealthInsurances()
    this.setState({ healthInsurances: healthInsurances })
  }

  getHealthInsurancePlans = async (code) => {
    const healthInsurancePlans = await insurances.getInsurancePlans(code)
    this.setState({ healthInsurancePlans: healthInsurancePlans })
  }

  getExams = async (token) => {
    const exams = await examsConsumer.get(token)
    this.setState({ exams })
  }

  getTypes = async () => {
    const response = await serviceTypes.getServiceTypes()
    this.setState({ types: response })
  }

  clearSchedule = async () => {
    this.typeRef.current.state.value = ''
    this.especialityRef.current.state.value = ''
    this.insuranceRef.current.state.value = ''
    this.scheduleRef.current.value = ''
    this.examRef.current.state.value = ''

    this.setState({
      date: null,
      schedule: null,
      specialty: null,
      healthInsurance: null,
      type: null,
      exam: null,
      description: RichTextEditor.createEmptyValue()
    })
  }

  handleInsuranceSelection = async (e) => {
    const selectedSchedules = this.state.selectedSchedules
    if (selectedSchedules.length) {
      const updatedSelectedSchedules = selectedSchedules.map((schedule) => ({ ...schedule, insuranceCode: e.value }))
      this.setState({
        selectedSchedules: updatedSelectedSchedules
      })
    }

    this.insurancePlanRef.current.state.value = ''
    this.setState({
      loading: true
    })
    setSwalLoading()
    await this.setState({ healthInsurance: e.value, disabledButtonSave: false })
    await this.getHealthInsurancePlans(this.state.healthInsurance)
    await this.setState({
      wasInsuranceSet: true,
      loading: false
    })
    closeAllSwal()
  }

  handleInsurancePlanSelection = (e) => {
    const selectedSchedules = this.state.selectedSchedules
    if (selectedSchedules.length) {
      const updatedSelectedSchedules = selectedSchedules.map((schedule) => ({ ...schedule, insurancePlanCode: e.value }))
      this.setState({
        selectedSchedules: updatedSelectedSchedules
      })
    }
  }

  setDescription = (value) => {
    this.setState({ description: value })
  }

  setCanGoOn = () => {
    const specialty = this.especialityRef.current.state.value
    const type = this.typeRef.current.state.value
    const insurance = this.insuranceRef.current.state.value
    const insurancePlan = this.insurancePlanRef.current.state.value
    const exam = this.examRef.current.state.value

    // IMPLEMENTAR DEPOIS

    // const mvUser = this.mvUserRef.current.value

    if (type || (insurance && insurancePlan) || (specialty || exam)) {
      this.setState({ canGoOn: true })
    }
  }

  handleScheduleSelection = (date) => {
    this.getSchedules(date)
  }

  getSchedules = async (date) => {
    const { provider, specialty, exam } = this.state
    console.log('PROVIDER', provider)
    const { setSwalLoading, closeAllSwal, showAlert } = this.props

    const formattedDate = moment(date).format('DD-MM-YYYY')
    setSwalLoading()
    const [responseError, availableSchedules] = await to(schedulesConsumer.listOracle({ month: formattedDate, provider, specialty }))

    if (responseError) {
      console.log(responseError)
      showAlert('error', 'Houve um erro')
    }
    closeAllSwal()

    this.setState({ availableSchedules }, async () => {
      if (!this.state.availableSchedules.length) {
        showAlert('warning', 'Nenhuma agenda encontrada !')
      }
    })
  }

  getSchedulesByDay = async (date) => {
    const [responseError, response] = await to(schedulesConsumer.listOracle({ date }))
    if (responseError) {
      console.log(responseError)
    }

    const formattedSchedules = response.result.data.result.map((schedule) => ({ start: String(schedule), AllDay: true, title: 'Livre' }))
    console.log('AGENDAS FORMATADAS', formattedSchedules)

    this.setState({
      availableSchedules: formattedSchedules
    })
  }

  daySchedulesQuery = async ({ date, page }) => {
    const { exam, provider, specialty } = this.state
    return {
      date,
      page,
      examItem: exam,
      provider,
      specialty
    }
  }

  getSoulSchedulesDay = async (date, page) => {
    const { showAlert, setSwalLoading, closeAllSwal } = this.props
    const { exam, specialty } = this.state
    this.setState({ selectedDate: date })

    if (exam === null || exam === '') {
      return showAlert(422, 'Atenção', 'Selecione o item de agendamento.')
    }

    const fullQuery = await this.daySchedulesQuery({ date, page, specialty })
    setSwalLoading()
    try {
      const response = await schedulesConsumer.schedulesByDay(fullQuery)
      const hourSchedules = response.data
      console.log('AGENDAS', hourSchedules.schedules)
      this.setState({
        openSelectOneSchedule: true,
        hourSchedules: hourSchedules.schedules,
        totalModalPages: hourSchedules.totalPages,
        modalPage: hourSchedules.page

      })
      closeAllSwal()
    } catch (err) {
      closeAllSwal()
      if (err.code >= 400 && err.code <= 499) {
        showAlert(err.code, 'Atenção', err.message)
      }
    }
  }

  selectSchedule = async (schedule) => {
    console.log('PROVIDER CD', schedule.providerCode)
    const {
      date,
      // schedule:schedule.itCodigoAgendaCentral,
      specialty,
      healthInsurance,
      healthInsurancePlan,
      type,
      exam,
      provider,
      mvUser,
      indicationTypeCode,
      providerWhoIndicatedCode,
      providerWhoIndicatedName

    } = this.state
    const { patient, patientId, operatorId, mvId } = this.props
    console.log('PROPS', this.props)
    this.setState((prevState) => {
      return {
        scheduleItemCode: schedule.scheduleItemCode,
        openSelectOneSchedule: false,
        mainScheduleCode: schedule.mainScheduleCode,
        date: schedule.scheduleDate,
        selectedSchedules: [{
          patientCode: Number(mvId),
          operatorId: operatorId,
          cellphone: patient.cellphone,
          email: patient.email,
          patientMongoId: patientId,
          mvUser: mvUser,
          scheduleItemCode: schedule.scheduleItemCode,
          mainScheduleCode: schedule.mainScheduleCode,
          scheduleDate: schedule.scheduleDate,
          providerName: schedule.providerName,
          providerType: schedule.providerType,
          attendanceUnit: schedule.attendanceUnit,
          specialtyCode: schedule.specialtyCode,
          insuranceCode: healthInsurance,
          providerCode: schedule.providerCode,
          insurancePlanCode: healthInsurancePlan,
          indicationTypeCode: indicationTypeCode,
          providerWhoIndicatedCode: providerWhoIndicatedCode,
          providerWhoIndicatedName: providerWhoIndicatedName,
          description: ''
        }]
      }
    }, async () => {
      setSwalLoading()
      await this.getSchedules()
      closeAllSwal()
    })
    console.log('AGENDAS SELECIONADAS', this.state.selectedSchedules)
  }

  removeSchedule = async (selectedScheduleCode) => {
    console.log('CODIGO ITEM AGENDA', this.state.selectedSchedules)
    const filteredSchedules = this.state.selectedSchedules.filter((schedule) => schedule.scheduleItemCode !== selectedScheduleCode)
    this.setState({
      selectedSchedules: filteredSchedules
    }, async () => {
      setSwalLoading()
      await this.getSchedules()
      closeAllSwal()
    })
  }

  openScheduleDescriptionModal = async (schedule) => {
    this.setState({
      openDescriptionModal: true
    })
  }

  setScheduleDescription = async (schedule, description) => {
    const { mvId } = this.props
    const { mvUser } = this.state

    console.log('ESTADO ATUAL DA AGENDA', schedule)
    const filteredSchedules = this.state.selectedSchedules.filter((scheduleElement) => scheduleElement.scheduleItemCode !== schedule.scheduleItemCode)
    const updtatedSchedules = [...filteredSchedules, {
      ...schedule,
      description: description,
      mvUser: this.state.mvUser,
      patientCode: Number(mvId)

    }]

    this.setState({
      selectedSchedules: updtatedSchedules
    })
  }

  handleIndicationSelection = (e) => {
    console.log('INDICATION TYPE SELECTED', e)
    this.setState({ indicationTypeCode: e.value }, () => {
      if (e.label === 'MEDICO') {
        this.setState((prevState) => {
          this.setState({ toggleProviderIndication: !prevState.toggleProviderIndication })
        })
        if (!this.state.isProviderIndication) {
          this.setState({ isProviderIndication: true })
          this.getAllProviders()
        }
      } else {
        this.setState({ toggleProviderIndication: false })
      }
    })
  }

 handleToggleProviderIndication = () => {
   this.setState((prevState) => ({ wasIndicated: true, toggleIndicationSelect: !prevState.toggleIndicationSelect }), () => {
     if (!this.state.toggleIndicationSelect) {
       this.setState({ toggleProviderIndication: false })
     }
   })
 }

  getIndicationTypes = async () => {
    const response = await indicationConsumer.getServiceTypes()

    this.setState({
      indicationTypes: response
    })
  }

  componentDidMount = async () => {
    const { token, match, showAlert, setSwalLoading, closeAllSwal } = this.props

    this.setState({
      loading: true
    })

    if (this.state.providers.length === 0) {
      setSwalLoading()
      try {
        console.log('DATA QUE FUNCIONA', new Date().toISOString().replace(/T.*$/, ''))
        await Promise.all([
          this.getSpecialties(token),
          this.getExams(token),
          this.getHealthInsurances(),
          this.getTypes(),
          this.getHealthInsurancePlans(),
          this.getIndicationTypes()

        ])

        closeAllSwal()
      } catch (e) {
        if (this.state.types.length !== 0) {
          this.setState({
            loading: false
          })

          showAlert('error', e.message)
        }
      }
    }

    if (this.state.types.length !== 0) {
      this.setState({
        loading: false
      })

      closeAllSwal()
    }

    if (match) {
      const { id } = match.params
      if (id) {
        this.getSchedule(id)
      }
    }

    const mvUser = localStorage.getItem('@Agenda/mvUser')

    if (mvUser !== 'undefined' && mvUser !== 'null' && mvUser !== null) {
      await this.setState({
        mvUserExists: true
      })
      await this.setState({
        mvUser: mvUser
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.wasIndicated !== this.state.wasIndicated) {
      if (prevState.wasIndicated) {
        this.setState({ allProviders: [] })
      }
    }
  }

  render () {
    const { errorMessages, match, setSwalLoading, showAlert, showConfirmation, showApproveNegate, closeAllSwal } = this.props
    const {
      selectedDate,
      modalPage,
      hourSchedules,
      totalModalPages,
      openSelectOneSchedule,
      healthInsurances,
      healthInsurancePlans,
      specialties,
      types,
      exams,
      loading,
      editSchedule,
      schedule,
      disabledButtonSave,
      errorsFront,
      schedules,
      filteredProviders,
      openDescriptionModal,
      isProtocolReady,
      providers
    } = this.state
    Inputmask().mask(document.querySelectorAll('input'))

    if (!schedule && match) {
      return (
        <Grid centered>
          <Grid.Row>
            <Bounce
              className='bounce-margin'
              color='#212121'
              size={22}
              speed={1}
              animating={true}
            />
          </Grid.Row>
        </Grid>
      )
    }

    return (
      <>
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column width={1} />

            <Grid.Column width={4}>

              <Select
                inputRef={this.examRef}
                label='Exame'
                options={exams}
                onChange={(e) => {
                  this.setState({ exam: e.value, disabledButtonSave: false })
                  this.setCanGoOn()
                }}
                error={errorMessages.exam || errorsFront.exam}
                placeholder='Selecione... '
                onBlur={(e) => this.setCanGoOn(e.target.value)}

              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Select
                inputRef={this.especialityRef}
                label='Especialidade do prestador'
                options={specialties}
                onChange={(e) => {
                  this.setState({ specialty: e.value, disabledButtonSave: false })
                  console.log(this.providerRef)

                  this.getProviders(e.value)
                }}
                error={errorMessages.specialty || errorsFront.specialty}
                placeholder='Selecione... '

              />

            </Grid.Column>

            <Grid.Column width={4}>
              <Select
                inputRef={this.providerRef}
                label='Prestador'
                options={providers}
                placeholder='Selecione... '
                onChange={(e) => {
                  if (e != null) {
                    this.setState({ provider: e.value, disabledButtonSave: false })
                    this.setCanGoOn()
                  } else {
                    this.setState({ provider: undefined, disabledButtonSave: true })
                  }
                }}
                error={errorMessages.provider || errorsFront.provider}
                disabled={!this.state.specialty}

              />
            </Grid.Column>
            <Grid.Column width={1} />

          </Grid.Row>
          {/* sgunda */}
          <Grid.Row>
            <Grid.Column width={1} />

            <Grid.Column width={4}>
              <Select
                inputRef={this.typeRef}
                label='Tipo'
                options={types}
                placeholder='Selecione... '
                onChange={(e) => {
                  this.setState({ type: e.value, disabledButtonSave: false })
                  this.setCanGoOn()
                }}
                error={errorMessages.type || errorsFront.type}

              />
            </Grid.Column>

            <Grid.Column width={4}>
              <Select
                inputRef={this.insuranceRef}
                label='Convênio'
                options={healthInsurances}
                onChange={(e) => {
                  this.handleInsuranceSelection(e)
                }}

                error={errorMessages.healthInsurance || errorsFront.healthInsurance}
                placeholder='Selecione... '
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Select
                inputRef={this.insurancePlanRef}
                label='Plano'
                options={healthInsurancePlans}
                onChange={(e) => {
                  this.setState({ healthInsurancePlan: e.value, disabledButtonSave: false })
                  this.handleInsurancePlanSelection(e)
                }}
                error={errorMessages.healthInsurancePlan || errorsFront.healthInsurance}
                placeholder='Selecione ... '
                disabled={!this.state.wasInsuranceSet}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ display: 'flex', alignItems: 'center' }}>
            <Grid.Column width={1} />
            <Grid.Column width={4}>
              <Input
                inputRef={this.mvUserRef}
                label='Login MvSoul'
                onChange={(e) => {
                  this.setState({ mvUser: e.target.value.toUpperCase(), disabledButtonSave: false })
                }}
                error={errorMessages.mvUser || errorsFront.mvUser}
                placeholder='Insira seu login '
                disabled={this.state.mvUserExists}
                value={this.state.mvUser.toUpperCase()}
              />
            </Grid.Column>

            <Grid.Column width={4}>
              <div className='align-toggle' style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                <Toggle
                  id='age-check'
                  defaultChecked={false}
                  onChange={this.handleToggleProviderIndication}
                />
                <label htmlFor="indication-check">Indicação</label>
              </div>
            </Grid.Column>
            { this.state.toggleIndicationSelect
              ? <>
                <Grid.Column width={4}>
                  <Select
                    inputRef={this.insuranceRef}
                    label='Tipo de Indicação'
                    options={this.state.indicationTypes}
                    onChange={this.handleIndicationSelection}
                    placeholder='Selecione... '
                  />
                </Grid.Column>
              </>
              : '' }
          </Grid.Row>
          <Grid.Row>
            { this.state.toggleProviderIndication
              ? <>
                <Grid.Column width={1}/>
                <Grid.Column width={4}>
                  <Select
                    inputRef={this.insuranceRef}
                    label='Prestador Que Indicou'
                    options={this.state.allProviders}
                    onChange={(e) => {
                      this.setState({ providerWhoIndidicatedCode: e.value, providerWhoIndicatedName: e.label })
                    }}
                    placeholder='Selecione... '
                  />
                </Grid.Column>
              </>
              : this.state.indicationTypeCode
                ? <>
                  <Grid.Column width={1}/>
                  <Grid.Column width={4}>
                    <Input
                      inputRef={this.insuranceRef}
                      label='Nome de quem indicou'
                      onChange={(e) => {
                        this.setState({ providerWhoIndidicatedCode: undefined, providerWhoIndicatedName: e.target.value })
                      }}
                      value={this.state.providerWhoIndidicatedName}
                      placeholder='Selecione... '
                    />
                  </Grid.Column>
                </> : ''}
          </Grid.Row>

          <Grid.Row style={{ display: 'flex', alignItems: 'center' }}>

            <Grid.Column width={15} textAlign='right'>

              <div className='uk-text-right uk-margin'>
                <Button filled onClick={() => {
                  this.setState({
                    initialDataFilled: true
                  })
                  this.getSchedules(moment().toDate())
                }}>
                  {loading ? (
                    <Bounce
                      className='bounce-margin'
                      color='#FCFCFC'
                      size={22}
                      speed={1}
                      animating={true}
                    />
                  ) : 'Filtrar'}
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={1} />
            <Grid.Column width={14}>
              <SelectedSchedulesTable single ={true} selectedSchedules={this.state.selectedSchedules}
                removeSchedule={this.removeSchedule}
                openScheduleDescriptionModal={this.openScheduleDescriptionModal}
                setScheduleDescription={this.setScheduleDescription}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
          : <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column width={1} ></Grid.Column>
            <Grid.Column width={14}>
              { this.state.availableSchedules.length && (this.state.specialty || this.state.provider) ? <ScheduleCalendar
                schedules={this.state.availableSchedules}
                onDateSelect={this.getSoulSchedulesDay}
              /> : ''}
              <ModalScheduleTable
                open={openSelectOneSchedule}
                onClose={() => this.setState({ openSelectOneSchedule: false })}
                hourSchedules={hourSchedules}
                getSoulSchedules={this.getSoulSchedulesDay}
                selectSchedule={this.selectSchedule}
                changePage={this.getSoulSchedulesDay}
                totalPages={totalModalPages}
                activePage={modalPage}
                selectedDate={selectedDate}
              />
              {/* { isProtocolReady ? <ProtocolViewModal open={isProtocolReady} pdfFile={this.state.protocolPdfFile} onClose={() => this.setState({ isProtocolReady: false })}/> : '' } */}
              <Modal open={isProtocolReady} centered closeIcon onClose={() => this.setState({ isProtocolReady: false })} size={'small'}>
                <Modal.Content >
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={'15'} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={() => window.open(this.state.protocolPdfFile, '_blank')}>
                      Visualizar Protocolo
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Modal.Content></Modal>
            </Grid.Column>
            <Grid.Column width={1} />
          </Grid.Row>
          {/* sgunda */}
          <Grid.Row>
            <Grid.Column width={1} />
            <Grid.Column width={1} />
          </Grid.Row>
          {this.state.selectedSchedules.length ? <Grid.Row>
            <Grid.Column width={16} textAlign='right' >
              <div className='uk-text-right uk-margin'>
                <>
                  <Button filled onClick={this.submit} disabled={loading}>
                    {loading ? (
                      <Bounce
                        className='bounce-margin'
                        color='#FCFCFC'
                        size={22}
                        speed={1}
                        animating={true}
                      />
                    ) : (
                      'Enviar'
                    )}
                  </Button>
                </>
              </div>
            </Grid.Column>
          </Grid.Row> : ''}
        </Grid>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  patientId: state.patient.id,
  mvId: state.patient.mvId,
  token: state.auth.token,
  router: state.router,
  patient: state.patient
})

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path)),
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal()),
  showApproveNegate: () => dispatch(showApproveNegate()),
  showConfirmation: () => dispatch(showConfirmation()),
  showDialling: () => dispatch(showDialling()),
  showOptionSelect: () => dispatch(showOptionSelect())

})

CreateScheduling.propTypes = {
  patientId: PropTypes.string,
  mvId: PropTypes.string,
  token: PropTypes.string,
  router: PropTypes.string,
  errorMessages: PropTypes.object,
  match: PropTypes.object,
  push: PropTypes.func,
  showAlert: PropTypes.func,
  onUpdate: PropTypes.func,
  setSwalLoading: PropTypes.func,
  closeAllSwal: PropTypes.func

}

export default connect(mapStateToProps, mapDispatchToProps)(CreateScheduling)
