import React, { Component } from 'react'
import { connect } from 'react-redux'
import ClickToCall from 'components/ClickToCall'
import { Table, Dropdown, Grid } from 'semantic-ui-react'
import { dateToBrSmall } from 'utils/formatDate'
import { formatPhone } from 'utils/formaters'
import { push } from 'connected-react-router'
import { Bounce } from 'react-activity'
import { getFirstAndLastWord, titleCase } from 'utils/stringMethods'
import IconButton from 'components/IconButton'

export class ListRecord extends Component {
  constructor (props) {
    super(props)

    this.state = {
      expandName: ''
    }
  }

  searchPhone = async (phone) => {
    const { push } = this.props

    push(`/admin/pacientes?phone=${phone}`)
  }

  toggleExpandName = () => this.setState({ expandName: !this.state.expandName })

  render () {
    const { recordings } = this.props
    const { expandName } = this.state
    if (!recordings) {
      return (
        <Grid centered>
          <Grid.Row>
            <Bounce
              className='bounce-margin'
              color='#212121'
              size={22}
              speed={1}
              animating={true}
            />
          </Grid.Row>
        </Grid>
      )
    }

    return (
      <>
        <Table celled striped padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Ramal</Table.HeaderCell>
              <Table.HeaderCell>Operador <IconButton basic dark small onClick={this.toggleExpandName} icon={expandName ? 'crop' : 'expand'} /></Table.HeaderCell>
              <Table.HeaderCell>Telefone</Table.HeaderCell>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {/* TODO: Pegar valores corretos */}
            {recordings.map((recording, index) => (
              <Table.Row key={index}>

                <Table.Cell>{recording.ramal}</Table.Cell>
                <Table.Cell>{ expandName ? titleCase(recording.userName) : getFirstAndLastWord(titleCase(recording.userName))}</Table.Cell>
                <Table.Cell>
                  <ClickToCall
                    label={formatPhone(recording.phone)}
                    to={recording.phone}
                  />
                </Table.Cell>
                <Table.Cell>{dateToBrSmall(recording.createdAt)}</Table.Cell>
                <Table.Cell>
                  <Dropdown text='Ações' button item>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        disabled={!recording.phone}
                        // FIXME: modar id quando o backend for trocado
                        onClick={() => this.searchPhone(recording.phone)}>
                        Buscar Paciente
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                        disabled={!recording.phone}
                        // FIXME: modar id quando o backend for trocado
                        onClick={() =>
                          this.listenRecording(recording.recordingLink)
                        }>
                        Ouvir
                              </Dropdown.Item> */}
                      <Dropdown.Item
                        disabled={!recording.phone}>
                        <a
                          className="dropdown-item"
                          href={String(recording.recordingLink).replace('152.249.238.124','10.10.0.10')}
                          target='_blank'
                          rel='noopener noreferrer'
                          download={`${recording.ramal}-${recording.phone}-${recording.createdAt}.mp3`}>
                          Download
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>

              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  router: state.router
})

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListRecord)
