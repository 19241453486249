import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'
import { dateToBrSmall } from 'utils/formatDate'
import PropTypes from 'prop-types'
import ScheduleDescriptionModal from 'components/ScheduleDescriptionModal'
import Button from 'components/Button'

function SelectedSchedulesTable ({
  selectedSchedules,
  removeSchedule,
  setScheduleDescription,
  single,
  many,
  initialDescription,
  getDescription

}) {
  const [isDescriptionModalVisible, setIsDescriptionModalVisible] = useState(false)
  const [selectedSchedule, setSelectedSchedule] = useState()
  const [selectedScheduleDescription, setSelectedScheduleDescription] = useState('')
  console.log('DESCRIÇÃO DA AGENDA SLEECIONADA', selectedScheduleDescription)
  return (
    <>
      <>
        {single && selectedSchedules.length ? (
          <>
            <h1 style={{ textAlign: 'center' }}>Agenda Selecionada</h1>
            <div style={{ height: 200 }}>
              <>
                <Table>
                  <Table.Header style={{ position: 'sticky', top: 0 }}>
                    <Table.Row>
                      <Table.HeaderCell>Agenda</Table.HeaderCell>
                      <Table.HeaderCell>Hora agenda</Table.HeaderCell>
                      <Table.HeaderCell>Nome prestador</Table.HeaderCell>
                      <Table.HeaderCell>Tipo prestador</Table.HeaderCell>
                      <Table.HeaderCell>Sigla unidade</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row key={selectedSchedules[0].scheduleItemCode}>
                      <Table.Cell>{selectedSchedules[0].mainScheduleCode}</Table.Cell>
                      <Table.Cell>{dateToBrSmall(selectedSchedules[0].scheduleDate)}</Table.Cell>
                      <Table.Cell>{selectedSchedules[0].providerName}</Table.Cell>
                      <Table.Cell>{selectedSchedules[0].providerType}</Table.Cell>
                      <Table.Cell>{selectedSchedules[0].attendanceUnit}</Table.Cell>
                      <Table.Cell><Button filled onClick={() => {
                        setIsDescriptionModalVisible(true)
                        setSelectedSchedule(selectedSchedules[0])
                        console.log('DESCRIÇÃO DA AGENDA SELECIONADA', selectedSchedules[0].description)
                        setSelectedScheduleDescription(selectedSchedules[0].description)
                      }}>Adicionar Descrição</Button> <Button filled onClick={() => removeSchedule(selectedSchedules[0].scheduleItemCode)}>Remover</Button></Table.Cell>
                      <ScheduleDescriptionModal
                        open={isDescriptionModalVisible}
                        onClose={() => setIsDescriptionModalVisible(false)}
                        scheduleDescription={initialDescription || selectedSchedules[0].description}
                        schedule={selectedSchedule}
                        setScheduleDescription={setScheduleDescription}
                        getDescription={getDescription}
                      />
                    </Table.Row>

                  </Table.Body>
                </Table>
              </>
            </div>
          </>

        ) : (
          ''
        )}
      </>
      <>
        {selectedSchedules && selectedSchedules.length > 0 && !single && many ? (
          <>
            <h1 style={{ textAlign: 'center' }}>Agendas Selecionadas</h1>
            <div style={{ height: 200, overflowY: 'scroll' }}>
              <>
                <Table>
                  <Table.Header style={{ position: 'sticky', top: 0 }}>
                    <Table.Row>
                      <Table.HeaderCell>Agenda</Table.HeaderCell>
                      <Table.HeaderCell>Hora agenda</Table.HeaderCell>
                      <Table.HeaderCell>Nome prestador</Table.HeaderCell>
                      <Table.HeaderCell>Tipo prestador</Table.HeaderCell>
                      <Table.HeaderCell>Sigla unidade</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {selectedSchedules.map((element) => (
                      <Table.Row key={element.scheduleItemCode}>
                        <Table.Cell>{element.mainScheduleCode}</Table.Cell>
                        <Table.Cell>{dateToBrSmall(element.scheduleDate)}</Table.Cell>
                        <Table.Cell>{element.providerName}</Table.Cell>
                        <Table.Cell>{element.providerType}</Table.Cell>
                        <Table.Cell>{element.attendanceUnit}</Table.Cell>
                        <Table.Cell><Button filled onClick={() => {
                          setIsDescriptionModalVisible(true)
                          setSelectedSchedule(element)
                          console.log('DESCRIÇÃO DA AGENDA SELECIONADA', element.description)
                          setSelectedScheduleDescription(element.description)
                        }}>Adicionar Descrição</Button> <Button filled onClick={() => removeSchedule(element.scheduleItemCode)}>Remover</Button></Table.Cell>
                        <ScheduleDescriptionModal
                          open={isDescriptionModalVisible}
                          onClose={() => setIsDescriptionModalVisible(false)}
                          scheduleDescription={element.description}
                          schedule={selectedSchedule}
                          setScheduleDescription={setScheduleDescription}
                        />
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </>
            </div>
          </>

        ) : (
          ''
        )}
      </>
    </>
  )
}

SelectedSchedulesTable.propTypes = {
  selectedSchedules: PropTypes.array,
  removeSchedule: PropTypes.func,
  openScheduleDescriptionModal: PropTypes.func,
  setScheduleDescription: PropTypes.func,
  selectedDate: PropTypes.string,
  single: PropTypes.bool,
  many: PropTypes.bool

}

export default SelectedSchedulesTable
