import styled from 'styled-components'

export const WrapperIcon = styled.div`
    flex-grow: 0.25;
    width: 30%;
    background-color: ${props => props.color ? props.color : '#dfdfdf'};
`

export const WrapperContent = styled.div`
    flexDirection: collumn;
    flex-grow: 1;        
`

export const Totals = styled.p`
    font-size: 1.2rem;    
    margin-bottom: 0;
    text-align: center;
    color: #ffffff;

    font-size: .9rem;    
`

export const TotalsNumber = styled.p`
    font-size: 1.4rem;    
    margin-bottom: 0;
    text-align: center;
    color: #ffffff;
    font-weight: bold;

    @media only screen and (max-width: 1440px) {        
        font-size: 1.1rem;    
    }
`

export const ContentChannel = styled.div`
    padding: 10px 10px 10px 0;

    @media only screen and (max-width: 1440px) {        
        padding: 5px 5px 5px 0;        
    }    
      
`

export const Border = styled.div`
    background-color: rgba(0,0,0, 0.95);
    border-radius: 10px;
    padding: 5px 15px;
    display: flex;
    flex-direction: column;    
`

export const SubTotals = styled.p`
    font-size: 1.2rem;
    margin-bottom: 0;
    margin-right: 10px;   
    
    @media only screen and (max-width: 1440px) {        
        margin-right: 5px;   
        font-size: .9rem;
    }    
`

export const LineBody = styled.div`
    display: flex;
    justify-content: space-between;
`

export const Header = styled.div`
    border-bottom: 1px solid #00000026;
    text-align: center;
    padding: 5px 0px;
    font-size: 1.4rem;    
    background: #efefef;

    @media only screen and (max-width: 1440px) {        
        font-size: 1.1rem;
    }   
`

export const ContentTotal = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
`

export const ContentTotalBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 25px;
    justify-content: center;

    @media only screen and (max-width: 1440px) {        
        padding-left: 15px;
    }
`

export const Content = styled.div`
    text-align: left;    
    padding: 15px;

    @media only screen and (max-width: 1440px) {        
        padding: 5px;
    }
`

export const Item = styled.div`
    margin-bottom: 5px;
    flex: 1;
    display: flex;
    justify-content: center;
`

export const ItemIcon = styled.div`
    display: flex;
    justify-content: center;    
    flex: 1;
`

export const LineHeaderBody = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
`
