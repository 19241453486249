import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import renderHTML from 'react-render-html'

import { CardInfo, Notify } from './styled'
import { showAlert } from 'store/modules/alert/actions'

function NotificationButton ({ title, body, active, read, children, ...rest }) {
  return (
    <CardInfo active={active} {...rest}>
      <h2>{title}</h2>
      <p>{renderHTML(body)}</p>
      {read === false ? <Notify><div/></Notify> : ''}
      {children}
    </CardInfo>
  )
}

NotificationButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string
  ]),
  title: PropTypes.string,
  body: PropTypes.string,
  active: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notification.notifications
  }
}

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) =>
    dispatch(showAlert(type, title, message, details, func))
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationButton)
