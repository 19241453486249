import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import user from 'consumers/user'

import { Bounce } from 'react-activity'
import { showAlert, showLoading } from 'store/modules/alert/actions'
import { push } from 'connected-react-router'
import { Grid, Image, Modal, Header } from 'semantic-ui-react'
import inputmask from 'inputmask'

import { titleCase } from 'utils/stringMethods'
import { onlyDigits } from 'utils/onlyDigits'
import { FormatDateBr, formatDate } from 'utils/formatDate'
import { formatPhone } from 'utils/formaters'

import { Container } from 'components/ImageClick/styled'
import EmailModal from 'components/EmailModal'
import Button from 'components/Button'
import Input from 'components/Input'
import Card from 'components/Card'
import Upload from 'components/Upload'

export class Schedule extends Component {
  constructor (props) {
    super(props)

    this.state = {
      userId: undefined,
      name: '',
      birthdate: '',
      cellphone: '',
      telephone: '',
      selfie: '',
      selfiePreview: '',
      selfieShow: '',
      open: false,
      change: false,
      errorMessages: {},
      loading: false
    }

    this.nameRef = createRef()
    this.birthdateRef = createRef()
    this.cellphoneRef = createRef()
    this.telephoneRef = createRef()
  }

  getUser = async () => {
    const { token, showAlert } = this.props

    try {
      const response = await user.getUser(token)
      this.setState({ userId: response.id, selfieShow: response.selfie })
      this.nameRef.current.value = titleCase(response.name) || ''
      this.birthdateRef.current.value = FormatDateBr(response.birthdate) || ''
      this.cellphoneRef.current.value = formatPhone(response.cellphone) || ''
      this.telephoneRef.current.value = formatPhone(response.telephone) || ''
    } catch (err) {
      if (!err.response) {
        return showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
      }
      showAlert(400, 'Atenção', err.message, err.technical)
      this.setState({ userStats: undefined })
    }
  }

  submit = async () => {
    const { token, showAlert } = this.props

    const {
      name,
      cellphone,
      telephone,
      birthdate,
      change
    } = this.state

    this.setState({ loading: true })
    if (!change) {
      this.setState({ loading: false })
      return showAlert(400, 'Atenção!', 'Pelo menos um dado precisa ser alterado.')
    }

    const data = {
      name,
      birthdate: formatDate(birthdate),
      cellphone: onlyDigits(cellphone),
      telephone: onlyDigits(telephone)
    }

    try {
      const response = await user.update(token, data)
      showAlert(response.code, 'Sucesso!', 'Os dados foram alterados.')
      this.cleanStates()
    } catch (err) {
      if (err.invalid) {
        const errorMessages = {}
        err.invalid.forEach((field) => {
          errorMessages[field.name] = field.message
        })
        this.setState({ errorMessages })
        showAlert(400, 'Atenção', err.message, err.technical)
      }
    }
    this.setState({ loading: false })
  }

  submitSelfie = async () => {
    const { token, showAlert, showLoading } = this.props
    const { selfie, userId } = this.state

    try {
      showLoading('Alterando...')
      const response = await user.selfie(token, userId, selfie)
      this.close()
      showAlert(200, 'Sucesso', response.message)
      this.getUser()
    } catch (err) {
      if (!err.response) {
        return showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
      }
      showAlert(400, 'Atenção', err.message, err.technical)
      this.setState({ userStats: undefined })
    }
  }

  showImage = () => {
    const { selfieShow } = this.state
    if (selfieShow) return selfieShow
    return 'https://react.semantic-ui.com/images/wireframe/square-image.png'
  }

  showPreview = () => {
    const { selfiePreview, selfieShow } = this.state
    if (selfiePreview) return selfiePreview
    if (selfieShow) return selfieShow
    return 'https://react.semantic-ui.com/images/wireframe/square-image.png'
  }

  handleUpload = file => {
    const upload = file.map(newFile => ({
      file: newFile,
      preview: URL.createObjectURL(newFile)
    }))

    this.setState({
      selfie: upload[0].file,
      selfiePreview: upload[0].preview
    })

    this.showPreview()
  }

  cleanStates = () => {
    this.setState({
      errorMessages: {},
      change: false
    })
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false, selfiePreview: '' })

  componentDidMount = async () => {
    await this.getUser()
  }

  render () {
    const { userId, open, errorMessages, loading } = this.state

    inputmask().mask(document.querySelectorAll('input'))

    if (!userId) {
      return (
        <Card title={'Perfil'}>
          <Grid centered>
            <Grid.Row>
              <Bounce className="bounce-margin" color="#212121" size={22} speed={1} animating={true} />
            </Grid.Row>
          </Grid>
        </Card>
      )
    }

    return (
      <Card title={'Perfil'}>
        <Grid columns='equal' centered>
          <Grid.Row>
            <Modal
              open={open}
              onOpen={this.open}
              onClose={this.close}
              trigger={
                <Container>
                  <Image style={{ cursor: 'pointer' }} src={this.showImage()}/>
                  <p>Clique para alterar</p>
                </Container>
              }>
              <Modal.Header >Sua foto</Modal.Header>
              <Modal.Content image>
                <Image style={{ objectFit: 'fill' }} wrapped size='large' src={this.showPreview()} />
                <Modal.Description style={{ width: '100%' }}>
                  <Header>Escolha uma foto:</Header>
                  <Upload onUpload={this.handleUpload}/>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.close}>Sair</Button>
                <Button filled style={{ marginLeft: '20px' }} onClick={this.submitSelfie} >Alterar</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Input
                inputRef={this.nameRef}
                label='Nome'
                placeholder='Insira o nome'
                onChange={(e) => this.setState({ name: e.target.value, change: true })}
                error={errorMessages.name}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Input
                inputRef={this.birthdateRef}
                label='Data de nacimento'
                data-inputmask="'mask': '99/99/9999', 'placeholder': 'dd/mm/aaaa'"
                placeholder='Insira a sua data de nacimento'
                onChange={(e) => this.setState({ birthdate: e.target.value, change: true })}
                error={errorMessages.birthdate}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Input
                inputRef={this.cellphoneRef}
                label='Celular'
                data-inputmask="'mask': '(99) 99999-9999'"
                placeholder='Insira o número do celular'
                onChange={(e) => this.setState({ cellphone: e.target.value, change: true })}
                error={errorMessages.cellphone}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Input
                inputRef={this.telephoneRef}
                label='Telefone'
                data-inputmask="'mask': '(99) 9999-9999'"
                placeholder='Insira o número do telefone'
                onChange={(e) => this.setState({ telephone: e.target.value, change: true })}
                error={errorMessages.telephone}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Button
              style={{ paddingLeft: '80px', paddingRight: '80px' }}
              onClick={this.submit}
              disabled={loading}
              filled
            >
              {loading
                ? <Bounce
                  color='#ffffff'
                  size={22}
                  speed={1}
                  animating={true}
                /> : 'Atualizar dados'}
            </Button>
          </Grid.Row>
          <Grid.Row >
            <EmailModal/>
          </Grid.Row>
        </Grid>
      </Card>
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  router: state.router
})

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path)),
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
  showLoading: (type, title, message, func) => dispatch(showLoading(type, title, message, func))
})

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
