import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Bounce } from 'react-activity'
import moment from 'moment'
import Inputmask from 'inputmask'

import IconButton from 'components/IconButton'
import ClickToCall from 'components/ClickToCall'
import Card from 'components/Card'
import Input from 'components/Input'
import CalendarHoursInput from 'components/CalendarHoursInput'
import Select from 'components/Select'
import PaginationShort from 'components/Pagination'
import Button from 'components/Button'
// import List from 'components/List'
import { Grid, Button as SButton, Header } from 'semantic-ui-react'

import { timeEntryOptions } from 'utils/resources'
import { formatPhone } from 'utils/formaters'
import { dateToBrSmall, toBrDisplay } from 'utils/formatDate'
import { onlyDigits } from 'utils/onlyDigits'
import { showAlert, closeAllSwal, setSwalLoading } from 'store/modules/alert/actions'
import missedCalls from 'consumers/missedCalls'
import { validateDateHour } from 'utils/validators'

class ListMissedCalls extends Component {
  constructor (props) {
    super(props)

    this.state = {
      calls: undefined,
      totalPages: null,
      activePage: null,
      page: 1,
      phone: '',
      dateStart: '',
      dateFinish: '',
      start: '',
      end: '',
      finished: '&finishedOption=false',
      openInfo: false,
      expandDate: false,
      history: undefined,
      lastFetch: null,
      sort: ''
    }

    this.sortRef = createRef()
    this.dateStartRef = createRef()
    this.dateEndRef = createRef()
    this.phoneRef = createRef()
    this.finishedRef = createRef()
  }

  changePhone = (event) => {
    const newPhone = onlyDigits(event.target.value)

    if (!newPhone || newPhone.length <= 0) {
      this.setState({ phone: '' }, () => {
        this.getMissedCalls()
      })
      return
    }

    if (newPhone.length <= 0) {
      this.getMissedCalls()
      return
    }
    if (newPhone.length < 4) return
    if (newPhone) {
      this.setState({ phone: newPhone }, () => {
        if (newPhone?.length >= 3 || newPhone === '') {
          this.getMissedCalls()
        }
      })
    }
  }

  changeSort = (event) => {
    if (event.value === '') {
      this.setState({ sort: '' }, () => {
        this.getMissedCalls()
      })
    } else {
      this.setState({ page: 1, sort: `&sort=${event.value}` }, () => {
        this.setState({ activePage: 1 })
        this.getMissedCalls()
      })
    }
  }

  changeFinished = (event) => {
    if (event) {
      this.setState({ finished: event.value }, () => {
        this.getMissedCalls()
      })
    }
  }

  changePage = (event) => {
    if (event) {
      this.setState({ page: event.target.getAttribute('value') }, () => {
        this.getMissedCalls()
      })
    }
  }

  changeStartDate = (event) => {
    const inputFormatDate = moment(event).format('DD/MM/YYYY HH:mm')
    this.setState({ dateStart: inputFormatDate })

    const searchFormatDate = moment(event).format('YYYY-MM-DDTHH:mm')
    this.concatDate(searchFormatDate, event, 'start')
  }

  changeEndDate = (event) => {
    const inputFormatDate = moment(event).format('DD/MM/YYYY HH:mm')
    this.setState({ dateEnd: inputFormatDate })

    const searchFormatDate = moment(event).format('YYYY-MM-DDTHH:mm')
    this.concatDate(searchFormatDate, event, 'end')
  }

  concatDate = (date, event, type) => {
    if (type === 'start') {
      if (validateDateHour(event)) {
        this.setState({ start: `&start=${date}` },
          () => {
            this.getMissedCalls()
          })
      } else {
        this.setState({ start: '' }, () => {
          this.getMissedCalls()
        })
      }
    }

    if (type === 'end') {
      if (validateDateHour(event)) {
        this.setState({ end: `&end=${date}` },
          () => {
            this.getMissedCalls()
          })
      }
    } else {
      this.setState({ end: '' }, () => {
        this.getMissedCalls()
      })
    }
  }

  clearFilters = () => {
    this.setState(
      {
        dateHourStart: '',
        dateHourEnd: '',
        start: '',
        end: '',
        tempDate: '',
        tempDateFinal: '',
        page: 1,
        activePage: 1,
        finished: '&finishedOption=false',
        answered: '&answeredOption=false'
      }, () => { this.getMissedCalls() }
    )
    this.sortRef.current.state.value = timeEntryOptions[0]
  }

  renderInfo = (updatedBy) => {
    const { history } = this.state
    return (
      <td colSpan="6" className="ui content">
        <Header>Histórico</Header>
        {updatedBy ? <p>{`Finalizado por: ${updatedBy?.name} em ${toBrDisplay(updatedBy?.at, true)}`}</p> : <></>}
        <table className="ui celled striped table">
          <thead>
            <tr>
              <th>Data</th>
              <th>Atendido por</th>
              <th>Tabulação</th>
            </tr>
          </thead>
          <tbody>
            {history.map((item, index) => (
              <tr key={`History-${index}`}>
                <td>{toBrDisplay(item.createdAt, true)}</td>
                <td>{item.ramal}</td>
                <td>{item.tabulationName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    )
  }

  fetchMissedCalls = async () => {
    const { token, showAlert } = this.props

    try {
      const response = await missedCalls.fetch(token)
      this.setState({ lastFetch: response.data.lastFetch })
      showAlert(response.code, 'Sucesso', response.message)
      this.getMissedCalls()
    } catch (e) {
      if (!e.response) {
        showAlert(500, 'Atenção', 'Não foi possível se comunicar com o servidor!')
      }

      showAlert(400, 'Atenção', e.message)
    }
  }

  updateStatus = async (id) => {
    const { token, showAlert } = this.props

    try {
      const response = await missedCalls.finish(token, id)

      showAlert(response.code, 'Sucesso', response.message)
      this.getMissedCalls()
    } catch (e) {
      if (!e.response) {
        showAlert(500, 'Atenção', 'Não foi possível se comunicar com o servidor!')
      }
      showAlert(400, 'Atenção', e.message)
    }
  }

  getHistory = async (id) => {
    const { token, showAlert } = this.props

    try {
      const response = await missedCalls.history(token, id)
      const objData = []
      Object.values(response.data).forEach(item => {
        item.forEach(subitem => {
          objData.push({
            _id: subitem._id,
            tabulationName: subitem.tabulationName,
            ramal: subitem.ramal,
            createdAt: subitem.createdAt
          })
        })
      })

      this.setState({ history: objData })
      this.setState({ openInfo: id })
    } catch (e) {
      if (!e.response) {
        showAlert(500, 'Atenção', 'Não foi possível se comunicar com o servidor!')
      }
      showAlert(400, 'Atenção', e.message)
    }
  }

  getMissedCalls = async () => {
    const { token, showAlert, setSwalLoading, closeAllSwal } = this.props
    const { page, phone, start, end, finished, sort } = this.state

    setSwalLoading()
    try {
      const response = await missedCalls.list(token,
        `?page=${page}${phone.length ? '&phone=' + phone : ''}${start}${end}${finished}${sort}`
      )
      this.setState({
        calls: response.docs,
        totalPages: response.totalPages,
        activePage: response.page
      })
    } catch (e) {
      if (e.code >= 400 && e.code <= 499) { showAlert(e.code, 'Atenção', e.message) }
    }
    closeAllSwal()
  }

  componentDidMount () {
    this.getMissedCalls()
  }

  toggleExpandDate = () => this.setState({ expandDate: !this.state.expandDate })

  render () {
    const { calls, totalPages, activePage, openInfo, lastFetch, expandDate } = this.state
    Inputmask().mask(document.querySelectorAll('input'))
    return (
      <Card title='Chamadas Perdidas'>
        {calls ? (
          <>
            <Grid>
              <Grid.Row>

                <Grid.Column width={2}>
                  <CalendarHoursInput
                    inputRef={this.dateStartRef}
                    label='Data inicial'
                    onSelect={(e) => { this.setState({ tempDate: e }); this.changeStartDate(e) }}
                    selected={this.state.tempDate}
                  />
                </Grid.Column>

                <Grid.Column width={2}>
                  <CalendarHoursInput
                    inputRef={this.dateEndRef}
                    label='Data Final'
                    onSelect={(e) => { this.setState({ tempDateFinal: e }); this.changeEndDate(e) }}
                    selected={this.state.tempDateFinal}
                  />
                </Grid.Column>

                <Grid.Column width={3}>
                  <Input
                    label='Celular/Telefone'
                    inputRef={this.phoneRef}
                    placeholder='Digite um fone do paciente'
                    data-inputmask="'mask': '(99) 9999[9]-9999'"
                    onChange={(e) => this.changePhone(e)}
                  />
                </Grid.Column>

                <Grid.Column width={2}>
                  <Select
                    inputRef={this.optionRef}
                    label='Finalizado?'
                    options={[
                      { label: 'Não finalizado', value: '&finishedOption=false' },
                      { label: 'Finalizado', value: '&finishedOption=true' },
                      { label: 'Todos', value: '' }
                    ]}
                    onChange={(e) => this.changeFinished(e)}
                  />
                </Grid.Column>

                <Grid.Column width={2} style={{ marginTop: 36, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    filled
                    onClick={() => this.fetchMissedCalls()}
                    disabled={moment(moment(Date.now()).diff(lastFetch)).minutes() <= 0}
                  >Atualizar</Button>
                </Grid.Column>

                <Grid.Column width={2} style={{ marginTop: 36, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button filled onClick={this.clearFilters}>
                      Limpar
                  </Button>
                </Grid.Column>

              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={3} style={{ marginRight: 36 }}>
                  <Select
                    inputRef={this.sortRef}
                    label='Ordenar'
                    options={timeEntryOptions}
                    onChange={(e) => this.changeSort(e)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <table className="ui celled table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>
                    Data
                    <IconButton basic dark small onClick={this.toggleExpandDate} icon={expandDate ? 'crop' : 'expand'} />
                  </th>
                  <th>Tempo de Espera</th>
                  <th>Finalizado</th>
                  <th>Número</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {calls.map((call, idx) => (
                  <>
                    <tr className="ui title">
                      <td>{call.asteriskCallId}</td>
                      <td>{expandDate ? toBrDisplay(call.timeEntry, true) : dateToBrSmall(call.timeEntry)}</td>
                      <td>{moment(call.durationWait * 1000).seconds()} segundos</td>
                      <td>{(call.finished === true) ? 'Sim' : 'Não'}</td>
                      <td>
                        {(call.finished)
                          ? <p style={{ textAlign: 'center' }}>Atendimento já finalizado!</p>
                          : <ClickToCall
                            label={formatPhone(call.phone)}
                            to={call.phone}
                            missed={call._id}
                          />}
                      </td>
                      <td>
                        <SButton
                          content={(openInfo === call._id) ? 'Fechar' : 'Ver histórico'}
                          disabled={!(call.history)}
                          onClick={() => {
                            if (openInfo && openInfo === call._id) this.setState({ openInfo: undefined })
                            else this.getHistory(call._id)
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      {(openInfo === call._id) ? this.renderInfo(call.updatedBy) : <></>}
                    </tr>
                  </>
                ))}
              </tbody>
            </table>

            <PaginationShort
              onPageChange={(e) => this.changePage(e)}
              totalPages={totalPages}
              activePage={activePage}
            />
          </>
        ) : (
          <Grid centered>
            <Grid.Row>
              <Bounce
                className='bounce-margin'
                color='#212121'
                size={22}
                speed={1}
                animating={true}
              />
            </Grid.Row>
          </Grid>
        )}
      </Card>
    )
  }
}

ListMissedCalls.propTypes = {
  token: PropTypes.string,
  showAlert: PropTypes.func,
  setSwalLoading: PropTypes.func,
  closeAllSwal: PropTypes.func
}

const mapStateToProps = (state) => ({
  token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, func) =>
    dispatch(showAlert(type, title, message, func)),
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal())
})

export default connect(mapStateToProps, mapDispatchToProps)(ListMissedCalls)
