import styled, { css } from 'styled-components'

const dragActive = css`
  border-color: green;
` 

const dragReject = css`
  border-color: red;
` 

export const Container = styled.div.attrs({
  className: "dropzone"
})`
  width: 100%;
  border: 2px dashed #666;
  border-radius: 4px;
  cursor: pointer;

  transition: height 0.2s ease;

  ${props => props.isDragActive && dragActive}
  ${props => props.isDragReject && dragReject}
`

const messageColors = {
  default: '#222',
  success: 'green',
  error: 'red'
}

export const Message = styled.p`
  display: flex;
  color: ${props => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
`