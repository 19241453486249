export const getStatusColor = status => {
  const success = ['available', 'online']
  const info = ['ending', 'waiting', 'paused']
  const warning = ['busy']
  const danger = ['unavailable']
  const disabled = ['offline']

  switch (true) {
    case success.includes(status): return '#34A853'
    case info.includes(status): return '#4285F4'
    case danger.includes(status): return '#EA4335'
    case warning.includes(status): return '#FBBC05'
    case disabled.includes(status): return '#414141'
    default: return '#4285F4'
  }
}

export const jeielColors = {
  green: '#99EA55',
  musgo: '#47C48E',
  orange: '#FF8B5D',
  pink: '#EB5583'
}
