import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 82px;
  width: 100%;
  background: #010101;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  grid-area: 1 / 1 / 2 / 3;
`

export const Side = styled.div`
  color: #fcfcfc;
  display: flex;
  align-items: center;
  margin-right: 20px;
`

export const Logo = styled.img`
  height: 76px;
  margin-left: 10px;
`
export const Divisor = styled.div`
  &:before {
    display: block;
    content: ' ';
    height: 48px;
    width: 2px;
    margin: 17px 0;
    background: #FFF;
  }
  height: 100%;
  width: 2px;
  margin: 0 10px;
`

export const ClientName = styled.div`
  color: #FFF;
  font-weight: 500;
  font-size: 18px;
`

export const Button = styled.button`
  color: #FFF;
  margin: 5px;
  border: none;
  background: none;
`

export const ClientPhone = styled.span`
    font-weight: 600;
    margin-right: 4px
`
