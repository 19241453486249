import React, { Component } from 'react'
import { connect } from 'react-redux'
import { showAlert } from 'store/modules/alert/actions'
import PropTypes from 'prop-types'

import { Tab } from 'semantic-ui-react'
import Table from 'components/ResourcesList'
import Card from 'components/Card'
import QueryOptions from 'components/ResourcesQuery'

import resources from 'consumers/resources'
import tabulationNameFunc from 'consumers/tabulationName'
import pauseReasonsFunc from 'consumers/pauseReasons'
import specialtiesFunc from 'consumers/specialties'
import insurancesFunc from 'consumers/insurances'
import unitsFunc from 'consumers/units'

class Resources extends Component {
  constructor (props) {
    super(props)

    this.state = {
      // loading
      loading: false,
      loadDelete: false,
      loadUpdate: false,
      // items
      pauseReasons: undefined,
      units: undefined,
      tabulationNames: undefined,
      specialties: undefined,
      insurances: undefined,
      // query
      inactive: false,
      name: '',
      // paginação
      page: 1,
      activeTab: 'Tipos de Tabulação'
    }
  }

  getOne = async () => {
    const { token } = this.props
    const { activeTab, inactive, page, name } = this.state

    switch (activeTab) {
      case 'Tipos de Tabulação': {
        try {
          // setSwalLoading()
          const response = await resources.tabulations(token,
            `?page=${page}&inactive=${inactive}&name=${name}`
          )
          this.setState({ tabulationNames: response })
          // closeAllSwal()
        } catch (err) {
          // closeAllSwal()
          if (!err.response) {
            return showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
          }
          showAlert(400, 'Atenção', err.message, err.technical)
          this.setState({ tabulationNames: undefined })
        }
        break
      }
      case 'Motivos de Pausa': {
        try {
          // setSwalLoading()
          const response = await resources.pauseReasons(token,
            `?page=${page}&inactive=${inactive}&name=${name}`
          )
          this.setState({ pauseReasons: response })
          // closeAllSwal()
        } catch (err) {
          // closeAllSwal()
          if (!err.response) {
            return showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
          }
          showAlert(400, 'Atenção', err.message, err.technical)
          this.setState({ pauseReasons: undefined })
        }
        break
      }
      case 'Especialidades': {
        try {
          // setSwalLoading()
          const response = await resources.specialties(token,
            `?page=${page}&inactive=${inactive}&name=${name}`
          )
          this.setState({ specialties: response })
          // closeAllSwal()
        } catch (err) {
          // closeAllSwal()
          if (!err.response) {
            return showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
          }
          showAlert(400, 'Atenção', err.message, err.technical)
          this.setState({ specialties: undefined })
        }
        break
      }
      case 'Convênios': {
        try {
          // setSwalLoading()
          const response = await resources.healthInsurances(token,
            `?page=${page}&inactive=${inactive}&name=${name}`
          )
          this.setState({ insurances: response })
          // closeAllSwal()
        } catch (err) {
          // closeAllSwal()
          if (!err.response) {
            return showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
          }
          showAlert(400, 'Atenção', err.message, err.technical)
          this.setState({ insurances: undefined })
        }
        break
      }
      case 'Unidades': {
        try {
          // setSwalLoading()
          const response = await resources.units(token,
            `?page=${page}&inactive=${inactive}&name=${name}`
          )
          this.setState({ units: response })
          // closeAllSwal()
        } catch (err) {
          // closeAllSwal()
          if (!err.response) {
            return showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
          }
          showAlert(400, 'Atenção', err.message, err.technical)
          this.setState({ units: undefined })
        }
        break
      }
      default: break
    }
    this.setState({ loading: false })
  }

  changeName = (event) => {
    const newName = event.target.value
    this.setState({ name: newName }, () => {
      if (newName.length >= 3 || newName === '') {
        this.setState({ page: 1 }, () => this.getOne())
      }
    })
  }

  changeActive = () => {
    this.setState({ loading: true })
    if (this.state.inactive) {
      this.setState({ inactive: false }, () => {
        this.getOne()
      })
    } else {
      this.setState({ inactive: true }, () => {
        this.getOne()
      })
    }
  }

  handleChildLoading = (childData) => {
    if (!childData.loadUpdate || (!childData.loadDelete && childData.loadDelete)) {
      this.setState({
        loadUpdate: childData.loadUpdate,
        loadDelete: childData.loadDelete
      }, () => {
        this.getOne()
      })
    } else {
      this.setState({
        loadUpdate: childData.loadUpdate,
        loadDelete: childData.loadDelete
      })
    }
  }

  handleChangePage = (childData) => {
    this.setState({ page: childData }, () => {
      this.getOne()
    })
  }

  componentDidMount () {
    this.getOne()
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  render () {
    const { loading, loadDelete, loadUpdate, inactive } = this.state
    const panes = [
      {
        menuItem: 'Tipos de Tabulação',
        render: () => (
          <Tab.Pane loading={loading}>
            <QueryOptions
              input={[{ width: 4, label: 'Nome', onChange: (e) => this.changeName(e) }]}
              toggle={[{ label: 'Mostrar Inativos', checked: inactive, onClick: () => this.changeActive() }]}
            />
            <Table
              body={this.state.tabulationNames}
              apiFunc={tabulationNameFunc}
              loadDelete={loadDelete}
              loadUpdate={loadUpdate}
              parentCallback={this.handleChildLoading}
              changePage={this.handleChangePage}
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Motivos de Pausa',
        render: () => (
          <Tab.Pane loading={loading}>
            <QueryOptions
              input={[{ width: 4, label: 'Nome', onChange: (e) => this.changeName(e) }]}
              toggle={[{ label: 'Mostrar Inativos', checked: inactive, onClick: () => this.changeActive() }]}
            />
            <Table
              body={this.state.pauseReasons}
              apiFunc={pauseReasonsFunc}
              loadDelete={loadDelete}
              loadUpdate={loadUpdate}
              parentCallback={this.handleChildLoading}
              changePage={this.handleChangePage}
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Especialidades',
        render: () => (
          <Tab.Pane loading={loading}>
            <QueryOptions
              input={[{ width: 4, label: 'Nome', onChange: (e) => this.changeName(e) }]}
              toggle={[{ label: 'Mostrar Inativos', checked: inactive, onClick: () => this.changeActive() }]}
            />
            <Table
              body={this.state.specialties}
              cantDelete={true}
              apiFunc={specialtiesFunc}
              loadDelete={loadDelete}
              loadUpdate={loadUpdate}
              parentCallback={this.handleChildLoading}
              changePage={this.handleChangePage}
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Convênios',
        render: () => (
          <Tab.Pane loading={loading}>
            <QueryOptions
              input={[{ width: 4, label: 'Nome', onChange: (e) => this.changeName(e) }]}
              toggle={[{ label: 'Mostrar Inativos', checked: inactive, onClick: () => this.changeActive() }]}
            />
            <Table
              body={this.state.insurances}
              cantDelete={true}
              apiFunc={insurancesFunc}
              loadDelete={loadDelete}
              loadUpdate={loadUpdate}
              parentCallback={this.handleChildLoading}
              changePage={this.handleChangePage}
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Unidades',
        render: () => (
          <Tab.Pane loading={loading}>
            <QueryOptions
              input={[{ width: 4, label: 'Nome', onChange: (e) => this.changeName(e) }]}
              toggle={[{ label: 'Mostrar Inativos', checked: inactive, onClick: () => this.changeActive() }]}
            />
            <Table
              body={this.state.units}
              cantDelete={true}
              apiFunc={unitsFunc}
              loadDelete={loadDelete}
              loadUpdate={loadUpdate}
              parentCallback={this.handleChildLoading}
              changePage={this.handleChangePage}
            />
          </Tab.Pane>
        )
      }
    ]
    return (
      <Card noBg title="Recursos">
        <Tab
          menu={{ pointing: true }}
          panes={panes}
          onTabChange={(e) => {
            this.setState({ page: 1, activeTab: e.currentTarget.text }, () => {
              this.getOne()
            })
          }}
        />
      </Card>
    )
  }
}

Resources.propTypes = {
  token: PropTypes.string,
  showAlert: PropTypes.func
}

const mapStateToProps = (state) => ({
  token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func))
})

export default connect(mapStateToProps, mapDispatchToProps)(Resources)
