import omniApi from 'services/apiOmnichanel'

const TOKEN = 'vDzPDNfjWMKMa8vq1w55VCm/WJ5l/EpBKEe1CxX9zxg='

const omnichanel = {

  get: async (query) => {
    try {
      const response = await omniApi.get('uxagenda/registers',
        { headers: { Authorization: `Bearer ${TOKEN}` }, params: query }
      )

      return response.data.data
    } catch (e) {
      if (!e.response) throw e
      throw new Error(e.response.data.message)
    }
  },

  dashboard: async (query) => {
    try {
      const response = await omniApi.get(
        `uxagenda/dashboard${query}`,
        { headers: { Authorization: `Bearer ${TOKEN}` } }
      )

      return response.data.data
    } catch (err) {
      if (!err.response) throw err

      throw err.response.data.message
    }
  },

  confirm: async (data, token) => {
    try {
      const response = await omniApi.patch('uxagenda/confirm',
        data,
        { headers: { Authorization: `Bearer ${token}` } }
      )

      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },

  cancel: async (data, token) => {
    try {
      const response = await omniApi.patch(
        'uxagenda/cancel',
        data,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },

  update: async (token, data) => {
    try {
      const response = await omniApi.patch('uxagenda/update',
        data,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      return response.data
    } catch (err) {
      if (!err.response) throw new Error('Sem resposta do servidor')
      throw new Error(err.response.data.message)
    }
  },

  userData: async (token) => {
    try {
      const response = await omniApi.get(`page${token}`)
      return response.data
    } catch (err) {
      if (!err.response) throw new Error('Sem resposta do servidor')
      throw new Error(err.response.data.message)
    }
  },

  confirmPage: async (token, answer) => {
    try {
      const response = await omniApi.get(`page/confirm${token}&answer=${answer}`)
      return response.data
    } catch (err) {
      if (!err.response) throw new Error('Sem resposta do servidor')
      throw new Error(err.response.data.message)
    }
  },

  notification: async () => {
    try {
      const response = await omniApi.get('uxagenda/notification', {
        headers: { Authorization: `Bearer ${TOKEN}` }
      })
      return response.data
    } catch (err) {
      if (!err.response) throw new Error('Sem resposta do servidor')
      throw new Error(err.response.data.message)
    }
  },

  totals: async () => {
    try {
      const response = await omniApi.get('uxagenda/totals', {
        headers: { Authorization: `Bearer ${TOKEN}` }
      })
      return response.data
    } catch (err) {
      if (!err.response) throw new Error('Sem resposta do servidor')
      throw new Error(err.response.data.message)
    }
  },

  ranking: async (query) => {
    try {
      const response = await omniApi.get(`uxagenda/ranking${query || '?period=day'}`, {
        headers: { Authorization: `Bearer ${TOKEN}` }
      })
      return response.data
    } catch (err) {
      if (!err.response) throw new Error('Sem resposta do servidor')
      throw new Error(err.response.data.message)
    }
  }
}

export default omnichanel
