import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showConfirmation, showAlert } from 'store/modules/alert/actions'
import { Dropdown } from 'semantic-ui-react'
import { Container, Status, Selfie, Footer, Name, ImageContainer } from './styled'
import { translateStatus, translatePeriod } from 'utils/translate'
import { getFirstAndLastWord } from 'utils/stringMethods'
import 'semantic-ui-css/semantic.min.css'
import { Link } from 'react-router-dom'
import { formatPhone } from 'utils/formaters'
import operatorConsumer from 'consumers/operator'

const OperatorCard = ({
  operator,
  timePaused,
  timeBusy,
  timeFinished,
  phone,
  period,
  token,
  showConfirmation,
  showAlert

}) => {
  const { _id, status, selfie, name, ramal, lastBusyWith, reasonName, active } = operator

  const picture = selfie || require('assets/img/profile.jpg')

  const activate = async (commit) => {
    if (commit) {
      try {
        const response = await operatorConsumer.activate(token, _id)
        showAlert(response.code, 'Sucesso!', response.message)
      } catch (err) {
        showAlert(err.code, 'Atenção!', err.message)
      }
    } else {
      showConfirmation(20, 'Ativar Operador?', `Deseja reativar o operador: ${name}?`, () => { activate(true) })
    }
  }

  const deactivate = async (commit = false) => {
    if (commit) {
      try {
        const response = await operatorConsumer.deactivate(token, _id)
        showAlert(response.code, 'Sucesso!', response.message)
      } catch (err) {
        showAlert(err.code, 'Atenção!', err.message)
      }
    } else {
      showConfirmation(20, 'Desativar Operador?', `Tem certeza de que deseja desativar o operador: ${name}?`, () => { deactivate(true) })
    }
  }

  return (
    <Container status={status} inactive={!active}>
      <ImageContainer>
        <Name status={status} className='footer-info'>
          {getFirstAndLastWord(name)}
        </Name>
        <Selfie src={picture} />
        <Status status={status}>
          {translateStatus(status, true)}
          <br />
          {(status === 'paused') ? `Motivo: ${reasonName}` : ''}
          {status === 'busy' || status === 'ending' ? formatPhone(lastBusyWith) : ''}
          <br />
          {status === 'paused' && timePaused} {status === 'busy' && timeBusy}
          {status === 'ending' && timeFinished}
        </Status>
      </ImageContainer>
      <Footer>
        <span className='footer-info'>
          Pausas: {operator.totals.pauses.today}
        </span>
        <br />
        <span className='footer-info'>Ramal: {ramal}</span>
        <br />
        <span className='footer-info'>{translatePeriod(period)}</span>
        <br />
        <Dropdown text='Ações'>
          <Dropdown.Menu>
            <Dropdown.Item>
              <Link className='dropdown-item' to={`/admin/operadores/${_id}`}>
                Ver Detalhes
              </Link>
            </Dropdown.Item>
            {
              !operator.active
                ? <Dropdown.Item text='Ativar' onClick={() => activate()}/>
                : <Dropdown.Item text='Desativar' onClick={() => deactivate()}/>
            }
          </Dropdown.Menu>
        </Dropdown>
      </Footer>
    </Container>
  )
}

OperatorCard.propTypes = {
  operator: PropTypes.object,
  timePaused: PropTypes.string,
  token: PropTypes.string
}

const mapStateToProps = (state) => ({
  token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
  showConfirmation: (type, title, message, func) => dispatch(showConfirmation(type, title, message, func)),
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func))
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatorCard)
