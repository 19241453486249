import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import AuthLayout from '../pages/_layouts/Auth'

export default function AuthRoute ({ component: Component, signupWidth, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthLayout signupWidth={signupWidth}>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  )
}

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
}
