import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Wrapper, Span, Notification } from './styled'
import phoneMissed from '../../assets/icon/phone-missed.svg'
import ranking from '../../assets/icon/podium.svg'

const getIcon = (icon) => {
  switch (icon) {
    case 'phone-missed': return <ion-icon src={phoneMissed} style={iconStyle}/>
    case 'ranking': return <ion-icon src={ranking} style={iconStyle}/>
    default: return <ion-icon name={icon} style={iconStyle}/>
  }
}

export default function LinkSideBar ({ children, icon, sidebarOpen, notifications, ...rest }) {
  return (
    <Wrapper>
      <NavLink className={sidebarOpen ? 'linkStyle' : 'linkStyle-small'} {...rest} >
        {getIcon(icon)}
        <Span className={sidebarOpen ? 'link-open-text' : 'link-closed-text'}>{children}</Span>
        {rest.to === '/admin/whats' && (notifications.confirmation) !== 0 ? <Notification className="scale-up-center">{ notifications.confirmation }</Notification> : ''}
      </NavLink>
    </Wrapper>
  )
}

const iconStyle = {
  height: '48px',
  marginLeft: '13px',
  marginRight: '13px',
  fontSize: '22px',
  color: '#121212'
}

LinkSideBar.propTypes = {
  children: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  icon: PropTypes.string,
  sidebarOpen: PropTypes.bool
}
