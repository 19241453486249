import React, { Component } from 'react'

import Input from 'components/Input'
import Button from 'components/Button'
import Select from 'components/Select'
import Toggle from 'react-toggle'
import PropTypes from 'prop-types'

import inputmask from 'inputmask'

import CalendarInput from 'components/CalendarInput/index.jsx'
// import { titleCase } from 'utils/stringMethods'

import { connect } from 'react-redux'
import {
  showAlert,
  setSwalLoading,
  closeAllSwal
} from 'store/modules/alert/actions'

import {
  setPatient,
  setPatientId,
  setPatientMvId,
  setPatientName,
  setPatientCpf,
  setPatientBirthdate,
  setPatientGender,
  setPatientEmail,
  setPatientCellphone,
  setPatientSecondCellphone,
  setPatientWhatsapp,
  setPatientTelephone,
  setPatientContactMethod,
  setPatientRegister,
  clearPatient
} from 'store/modules/patient/actions'

import { formatDate, FormatDateBr } from 'utils/formatDate'
import { onlyDigits } from 'utils/onlyDigits'

import { default as consumerPatient } from 'consumers/patient'
import { Grid } from 'semantic-ui-react'
import { Bounce } from 'react-activity'
// import { toDate } from 'utils/formaters'

// const TypeContact = [
//   { value: '', label: 'Selecione' },
//   { value: 'whatsapp', label: 'Whatsapp' },
//   { value: 'email', label: 'Email' },
//   { value: 'cellphone', label: 'Telefone' }
// ]

const genderType = [
  { value: '', label: 'Selecione' },
  { value: 'male', label: 'Masculino' },
  { value: 'female', label: 'Feminino' }
]

class CreatePatient extends Component {
  constructor (props) {
    super(props)

    this.state = {
      cpf: { changed: false },
      name: { changed: false },
      gender: { changed: false },
      birthdate: { changed: false },
      email: { changed: false },
      cellphone: { changed: false },
      secondCellphone: { changed: false },
      whatsapp: { changed: false },
      telephone: { changed: false },
      contactMethod: { changed: false },
      hasCpf: false,
      readCpf: false,
      idUser: null,
      loading: false
    }
  }

  submit = async () => {
    const { onSubmit, patient } = this.props

    const data = {
      cpf: onlyDigits(patient.cpf),
      name: patient.name,
      gender: patient.gender,
      birthdate: formatDate(FormatDateBr(patient.birthdate)),
      email: patient.email,
      cellphone: onlyDigits(patient.cellphone),
      secondCellphone: onlyDigits(patient.secondCellphone),
      telephone: onlyDigits(patient.telephone),
      whatsapp: patient.whatsapp,
      contactMethod: patient.contactMethod
    }

    this.setState({ loading: true })
    await onSubmit(data)
    this.setState({ loading: false })
  }

  update = async () => {
    const {
      name,
      gender,
      birthdate,
      email,
      cellphone,
      secondCellphone,
      whatsapp,
      telephone,
      contactMethod
    } = this.state

    const { onUpdate, patient } = this.props

    const data = {}

    if (name.changed) data.name = patient.name
    if (gender.changed) data.gender = patient.gender
    if (birthdate.changed) data.birthdate = patient.birthdate
    if (email.changed) data.email = patient.email
    if (cellphone.changed) data.cellphone = onlyDigits(patient.cellphone)
    if (secondCellphone.changed) data.secondCellphone = onlyDigits(patient.secondCellphone)
    if (whatsapp.changed) data.whatsapp = patient.whatsapp
    if (telephone.changed) data.telephone = onlyDigits(patient.telephone)
    if (contactMethod.changed) data.contactMethod = patient.contactMethod

    this.setState({ loading: true })
    await onUpdate(data, patient.id)
    this.setState({ loading: false })
  }

  getPacientByCPF = async (cpf) => {
    const {
      token,
      showAlert,
      cleanError,
      setPatient,
      setSwalLoading,
      closeAllSwal,
      setPatientName,
      patient
    } = this.props

    cpf = cpf.replace(/\D/g, '')
    if (cpf.length < 11 || patient?.name?.length > 0) {
      return
    }

    // TODO: Fazer função no redux para limpar mensagens de erro
    cleanError()

    try {
      setSwalLoading()
      const response = await consumerPatient.getByCpf(token, cpf)
      const user = response.data.data

      if (!user.id) {
        setPatientName(user.name)
      } else {
        this.setState({
          idUser: user.id,
          gender: { changed: false },
          contactMethod: { changed: false },
          whatsapp: { changed: false },
          readCpf: { changed: false, value: true },
          birthdate: { changed: false },
          cellphone: { changed: false },
          secondCellphone: { changed: false },
          email: { changed: false },
          name: { changed: false },
          telephone: { changed: false },
          invalidFields: {}
        })

        const data = {
          id: user.id,
          cpf: user.cpf,
          name: user.name,
          birthdate: new Date(user.birthdate),
          gender: user.gender,
          email: user.email,
          cellphone: user.cellphone,
          secondCellphone: user.secondCellphone,
          whatsapp: user.whatsapp,
          telephone: user.telephone,
          contactMethod: user.contactMethod,
          register: true,
          mvId: user.mvId
        }
        setPatient(data)
      }
      closeAllSwal()
    } catch (err) {
      closeAllSwal()
      if (err.code === 422) {
        showAlert(400, 'Atenção', err.invalid[0].message, err.technical)
      }
    }
  }

  checkFields = () => {
    const inputs = Object.keys(this.state)
    let changed = false
    inputs.forEach((input) => {
      if (this.state[input] && this.state[input].changed) {
        changed = true
      }
    })
    return changed
  }

  render () {
    const {
      hasNewButton,
      clearPatient,
      errorMessages,
      setPatientCpf,
      setPatientName,
      setPatientBirthdate,
      setPatientGender,
      setPatientEmail,
      setPatientCellphone,
      setPatientSecondCellphone,
      setPatientWhatsapp,
      setPatientTelephone,
      patient
    } = this.props
    const { loading, hasCpf } = this.state
    const anyFieldChanged = this.checkFields()

    inputmask().mask(document.querySelectorAll('input'))
    const patientCpf = onlyDigits(patient.cpf) || ''
    const isCpfFilled = patientCpf.length === 11 || hasCpf

    return (
      <>
        {hasNewButton && (
          <div className="button-rigth">
            <Button filled onClick={() => clearPatient()}>
              Novo Paciente
            </Button>
          </div>
        )}
        <Grid columns='equal'>
          <Grid.Row >
            <Grid.Column width={2}>
              <Input
                label='CPF *'
                placeholder='CPF'
                data-inputmask="'mask': '999.999.999-99'"
                onChange={(e) => {
                  this.setState({ cpf: { changed: true } })
                  setPatientCpf(e.target.value)
                }}
                value={patient.cpf}
                onBlur={(e) => this.getPacientByCPF(e.target.value)}
                readOnly={patient.register}
                error={errorMessages.cpf}
              />
            </Grid.Column>

            <Grid.Column width={3}>
              <div className='align-toggle'>
                <Toggle
                  id='age-check'
                  defaultChecked={false}
                  onChange={(e) => {
                    this.setState({ hasCpf: e.target.checked })
                  }}
                  checked={hasCpf}
                />
                <label htmlFor="age-check">Paciente menor de idade</label>
              </div>
            </Grid.Column>

            <Grid.Column width={5}>
              <Input
                label='Nome *'
                type='text'
                placeholder='Nome'
                onChange={(e) => {
                  this.setState({ name: { changed: true } })
                  setPatientName(e.target.value)
                }}
                value={patient.name}
                error={errorMessages.name}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <CalendarInput
                label='Data de nascimento *'
                onSelect={(e) => {
                  this.setState({ birthdate: { changed: true } })
                  setPatientBirthdate(e)
                }}
                selected={patient.birthdate}
                error={errorMessages.birthdate}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Select
                label='Gênero *'
                options={genderType}
                onChange={(e) => {
                  this.setState({ gender: { changed: true } })
                  setPatientGender(e.value)
                }}
                value={genderType.find(
                  (element) => element.value === patient.gender
                )}
                error={errorMessages.gender}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={4}>
              <Input
                label='Email'
                placeholder='Email'
                onChange={(e) => {
                  this.setState({ email: { changed: true } })
                  setPatientEmail(e.target.value)
                }}
                value={patient.email}
                error={errorMessages.email}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Input
                label='Celular *'
                placeholder='Digite seu celular'
                data-inputmask="'mask': '(99) 99999-9999'"
                onChange={(e) => {
                  this.setState({ cellphone: { changed: true } })
                  setPatientCellphone(e.target.value)
                }}
                value={patient.cellphone}
                error={errorMessages.cellphone}
              />
            </Grid.Column>

            <Grid.Column width={3}>
              <Input
                label='Celular 2'
                placeholder='Digite seu celular'
                data-inputmask="'mask': '(99) 99999-9999'"
                onChange={(e) => {
                  this.setState({ secondCellphone: { changed: true } })
                  setPatientSecondCellphone(e.target.value)
                }}
                value={patient.secondCellphone}
                error={errorMessages.secondCellphone}
              />
            </Grid.Column>

            <Grid.Column width={2}>
              <div className='align-toggle'>
                <Toggle
                  id='cheese-status'
                  defaultChecked={false}
                  onChange={(e) => {
                    this.setState({ whatsapp: { changed: true } })
                    setPatientWhatsapp(e.target.checked)
                  }}
                  checked={patient.whatsapp}
                />
                <span>Whatsapp</span>
              </div>
            </Grid.Column>

            <Grid.Column width={4}>
              <Input
                label='Telefone'
                placeholder='Digite seu telefone'
                data-inputmask="'mask': '(99) 9999-9999'"
                onChange={(e) => {
                  this.setState({ telephone: { changed: true } })
                  setPatientTelephone(e.target.value)
                }}
                value={patient.telephone}
                error={errorMessages.telephone}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} textAlign='right'>
              {loading ? (
                <Button filled disabled>
                  <Bounce
                    className='bounce-margin'
                    color='#FCFCFC'
                    size={22}
                    speed={1}
                    animating={true}
                  />
                </Button>
              ) : (
                <>
                  {patient.register ? (
                    <Button filled onClick={this.update}>
                       Salvar
                    </Button>
                  ) : (
                    <Button filled onClick={this.submit}>
                      Cadastrar
                    </Button>
                  )}
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }
}

CreatePatient.propTypes = {
  token: PropTypes.string,
  errorMessages: PropTypes.object,
  patient: PropTypes.object,
  setPatientCpf: PropTypes.func,
  setPatientName: PropTypes.func,
  setPatientBirthdate: PropTypes.func,
  setPatientGender: PropTypes.func,
  setPatientEmail: PropTypes.func,
  setPatientCellphone: PropTypes.func,
  setPatientSecondCellphone: PropTypes.func,
  setPatientWhatsapp: PropTypes.func,
  setPatientTelephone: PropTypes.func,
  setPatientContactMethod: PropTypes.func,
  showAlert: PropTypes.func,
  cleanError: PropTypes.func,
  setPatient: PropTypes.func,
  clearPatient: PropTypes.func,
  hasNewButton: PropTypes.bool,
  setSwalLoading: PropTypes.func,
  closeAllSwal: PropTypes.func,
  onUpdate: PropTypes.func,
  onSubmit: PropTypes.func
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  patient: state.patient
})

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),

  clearPatient: () => dispatch(clearPatient()),
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal()),
  setPatient: (data) => dispatch(setPatient(data)),
  setPatientRegister: (register) => dispatch(setPatientRegister(register)),
  setPatientId: (id) => dispatch(setPatientId(id)),
  setPatientMvId: (id) => dispatch(setPatientMvId(id)),
  setPatientName: (name) => dispatch(setPatientName(name)),
  setPatientCpf: (cpf) => dispatch(setPatientCpf(cpf)),
  setPatientBirthdate: (birthdate) => dispatch(setPatientBirthdate(birthdate)),
  setPatientGender: (gender) => dispatch(setPatientGender(gender)),
  setPatientEmail: (email) => dispatch(setPatientEmail(email)),
  setPatientCellphone: (cellphone) => dispatch(setPatientCellphone(cellphone)),
  setPatientSecondCellphone: (secondCellphone) => dispatch(setPatientSecondCellphone(secondCellphone)),
  setPatientWhatsapp: (whatsapp) => dispatch(setPatientWhatsapp(whatsapp)),
  setPatientTelephone: (telephone) => dispatch(setPatientTelephone(telephone)),
  setPatientContactMethod: (contact) =>
    dispatch(setPatientContactMethod(contact))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatePatient)
