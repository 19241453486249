import React, { useEffect, useState, useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  showConfirmation,
  showAlert,
  showLoading,
  showApproveNegate
} from 'store/modules/alert/actions'
import { clearSession, checkSession } from 'store/modules/auth/actions'
import { setNotification, setConfirmationNotification, setConfirmationRegisters, setConfirmationRanking, setConfirmationTotals, setFirstTimeRegisters, setFirstTimeTotals, setFirstTimeRanking } from 'store/modules/notification/actions'
import { checkTelephony, setConfirmation, cleanConfirmation } from 'store/modules/telephony/actions'
import logo from 'assets/logo.png'
import { Icon } from 'semantic-ui-react'
import { formatPhone } from 'utils/formaters'
import Swal from 'sweetalert2'

import {
  Container,
  Side,
  Logo,
  Divisor,
  ClientName,
  Button,
  ClientPhone
} from './styled'

import PauseResumeButton from 'components/PauseResumeButton'
import IconButton from 'components/IconButton'
import StatusIndicator from 'components/StatusIndicator'
import Tabulation from 'components/Tabulation'
import RamalButton from 'components/RamalButton'
import PerfilButton from 'components/PerfilButton'
import BellButon from 'components/BellButon'
import telephony from 'consumers/telephony'
import Notification from 'consumers/notification'
import Counter from 'components/Counter'
import omnichanel from 'consumers/omnichanel'
import { titleCase } from 'utils/stringMethods'
import socketIO from 'socket.io-client'
import { useHistory } from 'react-router-dom'

import { servers } from 'utils/config'

const ENV = process.env.REACT_APP_OMNI
const baseUrl = servers[ENV] || servers.local

const io = socketIO(baseUrl)

function NavBar ({
  user,
  router,
  phone,
  clientName,
  duration,
  showConfirmation,
  clearSession,
  checkSession,
  checkTelephony,
  onToggleClick,
  token,
  showLoading,
  showAlert,
  showApproveNegate,
  setNotification,
  cleanConfirmation,
  setConfirmationNotification,
  setConfirmationTotals,
  setConfirmationRegisters,
  setConfirmationRanking,
  notifications,
  call,
  confirmation
}) {
  let pausedInterval
  let lineInterval
  checkSession()
  checkTelephony()
  const [showLink, setShowLink] = useState(false)

  const exit = () => {
    clearInterval(pausedInterval)
    clearInterval(lineInterval)
    clearSession()
  }

  const history = useHistory()

  const forceEnd = () => {
    showConfirmation(
      10,
      'Encerrar Atendimento?',
      `Você tem certeza que deseja encerrar o atendimento de ${formatPhone(
        phone || '00000000000'
      )}?`,
      async () => {
        try {
          const res = await telephony.forceEnd(token)
          showAlert(res.code, 'Atenção', res.message)
          checkSession()
        } catch (err) {
          showAlert(err.response.code, '', err.response.data.message)
        }
      }
    )
  }

  const resolveRetention = async (token) => {
    try {
      const response = await omnichanel.cancel({ name: user.name }, token)
      showAlert(response.code, 'Sucesso', `Agendamento do paciente ${clientName} resolvido com sucesso!`, '')
    } catch (err) {
      if (!err.response) {
        return showAlert(500, 'Erro', 'Falha ao se comunicar com o servidor!')
      }
      showAlert(err.response.code, 'Atenção', err.response.message)
    } finally {
      cleanConfirmation()
    }
  }

  useLayoutEffect(() => {
    setConfirmationNotification()
  }, [])

  useEffect(() => {
    io.on('connection', () => {
      console.log('connected')
    })
    io.on('broadcast', () => {
      setConfirmationRegisters()
      setConfirmationRanking()
      setConfirmationTotals()
      setConfirmationNotification()
    })
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    pausedInterval = setInterval(() => {
      checkSession()
    }, 6000)
    return () => {
      clearInterval(pausedInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const lineInterval = setInterval(() => {
      checkTelephony()
    }, 2000)
    return () => {
      clearInterval(lineInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    notifications.forEach(notification => {
      if (notification.type === 'warning' && notification.read === false) {
        Swal.fire({
          icon: 'info',
          title: notification.title,
          html: notification.body,
          heightAuto: false,
          allowOutsideClick: false,
          onClose: async () => {
            try {
              await Notification.readConfirm(token, notification._id)
              setConfirmationNotification()
              setNotification(token)
            } catch (err) {
              if (!err.message) {
                showAlert(500, 'Atenção', 'Falha ao se comunicar com o servidor!')
              } else {
                showAlert(400, 'Atenção', err.message, err.technical)
              }
            }
          }
        })
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  useEffect(() => {
    setNotification()
    const lineInterval = setInterval(() => {
      setNotification()
    }, 10000)
    return () => {
      clearInterval(lineInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const register = async () => {
    showLoading('Registrando...')
    try {
      const response = await telephony.registerRamal(token)
      showAlert(response.code, 'Sucesso', 'Ramal cadastrado')
    } catch (err) {
      if (!err.message) {
        showAlert(500, 'Atenção', 'Falha ao se comunicar com o servidor!')
      } else {
        showAlert(400, 'Atenção', err.message, err.technical)
      }
    }
  }

  useEffect(() => {
    if (!duration) {
      setShowLink(false)
    }
    if (duration >= 10) {
      setShowLink(true)
    }
  }, [duration])

  useEffect(() => {
    if (confirmation.id !== null && user.status === 'ending' && confirmation.phone === call.phone) {
      if (confirmation.retention === true) {
        showConfirmation(
          10, 'Atenção!', `Confirmar a retenção do paciente ${confirmation.name}?`, async () => {
            resolveRetention(confirmation.token)
          }
        )
      } else {
        showApproveNegate(
          200, `Escolha a resposta do agendamento do paciente ${confirmation.name}`, 'O paciente aceitou ou negou o agendamento? Caso não tenha recebido resposta do paciente clique no <strong>X</strong> no canto superior direito para fechar.',
          async () => {
            try {
              await omnichanel.confirm({ registerId: confirmation.id, answer: 'SIM', name: user.name }, confirmation.token)
              showAlert(200, 'Sucesso!', 'Agenda confirmada com sucesso!')
            } catch (err) {
              showAlert(err.code, 'Atenção', err.message)
            } finally {
              cleanConfirmation()
              setConfirmationNotification()
            }
          },
          async () => {
            try {
              await omnichanel.confirm({ registerId: confirmation.id, answer: 'NAO', name: user.name }, confirmation.token)
              showAlert(200, 'Sucesso!', 'Agenda negada com sucesso!')
            } catch (err) {
              showAlert(err.code, 'Atenção', err.message)
            } finally {
              cleanConfirmation()
              setConfirmationNotification()
            }
          },
          async () => {
            cleanConfirmation()
            setConfirmationNotification()
          }
        )
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.status, confirmation.id])

  return (
    <>
      <Container>
        <Side>
          <Button onClick={() => onToggleClick()}>
            <Icon name='bars' size='large' />
          </Button>
          <Logo src={logo} alt='Logo UX Agenda' />
          <Divisor />
          <ClientName></ClientName>
          <StatusIndicator registerUser={register} status={user.status} />
          <div>
            {['busy', 'ending'].includes(user.status) && (
              <>
                <ClientPhone className="link-style" onClick={() => history.push('/admin/agenda')}>{!clientName ? `${formatPhone(phone)}` : titleCase(clientName)}</ClientPhone>
                {clientName ? <ClientPhone style={{ whiteSpace: 'nowrap' }}>{`${formatPhone(phone)}`}</ClientPhone> : null}
              </>
            )}
          </div>
          {router.location.pathname !== '/admin/home' && user.level === 'operator' ? <Counter nav /> : ''}
          {['busy'].includes(user.status) && showLink && (
            <button type='button' className='link-button' onClick={() => forceEnd()}>
              Não está em atendimento?
            </button>
          )}
        </Side>
        <Side>
          <Tabulation/>
          <PauseResumeButton/>
          <RamalButton registerUser={register}/>
          <BellButon />
          <PerfilButton name={user.name?.split(' ')[0]}/>
          <IconButton
            icon='power'
            onClick={() =>
              showConfirmation(10, 'Sair ?', 'Você deseja mesmo sair ?', exit)
            }
          />
        </Side>
      </Container>
    </>
  )
}
NavBar.propTypes = {
  user: PropTypes.object,
  router: PropTypes.object,
  phone: PropTypes.string,
  showConfirmation: PropTypes.func,
  clearSession: PropTypes.func,
  checkSession: PropTypes.func,
  checkTelephony: PropTypes.func,
  onToggleClick: PropTypes.func,
  token: PropTypes.string,
  showLoading: PropTypes.func,
  showAlert: PropTypes.func,
  setNotification: PropTypes.func,
  duration: PropTypes.number,
  notifications: PropTypes.array,
  clientName: PropTypes.string
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    router: state.router,
    phone: state.telephony.call.phone,
    clientName: state.telephony.client.name,
    duration: state.telephony.call.duration,
    sessionTimeout: state.auth.sessionTimeout,
    token: state.auth.token,
    refreshToken: state.auth.refreshToken,
    confirmation: state.telephony.confirmation,
    call: state.telephony.call,
    notifications: state.notification.notifications
  }
}
const mapDispatchToProps = (dispatch) => ({
  showConfirmation: (type, title, text, func) =>
    dispatch(showConfirmation(type, title, text, func)),
  showAlert: (type, title, message, details, func) =>
    dispatch(showAlert(type, title, message, details, func)),
  showLoading: (type, title, message, func) =>
    dispatch(showLoading(type, title, message, func)),
  showApproveNegate: (type, title, message, func, negatefunc, close) =>
    dispatch(showApproveNegate(type, title, message, func, negatefunc, close)),
  clearSession: () => dispatch(clearSession()),
  checkSession: () => dispatch(checkSession()),
  checkTelephony: () => dispatch(checkTelephony()),
  setNotification: (token) => dispatch(setNotification(token)),
  setConfirmation: (id, token, retention) => dispatch(setConfirmation(id, token, retention)),
  cleanConfirmation: () => dispatch(cleanConfirmation()),
  setConfirmationNotification: () => dispatch(setConfirmationNotification()),
  setConfirmationTotals: () => dispatch(setConfirmationTotals()),
  setConfirmationRegisters: () => dispatch(setConfirmationRegisters()),
  setConfirmationRanking: () => dispatch(setConfirmationRanking())
})
export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
