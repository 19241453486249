import React from 'react'
import PropTypes from 'prop-types'

import { Btn } from './styled'

export default function Button ({
  children,
  filled,
  block,
  ...rest
}) {
  return (
    <Btn filled={filled} block={block} {...rest}>
      {children}
    </Btn>
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string
  ]).isRequired,
  filled: PropTypes.bool,
  block: PropTypes.bool
}
