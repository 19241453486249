import { all } from 'redux-saga/effects'

import auth from './auth/sagas'
import telephony from './telephony/sagas'
import resources from './resources/sagas'
import notification from './notification/sagas'

export default function * rootSaga () {
  return yield all([auth, resources, telephony, notification])
}
