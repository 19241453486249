import React from 'react'
import propTypes from 'prop-types'
import { Icon, Pagination } from 'semantic-ui-react'

export default function PaginationShort ({ totalPages, activePage, ...rest }) {
  return (
    <Pagination
      activePage={activePage}
      ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}      
      totalPages={totalPages}
      {...rest}
    />
  )
}

PaginationShort.propTypes = {
  totalPages: propTypes.number,
  activePage: propTypes.number
}