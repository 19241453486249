import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'
import Card from 'components/Card'
import DayAndButton from 'components/Charts/DayAndButton'
import { Doughnut, Line, Pie } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'

import moment from 'moment'

import TextBorderLeft from 'components/Charts/TextBorderLeft'
import CardIconDash from 'components/Charts/CardIconDash'
import { make24Hours } from 'utils/stringMethods'

import { connect } from 'react-redux'
import { setSwalLoading, closeAllSwal } from 'store/modules/alert/actions'
import { getNameDay, validateHolidays, isSaturday } from 'utils/formatDate'

import omniApi from 'consumers/omnichanel'

/* verdes */
const anotherLayout = {
  lighter: '#B7D5D4',
  light: '#77878B',
  normal: '#488286',
  dark: '#305252',
  darker: '#373E40'
}


const jeielColors = {
  green: '#99EA55',
  musgo: '#47C48E',
  orange: '#FF8B5D',
  pink: '#EB5583'
}

class OmniDashboard extends Component {

  constructor(props) {
    super(props)

    this.state = {      
      whats: 0,
      ura: 0,
      email: 0,
      ligacao: 0,      

      URA_HOURS: { yesterday: [], beforeYesterday: [] },
      WHATS_HOURS: { yesterday: [], beforeYesterday: [] },
      EMAIL_HOURS: { yesterday: [], beforeYesterday: [] },
      LIGACAO_HOURS: { yesterday: [], beforeYesterday: [] },      

      activeDay: 'today',

      waysToConfirm: ['URA', 'Whats', 'Email', 'Operador'],
      waysToConfirmData: [],

      confirmed: 0,
      notConfirmed: 0,
      canceled: 0,
      total: 0,

      days: {},
      notFormatDays: {},

      selected: 'today',
      activeDays: {},
      confirmColors: [jeielColors.green, jeielColors.musgo, jeielColors.orange, jeielColors.pink], // [colors.blue, colors.green, colors.red, colors.purple], // [colorLayout.blue, colorLayout.nyanza, colorLayout.lemon, colorLayout.brown],

      allTotals: {}
    }
  }    

  fixAllDaysMoment = (allDays) => {           

    if(isSaturday(allDays.tomorrow)) {      
      allDays.tomorrow = moment(allDays.tomorrow).add(1, 'day')
      // allDays.afterTomorrow = moment(allDays.afterTomorrow).add(1, 'day')
    }

    if(isSaturday(allDays.afterTomorrow)) {      
      allDays.afterTomorrow = moment(allDays.afterTomorrow).add(1, 'day')
    }

    if(isSaturday(allDays.beforeYesterday)) {      
      allDays.beforeYesterday = moment(allDays.beforeYesterday).subtract(1, 'day')
    }    

    if(isSaturday(allDays.yesterday)) {      
      allDays.yesterday = moment(allDays.yesterday).subtract(1, 'day')
      // allDays.beforeYesterday = moment(allDays.beforeYesterday).subtract(1, 'day')
    }

    return allDays
  }
  
  getDays = async () => {        
    let today = moment()
    let yesterday = moment(today).subtract(1, 'day')
    let beforeYesterday = moment(today).subtract(2, 'day')
    let tomorrow = moment(today).add(1, 'day')
    let afterTomorrow = moment(today).add(2, 'day')   
        
    let allDays = {
      today,
      yesterday,
      beforeYesterday,
      tomorrow,
      afterTomorrow
    }    

    allDays = this.fixAllDaysMoment(allDays)

    if (validateHolidays(allDays.beforeYesterday, moment(allDays.beforeYesterday).isoWeekday())) {
      allDays.beforeYesterday = moment(allDays.beforeYesterday).subtract(1, 'day')
    }
  
    if (validateHolidays(allDays.yesterday, moment(allDays.yesterday).isoWeekday())) {            
      allDays.yesterday = moment(allDays.yesterday).subtract(1, 'day')
      allDays.beforeYesterday = moment(allDays.beforeYesterday).subtract(1, 'day')
    }
  
    if (validateHolidays(allDays.tomorrow, moment(allDays.tomorrow).isoWeekday())) {      
      allDays.tomorrow = moment(allDays.tomorrow).add(1, 'day')
      allDays.afterTomorrow = moment(allDays.afterTomorrow).add(1, 'day')
    }      
    
    allDays = this.fixAllDaysMoment(allDays)

    if(allDays.tomorrow.format('DD/MM') == allDays.afterTomorrow.format('DD/MM')) {
      allDays.afterTomorrow = moment(allDays.afterTomorrow).add(1, 'day')
    }

    if(allDays.yesterday.format('DD/MM') == allDays.beforeYesterday.format('DD/MM')) {
      allDays.beforeYesterday = moment(allDays.beforeYesterday).subtract(1, 'day')
    }

    const days = {
      today: allDays.today.format('DD/MM'),
      yesterday: allDays.yesterday.format('DD/MM'),
      beforeYesterday: allDays.beforeYesterday.format('DD/MM'),
      tomorrow: allDays.tomorrow.format('DD/MM'),
      afterTomorrow: allDays.afterTomorrow.format('DD/MM')
    }    

    const notFormatDays = {
      today: allDays.today,
      yesterday: allDays.yesterday,
      beforeYesterday: allDays.beforeYesterday,
      tomorrow: allDays.tomorrow,
      afterTomorrow: allDays.afterTomorrow
    }

    this.setState({
      days,
      notFormatDays
    })
  }    

  makeQuery = () => {
    const { day } = this.state

    let query = ''

    if (day) query += `?day=${day}`

    return query
  }

  getDashValue = async () => {
    const { setSwalLoading, closeAllSwal } = this.props

    const query = this.makeQuery()

    setSwalLoading()
    try {
      const response = await omniApi.dashboard(query)
      
      const { totals, contacts } = response
      const { yesterday, beforeYesterday } = contacts

      this.setWhats(totals.whats)
      this.setURA(totals.voz)
      this.setEmail(totals.email)
      this.setLigacao(totals.ligacao)
      
      this.setWhatsByHour(yesterday.whats, beforeYesterday.whats)
      this.setUraByHour(yesterday.voz, beforeYesterday.voz)
      this.setEmailByHour(yesterday.email, beforeYesterday.email)
      this.setLigacaoByHour(yesterday.ligacao, beforeYesterday.ligacao)

      this.setConfirmed(totals.confirmed)
      this.setNotConfirmed(totals.notConfirmed)
      this.setCanceled(totals.canceled)

      this.setState({ total: totals.total })

      this.setWaysToConfirmData()

    } catch (err) {
      console.log(err)
    }
    closeAllSwal()

  }

  setWaysToConfirmData = () => {
    const { ura, whats, email, ligacao } = this.state

    this.setState({ waysToConfirmData: [ura, whats, email, ligacao] })
  }

  setConfirmed = (value) => {
    this.setState({ confirmed: value })
  }

  setNotConfirmed = (value) => {
    this.setState({ notConfirmed: value })
  }

  setCanceled = (value) => {
    this.setState({ canceled: value })
  }

  setWhats = (value) => {
    this.setState({ whats: value })
  }

  setWhatsByHour = (yesterday, beforeYesterday) => {
    this.setState({ WHATS_HOURS: {
      yesterday,
      beforeYesterday
    } })
  }

  setURA = (value) => {
    this.setState({ ura: value })
  }

  setUraByHour = (yesterday, beforeYesterday) => {
    this.setState({ URA_HOURS: {
      yesterday,
      beforeYesterday
    } })
  }

  setEmail = (value) => {
    this.setState({ email: value })
  }

  setEmailByHour = (yesterday, beforeYesterday) => {
    this.setState({ EMAIL_HOURS: {
      yesterday,
      beforeYesterday
    } })
  }

  setLigacao = (value) => {
    this.setState({ ligacao: value })
  }

  setLigacaoByHour = (yesterday, beforeYesterday) => {
    this.setState({ LIGACAO_HOURS: {
      yesterday,
      beforeYesterday
    } })
  }

  changeDay = (value, active) => {

    this.setState({ activeDay: active })
    this.setState({ day: value.format('YYYY-MM-DD') }, () => this.getDashValue())
  }

  changeActive = (value) => {
    const { activeDay } = this.state

    return activeDay === value ? true : false
  }

  subtractDaysMoment = (day, subtract) => {                
    return moment(day).subtract(subtract, 'day').format('DD/MM')
  }

  componentDidMount() {
    this.getDays()
    this.getDashValue()    
  }

  render() {
    const {

      whats,
      ura,
      email,
      ligacao,      

      confirmed,
      notConfirmed,
      canceled,
      total,

      URA_HOURS,
      WHATS_HOURS,
      EMAIL_HOURS,
      LIGACAO_HOURS,

      waysToConfirm,
      waysToConfirmData,

      confirmColors,      
      notFormatDays,
      days,
      day
    } = this.state

    return (      
      <Grid className="m-3">
        <Grid.Row columns="equal">
          <Grid.Column>
            <DayAndButton
              day={notFormatDays.beforeYesterday}
              active={this.changeActive('beforeYesterday')}
              onClick={() => this.changeDay(notFormatDays.beforeYesterday, 'beforeYesterday')}
            />
          </Grid.Column>

          <Grid.Column>
            <DayAndButton
              day={notFormatDays.yesterday}
              active={this.changeActive('yesterday')}
              onClick={() => this.changeDay(notFormatDays.yesterday, 'yesterday')}
            />
          </Grid.Column>

          <Grid.Column>
            <DayAndButton
              day={notFormatDays.today}
              active={this.changeActive('today')}
              onClick={() => this.changeDay(notFormatDays.today, 'today')}
            />
          </Grid.Column>

          <Grid.Column>
            <DayAndButton
              day={notFormatDays.tomorrow}
              active={this.changeActive('tomorrow')}
              onClick={() => this.changeDay(notFormatDays.tomorrow, 'tomorrow')}
            />
          </Grid.Column>

          <Grid.Column>
            <DayAndButton
              day={notFormatDays.afterTomorrow}
              active={this.changeActive('afterTomorrow')}
              onClick={() => this.changeDay(notFormatDays.afterTomorrow, 'afterTomorrow')}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns="equal">
          <Grid.Column>
            <CardIconDash
              color={confirmColors[0]}
              icon="logo-reddit"
              number={ura}
              description="URA"
            />
          </Grid.Column>

          <Grid.Column>
            <CardIconDash 
              color={confirmColors[1]} 
              icon="logo-whatsapp" 
              number={whats} 
              description="WhatsApp" />
          </Grid.Column>

          <Grid.Column>
            <CardIconDash 
              color={confirmColors[2]} 
              icon="mail-sharp" 
              number={email} 
              description="Email" />
          </Grid.Column>

          <Grid.Column>
            <CardIconDash 
              color={confirmColors[3]} 
              icon="headset-outline" 
              number={ligacao} 
              description="Operador" />
          </Grid.Column>
        </Grid.Row>        
          
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={6} className="mt-sm-6">
            <div className="card-appearance-dash p-3 flex flex-1">
              <Doughnut
                data={{
                  labels: waysToConfirm,
                  datasets: [
                    {
                      data: waysToConfirmData,
                      backgroundColor: confirmColors,
                      borderWidth: 0,
                      weight: 10
                    }
                  ]
                }}
                options={{
                  cutoutPercentage: 70,
                  legend: {
                    position: 'left'
                  },
                  plugins: {
                    datalabels: {
                      color: '#fff',
                      textAlign: 'center',
                      font: {
                        lineHeight: 1.3,
                        weight: 'bold',
                        size: 15
                      },
                      formatter: (value, ctx) => {
                        if(value === 0) return ''
                        else return value                              
                      }
                    }
                  }
                }}   
                height={125}             
              />                
            </div>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={6} className="mt-sm-6">
            <div className="card-appearance-dash p-3 flex flex-1">
              <Pie
                data={{
                  labels: ['Confirmados', 'Não confirmados'],
                  datasets: [
                    {
                      data: [confirmed, notConfirmed],
                      backgroundColor: [anotherLayout.normal, anotherLayout.lighter],
                      borderWidth: 0,
                      weight: 10
                    }
                  ]
                }}
                options={{
                  legend: {
                    position: 'left'
                  },
                  plugins: {
                    datalabels: {
                      color: '#fff',
                      textAlign: 'center',
                      font: {
                        lineHeight: 1.3,
                        weight: 'bold',
                        size: 15
                      },
                      formatter: (value, ctx) => {
                        const total = confirmed + notConfirmed
                        const percentage = (value * 100) / total
                        return percentage.toFixed() + '%'
                      }
                    }
                  }                  
                }} 
                height={125}
              />
            </div>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={4} className="mt-md-6">
            <div className="card-appearance-dash p-3 flex flex-1">
              <div className="mb-4">
                <TextBorderLeft title="Total de agendas para confirmar." description={total} color={anotherLayout.darker} />
              </div>

              <div className="mb-4">
                <TextBorderLeft title="Agenda confirmadas" description={`${confirmed} das quais ${canceled} são canceladas.`} color={anotherLayout.normal} />
              </div>

              <div className="mb-4">
                <TextBorderLeft title="Agendas não confirmados" description={notConfirmed} color={anotherLayout.lighter} />
              </div>              
            </div>                          
          </Grid.Column>          
        </Grid.Row>     

        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8} className="mt-md-6">          
            <Card noPadding>
              <h3 className="mt-1 text-center">Confirmados no dia {this.subtractDaysMoment(day, 2)}</h3>
              <Line
                data={{
                  labels: [...make24Hours()],
                  datasets: [
                    {
                      data: URA_HOURS.beforeYesterday,
                      backgroundColor: confirmColors[0],
                      borderColor: confirmColors[0],
                      borderWidth: 2,
                      label: waysToConfirm[0],
                      pointRadius: 5,
                      fill: false
                    },
                    {
                      data: WHATS_HOURS.beforeYesterday,
                      backgroundColor: confirmColors[1],
                      borderColor: confirmColors[1],
                      borderWidth: 2,
                      label: waysToConfirm[1],
                      pointRadius: 5,
                      fill: false
                    },
                    {
                      data: EMAIL_HOURS.beforeYesterday,
                      backgroundColor: confirmColors[2],
                      borderColor: confirmColors[2],
                      borderWidth: 2,
                      label: waysToConfirm[2],
                      pointRadius: 5,
                      fill: false
                    },
                    {
                      data: LIGACAO_HOURS.beforeYesterday,
                      backgroundColor: confirmColors[3],
                      borderColor: confirmColors[3],
                      borderWidth: 2,
                      label: waysToConfirm[3],
                      pointRadius: 5,
                      fill: false
                    }
                  ]
                }}                      
                options={{
                  scales: {
                    yAxes: [{
                      scaleLabel: {
                        labelString: 'Qtde. Confirmações realizadas no dia.',
                        display: true
                      },
                      ticks: {
                        stepSize: 15,
                        beginAtZero: true
                      }
                    }],
                    xAxes: [{
                      scaleLabel: {
                        labelString: 'Hora que foi realizado a confirmação.',
                        display: true
                      },
                      time: {
                        display: true,
                        stepSize: 15,
                        unit: 'minute'
                      }
                    }]
                  },
                  responsive: true,
                  plugins: {
                    datalabels: {
                      color: '#fff',
                      textAlign: 'center',
                      font: {
                        lineHeight: 1.3,
                        weight: 'bold',
                        size: 15
                      },
                      formatter: (value, ctx) => {
                        // const total = confirmed + notConfirmed
                        // const percentage = (value * 100) / total
                        return ''
                      }
                    }
                  }
                }}
              />
            </Card>                
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={8} className="mt-md-3">
            <Card noPadding>
              <h3 className="mt-1 text-center">Confirmados no dia {this.subtractDaysMoment(day, 1)}</h3>
              <Line
                data={{
                  labels: [...make24Hours()],
                  datasets: [
                    {
                      data: URA_HOURS.yesterday,
                      backgroundColor: confirmColors[0],
                      borderColor: confirmColors[0],
                      borderWidth: 2,
                      label: waysToConfirm[0],
                      pointRadius: 5,
                      fill: false
                    },
                    {
                      data: WHATS_HOURS.yesterday,
                      backgroundColor: confirmColors[1],
                      borderColor: confirmColors[1],
                      borderWidth: 2,
                      label: waysToConfirm[1],
                      pointRadius: 5,
                      fill: false
                    },
                    {
                      data: EMAIL_HOURS.yesterday,
                      backgroundColor: confirmColors[2],
                      borderColor: confirmColors[2],
                      borderWidth: 2,
                      label: waysToConfirm[2],
                      pointRadius: 5,
                      fill: false
                    },
                    {
                      data: LIGACAO_HOURS.yesterday,
                      backgroundColor: confirmColors[3],
                      borderColor: confirmColors[3],
                      borderWidth: 2,
                      label: waysToConfirm[3],
                      pointRadius: 5,
                      fill: false
                    }
                  ]
                }}                      
                options={{
                  scales: {
                    yAxes: [{
                      scaleLabel: {
                        labelString: 'Qtde. Confirmações realizadas no dia.',
                        display: true
                      },
                      ticks: {
                        stepSize: 15,
                        beginAtZero: true
                      }
                    }],
                    xAxes: [{
                      scaleLabel: {
                        labelString: 'Hora que foi realizado a confirmação.',
                        display: true
                      },
                      time: {
                        display: true,
                        stepSize: 15,
                        unit: 'minute'
                      }
                    }]
                  },
                  responsive: true,
                  plugins: {
                    datalabels: {
                      color: '#fff',
                      textAlign: 'center',
                      font: {
                        lineHeight: 1.3,
                        weight: 'bold',
                        size: 15
                      },
                      formatter: (value, ctx) => {
                        // const total = confirmed + notConfirmed
                        // const percentage = (value * 100) / total
                        return ''
                      }
                    }
                  }
                }}
              />
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal())
})

export default connect(mapStateToProps, mapDispatchToProps)(OmniDashboard)
