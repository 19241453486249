const env = process.env.REACT_APP_ENV

const servers = {
  local: 'http://localhost:3001/',
  gus: 'http://192.168.1.14:3001/',
  chris: 'http://10.10.0.213:3005/',
  jamile: 'http://10.10.0.235:3003/',
  jamileOmni: 'http://10.10.0.47:3003',
  jeiel: 'http://10.10.0.141:3001/',
  hamachi: 'http://25.88.159.196:3000/',
  hamachiGus: 'http://25.15.218.104:3002/',
  hamachiJeiel: 'http://25.104.29.238:3002/',
  hamachiJamile: 'http://25.97.111.183:3001/',
  hamachiJamileOmni: 'http://25.97.111.183:3003',
  dev: 'http://development.api.agenda.agxsoftware.com/',
  prod: 'http://api.agenda.agxsoftware.com/',
  prod_umbler: 'http://api-agenda-agxsoftware-com.umbler.net/',
  prod_omni: 'https://omnichannel-server.herokuapp.com/'
}

const externalApiToken = 'Bearer ' + process.env.REACT_APP_EXTERNAL_TOKEN

export {
  env,
  servers,
  externalApiToken
}
