
export const signin = (email, password) => ({
  type: 'SIGNIN_REQUESTED',
  payload: {
    email,
    password
  }
})

export const passwordRequest = email => ({
  type: 'FORGOT_PASSWORD',
  payload: { email }
})

export const getSessionData = () => ({
  type: 'SET_SESSION'
})

export const clearSession = token => ({
  type: 'CLEAR_AND_REDIRECT',
  payload: { token }
})

export const setPause = (pauseId, token) => ({
  type: 'SET_PAUSE',
  payload: {
    pauseId,
    token
  }
})

export const setResume = (token) => ({
  type: 'SET_RESUME',
  payload: {
    token
  }
})

export const resetState = () => ({
  type: 'RESET_STATE'
})

export const checkSession = () => ({
  type: 'CHECK_SESSION'
})