import React, { useState, useEffect } from 'react'
import { Modal, Table, Grid } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import RichText from 'components/Richtext'
import RichTextEditor from 'react-rte'
import Button from 'components/Button'

function ScheduleDescriptionModal ({
  open,
  onClose,
  setScheduleDescription,
  scheduleDescription,
  getDescription,
  schedule

}) {
  const [description, setDescription] = useState(scheduleDescription ? RichTextEditor.createValueFromString(scheduleDescription, 'html') : RichTextEditor.createEmptyValue())
  return (
    <Modal
      open={open}
      onClose={onClose}
      size='large'
      closeIcon
      style={{ padding: 15, paddingBottom: 45 }}>
      <Modal.Content style={{ padding: 0 }}>
        <Grid columns='equal'>

          <Grid.Row>
            <Grid.Column width={1}/>
            <Grid.Column width={12}>
              <RichText
                label="Descrição"
                onChange={(e) => {
                  setDescription(e)
                }
                }
                value={description}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={1}/>
            <Grid.Column width={10}>
              <div className='uk-text-right uk-margin'>
                <Button onClick={() => {
                  if (setScheduleDescription) { setScheduleDescription(schedule, description.toString('html')) }
                  if (getDescription) getDescription(description.toString('html'))
                  onClose()
                }}> Salvar</Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Modal.Content>

    </Modal>
  )
}

ScheduleDescriptionModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export default ScheduleDescriptionModal
