import styled from 'styled-components'

export const CardInfo = styled.div`
  display: grid;
  background-color: ${props => props.active ? 'rgba(0,0,0,.87)' : '#fff'};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 3px;
  grid-template-columns: 1fr 45px;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "title advert"
                        "body advert";
  width: 100%;
  height: 60px;
  padding: 7px;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 200ms linear;

  &:hover {
    background-color: ${props => props.active ? '' : 'rgba(0,0, 0, 0.02)'};
    cursor: pointer;
  }
  
  h2 {
    margin: 0px;
    grid-area: title;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.active ? '#fff' : '#343434'};
    font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
  }

  p {
    width: 100%;
    height: 100%;
    grid-area: body;
    align-self: center;
    font-size: 11px;
    color: ${props => props.active ? '#fff' : '#545454'};
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
  }
`

export const Notify = styled.div`
  grid-area: advert;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background-color: black;
  }
`
