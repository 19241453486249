import styled from 'styled-components'

export const Wrapper = styled.div`        
  display: flex;
  background-color: #010101;      
  border-radius: 15px;   
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.18);
`

export const Icon = styled.div`  
  flex-grow: 1;  
  justify-content: center;
  align-items: center;
  display: flex;

  background-color: #fff;
  
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`

export const Content = styled.div`
  flex-grow: 2;    
  justify-content: center;
  align-items: center;  

  padding: 10px;
  padding-left: 15px;
`

export const Number = styled.p`
  font-size: 25px;    
  font-weight: bold;
  margin-bottom: 5px;

  color: white;
`

export const Text = styled.p`
  font-size: 14px;
  font-weight: bold;    
  color: white;
`
