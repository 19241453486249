import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import PatientLayout from '../pages/_layouts/Patient'

export default function PatientRoute ({ component: Component, signupWidth, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <PatientLayout signupWidth={signupWidth}>
          <Component {...props} />
        </PatientLayout>
      )}
    />
  )
}

PatientRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
}
