import mvApi from 'services/mvApi'
const serviceTypes = {

  getServiceTypes: async () => {
    try {
      const response = await mvApi.get('/v0/soul/servicetypes')
      const serviceTypes = response.data.result.data.serviceTypes
      console.log('TIPOS', serviceTypes)
      return serviceTypes
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  }

}

export default serviceTypes
