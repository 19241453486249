import styled from 'styled-components'

export const Button = styled.button`
  border: none;
  background: none;
  color: ${props => props.dark ? '#121212' : '#FFF'};;
  border-radius: 18px;
  margin: ${props => props.small ? '0 2px' : '0 10px'};
  padding: ${props => props.small ? '0' : '4px'};
  transition: all 100ms linear;
  &:hover {
    background-color: ${props => props.basic ? 'none' : '#414141'};
  }

  ion-icon {
    font-size: ${props => props.small ? '18px' : '32px'};
  }

`
