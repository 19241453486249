import React, { useState, useEffect } from 'react'
import useEventListener from '@use-it/event-listener'
import PropTypes from 'prop-types'
import { Bounce } from 'react-activity'
import { connect } from 'react-redux'
import { signin, resetState } from 'store/modules/auth/actions'
import logo from '../../assets/logo.png'
import IconInput from '../../components/IconInput'
import { Button, PasswordLink, Logo, WrapperPasswordLink } from './styled'
import { Link } from 'react-router-dom'
import { ExecuteIfSwalIsClosed } from 'utils/execute'
import { env } from 'utils/config'

function Signin ({
  signin,
  loading,
  resetState,
  emailValidator,
  passwordValidator
}) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  useEffect(() => {
    resetState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEventListener('keypress', (e) => {
    ExecuteIfSwalIsClosed(e.keyCode, () => {
      signin(email, password)
    })
  })

  return (
    <>
      <form autoComplete='true' style={{ width: '100%', display: 'contents' }}>
        <Logo src={logo} width='160px' alt='UX Agenda Logo' />
        <IconInput
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Email'
          type='email'
          unit
          icon='person-circle'
          error={emailValidator.message}
        />
        <IconInput
          placeholder='Senha'
          onChange={(e) => setPassword(e.target.value)}
          type='password'
          icon='lock-closed'
          error={passwordValidator.message}
        />

        <WrapperPasswordLink>
          {env !== 'prod' &&
            <PasswordLink style={{ textAlign: 'left' }}>
              <Link to='/signup'>Cadastrar</Link>
            </PasswordLink>
          }

          <PasswordLink>
            <Link to='/resetpassword'>Esqueceu a senha?</Link>
          </PasswordLink>
        </WrapperPasswordLink>

        <Button type='button' onClick={() => signin(email, password)}>
          {loading ? (
            <Bounce color='#FCFCFC' size={22} speed={1} animating={true} />
          ) : (
            'Entrar'
          )}
        </Button>
      </form>
    </>
  )
}

Signin.propTypes = {
  signin: PropTypes.func,
  resetState: PropTypes.func,
  loading: PropTypes.bool,
  emailValidator: PropTypes.exact({
    isValid: PropTypes.bool,
    message: PropTypes.string
  }),
  passwordValidator: PropTypes.exact({
    isValid: PropTypes.bool,
    message: PropTypes.string
  })
}

const mapStateToProps = (state) => ({
  emailValidator: state.auth.emailValidator,
  passwordValidator: state.auth.passwordValidator,
  loading: state.auth.loading
})

const mapDispatchToProps = (dispatch) => ({
  signin: (email, password) => dispatch(signin(email, password)),
  resetState: () => dispatch(resetState())
})

export default connect(mapStateToProps, mapDispatchToProps)(Signin)
