import React, { createRef, Component } from 'react'
import Card from 'components/Card'
import { Table, Popup, Grid, Button } from 'semantic-ui-react'
import { formatPhone, formatCpf, getFirstLetters, getFirstWord } from 'utils/formaters'
import { titleCase } from 'utils/stringMethods'
import StatusLabel from 'components/StatusLabel'
import { connect } from 'react-redux'
import {
  toUsDisplay,
  dateToUsSmall
  , difference6Hours
} from 'utils/formatDate'
import {
  makeCall,
  setConfirmation
} from 'store/modules/telephony/actions'
import {
  showOptionSelect,
  showConfirmation,
  setSwalLoading,
  closeAllSwal,
  showAlert,
  showApproveNegate
} from 'store/modules/alert/actions'
import IconButton from 'components/IconButton'

import socketIOClient from 'socket.io-client'

import { Bounce } from 'react-activity'
import CardOmniValues from 'components/CardOmniValues'
import omnichannel from 'consumers/omnichanel'
import ClickToCall from 'components/ClickToCall'
import PaginationShort from 'components/Pagination'
import Input from 'components/Input'
import PropTypes from 'prop-types'
import { setFirstTimeRegisters, setFirstTimeTotals } from 'store/modules/notification/actions'

class WhatsAppDashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 1,
      limit: 10,
      name: '',
      user: '',
      expandDate: false
    }

    this.nameRef = createRef()
  }

  makeQuery = () => {
    const { page, name, limit } = this.state

    let query = ''

    if (page) query += `?page=${page}`
    if (limit) query += `&limit=${limit}`
    if (name) query += `&name=${name}`

    return query
  }

  getRegisters = async () => {
    const { showAlert, setFirstTimeRegisters } = this.props
    const { page, name, limit } = this.state

    try {
      const response = await omnichannel.get({ page, limit, name })

      setFirstTimeRegisters(response)
    } catch (error) {
      if (error.message === 'Horário de confirmação finalizado!') showAlert('warning', 'Atenção', 'Horário de confirmação finalizado!')
      else if (!error.response) showAlert(500, 'Erro', 'Falha ao se comunicar com o servidor!')
      else showAlert('warning', 'Atenção', error.response.message)
    }
  }

  getTotals = async () => {
    const { showAlert, setFirstTimeTotals } = this.props

    try {
      const response = await omnichannel.totals()

      setFirstTimeTotals(response.data)
    } catch (error) {
      if (!error.response) showAlert(500, 'Erro', 'Falha ao se comunicar com o servidor!')
      else showAlert('warning', 'Atenção', error.response.message)
    }
  }

  getAll = () => {
    this.getRegisters()
    this.getTotals()
  }

  confirmSchedule = async (data) => {
    const { user } = this.state
    const { showAlert, showApproveNegate } = this.props

    showApproveNegate(
      200, `Escolha a resposta do agendamento do paciente ${data.name}`, 'O paciente aceitou ou negou o agendamento? Caso não tenha recebido resposta do paciente clique no <strong>X</strong> no canto superior direito para fechar.',
      async () => {
        try {
          await omnichannel.confirm({ registerId: data.id, answer: 'SIM', name: user.name }, data.token)
          showAlert(200, 'Sucesso!', 'Agenda confirmada com sucesso!')
        } catch (error) {
          if (!error.response) showAlert(500, 'Erro', 'Falha ao se comunicar com o servidor!')
          else showAlert('warning', 'Atenção', error.response.message)
        }
      },
      async () => {
        try {
          omnichannel.confirm({ registerId: data.id, answer: 'NAO', name: user.name }, data.token)
          showAlert(200, 'Sucesso!', 'Agenda negada com sucesso!')
        } catch (error) {
          if (!error.response) showAlert(500, 'Erro', 'Falha ao se comunicar com o servidor!')
          else showAlert('warning', 'Atenção', error.response.message)
        }
      },
      () => { console.log('ok') }
    )
  }

  resolveRetention = async (data) => {
    const { user } = this.state
    const { showAlert } = this.props

    try {
      const response = await omnichannel.cancel({ name: user.name }, data.token)
      showAlert(response.code, 'Sucesso', `Agendamento do paciente ${data.name} resolvido com sucesso!`, '', () => this.getAll())
    } catch (error) {
      if (!error.response) showAlert(500, 'Erro', 'Falha ao se comunicar com o servidor!')
      else showAlert('warning', 'Atenção', error.response.message)
    }
  }

  updateTryOmnichanel = async (token, data) => {
    const { showAlert } = this.props

    try {
      await omnichannel.update(token, data)
    } catch (error) {
      if (!error.response) showAlert(500, 'Erro', 'Falha ao se comunicar com o servidor!')
      else showAlert('warning', 'Atenção', error.response.message)
    }
  }

  changePage = (page) => this.setState({ page }, () => this.getRegisters())

  searchName = (name) => {
    if (name.length >= 3 || name.length === 0) this.setState({ name }, () => this.getRegisters())
  }

  toggleExpandDate = () => this.setState({ expandDate: !this.state.expandDate })

  setRegisterIdConfirmation = async (data, phone) => {
    const { user } = this.state
    const { setConfirmation } = this.props

    setConfirmation(data.registerId, data.token, data.refused, data.name, phone)
    this.updateTryOmnichanel(data.token, { type: 'ligacao', ramal: user.ramal })
  }

  componentDidMount () {
    const { registers } = this.props

    if (!registers) this.getAll()
    // eslint-disable-next-line react/prop-types
    this.setState({ user: this.props.user })
  }

  render () {
    const { page, expandDate, user } = this.state
    const { showConfirmation, totals, registers, totalPages } = this.props

    return (
      <>
        <Card noBg title='Confirmações de Agenda'>
          {totals
            ? <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <CardOmniValues
                    header={totals.beforeYesterday.date}
                    totalConfirmed={totals.beforeYesterday.confirmed}
                    totalNotConfirmed={totals.beforeYesterday.notConfirmed}
                    total={totals.beforeYesterday.confirmed + totals.beforeYesterday.notConfirmed}
                    data={totals.beforeYesterday.data}
                  />
                </Grid.Column>

                <Grid.Column>
                  <CardOmniValues
                    header={totals.yesterday.date}
                    totalConfirmed={totals.yesterday.confirmed}
                    totalNotConfirmed={totals.yesterday.notConfirmed}
                    total={totals.yesterday.confirmed + totals.yesterday.notConfirmed}
                    data={totals.yesterday.data}
                  />
                </Grid.Column>

                <Grid.Column>
                  <CardOmniValues
                    header={totals.today.date + ' (confirmar hoje)'}
                    totalConfirmed={totals.today.confirmed}
                    totalNotConfirmed={totals.today.notConfirmed}
                    total={totals.today.confirmed + totals.today.notConfirmed}
                    data={totals.today.data}
                  />
                </Grid.Column>

                <Grid.Column>
                  <CardOmniValues
                    header={totals.tomorrow.isMultiple ? totals.tomorrow.date + ' (confirmar hoje)' : totals.tomorrow.date}
                    totalConfirmed={totals.tomorrow.confirmed}
                    totalNotConfirmed={totals.tomorrow.notConfirmed}
                    total={totals.tomorrow.confirmed + totals.tomorrow.notConfirmed}
                    data={totals.tomorrow.data}
                  />
                </Grid.Column>

                <Grid.Column>
                  <CardOmniValues
                    header={totals.afterTomorrow.date}
                    totalConfirmed={totals.afterTomorrow.confirmed}
                    totalNotConfirmed={totals.afterTomorrow.notConfirmed}
                    total={totals.afterTomorrow.confirmed + totals.afterTomorrow.notConfirmed}
                    data={totals.afterTomorrow.data}
                  />
                </Grid.Column>

              </Grid.Row>
            </Grid>
            : <>
              <Grid centered>
                <Grid.Row>
                  <Bounce
                    className="bounce-margin"
                    color="#212121"
                    size={22}
                    speed={1}
                    animating={true}
                  />
                </Grid.Row>
              </Grid>
            </>
          }

        </Card>
        <Card>
          {!registers ? <>
            <Grid centered>
              <Grid.Row>
                <Bounce
                  className="bounce-margin"
                  color="#212121"
                  size={22}
                  speed={1}
                  animating={true}
                />
              </Grid.Row>
            </Grid>
          </>
            : <>
              <Grid>
                <Grid.Row>

                  <Grid.Column width={3}>
                    <Input
                      label='Nome'
                      inputRef={this.nameRef}
                      placeholder='Digite o nome do paciente'
                      onChange={(e) => this.searchName(e.target.value)}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Table celled striped padded compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Nome</Table.HeaderCell>
                    <Table.HeaderCell>Médico</Table.HeaderCell>
                    <Table.HeaderCell>Agenda</Table.HeaderCell>
                    <Table.HeaderCell>
                      Data
                      <IconButton
                        basic
                        dark
                        small
                        onClick={() => this.toggleExpandDate()}
                        icon={expandDate ? 'crop' : 'expand'}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell>CPF</Table.HeaderCell>
                    <Table.HeaderCell>Telefone 1</Table.HeaderCell>
                    <Table.HeaderCell>Telefone 2</Table.HeaderCell>
                    <Table.HeaderCell>Tentativas</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {registers.map((register) => (
                    <Table.Row key={register.id}>
                      <Table.Cell style={{ maxWidth: '95px' }}>
                        { register.refused
                          ? <StatusLabel type="canceled">
                            <Popup
                              trigger={ <span>Retenção</span> }
                              content="Paciente já cancelou o agendamento e precisa ser retido para confirmação da decisão ou reagendamento."
                              on='hover'
                            />
                          </StatusLabel>
                          : <StatusLabel type="pending">
                            <Popup
                              trigger={ <span>Pendente</span> }
                              content="Paciente ainda não confirmou seu agendamento."
                              on='hover'
                            />
                          </StatusLabel>
                        }
                      </Table.Cell>
                      <Table.Cell>{titleCase(register.name)}</Table.Cell>
                      <Table.Cell>
                        <Popup
                          trigger={<span style={{ textDecoration: 'underline dotted', cursor: 'default' }}>{register.doctor ? titleCase(getFirstWord(register.doctor)) : 'N/A'}</span>}
                          content={register.doctor ? titleCase(register.doctor) : 'N/A'}
                          on='hover'
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Popup
                          trigger={<span style={{ textDecoration: 'underline dotted', cursor: 'default' }}>{getFirstLetters(register.consult)}</span>}
                          content={register.consult}
                          on='hover'
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {expandDate
                          ? toUsDisplay(register.scheduleDate, true)
                          : dateToUsSmall(register.scheduleDate)}
                        {difference6Hours(register.scheduleDate) > -6 ? (
                          <span style={{ color: 'red' }}> ***</span>
                        ) : (
                          ''
                        )}
                      </Table.Cell>
                      <Table.Cell>{formatCpf(register.cpf)}</Table.Cell>
                      <Table.Cell textAlign='center'>
                        {register.phone1
                          ? <ClickToCall
                            label={formatPhone(register.phone1)}
                            to={register.phone1}
                            confirmation={() => this.setRegisterIdConfirmation(register, register.phone1)}
                            onClick={() => this.updateTryOmnichanel(register.token, user.name)}
                          />
                          : <span className='unimportant-text'>N/A</span>
                        }
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        {register.phone2
                          ? <ClickToCall
                            label={formatPhone(register.phone2)}
                            to={register.phone2}
                            confirmation={() => this.setRegisterIdConfirmation(register, register.phone2)}
                            onClick={() => this.updateTryOmnichanel(register.token, user.name)}
                          />
                          : <span className='unimportant-text'>N/A</span>
                        }
                      </Table.Cell>

                      <Table.Cell>{register.called}</Table.Cell>

                      <Table.Cell>
                        {register.refused
                          ? <Button
                            onClick={() => {
                              showConfirmation(409, 'Atenção', 'Tem certeza que deseja finalizar esse agendamento, os dados do paciente sairão da tabela.', () => {
                                this.resolveRetention(register)
                              })
                            }}
                          >Resolver</Button>
                          : <Button
                            onClick={() => {
                              showConfirmation(409, 'Atenção', 'Tem certeza que deseja finalizar esse agendamento, os dados serão alterados no MV.', () => {
                                this.confirmSchedule(register)
                              })
                            }}
                          >Confirmar agenda</Button>}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>

              <PaginationShort
                onPageChange={(e) => this.changePage(e.target.getAttribute('value'))}
                totalPages={totalPages}
                activePage={page}
              />
            </>
          }
        </Card>
      </>
    )
  }
}

WhatsAppDashboard.propTypes = {
  registers: PropTypes.array,
  totalDocs: PropTypes.number,
  totals: PropTypes.object,
  showAlert: PropTypes.func,
  showApproveNegate: PropTypes.func,
  showConfirmation: PropTypes.func,
  setFirstTimeRegisters: PropTypes.func,
  setFirstTimeTotals: PropTypes.func,
  setConfirmation: PropTypes.func,
  totalPages: PropTypes.number
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notification,
    token: state.auth.token,
    user: state.auth.user,
    registers: state.notification.registers,
    totalDocs: state.notification.totalDocs,
    totals: state.notification.totals,
    totalPages: state.notification.totalPages
  }
}

const mapDispatchToProps = (dispatch) => ({
  showOptionSelect: (title, options, func) =>
    dispatch(showOptionSelect(title, options, func)),
  makeCall: (phone) => dispatch(makeCall(phone)),
  showAlert: (type, title, text, details, func) => dispatch(showAlert(type, title, text, details, func)),
  setConfirmation: (id, token, refused, name, phone) => dispatch(setConfirmation(id, token, refused, name, phone)),
  showConfirmation: (type, title, text, func) =>
    dispatch((showConfirmation(type, title, text, func))),
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal()),
  showApproveNegate: (type, title, message, func, negatefunc, close) =>
    dispatch(showApproveNegate(type, title, message, func, negatefunc, close)),
  setFirstTimeRegisters: (msg) => dispatch(setFirstTimeRegisters(msg)),
  setFirstTimeTotals: (msg) => dispatch(setFirstTimeTotals(msg))
})

export default connect(mapStateToProps, mapDispatchToProps)(WhatsAppDashboard)
