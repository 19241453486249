import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 300ms linear;
  border-radius: 6px;
  &:hover {
    background-color: rgba(250, 250, 250, 0.2);
    cursor: pointer;
  }
`

export const PausedText = styled.span`
  font-size: 16px;
  color: ${(props) => (props.paused ? '#44F' : '#FFF')};
`

export const Button = styled.button`
  background: none;
  border: none;
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  transition: all 300ms linear;
  border-radius: 6px;
  &:hover {
    background-color: rgba(250, 250, 250, 0.2);
    cursor: pointer;
  }
`
