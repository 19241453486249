import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import { Container, Title, Header, FullContainer } from './styled'

export default function Card ({ children, title, sideButtonPress, titleSize, sideButtonText, contentRef, center, noBg, noPadding, ...rest }) {
  return (
    <FullContainer noPadding={noPadding}>

      {title &&
        <Header><Title titleSize={titleSize}>{title}</Title>{sideButtonPress ? <Button filled onClick={sideButtonPress}>{sideButtonText}</Button> : null}</Header>
      }

      <Container {...rest} ref={contentRef} center={center} noBg={noBg} noPadding={noPadding}>
        {children}
      </Container>

    </FullContainer>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  sideButtonText: PropTypes.string,
  contentRef: PropTypes.any,
  center: PropTypes.bool,
  sideButtonPress: PropTypes.func,
  titleSize: PropTypes.string
}
