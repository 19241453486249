import React from 'react'

import { Wrapper, Title, Description } from './styled'

export default function TextBorderLeft ({ title, description, color }) {
  return (
    <Wrapper color={color}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Wrapper>
  )
}
