import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Switch, Route } from 'react-router-dom'
import { push } from 'connected-react-router'

import ListSchedules from 'components/ListSchedule'
import ScheduleDetails from 'components/ScheduleDetails'

import Card from 'components/Card'

export class Schedule extends Component {

  render() {

    const { pathname } = this.props.router.location
    const isRoot = pathname === '/admin/agendamentos' || pathname === '/admin/agendamentos/'

    if (isRoot) {
      this.props.push('/admin/agendamentos/listar')
    }

    return (
      <Switch>
        <Route
          path={'/admin/agendamentos/listar'}
          key='scList'>

          <Card
            title={'Agendamentos'}>
            <ListSchedules />
          </Card>
          
        </Route>

        <Route
          path={'/admin/agendamentos/:id'}
          component={ScheduleDetails}
          key='scGet'
        />
      </Switch>
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  router: state.router
})

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path))
})

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)