import React, { PureComponent } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import propTypes from 'prop-types'

import { Label, ErrorMsg, Spacer } from './styled'

import 'react-datepicker/dist/react-datepicker.css'
import ptbr from 'date-fns/locale/pt-BR' // the locale you want
registerLocale('pt-BR', ptbr) // register it with the name you want

export default class CalendarInput extends PureComponent {
  constructor(props) {
    super(props)
    this.inputRef = this.props.inputRef || React.createRef('')
  }
  componentDidMount () {
    this.inputRef.current.input.setAttribute('data-inputmask', "'mask': '99/99/9999'")
    this.inputRef.current.input.setAttribute('placeholder', 'Escolha a data')
  }

  render () {
    const hasError = !!(this.props.error && this.props.error.trim())

    return (
      <>
        <Label>{this.props.label}</Label>
        <br />
        <DatePicker
          ref={this.inputRef}
          selected={this.props.selected}
          onSelect={this.props.onSelect}
          disabled={this.props.disabled}
          locale='pt-BR'
          showYearDropdown
          scrollableYearDropdown
          showMonthDropdown
          yearDropdownItemNumber={100}
          maxDate={
            new Date(new Date().setFullYear(new Date().getFullYear() + 1))
          }
          dateFormat='dd/MM/yyyy'
          className={hasError ? 'Date-picker Date-picker-error' : 'Date-picker'}
        />
        {hasError ? <ErrorMsg>{this.props.error}</ErrorMsg> : <Spacer />}
      </>
    )
  }
}

CalendarInput.propTypes = {
  label: propTypes.string
}
