import mvApi from 'services/mvApi'

const exams = {

  get: async (data = undefined) => {
    try {
      const response = await mvApi.get('/v0/soul/providers', { params: data ? { specialtyCode: data.specialtyCode } : undefined })
      console.log('PROVIDERS', response)
      if (response.data.result) return response.data.result.data.providers
    } catch (e) {
      throw e.response.data
    }
  },

  getIdsBySpecialty: async (specialty) => {
    try {
      console.log('ESPECIALIDADE', specialty)
      const response = await mvApi.get(`/v0/soul/providers/specialty/${specialty}`)
      return response.data.data.ids
    } catch (e) {
      throw e.response.data
    }
  }
}

export default exams
