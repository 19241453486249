import { call, put, all, takeLatest, select } from 'redux-saga/effects'
import notification from 'consumers/notification'
import omnichanel from 'consumers/omnichanel'

function * setNotification (action) {
  const token = yield select((state) => state.auth.token)

  try {
    const response = yield call(notification.list, token)

    yield put({
      type: 'SET_NOTIFICATIONS',
      notifications: response.data.notifications
    })
  } catch (err) {
  }
}

function * setConfirmationNotification () {
  try {
    const response = yield call(omnichanel.notification)
    const { total } = response.data

    yield put({
      type: 'SET_CONFIRMATION_NOTIFICATIONS',
      confirmation: total
    })
  } catch (err) {
    console.log(err)
  }
}

function * setConfirmationRegisters () {
  try {
    const response = yield call(omnichanel.get)

    yield put({
      type: 'SET_CONFIRMATION_REGISTERS',
      registers: response.registers,
      totalDocs: response.totalDocs
    })
  } catch (err) {
    console.log(err)
  }
}

function * setConfirmationTotals () {
  try {
    const response = yield call(omnichanel.totals)

    yield put({
      type: 'SET_CONFIRMATION_TOTALS',
      totals: response.data.totals
    })
  } catch (err) {
    console.log(err)
  }
}

function * setConfirmationRanking () {
  try {
    const response = yield call(omnichanel.ranking)

    yield put({
      type: 'SET_CONFIRMATION_RANKING',
      ranking: response.data.ranking
    })
  } catch (err) {
    console.log(err)
  }
}

export default all([
  takeLatest('SET_NOTIFICATION', setNotification),
  takeLatest('GET_CONFIRMATION_NOTIFICATION', setConfirmationNotification),
  takeLatest('GET_CONFIRMATION_REGISTERS', setConfirmationRegisters),
  takeLatest('GET_CONFIRMATION_TOTALS', setConfirmationTotals),
  takeLatest('GET_CONFIRMATION_RANKING', setConfirmationRanking)
])
