import axios from 'axios'
import { servers, externalApiToken, env } from 'utils/config'

const baseUrl = servers[env] || servers.local

const mvApi = axios.create({
  baseURL: baseUrl,
  timeout: 30000
})

mvApi.defaults.headers.post['Content-Type'] = 'application/json'
mvApi.defaults.headers.common.Authorization = externalApiToken

export default mvApi
