import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showAlert, setSwalLoading, closeAllSwal } from 'store/modules/alert/actions'
import { Grid, Radio, Form, Header } from 'semantic-ui-react'
import { Bounce } from 'react-activity'
import { push } from 'connected-react-router'
import Card from 'components/Card'

import CreatePerson from 'components/CreatePerson'
import OperatorTable from 'components/OperatorTable'

import operator from 'consumers/operator'

class OperatorDetails extends Component {
  constructor (props) {
    super(props)

    this.state = {
      operatorInfo: undefined,
      errorMessages: {},
      loading: false,
      selectedOption: 'day',
      label: 'Dia'
    }

    this.onValueChange = this.onValueChange.bind(this)
  }

  getOperator = async () => {
    const { token, showAlert, setSwalLoading, closeAllSwal } = this.props
    const { operatorInfo, selectedOption } = this.state

    const { id } = this.props.match.params

    try {
      setSwalLoading()
      const response = await operator.getById(token, id, selectedOption)

      if (JSON.stringify(operatorInfo) !== JSON.stringify(response.data)) {
        this.setState({ operatorInfo: response.data })
        if(selectedOption === 'day') this.setState({ label: 'Hoje' })
        else if(selectedOption === 'week') this.setState({ label: 'Semana' })
        else if(selectedOption === 'month') this.setState({ label: 'Mês' })
      }
      closeAllSwal()
    } catch (err) {
      if (!err.response) {
        return showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
      }
      showAlert(400, 'Atenção', err.message, err.technical)
      this.setState({ operatorInfo: undefined })
    }
  }

  updateOperator = async (id, data) => {
    const { token, showAlert, push } = this.props

    this.setState({ loading: true })
    try {
      const response = await operator.update(token, id, data)
      showAlert(response.code, 'Sucesso!', response.message, '', () => {
        push('/admin/operadores/listar')
      })
    } catch (err) {
      if (err.invalid) {
        const errorMessages = {}
        err.invalid.forEach((field) => {
          errorMessages[field.name] = field.message
        })
        this.setState({ errorMessages })
        showAlert('warning', 'Atenção', err.message)
      }
    }
    this.setState({ loading: false })
  }

  onValueChange (e, { value }) {
    this.setState({ selectedOption: value }, () => {
      this.getOperator()
    })
  }

  componentDidMount () {
    this.getOperator()
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  returnListOperator = () => {
    this.props.push('/admin/operadores/listar')
  }

  render () {
    const { operatorInfo, label } = this.state

    if (!operatorInfo) {
      return (
        <Card
          title='Operadores'>
          <Grid centered>
            <Grid.Row>
              <Bounce className="bounce-margin" color="#212121" size={22} speed={1} animating={true} />
            </Grid.Row>
          </Grid>
        </Card>
      )
    }

    return (
      <>
        <Card
          title='Operadores'
          sideButtonPress={this.returnListOperator}
          sideButtonText='Voltar'>
          <CreatePerson
            onSubmit={this.updateOperator}
            invalidFields={this.state.errorMessages}
            type='operator'
            loading={this.state.loading}
            {...this.props}
          />
        </Card>
        <Card
          title='Detalhes do operador'>
          <Grid centered>
            <Grid.Row><p style={{ padding: '10px' }}>Escolha o período desejado: </p></Grid.Row>
            <Form>
              <Form.Group inline>
                <Form.Field>
                  <Radio
                    label='Hoje'
                    type="radio"
                    name="day"
                    value="day"
                    checked={this.state.selectedOption === 'day'}
                    onChange={this.onValueChange}
                  /></Form.Field>
                <Form.Field>
                  <Radio
                    label='Semana'
                    type="radio"
                    name="week"
                    value="week"
                    checked={this.state.selectedOption === 'week'}
                    onChange={this.onValueChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Este mês"
                    type="radio"
                    name="month"
                    value="month"
                    checked={this.state.selectedOption === 'month'}
                    onChange={this.onValueChange}
                  />
                </Form.Field></Form.Group>
            </Form>
          </Grid>

          <Grid centered>
            <Header>{label}</Header>
          </Grid>

          <Grid>
            <Grid.Row columns='three'>
              <Grid.Column>
                <OperatorTable
                  header='Agendamentos'
                  content={[{ title: 'Total', value: operatorInfo.schedules.total }]}
                  objectContent={[operatorInfo.schedules.situation, operatorInfo.schedules.consultType]}
                  translate={['situation', 'consultType']}
                />
              </Grid.Column>

              <Grid.Column>
                <OperatorTable
                  header='Tabulações'
                  content={[{ title: 'Total', value: operatorInfo.tabulations.total }]}
                  objectContent={operatorInfo.tabulations.types}
                />
              </Grid.Column>

              <Grid.Column>
                <OperatorTable
                  header='Pausas'
                  content={[
                    { title: 'Total', value: operatorInfo.pauses.total },
                    { title: 'Tempo por pausa', value: operatorInfo.pauses.timePerPause },
                    { title: 'Tempo total', value: operatorInfo.pauses.time }
                  ]}
                  objectContent={operatorInfo.pauses.types}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>
      </>
    )
  }
}

OperatorDetails.propTypes = {
  token: PropTypes.string,
  showAlert: PropTypes.func,
  push: PropTypes.func,
  match: PropTypes.object
}

const mapStateToProps = (state) => ({
  token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
  push: (url) => dispatch(push(url)),
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal()),
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func))
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatorDetails)
