import mvApi from 'services/mvApi'

const exams = {

  get: async (id, token, data) => {
    try {
      const response = await mvApi.get('/v0/soul/examItens')
      console.log('Exames', response.data)
      return response.data.result.data.examsItens
    } catch (e) {
      throw e.response.data
    }
  }
}

export default exams
