import styled from 'styled-components'

export const Btn = styled.button`
  font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: ${props => props.filled ? '#fff' : '#000'};
  background-color: ${props => props.filled ? '#002D5C' : '#fff'};
  height: 40px;
  width: ${props => props.block ? '100%' : null};
  padding: 0px 30px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  transition: all 100ms linear;

  &:hover {
    background-color: ${props => props.filled ? '#143d6d' : '#fcfcfc'};
    box-shadow: ${props => props.filled ? '0px 3px 6px rgba(0, 0, 0, 0.35)' : '0px 2px 6px rgba(0, 0, 0, 0.25)'};
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    background-color: #0e4782;
    &:hover {
      cursor: not-allowed
    }
    &:active {
    transform: none;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  }
  }
`
