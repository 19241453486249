import React, { useEffect } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import propTypes from 'prop-types'
import './styles.css'

import { Label, ErrorMsg, Spacer } from './styled'

import 'react-datepicker/dist/react-datepicker.css'
import ptbr from 'date-fns/locale/pt-BR' // the locale you want
registerLocale('pt-BR', ptbr) // register it with the name you want

export default function CalendarHoursInput ({ label, inputRef, selected, onSelect, error }) {
  const ref = inputRef || React.createRef('')
  useEffect(() => {

    ref.current.input.setAttribute('data-inputmask', "'mask': '99/99/9999 99:99'")
    ref.current.input.setAttribute('placeholder', 'Escolha a data')
  })
  const hasError = !!(error && error.trim())

  return (
    <>
      <Label>{label}</Label>
      <br />
      <DatePicker
        ref={ref}
        selected={selected}
        onChange={onSelect}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Hora"
        locale='pt-BR'
        showYearDropdown
        scrollableYearDropdown
        showMonthDropdown
        yearDropdownItemNumber={100}
        maxDate={
          new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        }
        dateFormat='dd/MM/yyyy HH:mm'
        className={hasError ? 'Date-picker Date-picker-error' : 'Date-picker'}
      />
      {hasError ? <ErrorMsg>{error}</ErrorMsg> : <Spacer />}
    </>
  )
}

CalendarHoursInput.propTypes = {
  label: propTypes.string,
  inputRef: propTypes.object,
  selected: propTypes.func,
  onSelect: propTypes.func,
  error: propTypes.func
}
