import api from 'services/api'
import mvApi from 'services/mvApi'

const reports = {

  tabulation: async (token, query) => {
    try {
      const response = await api.get(
        `reports/tabulations${query}`,
        { headers: { Authorization: token } }
      )

      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  },

  schedule: async (token, query) => {
    try {
      const response = await api.get(
        `reports/schedules${query}`,
        { headers: { Authorization: token } }
      )

      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  },

  pauses: async (token, query) => {
    try {
      const response = await api.get(
        `reports/pauses${query}`,
        { headers: { Authorization: token } }
      )

      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  },

  calls: async (token, query) => {
    try {
      const response = await api.get(
        `reports/calls${query}`,
        { headers: { Authorization: token } }
      )

      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  },
  
  actions: async (token, query) => {
    try {
      const response = await mvApi.get(
        `v0/soul/reports/generate${query}`,{responseType: 'blob',}
      )

      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  },

  allReports: async (token, range) => {
    try {
      const response = await api.get(
        `reports?range=${range}`,
        { headers: { Authorization: token } }
      )

      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  }
}

export default reports