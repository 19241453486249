import React, { useEffect, useState } from 'react'
import Card from 'components/CardButton'
import { Doughnut } from 'react-chartjs-2'
import moment from 'moment'

import { Button } from './styled'

const DayAndButton = ({ active, day, onClick }) => {
  // ChartJs.pluginService.register({})

  const handleWeekDay = () => {
    const formatDate = moment(day).format('DD/MM')

    switch (moment(day).weekday()) {
      case 0: {
        return `Domingo: ${formatDate}`
      }        
      case 7: {
        return `Segunda: ${formatDate}`
      }
      case 1:
        return `Segunda: ${formatDate}`
      case 2:
        return `Terça: ${formatDate}`
      case 3:
        return `Quarta: ${formatDate}`
      case 4:
        return `Quinta: ${formatDate}`
      case 5:
        return `Sexta: ${formatDate}`
      case 6:
        return `Sabado: ${formatDate}`
    }
  }    

  return (
    <Card noPadding active={active} onClick={onClick} >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        <Button active={active}>{handleWeekDay()}</Button>
      </div>
    </Card>
  )
}

export default DayAndButton
