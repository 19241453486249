const _initialState = {
  pauseReasons: []
}

const resources = (state = _initialState, action) => {
  switch (action.type) {
    case 'RESOURCE_SET_PAUSE_REASONS': {
      return {
        ...state,
        pauseReasons: action.data.pauseReasons
      }
    }

    default:
      return state
  }
}

export default resources
