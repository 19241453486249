import React, { Component } from 'react'
import PropTypes from 'prop-types'
import patient from 'consumers/patient'

import { connect } from 'react-redux'

import { Table, Grid, Button as SButton } from 'semantic-ui-react'
import { showAlert, showConfirmation, setSwalLoading, closeAllSwal } from 'store/modules/alert/actions'
import { getFirstAndLastWord, titleCase } from 'utils/stringMethods'

import Inputmask from 'inputmask'
import { Bounce } from 'react-activity'

import PaginationShort from 'components/Pagination'
import Card from 'components/Card'
import Input from 'components/Input'
import Button from 'components/Button'
import ClickToCall from 'components/ClickToCall'

import { formatPhone, formatCpf } from 'utils/formaters'
import { onlyDigits } from 'utils/onlyDigits'
import { setPatient, clearPatient } from 'store/modules/patient/actions'
import { push } from 'connected-react-router'
import IconButton from 'components/IconButton'

class ListPatients extends Component {
  constructor (props) {
    super(props)

    this.state = {
      patient: undefined,

      // paginação
      expandName: '',
      totalPages: null,
      activePage: null,
      page: 1,
      cpf: '',
      phone: '',
      name: ''
    }
  }

  changeName = (event) => {
    const newName = event.target.value

    this.setState({ name: newName, activePage: 1 }, () => {
      this.setState({ activePage: 1, page: 1 })
      if (newName.length >= 3 || newName === '') this.getPatient()
    })
  }

  changeCpf = (event) => {
    const newCpf = onlyDigits(event.target.value) || ''

    this.setState({ cpf: newCpf }, () => {
      this.setState({ activePage: 1, page: 1 })
      if (newCpf.length >= 3 || newCpf === '') this.getPatient()
    })
  }

  changePhone = (event) => {
    const newPhone = onlyDigits(event.target.value) || ''

    this.setState({ phone: newPhone },
      () => {
        this.setState({ activePage: 1, page: 1 })
        if (newPhone.length >= 5 || newPhone === '') this.getPatient()
      })
  }

  changePage = (event) => {
    if (event) {
      this.setState(
        {
          page: event.target.value
        },
        () => {
          this.getPatient()
        }
      )
    }
  }

  toggleExpandName = () => this.setState({ expandName: !this.state.expandName })

  getPatient = async () => {
    const { token, showAlert, setSwalLoading, closeAllSwal } = this.props
    const { page, cpf, phone, name } = this.state

    const data = {
      cpf,
      phone,
      name
    }

    const query = { page }

    try {
      setSwalLoading()
      const response = await patient.search(token, data, query)
      this.setState({
        patients: response.docs,
        totalPages: response.totalPages,
        activePage: response.page
      })
      closeAllSwal()
    } catch (e) {
      if (e.code >= 400 && e.code <= 499) { showAlert(e.code, 'Atenção', e.message, e.technical) }
    }
  }

  changePage = (event) => {
    if (event) {
      this.setState(
        {
          page: event.target.getAttribute('value')
        },
        () => {
          this.getPatient()
        }
      )
    }
  }

  componentDidMount () {
    const { query } = this.props
    if (query && query.phone) {
      this.setState({ phone: query.phone }, () => this.getPatient())
    } else {
      this.getPatient()
    }
  }

  clearFilters = () => {
    this.setState({
      cpf: '',
      name: '',
      phone: '',
      page: 1,
      activePage: 1
    }, () => { this.getPatient() })
  }

  goToPatient = async (id) => {
    const { push, clearPatient } = this.props

    clearPatient()

    push({
      pathname: '/admin/agenda',
      state: id
    })
    // const { token, setPatient, showAlert, push } = this.props
    // try {
    //   const response = await patient.getById(token, id, 1)
    //   const user = response.data

    //   console.log(user)
    //   setPatient({ ...response.data, register: true, birthdate: new Date(user.birthdate) })

    // } catch (e) {
    //   showAlert(e.code, e.message)
    // }
  }

  render () {
    Inputmask().mask(document.querySelectorAll('input'))
    const { patients, name, phone, cpf, totalPages, activePage, expandName } = this.state
    return (
      <Card title='Pacientes'>
        {!patients ? (<Grid centered>
          <Grid.Row>
            <Bounce
              className='bounce-margin'
              color='#212121'
              size={22}
              speed={1}
              animating={true}
            />
          </Grid.Row>
        </Grid>) : (
          <>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input
                    label='Nome'
                    type='text'
                    placeholder='Digite o nome'
                    value={name}
                    onChange={(e) => this.changeName(e)}
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <Input
                    label='CPF'
                    placeholder='Digite o CPF'
                    data-inputmask="'mask': '999.999.999-99'"
                    value={cpf}
                    onChange={(e) => this.changeCpf(e)}
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <Input
                    label='Celular ou telefone'
                    placeholder='Digite um fone do paciente'
                    data-inputmask="'mask': '(99) 9999[9]-9999'"
                    value={phone}
                    onChange={(e) => this.changePhone(e)}
                  />
                </Grid.Column>

                <Grid.Column width={2} style={{ marginTop: 36 }} className="button-side-inputs">
                  <Button
                    filled
                    onClick={this.clearFilters}
                  >Limpar</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Table celled striped padded compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nome <IconButton basic dark small onClick={this.toggleExpandName} icon={expandName ? 'crop' : 'expand'} /></Table.HeaderCell>
                  <Table.HeaderCell>Cpf</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Celular</Table.HeaderCell>
                  <Table.HeaderCell>Celular 2</Table.HeaderCell>
                  <Table.HeaderCell>Telefone</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {patients.map((patient) => (
                  <Table.Row key={patient.id}>
                    <Table.Cell>{expandName ? titleCase(patient.name) : getFirstAndLastWord(titleCase(patient.name))}</Table.Cell>
                    <Table.Cell textAlign='center'>{patient.cpf ? formatCpf(patient.cpf) : <span className='unimportant-text'>N/A</span>}</Table.Cell>
                    <Table.Cell>{patient.email}</Table.Cell>
                    <Table.Cell textAlign='center'>
                      {patient.cellphone
                        ? <ClickToCall
                          label={formatPhone(patient.cellphone)}
                          to={patient.cellphone}
                          whats={patient.whatsapp}
                        />
                        : <span className='unimportant-text'>N/A</span>
                      }
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      {patient.secondCellphone
                        ? <ClickToCall
                          label={formatPhone(patient.secondCellphone)}
                          to={patient.secondCellphone}
                          whats={patient.whatsapp}
                        />
                        : <span className='unimportant-text'>N/A</span>
                      }
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      {patient.telephone
                        ? <ClickToCall
                          label={formatPhone(patient.telephone)}
                          to={patient.telephone}
                        />
                        : <span className='unimportant-text'>N/A</span>
                      }
                    </Table.Cell>
                    <Table.Cell>
                      <SButton onClick={() => this.goToPatient(patient.id)}>Selecionar</SButton>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <PaginationShort
              onPageChange={(e) => this.changePage(e)}
              totalPages={totalPages}
              activePage={activePage}
            />
          </>
        )}
      </Card>
    )
  }
}

ListPatients.propTypes = {
  token: PropTypes.string,
  query: PropTypes.object,
  setPatient: PropTypes.func,
  clearPatient: PropTypes.func,
  showAlert: PropTypes.func,
  push: PropTypes.func
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  query: state.router.location.query
})

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
  showConfirmation: (type, title, message, func) =>
    dispatch(showConfirmation(type, title, message, func)),
  clearPatient: () => dispatch(clearPatient()),
  setPatient: (data) =>
    dispatch(setPatient(data)),
  push: (url) =>
    dispatch(push(url)),
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal())
})

export default connect(mapStateToProps, mapDispatchToProps)(ListPatients)
