import CreatePatient from 'pages/operator/createPatientPage'
import PricingPage from 'pages/operator/PricingPage'
import Operators from 'pages/administrator/Operators'
import Schedule from 'pages/common/Schedule'
import ListPatients from 'pages/common/ListPatients'
import Home from 'pages/operator/Home'
import ListTabulations from 'pages/common/ListTabulations'
import Resources from 'pages/administrator/Resources'
import ListReport from 'components/ListReport'
import Records from 'pages/administrator/Records'
import Perfil from 'pages/common/Perfil'
import ListMissedCalls from 'pages/common/ListMissedCalls'
import CreateNotification from 'pages/administrator/CreateNotification'
import WhatsappDashboard from 'pages/common/WhatsappDashboard'
import DashboardHes from 'pages/hes/DashboardHes'
import WhatsAppDashRanking from 'pages/administrator/WhatsAppDashRanking'
import OmniDashboard from 'pages/common/OmniDashboard'
import ScheduleManager from 'pages/operator/ScheduleManager'

// import Reports from 'pages/Reports'

const patient = {
  title: 'Agendar',
  path: '/agenda',
  component: CreatePatient,
  key: 1,
  icon: 'calendar-outline'
}
const operators = {
  title: 'Operadores',
  path: '/operadores',
  component: Operators,
  key: 2,
  icon: 'people-circle-outline'
}

const scheduleManager = {
  title: 'Central de Agendas',
  path: '/central-agendamentos',
  component: ScheduleManager,
  key: 3,
  icon: 'calendar'
}

const listPatients = {
  title: 'Pacientes',
  path: '/pacientes',
  component: ListPatients,
  key: 4,
  icon: 'people'
}

const operatorsHome = {
  title: 'Home',
  path: '/home',
  component: Home,
  key: 5,
  icon: 'home'

}

const tabulations = {
  title: 'Tabulações',
  path: '/tabulacoes',
  component: ListTabulations,
  key: 6,
  icon: 'file-tray-full-outline'

}

const recordings = {
  title: 'Gravações',
  path: '/gravacoes',
  component: Records,
  key: 7,
  icon: 'play-circle-outline'
}

const perfil = {
  title: 'Perfil',
  path: '/perfil',
  component: Perfil,
  key: 8,
  icon: 'happy-outline'
}

const resources = {
  title: 'Recursos',
  path: '/resources',
  component: Resources,
  key: 9,
  icon: 'construct-outline'
}

const listMissedCalls = {
  title: 'Perdidas',
  path: '/perdidas',
  component: ListMissedCalls,
  key: 10,
  icon: 'phone-missed'
}

const reports = {
  title: 'Relatórios',
  path: '/reports',
  component: ListReport,
  key: 11,
  icon: 'document-text-outline'
}

const notification = {
  title: 'Notificar',
  path: '/notification',
  component: CreateNotification,
  key: 12,
  icon: 'notifications-circle-outline'
}

const whatsappDashboard = {
  title: 'Confirmações',
  path: '/whats',
  component: WhatsappDashboard,
  key: 13,
  icon: 'logo-whatsapp'
}

const omniDashboard = {
  title: 'Dashboard',
  path: '/omnidashboard',
  component: OmniDashboard,
  key: 15,
  icon: 'analytics-outline'
}

const dashboardHes = {
  title: 'Dashboard',
  path: '/dashboard/hes',
  component: DashboardHes,
  key: 14,
  icon: 'analytics-outline'
}

const whatsappDashboardRanking = {
  title: 'Ranking',
  path: '/ranking',
  component: WhatsAppDashRanking,
  key: 16,
  icon: 'ranking'
}
const pricingPage = {
  title: 'Valores dos Procedimentos',
  path: '/pricing',
  component: PricingPage,
  key: 17,
  icon: 'cash'
}
const availableCustomRoutes = {
  resources: resources,
  reports: reports,
  perfil: perfil,
  notification: notification,
  recordings: recordings,
  operators: operators,
  whatsRanking: whatsappDashboardRanking,
  omniDashboard: omniDashboard
}

const getCustomRoutes = (routes) => {
  if (!routes) return []

  const customRoutes = []

  routes.map(route => {
    const selectedRoute = availableCustomRoutes[route]
    if (selectedRoute) customRoutes.push(selectedRoute)
  })

  return customRoutes
}

const getRoutes = (level, routes) => {
  const customRoutes = getCustomRoutes(routes)

  console.log('ROTAS A MAIS', customRoutes)
  switch (level) {
    case 'operator':
      return [operatorsHome, patient, scheduleManager, listPatients, listMissedCalls, tabulations, perfil, whatsappDashboard, pricingPage, ...customRoutes]
    case 'admin':
      return [omniDashboard, patient, tabulations, recordings, scheduleManager, operators, listPatients, listMissedCalls, resources, reports, perfil, notification, whatsappDashboard, whatsappDashboardRanking, pricingPage, ...customRoutes]
    case 'master':
      return [omniDashboard, patient, tabulations, recordings, scheduleManager, operators, listPatients, listMissedCalls, resources, reports, perfil, notification, whatsappDashboard, whatsappDashboardRanking, pricingPage, ...customRoutes]
    case 'hesAdmin':
      return [dashboardHes]
    default:
      return []
  }
}

export default getRoutes
