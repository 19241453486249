import React from 'react'
import { Card } from 'semantic-ui-react'
import Button from 'components/Button'
import propTypes from 'prop-types'
import { WrapperIcon, WrapperContent } from './styled'

export default function CardIcon ({ description, header, meta, textButton, onClick, icon, numberText, color, className, active, dashClick }) {
  return (
    <>
      <Card fluid style={cardStyle} className={className} active={active} onClick={dashClick}>
        <WrapperIcon color={color}>
          <ion-icon name={icon} style={iconStyle} />
        </WrapperIcon>

        <WrapperContent>
          <Card.Header><strong>{header}</strong></Card.Header>
          <Card.Meta>{meta}</Card.Meta>

          <Card.Description style={{ width: '80%' }}>
            {description}
          </Card.Description>

          <Card.Description style={{ fontSize: '1.5rem' }}>
            {numberText}
          </Card.Description>

          {textButton && (
            <Button style={{ marginTop: 15 }} filled onClick={onClick}>
              {textButton}
            </Button>
          )}
        </WrapperContent>
      </Card>
    </>
  )
}

CardIcon.propTypes = {
  description: propTypes.string,
  header: propTypes.string,
  meta: propTypes.string,
  textButton: propTypes.string,
  onClick: propTypes.func
}

const iconStyle = {
  height: '100%',
  fontSize: '70px',
  color: 'black'
}

const cardStyle = {
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  height: '100%'
}
