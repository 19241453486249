import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import schedule from 'consumers/schedule'
import patient from 'consumers/patient'
import protocolConsumer from 'consumers/scheduleProtocol'
import schedulesConsumer from 'consumers/schedule'
import to from 'await-to-js'
import Card from 'components/Card'
import TransferModal from 'components/TransferModal'
import { Table, Label, Dropdown, Grid, Modal } from 'semantic-ui-react'
import {
  showAlert,
  showConfirmation,
  showOptionSelect,
  setSwalLoading,
  closeAllSwal
} from 'store/modules/alert/actions'

import CalendarHoursInput from 'components/CalendarHoursInput'

import Inputmask from 'inputmask'
import { Bounce } from 'react-activity'

import { toBrDisplay, dateToBrSmall } from 'utils/formatDate'
import { formatPhone, formatCpf } from 'utils/formaters'
import { validateDateHour } from 'utils/validators'

import Select from 'components/Select'
import PaginationShort from 'components/Pagination'

import { consultOptions, situationOptions, sortOptions } from 'utils/resources'
import moment from 'moment'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'

import { setPatient } from 'store/modules/patient/actions'
import { Link } from 'react-router-dom'

import renderHTML from 'react-render-html'

import {
  situationToColor,
  translateSituation,
  attendedToColor,
  translateConsultType,
  translateAttended,
  fittingToColor,
  translateFitting
} from 'utils/translate'
import { makeCall } from 'store/modules/telephony/actions'
import { Container } from 'pages/_layouts/Admin/styled'
import { getFirstAndLastWord, titleCase } from 'utils/stringMethods'
import IconButton from 'components/IconButton'
import insurances from 'consumers/insurances'
import serviceTypes from 'consumers/serviceTypes'
import specialtiesConsumer from 'consumers/specialties'
import examsConsumer from 'consumers/exams'
import providersConsumer from 'consumers/providers'
class ListSchedules extends Component {
  constructor (props) {
    super(props)
    this.state = {
      schedules: [],
      // paginação
      totalPages: null,
      activePage: null,
      page: 1,
      consultType: '',
      situation: '',
      sort: '',

      dateStart: '',
      dateEnd: '',

      dateHourStart: '',
      dateHourEnd: '',
      expandName: false,
      expandDate: false,
      expandCreateAt: false,
      descriptions: [],
      showTransferModal: false,
      availableSchedules: null,
      scheduleToTransfer: null,
      scheduleTotransferDescription: null,
      types: null,
      exams: null,
      healthInsurancePlans: null,
      healthInsurances: null,
      specialties: null,
      providers: null

    }
    this.sortRef = createRef()
    this.consultRef = createRef()
    this.situationRef = createRef()
    this.dateStartRef = createRef()
    this.dateEndRef = createRef()

    this.paginationRef = createRef()
  }

  changePage = (event) => {
    if (event) {
      this.setState(
        {
          page: event.target.getAttribute('value')
        },
        () => {
          this.getSchedules()
        }
      )
    }
  }

  changeSort = (event) => {
    if (event.value === '') {
      this.setState({ sort: '' }, () => {
        this.getSchedules()
      })
    } else {
      this.setState({ page: 1, sort: `&sort=${event.value}` }, () => {
        this.setState({ activePage: 1 })
        this.getSchedules()
      })
    }
  }

  changeConsult = (event) => {
    if (event.value === '') {
      this.setState({ consultType: '' }, () => {
        this.getSchedules()
      })
    } else {
      this.setState(
        { page: 1, consultType: `&consultType=${event.value}` },
        () => {
          this.setState({ activePage: 1 })
          this.getSchedules()
        }
      )
    }
  }

  changeSituation = (event) => {
    if (event.value === '') {
      this.setState({ situation: '' }, () => {
        this.getSchedules()
      })
    } else {
      this.setState({ page: 1, situation: `&situation=${event.value}` }, () => {
        this.setState({ activePage: 1 })
        this.getSchedules()
      })
    }
  }

  changeStartDate = (event) => {
    const inputFormatDate = moment(event).format('DD/MM/YYYY HH:mm')
    this.setState({ dateStart: inputFormatDate })

    const searchFormatDate = moment(event).format('YYYY-MM-DDTHH:mm')
    this.concatDate(searchFormatDate, event, 'start')
  }

  changeEndDate = (event) => {
    const inputFormatDate = moment(event).format('DD/MM/YYYY HH:mm')
    this.setState({ dateEnd: inputFormatDate })

    const searchFormatDate = moment(event).format('YYYY-MM-DDTHH:mm')
    this.concatDate(searchFormatDate, event, 'end')
  }

  concatDate = (date, event, type) => {
    if (type === 'start') {
      if (validateDateHour(event)) {
        this.setState({ dateHourStart: `&start=${date}` }, () => {
          this.setState({ activePage: 1, page: 1 })
          this.getSchedules()
        })
      } else {
        this.setState({ dateHourStart: '' }, () => {
          this.setState({ activePage: 1, page: 1 })
          this.getSchedules()
        })
      }
    }

    if (type === 'end') {
      if (validateDateHour(event)) {
        this.setState({ dateHourEnd: `&end=${date}` }, () => {
          this.setState({ activePage: 1, page: 1 })
          this.getSchedules()
        })
      } else {
        this.setState({ dateHourEnd: '' }, () => {
          this.getSchedules()
        })
      }
    }
  }

  clearFilters = () => {
    this.setState(
      {
        tempDate: '',
        tempDateFinal: '',
        consultType: '',
        situation: '',
        dateHourStart: '',
        dateHourEnd: '',
        dateStart: '',
        dateEnd: '',
        page: 1,
        activePage: 1
      },
      () => {
        this.getSchedules()
      }
    )

    this.sortRef.current.state.value = sortOptions[0]
    this.consultRef.current.state.value = consultOptions[0]
    this.situationRef.current.state.value = situationOptions[0]
  }

  getSchedules = async () => {
    const {
      token,
      showAlert,
      patientId,
      setSwalLoading,
      closeAllSwal,
      cpf
    } = this.props

    const {
      page,
      consultType,
      situation,
      dateHourStart,
      dateHourEnd,
      sort
    } = this.state

    const data = {
      page,
      consultType,
      situation,
      dateHourStart,
      dateHourEnd,
      sort
    }

    let response
    try {
      setSwalLoading()
      if (cpf) {
        response = await schedule.listByPatientOracle({ cpf, page })
        console.log('RESPOSTA HISTORICO', response)
        this.setState({
          schedules: response.schedules,
          totalPages: response.totalPages,
          activePage: response.page
        })
      }

      closeAllSwal()
    } catch (e) {
      if (e.code >= 400 && e.code <= 499) { showAlert(400, 'Atenção', e.message, e.technical) }
      console.log('ERRO AO LISTAR AGENDAS', e)
    }
  }

  shouldCancel = ({ patientCode, scheduleItemCode, mainScheduleCode }) => {
    this.props.showConfirmation(
      'warning',
      'Cancelar?',
      'Tem certeza de que deseja cancelar este agendamento?',
      () => {
        this.cancel({ patientCode, scheduleItemCode, mainScheduleCode })
      }
    )
  }

  cancel = async ({ patientCode, scheduleItemCode, mainScheduleCode }) => {
    const { token, showAlert, setSwalLoading, closeAllSwal } = this.props
    try {
      const [responseError, response] = await to(schedulesConsumer.clear(
        {
          patientCode,
          scheduleItemCode,
          mainScheduleCode
        }))
    } catch (err) {
      showAlert(err.code, 'Algo deu errado!', err.message)
    } finally {
      setSwalLoading()
      showAlert('success', 'Agendamento cancelado com sucesso', '', '', async () => {
        await this.getSchedules()
        closeAllSwal()
      })
    }
  }

  confirm = async (id) => {
    const { token, showAlert } = this.props
    try {
      const response = await schedule.confirm(id, token)
      showAlert(
        response.code,
        'Sucesso!',
        response.message,
        response.technical,
        this.getSchedules()
      )
    } catch (err) {
      showAlert(err.code, 'Algo deu errado!', err.message)
    } finally {
      this.getSchedules()
    }
  }

  pending = async (id) => {
    const { token, showAlert } = this.props
    try {
      const response = await schedule.pending(token, id)
      showAlert(
        response.code,
        'Sucesso!',
        response.message,
        response.technical,
        this.getSchedules()
      )
    } catch (err) {
      showAlert(err.code, 'Algo deu errado!', err.message)
    } finally {
      this.getSchedules()
    }
  }

  setPatient = async (id) => {
    const { token, setPatient, push } = this.props

    try {
      const response = await patient.getById(token, id)
      const user = response.data

      setPatient({
        ...user,
        register: true,
        birthdate: new Date(user.birthdate)
      })

      push('/admin/agenda')
    } catch (err) {
      if (!err.response) {
        showAlert(
          500,
          'Atenção',
          'Não foi possível se comunicar com o servidor!'
        )
      }
    }
  }

  selectPhone = async (id) => {
    const { token, showOptionSelect, makeCall } = this.props

    try {
      const response = await patient.getById(token, id)
      const user = response.data

      const phones = {}

      if (user.telephone) {
        phones[user.telephone] = `Telefone: ${formatPhone(user.telephone)}`
      }
      if (user.cellphone) {
        phones[user.cellphone] = `Celular: ${formatPhone(user.cellphone)}`
      }
      showOptionSelect('Selecione um Telefone', phones, (value) => {
        makeCall(value)
      })
    } catch (err) {
      if (!err.response) {
        showAlert(
          500,
          'Atenção',
          'Não foi possível se comunicar com o servidor!'
        )
      }
    }
  }

  setSchedule = async (id) => {
    const { token, showAlert } = this.props

    try {
      await schedule.getById(id, token)
    } catch (err) {
      if (!err.response) {
        showAlert(
          500,
          'Atenção',
          'Não foi possível se comunicar com o servidor!'
        )
      }
    }
  }

  getProviders = async (specialtyCode) => {
    const providers = await providersConsumer.get({ specialtyCode: specialtyCode })
    this.setState({ providers })
  }

  getSpecialties = async (token) => {
    const specialties = await specialtiesConsumer.get()
    this.setState({ specialties: specialties })
  }

  getHealthInsurances = async (token) => {
    const healthInsurances = await insurances.getHealthInsurances()
    this.setState({ healthInsurances: healthInsurances })
  }

  getHealthInsurancePlans = async (code) => {
    const healthInsurancePlans = await insurances.getInsurancePlans(code)
    this.setState({ healthInsurancePlans: healthInsurancePlans })
  }

  getExams = async (token) => {
    const exams = await examsConsumer.get(token)
    this.setState({ exams })
  }

  getTypes = async () => {
    const response = await serviceTypes.getServiceTypes()
    this.setState({ types: response })
  }

  handleTransferModal = async ({ date, scheduleTotransferDescription, scheduleToTransfer }) => {
    this.setState({
      scheduleToTransfer: scheduleToTransfer
    }, () => this.setState({
      showTransferModal: true
    }))
  }

  toggleExpandName = () => this.setState({ expandName: !this.state.expandName })
  toggleExpandDate = () => this.setState({ expandDate: !this.state.expandDate })
  toggleExpandCreateAt = () => this.setState({ expandCreateAt: !this.state.expandCreateAt })

  closeTransferModal = () => {
    this.setState({
      showTransferModal: false
    })
  }

  fetchAllData = async () => {
    await this.getSchedules()
    await this.getTypes()
    await this.getExams()
    await this.getSpecialties()
  }

  componentDidMount () {
    this.fetchAllData()
  }

  render () {
    const {
      schedules,
      totalPages,
      activePage,
      expandName,
      expandDate,
      expandCreateAt,
      showTransferModal,
      availableSchedules,
      getSoulSchedulesDay
    } = this.state

    const { patientId, hideControls, level, showButton, setSwalLoading, closeAllSwal } = this.props
    Inputmask().mask(document.querySelectorAll('input'))
    return (
      <Card title='Central de Agendas'>
        <>
          {showTransferModal ? <TransferModal
            open={showTransferModal}
            schedules={availableSchedules}
            onDateSelect={this.getSoulSchedulesDay}
            onClose={() => this.setState({ showTransferModal: false })}
            scheduleToTransfer={this.state.scheduleToTransfer}
            closeTransferModal={this.closeTransferModal}
            updateSchedulesHistory={this.getSchedules}
          /> : ''}
          <>
            <div className='button-schedule'>
              <Button filled onClick={this.getSchedules}>
                  Recarregar
              </Button>
            </div>

            <Grid>

              <Grid.Row>
                <Grid.Column width={1}/>

                <Grid.Column width={4}>
                  <Select
                    inputRef={this.situationRef}
                    label='Situação'
                    options={situationOptions}
                    onChange={(e) => this.changeSituation(e)}
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <Select
                    label='Tipo consulta'
                    options={this.state.types || []}
                    onChange={(e) => this.changeConsult(e)}
                  />
                </Grid.Column>

                <Grid.Column width={4}>

                  <Select
                    // inputRef={this.examRef}
                    label='Exame'
                    options={this.state.exams || []}
                    onChange={(e) => {
                      this.setState({ exam: e.value, disabledButtonSave: false })
                    }}
                    placeholder='Selecione... '
                  />
                </Grid.Column>

              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={1} />
                <Grid.Column width={4}>
                  <Select
                    // inputRef={this.especialityRef}
                    label='Especialidade do prestador'
                    options={this.state.specialties || []}
                    onChange={(e) => {
                      this.setState({ specialty: e.value, disabledButtonSave: false })
                      console.log(this.providerRef)
                      if (this?.providerRef?.current?.state) {
                        this.providerRef.current.select.clearValue()
                      }

                      this.getProviders(e.value)
                    }}
                    placeholder='Selecione... '

                  />

                </Grid.Column>

                <Grid.Column width={4}>
                  <Select
                    // inputRef={this.providerRef}
                    label='Prestador'
                    options={this.state.providers || []}
                    placeholder='Selecione... '
                    onChange={(e) => {
                      if (e != null) {
                        this.setState({ provider: e.value, disabledButtonSave: false })
                        this.setCanGoOn()
                      } else {
                        this.setState({ provider: undefined, disabledButtonSave: true })
                      }
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <CalendarHoursInput
                    label='Data inicial do agendamento'
                    inputRef={this.dateStartRef}
                    onSelect={(e) => {
                      this.setState({ tempDate: e })
                      this.changeStartDate(e)
                    }}
                    selected={this.state.tempDate}
                  />
                </Grid.Column>

                <Grid.Column width={2}>
                  <CalendarHoursInput
                    label='Data Final do agendamento'
                    inputRef={this.dateEndRef}
                    onSelect={(e) => {
                      this.setState({ tempDateFinal: e })
                      this.changeEndDate(e)
                    }}
                    selected={this.state.tempDateFinal}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row style={{ display: 'flex', alignItems: 'center' }}>
                <Grid.Column width={1}/>

                <Grid.Column width={3}>
                  {/* <Select
                    inputRef={this.sortRef}
                    label='Ordenar'
                    options={sortOptions}
                    onChange={(e) => this.changeSort(e)}
                  /> */}
                </Grid.Column>
                <Grid.Column width={1}/>
                <Grid.Column width={3}>
                  <Button filled >
                      Filtrar
                  </Button>
                </Grid.Column>
                <Grid.Column width={3} >
                  <Button filled onClick={this.clearFilters}>
                       Limpar
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Table celled striped padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell class="th-nowrap">Situação</Table.HeaderCell>
                  <Table.HeaderCell class="th-nowrap">Encaixe</Table.HeaderCell>

                  <Table.HeaderCell class="th-nowrap">Operador</Table.HeaderCell>
                  {!patientId && (
                    <>
                      <Table.HeaderCell class="th-nowrap">
                        Paciente
                        <IconButton
                          basic
                          dark
                          small
                          onClick={this.toggleExpandName}
                          icon={expandName ? 'crop' : 'expand'}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell class="th-nowrap">CPF</Table.HeaderCell>
                    </>
                  )}
                  <Table.HeaderCell class="th-nowrap">Consulta</Table.HeaderCell>
                  <Table.HeaderCell class="th-nowrap">Especialidade</Table.HeaderCell>
                  <Table.HeaderCell class="th-nowrap">Médico(a)</Table.HeaderCell>
                  <Table.HeaderCell class="th-nowrap">Exame</Table.HeaderCell>
                  <Table.HeaderCell class="th-nowrap">Plano de Saúde</Table.HeaderCell>
                  <Table.HeaderCell class="th-nowrap">Descrição</Table.HeaderCell>
                  <Table.HeaderCell class="th-nowrap">
                    Dt. Agendada
                    <IconButton
                      basic
                      dark
                      small
                      onClick={this.toggleExpandDate}
                      icon={expandDate ? 'crop' : 'expand'}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell class="th-nowrap"></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {/* TODO: Pegar valores corretos */}
                {schedules.map(() =>
                  <Table.Row key={schedule.mainScheduleCode}>
                    <Table.Cell textAlign='center'>
                      <Label color={situationToColor(schedule.situation)}>
                        {translateSituation(schedule.situation)}
                      </Label>
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      <Label color={fittingToColor(schedule.fitting)}>
                        {translateFitting(schedule.fitting)}
                      </Label>
                      { schedule.fitting ? <a>Ver Mais</a> : ''}
                    </Table.Cell>
                    <Table.Cell>
                      {getFirstAndLastWord(
                        titleCase(schedule.createdBy)
                      )}
                    </Table.Cell>
                    {!patientId && (
                      <>
                        <Table.Cell singleLine>
                          {expandName
                            ? titleCase(schedule.patientName)
                            : getFirstAndLastWord(
                              titleCase(schedule.patientName)
                            )}
                        </Table.Cell>
                        <Table.Cell singleLine>
                          {schedule.cpf ? formatCpf(schedule.cpf) : <span className='unimportant-text'>N/A</span>}
                        </Table.Cell>
                      </>
                    )}
                    <Table.Cell>
                      {translateConsultType(schedule?.consultType)}
                    </Table.Cell>
                    <Table.Cell>{schedule.specialtyName ? schedule.specialtyName : <span className='unimportant-text'>N/A</span>}</Table.Cell>
                    <Table.Cell>{schedule.providerName ? schedule.providerName : <span className='unimportant-text'>N/A</span>}</Table.Cell>
                    <Table.Cell>{schedule.examName ? schedule.examName : <span className='unimportant-text'>N/A</span>}</Table.Cell>
                    <Table.Cell>{schedule.insuranceName}</Table.Cell>
                    <Table.Cell>
                      {schedule.description &&
                      schedule.description !== '<p><br></p>' ? (
                          <Modal
                            trigger={<Link to='#'>Ver descrição</Link>}
                            header='Descrição'
                            content={
                              <Container className='add-padding'>
                                {renderHTML(schedule.description)}
                              </Container>
                            }
                            actions={['Fechar']}
                          />
                        ) : (
                          <span className='unimportant-text'>Sem descrição</span>
                        )}
                    </Table.Cell>
                    <Table.Cell>
                      {expandDate
                        ? toBrDisplay(schedule.scheduleDate, true)
                        : dateToBrSmall(schedule.scheduleDate)}
                    </Table.Cell>
                    <Table.Cell>
                      <Dropdown text='Ações' button item>
                        <Dropdown.Menu>
                          {!schedule.attended ? (
                            <Dropdown.Item>
                              <Link className='dropdown-item' onClick={() => {
                                this.handleTransferModal({
                                  scheduleToTransfer: schedule
                                })
                              }} >
                                Transferir
                              </Link>
                            </Dropdown.Item>
                          ) : (
                            ''
                          )}
                          {schedule.patientId && (
                            <Dropdown.Item
                              // FIXME: modar id quando o backend for trocado
                              onClick={() =>
                                this.selectPhone(schedule.patientCode)
                              }>
                              Ligar para paciente
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            // FIXME: modar id quando o backend for trocado
                            onClick={ async () => {
                              setSwalLoading()
                              const data = await protocolConsumer.get({ patientCode: schedule.patientCode, scheduleItemCode: schedule.scheduleCode })
                              closeAllSwal()
                              window.open(data.pdfUrl, '_blank')
                            }}>
                              Imprimir
                          </Dropdown.Item>

                          {hideControls ? (
                            ''
                          ) : (
                            <Dropdown.Item
                              // FIXME: modar id quando o backend for trocado
                              onClick={() =>
                                this.setPatient(schedule.patientCode)
                              }>
                              Ver Paciente
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            onClick={() => this.confirm(schedule.mainScheduleCode)}
                            disabled={schedule.confirmed}>
                            Concluir
                          </Dropdown.Item>
                          <Dropdown.Item
                            className='cancel-text'
                            disabled={false}
                            onClick={() => this.shouldCancel({ patientCode: schedule.patientCode, scheduleItemCode: schedule.scheduleCode, mainScheduleCode: schedule.mainScheduleCode })}>
                            Cancelar
                          </Dropdown.Item>
                          {level === 'admin' ? (
                            <Dropdown.Item
                              disabled={
                                schedule.situation !== 'pending' &&
                                level !== 'admin'
                              }
                              onClick={() => this.pending(schedule.mainScheduleCode)}>
                              Aguardando
                            </Dropdown.Item>
                          ) : (
                            ''
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>

            <PaginationShort
              ref={this.paginationRef}
              onPageChange={(e) => this.changePage(e)}
              totalPages={totalPages}
              activePage={activePage}
            />
          </>

        </>
      </Card>
    )
  }
}

ListSchedules.propTypes = {
  token: PropTypes.string,
  cpf: PropTypes.string,
  showAlert: PropTypes.func,
  showConfirmation: PropTypes.func,
  makeCall: PropTypes.func
}

const mapStateToProps = (state) => ({
  patientId: state.patient.id,
  mvId: state.patient.mvId,
  token: state.auth.token,
  router: state.router,
  patient: state.patient
})

const mapDispatchToProps = (dispatch) => ({
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: (bool) => dispatch(closeAllSwal()),
  showAlert: (type, title, message, details, func) =>
    dispatch(showAlert(type, title, message, details, func)),
  showConfirmation: (type, title, message, func) =>
    dispatch(showConfirmation(type, title, message, func)),
  push: (value) => dispatch(push(value)),
  showOptionSelect: (title, options, func) =>
    dispatch(showOptionSelect(title, options, func)),
  makeCall: (phone) => dispatch(makeCall(phone)),
  setPatient: (data) => dispatch(setPatient(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListSchedules)
