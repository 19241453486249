import api from 'services/api'
import mvApi from 'services/mvApi'
import moment from 'moment-timezone'
import swal from 'sweetalert'

const schedule = {
  create: async (token, data) => {
    try {
      const response = await api.post('schedules', data, {
        headers: { Authorization: token }
      })

      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        error.code = 503
        throw error
      }
      throw e.response.data
    }
  },

  list: async (token, data) => {
    try {
      const response = await api.get(
        `schedules?page=${data.page}${data.sort}${data.consultType}${data.situation}${data.dateHourStart}${data.dateHourEnd}`,
        { headers: { Authorization: token } }
      )
      return response.data
    } catch (e) {
      if (e) {
        console.log(e)
      }
    }
  },
  schedulesByDay: async (params) => {
    console.log('filtros dia', params)
    try {
      const response = await mvApi.get('/v0/soul/schedules/day', { params })
      console.log('Response do dia', response)
      return response.data
    } catch (e) {
      if (e) {
        console.log(e)
      }
    }
  },

  listOracle: async (data) => {
    try {
      const response = await mvApi.get('/v0/soul/schedules/list', {
        params: {
          provider: data.provider,
          month: data.month,
          specialty: data.specialty
        }
      })
      console.log('filtros geral', data)
      console.log('Response do geral', response)

      return response.data.data.schedules
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  },
  listByPatientOracle: async (data) => {
    try {
      const response = await mvApi.get('/v0/soul/schedules/patients/list', {
        params: {
          start: data.start,
          end: data.end,
          provider: data.provider,
          date: data.date,
          month: data.month,
          unitCode: data.unitCode,
          specialty: data.specialty,
          cpf: data.cpf,
          page: data.page
        }
      })

      return response.data.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  },
  insertPatient: async (data) => {
    try {
      const response = await mvApi.post(
        '/v0/soul/schedules/patients/insert',
        data
      )
      console.log(response.code)

      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw e.response.data
    }
  },

  // try {
  //   const response = await api.get(
  //       `/patients/search/cpf/${cpf}`,
  //       { headers: { Authorization: token } }
  //   )
  //   return response
  // } catch (err) {
  //   if (!err.response) {
  //     const error = new Error('CONNECTION_ERROR')
  //     error.message = 'Não foi possível se comunicar com o servidor'
  //     throw error
  //   }

  //   throw err.response.data
  // }
  clear: async (data) => {
    try {
      const response = await mvApi.post(
        '/v0/soul/schedules/patients/clear',
        data
      )
      console.log(response.code)

      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw e.response.data
    }
  },
  listPatient: async (token, data, patientId) => {
    try {
      const response = await api.get(
        `schedules/search?page=${data.page}&patientId=${patientId}${data.sort}${data.consultType}${data.situation}${data.dateHourStart}${data.dateHourEnd}`,
        { headers: { Authorization: token } }
      )
      return { data: { ...response.data.data.schedules } }
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  },

  getById: async (id, token) => {
    try {
      const response = await api.get(`schedules/${id}`, {
        headers: { Authorization: token }
      })
      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  },

  update: async (id, data, token) => {
    try {
      const response = await api.patch(`schedules/${id}`, data, {
        headers: { Authorization: token }
      })
      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  },

  cancel: async (id, token) => {
    try {
      const response = await api.patch(
        `schedules/${id}/cancel`,
        {},
        { headers: { Authorization: token } }
      )

      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  },
  confirm: async (id, token) => {
    try {
      const response = await api.patch(
        `schedules/${id}/confirm`,
        {},
        { headers: { Authorization: token } }
      )

      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  },
  pending: async (token, id) => {
    try {
      const response = await api.patch(
        `schedules/${id}/pending`,
        {},
        { headers: { Authorization: token } }
      )

      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  },
  report: async (token, range) => {
    try {
      const response = await api.get(`schedules?report&range=${range}`, {
        headers: { Authorization: token }
      })
      return response.data
    } catch (err) {
      if (!err) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw err.response.data
      }
    }
  }
}

export default schedule
