import React from 'react'
import { Modal, Table } from 'semantic-ui-react'
import { dateToBrSmall } from 'utils/formatDate'
import PropTypes from 'prop-types'

import PaginationShort from 'components/Pagination'
import Button from 'components/Button'

// if (description && description.toString('html') !== initialState.description.toString('html')) {
//   changedData.description = description.toString('html')
// }
function ModalScheduleTable ({
  open,
  onClose,
  hourSchedules,
  totalPages,
  activePage,
  changePage,
  selectSchedule,
  selectedDate
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      size='large'
      closeIcon
      style={{ padding: 15, paddingBottom: 45 }}>
      <Modal.Header style={{ borderBottom: 0 }}>
        Horas e agendamento
      </Modal.Header>

      <Modal.Content scrolling style={{ padding: 0 }}>
        {hourSchedules ? (
          <Table>
            <Table.Header style={{ position: 'sticky', top: 0 }}>
              <Table.Row>
                <Table.HeaderCell>Agenda</Table.HeaderCell>
                <Table.HeaderCell>Hora agenda</Table.HeaderCell>
                <Table.HeaderCell>Nome prestador</Table.HeaderCell>
                <Table.HeaderCell>Especialidade</Table.HeaderCell>
                <Table.HeaderCell>Sigla unidade</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {hourSchedules.map((element) => (
                <Table.Row key={element.scheduleItemCode}>
                  <Table.Cell>{element.mainScheduleCode}</Table.Cell>
                  <Table.Cell>{dateToBrSmall(element.scheduleDate)}</Table.Cell>
                  <Table.Cell>{element.providerName}</Table.Cell>
                  <Table.Cell>{element.specialtyName}</Table.Cell>
                  <Table.Cell>{element.attendanceUnit}</Table.Cell>
                  <Table.Cell><Button filled onClick={() => selectSchedule(element)}>Selecionar</Button></Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          ''
        )}
      </Modal.Content>

      <PaginationShort
        style={{ marginTop: 15 }}
        onPageChange={(e) => changePage(selectedDate, e.target.getAttribute('value'))}
        totalPages={totalPages}
        activePage={activePage}
      />
    </Modal>
  )
}

ModalScheduleTable.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  hourSchedules: PropTypes.array,
  totalPages: PropTypes.number,
  activePage: PropTypes.number,
  changePage: PropTypes.func,
  selectSchedule: PropTypes.func,
  selectedDate: PropTypes.string
}

export default ModalScheduleTable
