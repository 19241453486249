import styled from 'styled-components'

import { getStatusColor } from 'utils/colors'

export const Container = styled.div`
  height: 300px;
  width: 200px;
  display: grid;
  grid-template-rows: 2fr 1fr;
  grid-template-areas: "title" "details";
  filter: ${props => props.inactive ? 'grayscale(1) brightness(0.8)' : 'none'};
  background-color: ${props => getStatusColor(props.status)};
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  transition: all 250ms linear;
  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.85);
  }
`

export const Status = styled.span`
  margin-top: 4px;
  color: ${props => props.status === 'offline' ? '#FCFCFC' : '#212121'};
  font-weight: 600;
  text-align: center;
`

export const ImageContainer = styled.div`
  grid-area: "title";
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Selfie = styled.img`
  display: block;
  color: #FFF;
  height: 86px;
  width: 86px;
  background-color: #FFF;
`

export const Footer = styled.div`
  grid-area: "details";
  color: #FFF;
  height: 100%;
  bottom: 0;
  width: 100%;
  background-color: #212121;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: center;
  padding: 8px;

  span.footer-info {
    color: #FCFCFC;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500
  }
`

export const Name = styled.span`
  color: ${props => props.status === 'offline' ? '#FCFCFC' : '#212121'};
  font-weight: 600;
  margin-top: 4px;
`
