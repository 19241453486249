import api from 'services/api'

const emptyItem = { label: 'Nenhum...', value: undefined }

const resources = {

  pauseReasons: async (token, query) => {
    try {
      const response = await api.get(
        `resources/pausereasons${query || ''}`,
        { headers: { Authorization: token } }
      )
      const pauseReasons = response.data.data.pauseReasons.docs
      const { totalPages, page } = response.data.data.pauseReasons
      if (totalPages) pauseReasons.totalPages = totalPages
      if (page) pauseReasons.page = page
      return pauseReasons
    } catch (e) {
      throw e.response.data
    }
  },
  specialties: async (token, query) => {
    try {
      const response = await api.get(
        `resources/specialties${query || ''}`,
        { headers: { Authorization: token } }
      )
      let specialties
      if (query) {
        specialties = response.data.data.specialties.docs
      } else {
        specialties = response.data.data.specialties.docs.map(specialtie => {
          const spec = {
            value: specialtie._id,
            label: specialtie.name
          }
          return spec
        })
      }
      const { totalPages, page } = response.data.data.specialties
      if (totalPages) specialties.totalPages = totalPages
      if (page) specialties.page = page
      return [emptyItem, ...specialties]
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  },

  exams: async (token, query) => {
    try {
      const response = await api.get(
        `resources/exams${query || ''}`,
        { headers: { Authorization: token } }
      )
      let exams
      if (query) {
        exams = response.data.data.exams.docs
      } else {
        exams = response.data.data.exams.docs.map(specialtie => {
          const spec = {
            value: specialtie._id,
            label: specialtie.name
          }
          return spec
        })
      }
      const { totalPages, page } = response.data.data.exams
      if (totalPages) exams.totalPages = totalPages
      if (page) exams.page = page
      return [emptyItem, ...exams]
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  },
  units: async (token, query) => {
    try {
      const response = await api.get(
        `resources/units${query || ''}`,
        { headers: { Authorization: token } }
      )
      let units
      if (query) {
        units = response.data.data.units.docs
      } else {
        units = response.data.data.units.docs.map(unit => {
          const spec = {
            value: unit._id,
            label: unit.name
          }
          return spec
        })
      }
      const { totalPages, page } = response.data.data.units
      if (totalPages) units.totalPages = totalPages
      if (page) units.page = page
      return units
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  },
  healthInsurances: async (token, query) => {
    try {
      const response = await api.get(
        `resources/insurances${query || ''}`,
        { headers: { Authorization: token } }
      )
      let insurances
      if (query) {
        insurances = response.data.data.insurances.docs
      } else {
        insurances = response.data.data.insurances.docs.map(insurance => {
          const insu = {
            value: insurance._id,
            label: insurance.name
          }
          return insu
        })
      }
      const { totalPages, page } = response.data.data.insurances
      if (totalPages) insurances.totalPages = totalPages
      if (page) insurances.page = page
      return insurances
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  },
  tabulations: async (token, query) => {
    try {
      const response = await api.get(
        `resources/tabulations${query || ''}`,
        { headers: { Authorization: token } }
      )
      const { totalPages, docs, page } = response.data.data.tabulationNames
      const tabulations = docs
      if (totalPages) tabulations.totalPages = totalPages
      if (page) tabulations.page = page
      return tabulations
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  }
}

export default resources