import api from 'services/api'
import mvApi from 'services/mvApi'

const specialties = {

  update: async (id, token, data) => {
    try {
      const response = await api.patch(
        `specialties/${id}`, data,
        { headers: { Authorization: token } }
      )
      return response.data
    } catch (e) {
      throw e.response.data
    }
  },
  get: async () => {
    try {
      const response = await mvApi.get('/v0/soul/specialties')
      if(response.data.result){
        return response.data.result.data.specialties
      }
      throw Error("specialties not found")

    } catch (e) {
      throw e.response.data
    }
  }
}

export default specialties
