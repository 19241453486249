import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Bounce } from 'react-activity'
import { connect } from 'react-redux'
import { passwordRequest, resetState } from 'store/modules/auth/actions'
import logo from '../../assets/logo.png'
import IconInput from '../../components/IconInput'
import { Button, PasswordLink, Logo } from './styled'
import { Link } from 'react-router-dom'
// import user from 'consumers/user'
import { showAlert } from 'store/modules/alert/actions'
import { validateData } from 'utils/validators'
import { toDateWithoutHour } from 'utils/formaters'

import { Grid } from 'semantic-ui-react'
import Inputmask from 'inputmask'

function SignUp ({ passwordRequest, resetState, loading, emailValidator, showAlert }) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const [cpf, setCPF] = useState('')
  const [birthdate, setBirthdate] = useState('')
  const [cellPhone, setCellphone] = useState('')
  const [telephone, setTelephone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [errors, setErros] = useState({})

  Inputmask().mask(document.querySelectorAll('input'))

  useEffect(() => {
    resetState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submit = async (data) => {
    
    let errors = validateData(data)        
    setErros(errors)        

    if (Object.keys(errors).length === 0) {    

      if (data.password !== data.confirmPassword) {
        showAlert(400, 'Atenção', 'Senhas não correspondem')
        return
      }

      if (data.email !== data.confirmEmail) {
        showAlert(400, 'Atenção', 'Emails não correspondem')
        return
      }

    } else {
      console.log('with errors')
    }

    // try {
    //   const response = await user.forgotPassword({email})
    //   showAlert(response.code, 'Sucesso!', response.message)
    // } catch (err) {
    //   if (!err.response) {
    //     showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
    //   }
    //   showAlert(400, 'Atenção', err.message, err.technical)
    // }
  }

  return (
    <>
      <Logo src={logo} width='160px' alt='UX Agenda Logo' />

      <Grid columns='equal'>

        <Grid.Row centered>
          <h1>Criar uma conta</h1>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={6}>
            <IconInput
              onChange={(e) => setCPF(e.target.value)}
              placeholder='CPF'
              label='CPF'
              type='text'
              signup
              icon='person-circle'
              data-inputmask="'mask': '999.999.999-99'"
              error={errors.cpf}
            />
          </Grid.Column>

          <Grid.Column>
            <IconInput
              onChange={(e) => setName(e.target.value)}
              placeholder='Nome'
              label='Nome'
              type='text'
              signup
              icon='person-circle'
              error={errors.name}
            />
          </Grid.Column>
        </Grid.Row>        

        <Grid.Row>
          <Grid.Column width={6}>
            <IconInput
              onChange={(e) => setBirthdate(e.target.value)}
              placeholder='Data de nascimento'
              label='Data de nascimento'
              type='text'
              signup
              icon='calendar'
              data-inputmask="'mask': '99/99/9999'"
              error={errors.birthdate}
            />
          </Grid.Column>

          <Grid.Column>
            <IconInput
              onChange={(e) => setCellphone(e.target.value)}
              placeholder='Celular'
              label='Celular'
              type='text'
              signup
              icon='call-outline'
              data-inputmask="'mask': '(99) 99999-9999'"
              error={errors.cellPhone}
            />
          </Grid.Column>

          <Grid.Column>
            <IconInput
              onChange={(e) => setTelephone(e.target.value)}
              placeholder='Telefone'
              label='Telefone'
              type='text'
              signup
              icon='call-outline'
              data-inputmask="'mask': '(99) 9999-9999'"
              error={errors.telephone}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <IconInput
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
              label='Email'
              type='email'
              signup
              icon='mail-outline'
              error={errors.email}
            />
          </Grid.Column>

          <Grid.Column>
            <IconInput
              onChange={(e) => setConfirmEmail(e.target.value)}
              placeholder='Confirmar Email'
              label='Email'
              type='email'
              signup
              icon='mail-outline'
              error={errors.confirmEmail}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <IconInput
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Senha'
              label='Senha'
              type='password'
              icon="key-outline"              
              signup 
              error={errors.password}                           
            />
          </Grid.Column>

          <Grid.Column>
            <IconInput
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder='Confirmar senha'
              label='Confirmar senha'
              type='password'              
              icon="key-outline"              
              signup        
              error={errors.confirmPassword}                                 
            />
          </Grid.Column>
        </Grid.Row>
          
        <Grid.Row centered>          
            <Button style={{ width: '50%', marginTop: 21 }} onClick={() => submit({ email, confirmEmail, name, cpf, birthdate: toDateWithoutHour(birthdate), cellPhone, telephone, password, confirmPassword })}>
              {loading ? (
                <Bounce color='#FCFCFC' size={22} speed={1} animating={true} />
              ) : (
                'Cadastrar'
              )}
            </Button>                    
        </Grid.Row>

        <Grid.Row centered>
          <PasswordLink style={{ textAlign: 'center' }}>
            Já possui login? <Link to='/'>entrar no sistema</Link>
          </PasswordLink>
        </Grid.Row>
      </Grid>
    </>
  )
}

SignUp.propTypes = {
  passwordRequest: PropTypes.func,
  resetState: PropTypes.func,
  loading: PropTypes.bool,
  emailValidator: PropTypes.exact({
    isValid: PropTypes.bool,
    message: PropTypes.string
  })
}

const mapStateToProps = (state) => ({
  emailValidator: state.auth.emailValidator,
  loading: state.auth.loading
})

const mapDispatchToProps = (dispatch) => ({
  passwordRequest: (email, password) => dispatch(passwordRequest(email, password)),
  resetState: () => dispatch(resetState()),
  showAlert: (type, title, message) => dispatch(showAlert(type, title, message))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
