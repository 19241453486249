import React, { Component } from 'react'
import { Checkbox, Grid } from 'semantic-ui-react'
import Input from 'components/Input'
import Button from 'components/Button'

class QueryOptions extends Component {
  render() {
    const { input, toggle, button } = this.props
    return (
      <Grid>
        <Grid.Row verticalAlign='middle'>
          {input ? input.map(item => {
            return (
              <Grid.Column key={item.label} width={item.width ? item.width : 2}>
                <Input label={item.label} onSelect={item.onSelect} onChange={item.onChange} />
              </Grid.Column>
            )
          }) : <></>}
          {toggle ? toggle.map(item => {
            return (
              <Grid.Column key={item.label} width={item.width ? item.width : 3}>
                <Checkbox toggle
                  label={item.label}
                  checked={item.checked}
                  onClick={item.onClick}
                />
              </Grid.Column>
            )
          }) : <></>}
          {button ? button.map(item => {
            return (
              <Grid.Column key={item.label} width={item.width ? item.width : 2}>
                <Button filled onClick={item.onClick}>{item.label}</Button>
              </Grid.Column>
            )
          }) : <></>}
        </Grid.Row>
      </Grid>
    )
  }
}

export default QueryOptions
