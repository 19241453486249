import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { setNotification } from 'store/modules/notification/actions'
import NotificationButton from 'components/NotificationButton'
import renderHTML from 'react-render-html'
import Notification from 'consumers/notification'

import { Wrapper, TitleContainer, Title, NavContainer, BodyContainer, FooterContainer, DeleteButton } from './styled'
import { Modal, Grid, Button, Icon } from 'semantic-ui-react'
import { showConfirmation, showAlert, showLoading } from 'store/modules/alert/actions'

function NotificationModal ({
  notifications,
  open,
  onClose,
  indexActive,
  token,
  showAlert,
  showConfirmation,
  setNotification,
  handleReadConfirm
  }) {
  const [active, setActive] = useState(1)

  useEffect(() => {
    setActive(indexActive)
    console.log('alterou', indexActive)
    if (notifications[indexActive]?.read === false) handleReadConfirm(indexActive)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexActive])

  useEffect(() => {
    if (notifications[active]?.read === false) handleReadConfirm(active)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  const handleDeleteAll = () => {
    showConfirmation(20, 'Remover', 'Deseja remover todas as notificações?', async () => {
      try {
        const response = await Notification.deleteAll(token)
        showAlert(response.code, 'Sucesso', response.message)
        setNotification(token)
      } catch (err) {
        if (!err.response) {
          return showAlert(500, 'Atenção', 'Falha ao se comunicar com o servidor!')
        }
        showAlert(err.response.data.code, 'Atenção', err.response.data.message)
      }
    })
  }

  const handleDelete = async (id) => {
    try {
      await Notification.delete(token, id)
      setNotification(token)
    } catch (err) {
      if (!err.response) {
        return showAlert(500, 'Atenção', 'Falha ao se comunicar com o servidor')
      }
      showAlert(err.response.data.code, 'Atenção', err.response.data.message)
    }
  }

  return (
    <Modal open={open} onClose={onClose} style={{ margin: '0' }}>
      <Modal.Header style={{ backgroundColor: 'rgba(0,0,0, 0.92)', color: '#fff' }}>Notificações</Modal.Header>
      <Modal.Content style={{ backgroundColor: 'rgba(0,0,0,.1)' }}>
        <Grid>
          <Grid.Column width={3} style={{ padding: '0px' }}>
            <NavContainer>
              {notifications
                ? notifications.map((notify, index) => (
                  <NotificationButton
                    key={index}
                    title={notify.title}
                    body={notify.body}
                    onClick={() => setActive(index)}
                    active={active === index}
                  />
                )) : ''}
              {
              notifications.length ?
              <Button
                style={{ width: '100%', margin: '5px 0px', fontSize: '13px' }}
                icon
                color='red'
                labelPosition='left'
                onClick={handleDeleteAll}>
                <Icon name='trash alternate outline' />
                Deletar todos
              </Button> : ''
              }
            </NavContainer>
          </Grid.Column>
          <Grid.Column width={13} style={{ padding: '0px 0px 0px 8px', backgroundColor: 'rgba(0,0,0, 0)' }}>
            {notifications
              ? notifications.map((notify, index) => (
                index === active
                  ? <Wrapper key={index}>
                    <TitleContainer>
                      <Title>{notify.title}</Title>
                      <DeleteButton onClick={() => handleDelete(notify._id)}>Excluir</DeleteButton>
                    </TitleContainer>
                    <BodyContainer>
                      <p>{renderHTML(notify.body)}</p>
                    </BodyContainer>
                    <FooterContainer>
                      <h1>AGX software</h1>
                      <p>{notify.creatorName}</p>
                    </FooterContainer>
                  </Wrapper>
                  : ''
              ))
              : ''
            }
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

NotificationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  indexActive: PropTypes.number,
  notifications: PropTypes.array,
  token: PropTypes.string,
  showAlert: PropTypes.func,
  showConfirmation: PropTypes.func,
  setNotification: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notification.notifications,
    token: state.auth.token
  }
}

const mapDispatchToProps = (dispatch) => ({
  showConfirmation: (type, title, text, func) =>
    dispatch(showConfirmation(type, title, text, func)),
  showAlert: (type, title, message, details, func) =>
    dispatch(showAlert(type, title, message, details, func)),
  showLoading: (type, title, message, func) =>
    dispatch(showLoading(type, title, message, func)),
  setNotification: (token) => dispatch(setNotification(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal)
