import api from 'services/api'

const tabulationName = {

  delete: async (id, token) => {
    try {
      const response = await api.delete(
        `tabulationnames/${id}`,
        { headers: { Authorization: token } }
      )
      return response.data
    } catch (e) {
      throw e.response.data
    }
  },

  update: async (id, token, data) => {
    try {
      const response = await api.patch(
        `tabulationnames/${id}`, data,
        { headers: { Authorization: token } }
      )
      return response.data
    } catch (e) {
      throw e.response.data
    }
  }
}

export default tabulationName
