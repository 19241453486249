import { capitalize } from './stringMethods'

export const translateStatus = (value, card, isOnPhone, clientName) => {
  switch (value) {
    case 'unavailable':
      return 'Indisponível'
    case 'online':
      return 'Online'
    case 'available':
      return 'Disponível'
    case 'busy':
      return card ? 'Em atendimento' : isOnPhone ? '' : ''
    case 'ending':
      return clientName ? 'Finalizando atendimento de ' : 'Finalizando atendimento...'
    case 'paused':
      return 'Pausado'
    default:
      if (!value) return 'Offline'
      return capitalize(value)
  }
}

export const translatePhone = (value) => {
  switch (value) {
    case 'cellphone':
      return 'Celular'
    case 'secondCellphone':
      return 'Celular'
    case 'telephone':
      return 'Telefone'
    default:
      return value
  }
}

export const translatePeriod = (value) => {
  switch (value) {
    case 'afternoon':
      return 'Tarde'
    case 'morning':
      return 'Manha'
    case 'night':
      return 'Noite'
    case 'full-time':
      return 'Integral'
    default:
      return value
  }
}

export const situationToColor = (value) => {
  switch (value) {
    case 'pending':
      return 'gray'
    case 'attended':
      return 'green'
    case 'confirmed':
      return 'purple'
    default:
      return 'white'
  }
}
export const translateSituation = (value) => {
  switch (value) {
    case 'pending':
      return 'Agendado'
    case 'attended':
      return 'Atendido'
    case 'confirmed':
      return 'Confirmado'
    default:
      return capitalize(value)
  }
}

export const attendedToColor = (value) => {
  if (value) {
    return 'green'
  } else {
    return 'red'
  }
}
export const translateAttended = (value) => {
  if (value) {
    return 'Atendido'
  } else {
    return 'Não atendido'
  }
}
export const fittingToColor = (value) => {
  if (value) {
    return ''
  } else {
    return ''
  }
}
export const translateFitting = (value) => {
  if (value) {
    return 'Sim'
  } else {
    return 'Não'
  }
}
export const whatsAppCollor = (value) => {
  switch (value) {
    case true:
      return 'green'
    case false:
      return 'red'
    default:
      return 'gray'
  }
}

export const translateWhatsApp = (value) => {
  switch (value) {
    case true:
      return 'Sim'
    case false:
      return 'Não'
    default:
      return ''
  }
}

export const translateConsultType = (value) => {
  switch (value) {
    case 'new':
      return 'Nova'
    case 'return':
      return 'Retorno'
    case 'routine':
      return 'Rotina'
    case 'emergency':
      return 'Emergência'
    default:
      return capitalize(value)
  }
}

export const translateReportType = value => {
  switch (value) {
    case 'pauses':
      return 'Pausas'
    case 'tabulation':
      return 'Tabulação'
    case 'schedule':
      return 'Agendamento'
    case 'operator':
      return 'Operador'
    default:
      return capitalize(value)
  }
}

export const translateSentStatusWhats = value => {
  if (value) {
    return 'Enviado'
  } else {
    return 'Não enviado'
  }
}

export const convertMessageByCode = (type) => {
  let icon = ''
  if (!type) return 'info'
  if (isNaN(Number(type))) return type
  switch (Number(type)) {
    case 20:
      icon = 'question'
      break
    case 10:
      icon = 'info'
      break
    case 200:
    case 201:
      icon = 'success'
      break
    case 400:
    case 402:
    case 403:
    case 404:
    case 409:
    case 422:
      icon = 'warning'
      break
    case 500:
    case 503:
      icon = 'error'
      break
    default:
      break
  }
  return icon
}
