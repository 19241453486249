import React, { useState, useEffect } from 'react'

import omnichannel from 'consumers/omnichanel'
import { ConfirmationContainer, ConfirmationLink, RejectLink, Loader } from './styled'

const Confirmation = ({ location }) => {
  // const token = location.search
  const [user, setUser] = useState({})
  const [confirmation, setConfirmation] = useState('')
  const [loading, setLoading] = useState(false)

  const getUserData = async () => {
    const token = location.search

    try {
      const response = await omnichannel.userData(token)

      setUser(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async (answer) => {
    setLoading(true)
    const token = location.search
    try {
      const response = await omnichannel.confirmPage(token, answer)
      console.log(response.data.response)
      setConfirmation(response.data.response)
    } catch (error) {
      console.log(error.message)
      setConfirmation(error.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    setLoading(true)
    await getUserData()
    setLoading(false)
  }, [])

  const renderChose = (
    <>
      <header>
        {user?.name ? <h1>Olá, {user?.name}!</h1> : (
          <>
            <h1>Olá!<br/>Obrigado por tentar confirmar seu agendamento.</h1>
            <p>Infelizmente não encontramos seu agendamento, tente mais tarde.</p>
          </>
        ) }
      </header>
      <main>
        { user?.confirmation === true ? (
          user?.consultType ? <h3>Você confirma sua presença para a {user?.consultType}?</h3> : ''
        ) : (
          <h3>Obrigado por responder!</h3>
        )}
        <div>
          { user?.address ? <p>Local: {user?.address}</p> : '' }
          { user?.doctor ? <p>Médico: {user?.doctor}</p> : '' }
          { user?.date ? <p>Dia: {user?.date}</p> : '' }
          { user?.hour ? <p>Hora: {user?.hour}</p> : '' }
        </div>
        <form>
          { user?.confirmation === false ? (
            <>
              <ConfirmationLink onClick={(event) => {
                event.preventDefault()
                handleSubmit('accept')
              }}
              >Confirmo</ConfirmationLink>
              <RejectLink onClick={(event) => {
                event.preventDefault()
                handleSubmit('reject')
              }}
              >Não confirmo</RejectLink>
            </>
          ) : '' }
        </form>
      </main>
      <footer>

      </footer>
    </>
  )

  const renderConfirmation = (
    <header>
      <h1>Obrigado.<br/>{confirmation}</h1>
    </header>
  )

  return (
    <ConfirmationContainer loading={loading}>
      {
        loading ? (
          <Loader/>
        ) : (confirmation ? renderConfirmation : renderChose)
      }

    </ConfirmationContainer>
  )
}

export default Confirmation
