import mvApi from 'services/mvApi'
const indicationTypes = {

  getServiceTypes: async () => {
    try {
      const response = await mvApi.get('/v0/soul/indications/types')
      const indicationTypes = response.data.data.result.data.indicationTypes
      return indicationTypes
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  }

}

export default indicationTypes
