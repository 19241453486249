import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { getTimeDifference, toDisplayTime } from 'utils/formaters'

import { Circle, Time } from './styled'
import moment from 'moment'

function Counter ({ lastCall, status, call, nav }) {
  const [now, setNow] = useState(Date.now())

  useEffect(() => {
    const pausedInterval = setInterval(() => {
      setNow(Date.now())
    }, 1000)
    if (!lastCall) clearTimeout(pausedInterval)
    return () => {
      clearTimeout(pausedInterval)
    }
  }, [lastCall])

  const getTime = (lastCall, now) => {
    if(lastCall === null) return ""
    if ((status === 'available' && call.id) || (status === 'busy' && call.id === null)) return '--:--'
    if (status === 'busy' || status === 'ending') return toDisplayTime(moment(now).diff(moment.utc(lastCall)))
    return toDisplayTime(getTimeDifference(lastCall, now))
  }

  if (nav) {
    return ['paused', 'unavailable'].includes(status)
      ? <></>
      : <Time nav>{status === 'busy' ? getTime(call.createdAt, now) : status === 'ending' ? getTime(call.finishedAt, now) : getTime(lastCall, now)}</Time>
  } else {
    return (
      <Circle typeColor={status}>
        {['paused', 'busy', 'unavailable', 'ending'].includes(status)
          ? <Time>{
            status === 'paused' ? 'Pausado'
              : (status === 'busy') ? getTime(call.createdAt, now)
                : (status === 'ending') ? 'Finalizando' : 'Indisponível'}</Time>
          : <Time>{getTime(lastCall, now)}</Time>}
      </Circle>
    )
  }
}

Counter.propTypes = {
  lastCall: PropTypes.number,
  status: PropTypes.string
}

const mapStateToProps = (state) => ({
  lastCall: state.telephony.lastCall,
  status: state.auth.user.status,
  call: state.telephony.call
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Counter)
