import styled from 'styled-components'

export const WrapperIcon = styled.div `
    flex-grow: 0.25;
    width: 30%;
    background-color: ${props => props.color ? props.color : "#dfdfdf"};
`

export const WrapperContent = styled.div `
    flexDirection: collumn;
    flex-grow: 1;    
    padding: 15px;    
    margin: auto;
`