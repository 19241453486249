import api from 'services/api'

const user = {
  getUser: async (token) => {
    try {
      const response = await api.get(
        `/users`,
        { headers: { Authorization: token } }
      )
      return response.data.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },

  update: async (token, data) => {
    try {
      const response = await api.patch('/users', data,
        {headers: { Authorization: token }}
      )
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },
  
  selfie: async (token, id, file) => {
    const formData = new FormData()
    formData.append("selfie", file)

    try {
      const response = await api.put(`/upload/${id}/selfie`, formData,{
        headers: { Authorization: token,
        "Content-Type": "multipart/form-data" 
      }})
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },

  forgotPassword: async (data) => {
    try {
      const response = await api.post(`/forgotPassword`, data)
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },

  resetPassword: async (token, data) => {
    try {
      const response = await api.patch(`/forgotPassword${token}`, data)
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },

  changeEmail: async (token, data) => {
    try {
      const response = await api.post('/users/email/change', data, 
        {headers: { Authorization: token }}
      )
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },

  emailConfirm: async (token) => {
    try {
      const response = await api.patch(`/confirmations/email${token}`)
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },
}

export default user