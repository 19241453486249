const _initialState = {
  notifications: [],
  confirmation: 0,
  registers: null,
  totalDocs: 0,
  totals: null,
  ranking: null,
  totalPages: 0
}

const notification = (state = _initialState, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS': {
      return {
        ...state,
        notifications: action.notifications
      }
    }

    case 'SET_CONFIRMATION_NOTIFICATIONS': {
      return {
        ...state,
        confirmation: action.confirmation
      }
    }

    case 'SET_CONFIRMATION_REGISTERS': {
      const aux = String(action.totalDocs / 10).split('.')
      const totalPages = aux.length > 1 ? Number(aux[0]) + 1 : Number(aux[0])
      return {
        ...state,
        registers: action.registers,
        totalDocs: action.totalDocs,
        totalPages
      }
    }

    case 'SET_CONFIRMATION_TOTALS': {
      return {
        ...state,
        totals: action.totals
      }
    }

    case 'SET_CONFIRMATION_RANKING': {
      return {
        ...state,
        ranking: action.ranking
      }
    }

    case 'SET_FIRST_TIME_REGISTERS': {
      const aux = String(action.totalDocs / 10).split('.')
      const totalPages = aux.length > 1 ? Number(aux[0]) + 1 : Number(aux[0])
      return {
        ...state,
        registers: action.registers,
        totalDocs: action.totalDocs,
        totalPages
      }
    }

    case 'SET_FIRST_TIME_TOTALS': {
      return {
        ...state,
        totals: action.totals
      }
    }

    case 'SET_FIRST_TIME_RANKING': {
      return {
        ...state,
        ranking: action.ranking
      }
    }

    default:
      return state
  }
}

export default notification
