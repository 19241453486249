import styled, { keyframes } from 'styled-components'

export const Input = styled.input`
  height: 48px;
  background: #262626;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 90%;
  color: #fcfcfc;
  padding-left: 40px;
  padding-right: 8px;
  font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
  margin-bottom: ${props => props.unit ? '12px' : '0'};
  &::placeholder{
    color: #A6A6A6;
  }
`

export const Button = styled.button`
  width: 76%;
  background: #002D5C;
  height: 48px;
  box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.0);
  border-radius: 67px;
  color: #F9F9F9;
  font-weight: 500;
  font-size: 18px;
  font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
  transition: all 0.1s linear;
  &:hover {
    box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.1);
  }
`

export const PasswordLink = styled.div`
  width: 76%;
  text-align: right;
  margin-top: 12px;
  margin-bottom: 12px;
  a {
    font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
    color: #fff;
    font-weight: 500;
  }
`

export const LoginLink = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 12px;
  height: 100px;
  a {
    
    font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
    width: 70%;
    color: #fff;
    font-weight: bold;
    padding: 15px 30px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    background-color: #002D5C;
    border-radius: 15px;
    align-self: center;
    vertical-align: middle;
  }
`

export const Logo = styled.img`
  margin-bottom: 20px;
`

export const WrapperPasswordLink = styled.div`
  margin: 5px 0;  
  display: flex;
  width: 76%;
`
export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: ${props => props.loading ? 'center' : ''};

  justify-content: center;

  header, main, footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  header, footer {
    flex: auto;
  }

  header {
    text-align: center;
    flex-direction: column;

    p {
      margin-top: 60px;
    }
  }

  main {
    flex: 2;
    flex-direction: column;


    form {
      flex: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: auto;
      margin: 20px 0 0 0;

      button {
        transition: background-color 150ms ease-in;
        border-radius: 15px;
        color: #fafafa;
        font-size: 17px;
        text-decoration: none;
        margin: 0 20px;
      }

    }
  }
`

export const ConfirmationLink = styled.button`
  background-color: #41b441;
  padding: 5px 28px;

  &:hover {
    background-color: #109710;
  }
`

export const RejectLink = styled.button`
  background-color: #b44141;
  padding: 5px 18px;

  &:hover {
    background-color: #971010;
  }
`
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
` 

export const Loader = styled.div`
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: ${spin} 2s linear infinite;
` 
