import styled from 'styled-components'

export const Label = styled.label`
  font-weight: 600;
  color: #818181;
  margin-left: 4px;
  line-height: 38px;
`

export const Input = styled.input`
  height: 36px;
  border-bottom: ${props => props.hasError ? '1px solid red' : '1px solid #919191'};
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  width: 100%;
  color: #000000;
  padding-left: ${props => props.hasIcon ? '40px' : '10px'};
  padding-right: 12px;
  transition: box-shadow 100ms linear;
  margin-bottom: ${props => props.hasError ? '5px' : null};


  &::placeholder{
    color: #A6A6A6;
  }

  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  }
  
  &:focus {
    border-bottom: ${props => props.hasError ? '1px solid red' : '1px solid #515151'};
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    background: #FAFAFA;
    border-bottom: '1px solid #FAFAFA';
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
  `

export const Wrapper = styled.div`
  margin-bottom: ${props => props.unit ? '12px' : '0'};
  position: relative;
`

export const ErrorMsg = styled.span`
  font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
  color: red;
  font-size: 11px;
  margin-left: 4px;
`
export const Spacer = styled.div`
  height: 17px;
  margin: 4px;
`
