import React, { Component, createRef } from 'react'
import Card from 'components/Card'
import { Table, Grid } from 'semantic-ui-react'
import { Bounce } from 'react-activity'
import omnichannel from 'consumers/omnichanel'
import { connect } from 'react-redux'
import {
  setSwalLoading,
  closeAllSwal,
  showAlert
} from 'store/modules/alert/actions'
import { Bar } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import Select from 'components/Select'
import { setFirstTimeRanking } from 'store/modules/notification/actions'

class WhatsAppDashRanking extends Component {
  constructor (props) {
    super(props)
    this.state = {
      period: 'day',
      date: null,
      allPeriods: [
        { value: 'day', label: 'Diário' },
        { value: 'week', label: 'Semanal' },
        { value: 'month', label: 'Mensal' },
        { value: 'year', label: 'Anual' }
      ],
      top3: {
        label: [],
        data: []
      }
    }

    this.periodRef = createRef()
  }

  getRanking = async () => {
    const { period, date } = this.state
    const { setFirstTimeRanking } = this.props

    const query = period
      ? date ? '?period=' + period + '&date=' + date : '?period=' + period
      : ''

    try {
      const response = await omnichannel.ranking(`${query}`)

      setFirstTimeRanking(response.data)
      const aux = response.data.ranking

      const auxTop3 = {
        label: [aux[1].name, aux[0].name, aux[2].name],
        data: [aux[1].total, aux[0].total, aux[2].total]
      }

      this.setState({ top3: auxTop3 })
    } catch (error) {
      console.log(error)
    }
  }

  handlePeriodChange = (e) => {
    this.setState({ period: e.period }, () => this.getRanking())
  }

  handleDateChange = (e) => this.setState({ date: e })

  componentDidMount () {
    this.getRanking()
  }

  render () {
    const { allPeriods, top3 } = this.state
    const { ranking } = this.props

    return (
      <>
        <Card title='Ranking'>
          { !ranking
            ? <>
              <Grid centered>
                <Grid.Row>
                  <Bounce
                    className="bounce-margin"
                    color="#212121"
                    size={22}
                    speed={1}
                    animating={true}
                  />
                </Grid.Row>
              </Grid>
            </>
            : <>
              <Grid>
                <Grid.Row centered>
                  <Grid.Column width={8}>
                    <Bar
                      data={{
                        labels: top3.label,
                        datasets: [
                          {
                            data: top3.data,
                            backgroundColor: ['#B4B4B4', '#C9B037', '#AD8A56'],
                            borderWidth: 0,
                            weight: 10
                          }
                        ]
                      }}
                      height={100}
                      legend={{
                        display: false
                      }}
                    >
                    </Bar>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Select
                      inputRef={this.periodRef}
                      label='Período'
                      options={allPeriods}
                      placeholder='Selecione... '
                      onChange={(e) => this.handlePeriodChange({ period: e.value })}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Table celled striped padded compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Operador</Table.HeaderCell>
                    <Table.HeaderCell>Confirmações</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {ranking.map((rank, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{rank.name}</Table.Cell>
                      <Table.Cell>{rank.total}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          }
        </Card>
      </>
    )
  }
}

WhatsAppDashRanking.propTypes = {
  ranking: PropTypes.array,
  setFirstTimeRanking: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notification,
    token: state.auth.token,
    user: state.auth.user,
    ranking: state.notification.ranking
  }
}

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, text, details, func) => dispatch(showAlert(type, title, text, details, func)),
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal()),
  setFirstTimeRanking: (msg) => dispatch(setFirstTimeRanking(msg))
})

export default connect(mapStateToProps, mapDispatchToProps)(WhatsAppDashRanking)
