import React from 'react'
import { Label } from 'semantic-ui-react'
import waIcon from 'assets/img/whatsapp-icone.png'

const colors = {
  'Não enviado': 'red',
  Enviado: 'green',
  pending: 'grey',
  canceled: 'yellow'
}

function StatusLabel ({ type, whats, children, ...rest }) {
  const color = colors[type]
  return (
    <Label {...rest} color={color} image style={{ paddingLeft: whats ? '' : 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {whats && (
        <img src={waIcon} alt="icone do aplicativo de mensagens whatsapp" />
      )}
      {children}
    </Label>
  )
}

export default StatusLabel
