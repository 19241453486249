import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AuthRoute from './AuthRoute'
import AdminRoute from './AdminRoute'
import PatientRoute from './PatientRoute'

import Signin from 'pages/auth/Signin'
import ForgotPass from 'pages/auth/ForgotPass'
import SignUp from 'pages/auth/SignUp'
import ResetPass from 'pages/auth/ResetPass'
import ConfirmEmail from 'pages/auth/ConfirmEmail'
import Confirmation from 'pages/auth/Confirmation'
import OmniDashboard from 'pages/common/OmniDashboard'
import ConsentPage from 'pages/common/ConsentPage'
// import FilesUpload from 'components/FilesUpload'

// const FileUpload = () =>
//   <FilesUpload files={[{ name: 'selfie', label: 'Selfie' }]}/>

export default function Routes () {
  return (
    <Switch>
      <AuthRoute exact path='/' component={Signin} />
      <AuthRoute exact path='/resetpassword' component={ForgotPass} />
      <AuthRoute signupWidth exact path='/signup' component={SignUp} />
      <AuthRoute path='/resetpassword/reset' component={ResetPass} />
      <AuthRoute path='/email/confirm' component={ConfirmEmail} />
      <PatientRoute path='/confirmation' component={Confirmation} />
      <Route path='/dash' component={OmniDashboard} />
      <Route path='/consent/:number' component={ConsentPage} />
      <AdminRoute
        path='/admin'
      />
      {/* <AuthRoute path='/file/upload' component={FileUpload}/> */}
      <Route path='*'><Redirect to='/'/></Route>
    </Switch>
  )
}
