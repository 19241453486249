import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getPauseReasons } from 'store/modules/resources/actions'
import { setPause, setResume } from 'store/modules/auth/actions'
import { toDisplayTime } from 'utils/formaters'

import { Dropdown, Icon } from 'semantic-ui-react'
import { Wrapper, PausedText, Button } from './styled'

function PauseResumeButton ({
  getPauseReasons,
  setPause,
  setResume,
  token,
  pauseReasons,
  logged,
  status,
  pauseStartedAt
}) {
  const [timePaused, setTimePaused] = useState(
    toDisplayTime(Date.now() - pauseStartedAt)
  )

  const DropdownTrigger = () => (
    <Wrapper>
      <Icon name={status !== 'paused' ? 'pause' : 'play'}/>
      <PausedText paused={!!pauseStartedAt}>
        {status !== 'paused' ? 'Pausar' : `Pausado (${timePaused})`}
      </PausedText>
    </Wrapper>
  )
  useEffect(() => {
    if (Array.isArray(pauseReasons) && pauseReasons.length <= 0) {
      getPauseReasons(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged])
  useEffect(() => {
    const pausedInterval = setInterval(() => {
      let time = toDisplayTime(Date.now() - pauseStartedAt)
      if (pauseStartedAt === null) time = '00:00'
      setTimePaused(time)
    }, 1000)
    if (!pauseStartedAt) {
      clearTimeout(pausedInterval)
      setTimePaused('00:00')
    }
    return () => {
      clearTimeout(pausedInterval)
      setTimePaused('00:00')
    }
  }, [pauseStartedAt])
  return (
    <>
      {pauseStartedAt ? (
        <Button onClick={() => setResume(token)} >
          <Icon name='play'></Icon>
          <PausedText paused={!!pauseStartedAt}>
            {`Pausado (${timePaused})`}
          </PausedText>
        </Button>
      ) : (
        <Dropdown trigger={<DropdownTrigger />} icon={null} disabled={status === 'busy'}>
          <Dropdown.Menu>
            {pauseReasons
              ? pauseReasons.map((reason) => (
                <Dropdown.Item
                  key={reason._id}
                  onClick={() => {
                    setPause(reason._id, token)
                  }}>
                  {reason.name}
                </Dropdown.Item>
              )) : <></>}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  )
}

PauseResumeButton.propTypes = {
  token: PropTypes.string,
  logged: PropTypes.bool,
  status: PropTypes.string,
  pauseStartedAt: PropTypes.number,
  pauseReasons: PropTypes.arrayOf(PropTypes.object),
  getPauseReasons: PropTypes.func,
  setPause: PropTypes.func,
  setResume: PropTypes.func
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  logged: state.auth.logged,
  pauseStartedAt: state.auth.pauseStartedAt,
  pauseReasons: state.resources.pauseReasons,
  status: state.auth.user.status
})

const mapDispatchToProps = (dispatch) => ({
  getPauseReasons: (token) => dispatch(getPauseReasons(token)),
  setPause: (pauseId, token) => dispatch(setPause(pauseId, token)),
  setResume: (token) => dispatch(setResume(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(PauseResumeButton)
