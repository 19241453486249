import { onlyDigits } from './onlyDigits'
import moment from 'moment'

const toDisplayTime = (value) => {
  const hours = moment.utc(value).format('HH')
  const minutes = moment.utc(value).format('mm')
  const seconds = moment.utc(value).format('ss')

  if (hours !== '00') return `${hours}:${minutes}:${seconds}`
  return `${minutes}:${seconds}`
}

const toDate = (day, hour) => {
  const explode = day.split('/')
  const dateFormat = explode[2] + '-' + explode[1] + '-' + explode[0]
  return new Date(dateFormat + ' ' + hour)
}

const toDateWithoutHour = (day) => {
  const explode = day.split('/')
  const dateFormat = explode[2] + '-' + explode[1] + '-' + explode[0]
  return new Date(dateFormat)
}

const getTimeDifference = (time) => {
  const now = Date.now()
  return now - time
}

const formatCpf = (cpf) => {
  if (!cpf || cpf === null) return ''
  if ((typeof cpf !== 'string')) { throw new Error('CPF is not a String.') }
  cpf = onlyDigits(cpf)
  if (cpf.length < 11) { throw new Error('CPF is too small') }
  return `${cpf.substr(0, 3)}.${cpf.substr(3, 3)}.${cpf.substr(
    6,
    3
  )}-${cpf.substr(9)}`
}

const formatPhone = (phone) => {
  if (!phone || phone === null) return ''
  if (phone.length <= 5) return phone
  return `(${phone.substr(0, 2)}) ${phone.substr(2, 5)}-${phone.substr(7)}`
}

const toDateFilter = (day, hour) => {
  const explode = day.split('/')
  const dateFormat = explode[2] + '-' + explode[1] + '-' + explode[0]
  return dateFormat + 'T' + hour
}

const getFirstLetters = (value) => {
  if(!value) return  
  if(value.length <= 8) return value

  let arrayWords = []
  let finalWord = ''

  arrayWords = value.split(" ")
  arrayWords.forEach(word => {
    finalWord += word[0] + " "
  })
  return finalWord
}

const getFirstWord = (value) => {
  if(!value) return  
  let arrayWords = []

  arrayWords = value.split(" ")
  return arrayWords[0]
}

export {
  toDisplayTime,
  toDate,
  toDateFilter,
  formatPhone,
  getTimeDifference,
  formatCpf,
  toDateWithoutHour,
  getFirstLetters,
  getFirstWord
}
