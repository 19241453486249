import mvApi from 'services/mvApi'

const scheduleProtocol = {

  create: async (data) => {
    try {
      const response = await mvApi.post(
        '/v0/soul/schedules/patients/insert/protocol/generate',
        {
          scheduleItemCode: data.scheduleItemCode,
          patientCode: data.patientCode
        }
      )
      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        error.code = 503
        throw error
      }
      throw e.response.data
    }
  },

  get: async (data) => {
    try {
      const response = await mvApi.get(
        '/v0/soul/schedules/patients/protocol/retrieve',
        {
          params: {
            scheduleItemCode: data.scheduleItemCode,
            patientCode: data.patientCode
          }
        }
      )
      console.log('PROTOCOL URL', response.data)

      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        error.code = 503
        throw error
      }
      throw e.response.data
    }
  }

}

export default scheduleProtocol
