import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Dropdown, Icon } from 'semantic-ui-react'
import { Wrapper, Text } from './styled'

function RamalButton ({
  token,
  ramal,
  status,
  registerUser
}) {
  const DropdownTrigger = () => (
    <Wrapper>
      <Icon name='phone'/>
      <Text>
        {ramal}
      </Text>
    </Wrapper>
  )

  return (
    <Dropdown trigger={<DropdownTrigger />} icon={null}>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => { registerUser() }}
          disabled={!['unavailable', 'offline'].includes(status) }
        >
          {!['unavailable', 'offline'].includes(status) ? 'Ramal Registrado' : 'Registrar Ramal'}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

RamalButton.propTypes = {
  token: PropTypes.string,
  ramal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.string,
  registerUser: PropTypes.func
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  ramal: state.auth.user.ramal,
  status: state.auth.user.status
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(RamalButton)
