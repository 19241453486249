
export const setNotification = () => ({
  type: 'SET_NOTIFICATION'
})

export const setConfirmationNotification = () => ({
  type: 'GET_CONFIRMATION_NOTIFICATION'
})

export const setConfirmationRegisters = () => ({
  type: 'GET_CONFIRMATION_REGISTERS'
})

export const setConfirmationTotals = () => ({
  type: 'GET_CONFIRMATION_TOTALS'
})

export const setConfirmationRanking = () => ({
  type: 'GET_CONFIRMATION_RANKING'
})

export const setFirstTimeRegisters = (msg) => ({
  type: 'SET_FIRST_TIME_REGISTERS',
  registers: msg.registers,
  totalDocs: msg.totalDocs
})

export const setFirstTimeTotals = (msg) => ({
  type: 'SET_FIRST_TIME_TOTALS',
  totals: msg.totals
})

export const setFirstTimeRanking = (msg) => ({
  type: 'SET_FIRST_TIME_RANKING',
  ranking: msg.ranking
})
