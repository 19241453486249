import React, { useState, useEffect } from 'react'
import { Grid, Modal, Table, Label, Dropdown } from 'semantic-ui-react'
import Select from 'components/Select'
import { showAlert, setSwalLoading, closeAllSwal, showLoading } from 'store/modules/alert/actions'
import CalendarHoursInput from 'components/CalendarHoursInput'
import { FormatDateBr, toBrDisplay, dateToBrSmall } from 'utils/formatDate'
import to from 'await-to-js'
import Button from 'components/Button'
import PaginationShort from 'components/Pagination'
import { formatPhone, formatCpf } from 'utils/formaters'
import { validateDateHour } from 'utils/validators'
import { Header, Title } from 'components/Card/styled'
import Card from 'components/Card'

import {
  situationToColor,
  translateSituation,
  attendedToColor,
  translateConsultType,
  translateAttended,
  fittingToColor,
  translateFitting
} from 'utils/translate'
const PricingPage = () => {
  return (
    <>
      <>
        <Card noBg>
          <Header><Title> Valores dos Procedimentos</Title></Header>
        </Card>
      </>
      <>
        <Card noPadding>

          <Grid>

            <Grid.Row>
              <Grid.Column width={3} />

              <Grid.Column width={3}>

                {/* <CalendarHoursInput
                  label='Data inicial do agendamento'
                  onSelect={(e) => {
                    console.log('dfd')
                  }}
                  selected={''}

                /> */}
              </Grid.Column >

              <Grid.Column width={3}>
                {/* <CalendarHoursInput
                  label='Data Final do agendamento'
                  onSelect={(e) => {
                    console.log('sd')
                  }}
                /> */}
              </Grid.Column>

              <Grid.Column width={3}>
                <Select
                  label='Ordenar'
                  options={['ggg']}
                />
              </Grid.Column>

              <Grid.Column width={3} className='table-buttons'>
                <Button filled >
                  Limpar
                </Button>
                {/* <Button filled onClick={this.getReports}>
          Extrair
        </Button> */}
              </Grid.Column>
              <Grid.Column width={2}></Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>

        <Table celled striped padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell class="th-nowrap">Preço</Table.HeaderCell>
              <Table.HeaderCell class="th-nowrap">Preço</Table.HeaderCell>

              <Table.HeaderCell class="th-nowrap">
                Preço
              </Table.HeaderCell>
              <Table.HeaderCell class="th-nowrap">Preço</Table.HeaderCell>

              <Table.HeaderCell class="th-nowrap">Preço</Table.HeaderCell>
              <Table.HeaderCell class="th-nowrap">Preço</Table.HeaderCell>
              <Table.HeaderCell class="th-nowrap">Preço</Table.HeaderCell>
              <Table.HeaderCell class="th-nowrap">Preço</Table.HeaderCell>
              <Table.HeaderCell class="th-nowrap">Preço</Table.HeaderCell>
              <Table.HeaderCell class="th-nowrap">Preço</Table.HeaderCell>
              <Table.HeaderCell class="th-nowrap">Preço</Table.HeaderCell>
              <Table.HeaderCell class="th-nowrap">Preço</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>

            <Table.Row >

              <Table.Cell>
              </Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>

              <Table.Cell>
                {/* <Dropdown text='Ações' button item>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                              Ligar para paciente
                    </Dropdown.Item>

                    <Dropdown.Item>
                              Imprimir
                    </Dropdown.Item>

                    <Dropdown.Item>
                              Ver Paciente
                    </Dropdown.Item>

                    <Dropdown.Item className='cancel-text'>
                            Cancelar
                    </Dropdown.Item>

                    <Dropdown.Item>
                              Aguardando
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown> */}
              </Table.Cell>
            </Table.Row>

          </Table.Body>
        </Table>
      </>
    </>)
}

export default PricingPage
