import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Select from 'components/Select'
import resources from 'consumers/resources'
import IconButton from 'components/IconButton'
import { toBrDisplay, dateToBrSmall } from 'utils/formatDate'

import {
  Table,
  Dropdown,
  Grid,
  Modal,
  Button as SButton
} from 'semantic-ui-react'
import {
  showAlert,
  showConfirmation,
  setSwalLoading,
  closeAllSwal
} from 'store/modules/alert/actions'

import CalendarHoursInput from 'components/CalendarHoursInput'

import Inputmask from 'inputmask'
import { Bounce } from 'react-activity'

import { formatPhone } from 'utils/formaters'
import { validateDateHour } from 'utils/validators'

import { getFirstAndLastWord, titleCase } from 'utils/stringMethods'
import Card from 'components/Card'
import PaginationShort from 'components/Pagination'

import { hoursOptions, sortOptions } from 'utils/resources'
import moment from 'moment'
import Button from 'components/Button'
import telephony from 'consumers/telephony'
import ClickToCall from 'components/ClickToCall'
import { setPatient } from 'store/modules/patient/actions'
import { Link } from 'react-router-dom'
import Input from 'components/Input'

class ListTabulations extends Component {
  constructor (props) {
    super(props)

    this.state = {
      tabulations: undefined,

      // paginação
      totalPages: null,
      activePage: null,
      page: 1,

      dateStart: '',
      dateFinish: '',

      ramal: '',

      hourStart: hoursOptions[0].value,
      hourFinish: hoursOptions[0].value,

      tabulationOptions: [],
      sort: '',
      typeTabulation: '',
      dateHourStart: '',
      dateHourFinish: '',
      expandName: true,
      expandDate: false
    }

    this.dateStartRef = createRef()
    this.dateFinishRef = createRef()
    this.ramalRef = createRef()
  }

  componentDidMount () {
    this.getTabulations()
    this.handleTabulationName()
  }

  // TODO
  handleTabulationName = async () => {
    const { token } = this.props
    try {
      const response = await resources.tabulations(token)
      const tabulationOptions = response.map(tabulation => {
        return { value: tabulation._id, label: tabulation.name }
      })
      this.setState({ tabulationOptions })
    } catch (err) {
      if (!err.response) {
        return showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
      }
      showAlert(400, 'Atenção', err.message, err.technical)
      // this.setState({ tabulationNames: undefined })
    }
  }

  changePage = (event) => {
    if (event) {
      this.setState(
        {
          page: event.target.getAttribute('value')
        },
        () => {
          this.getTabulations()
        }
      )
    }
  }

  changeStartDate = (event) => {
    const inputFormatDate = moment(event).format('DD/MM/YYYY HH:mm')
    this.setState({ dateStart: inputFormatDate })

    const searchFormatDate = moment(event).format('YYYY-MM-DDTHH:mm')
    this.concatDate(searchFormatDate, event, 'start')
  }

  changeFinishDate = (event) => {
    const inputFormatDate = moment(event).format('DD/MM/YYYY HH:mm')
    this.setState({ dateEnd: inputFormatDate })

    const searchFormatDate = moment(event).format('YYYY-MM-DDTHH:mm')
    this.concatDate(searchFormatDate, event, 'finish')
  }

  concatDate = (date, event, type) => {
    if (type === 'start') {
      if (validateDateHour(event)) {
        this.setState({ page: 1, dateHourStart: `&start=${date}` }, () => {
          this.setState({ activePage: 1 })
          this.getTabulations()
        })
      } else {
        this.setState({ dateHourStart: '' }, () => {
          this.getTabulations()
        })
      }
    }

    if (type === 'finish') {
      if (validateDateHour(event)) {
        this.setState({ page: 1, dateHourFinish: `&end=${date}` }, () => {
          this.setState({ activePage: 1 })
          this.getTabulations()
        })
      } else {
        this.setState({ dateHourFinish: '' }, () => {
          this.getTabulations()
        })
      }
    }
  }

  clearFilters = () => {
    this.ramalRef.current.value = ''
    this.setState(
      {
        dateHourStart: '',
        dateHourFinish: '',
        tempDate: '',
        tempDateFinal: '',
        ramal: '',
        page: 1,
        activePage: 1
      },
      () => {
        this.getTabulations()
      }
    )
  }

  setRamal = (ramal) => {
    if (ramal === '') {
      this.setState({ page: 1, ramal: '', activePage: 1 }, () => {
        this.getTabulations()
      })
    } else {
      if (ramal.length >= 4) {
        this.setState({ page: 1, ramal, activePage: 1 }, () => {
          this.getTabulations()
        })
      }
    }
  }

  getTabulations = async () => {
    const { token, showAlert, setSwalLoading, closeAllSwal } = this.props
    const { page, dateHourStart, dateHourFinish, ramal, typeTabulation, sort } = this.state

    const data = {
      page,
      dateHourStart,
      dateHourFinish,
      typeTabulation,
      sort,
      ramal: `&ramal=${ramal}`
    }

    try {
      setSwalLoading()
      const response = await telephony.tabulations(token, data)
      this.setState({
        tabulations: response?.docs,
        totalPages: response?.totalPages,
        activePage: response?.page
      })
      closeAllSwal()
    } catch (e) {
      if (!e.response) {
        return showAlert(
          500,
          'Atenção',
          'Não foi possível se comunicar com o servidor!'
        )
      }
      showAlert(400, 'Atenção', e.message, e.technical)
    }
  }

  toggleExpandDate = () => this.setState({ expandDate: !this.state.expandDate })
  toggleExpandName = () => this.setState({ expandName: !this.state.expandName })

  searchPhone = async (phone) => {
    const { push } = this.props
    push(`/admin/pacientes?phone=${phone}`)
  }

  render () {
    const { tabulationOptions, expandDate, tabulations, totalPages, activePage, expandName } = this.state
    const { level } = this.props
    Inputmask().mask(document.querySelectorAll('input'))
    return (
      <Card title='Tabulações'>
        {!tabulations ? (
          <Grid centered>
            <Grid.Row>
              <Bounce
                className='bounce-margin'
                color='#212121'
                size={22}
                speed={1}
                animating={true}
              />
            </Grid.Row>
          </Grid>
        ) : (
          <>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Input
                    label='Ramal'
                    placeholder='Digite um ramal'
                    onChange={(e) => {
                      this.setRamal(e.target.value)
                    }}
                    inputRef={this.ramalRef}
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <CalendarHoursInput
                    label='Data inicial'
                    onSelect={(e) => {
                      this.setState({ tempDate: e })
                      this.changeStartDate(e)
                    }}
                    selected={this.state.tempDate}
                  />
                </Grid.Column>

                <Grid.Column width={2}>
                  <CalendarHoursInput
                    inputRef={this.dateStartRef}
                    label='Data Final'
                    onSelect={(e) => {
                      this.setState({ tempDateFinal: e })
                      this.changeFinishDate(e)
                    }}
                    selected={this.state.tempDateFinal}
                  />
                </Grid.Column>

                <Grid.Column width={3}>
                  <Select
                    label='Tipo de tabulação'
                    options={[{ value: '', label: 'Nenhum...' }, ...tabulationOptions]}
                    onChange={(e) => {
                      this.setState({ typeTabulation: `&typeTabulation=${e.value}` }, () => {
                        this.getTabulations()
                      })
                    }}
                    placeholder='Selecione... '
                  />
                </Grid.Column>

                <Grid.Column width={3}>
                  <Select
                    label='Ordenar'
                    options={sortOptions}
                    onChange={(e) => {
                      this.setState({ sort: `&sort=${e.value}` }, () => {
                        this.getTabulations()
                      })
                    }}
                    placeholder='Selecione... '
                  />
                </Grid.Column>

                <Grid.Column width={2} style={{ marginLeft: 10, marginTop: 36 }}>
                  <Button filled onClick={this.clearFilters}>
                    Limpar
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Table celled striped padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Ramal</Table.HeaderCell>
                  <Table.HeaderCell>Tipo</Table.HeaderCell>
                  {level === 'admin' && (
                    <Table.HeaderCell>
                      Operador{' '}
                      <SButton
                        circular
                        onClick={this.toggleExpandName}
                        icon={expandName ? 'crop' : 'expand'}
                      />
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell>Fone</Table.HeaderCell>
                  <Table.HeaderCell>Data da tabulação
                    <IconButton
                      basic
                      dark
                      small
                      onClick={this.toggleExpandDate}
                      icon={expandDate ? 'crop' : 'expand'}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>Descrição</Table.HeaderCell>
                  <Table.HeaderCell>Nome do paciente</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {/* TODO: Pegar valores corretos */}
                {tabulations?.map((tabulation) => (
                  <Table.Row key={tabulation.id}>
                    <Table.Cell>{tabulation.ramal}</Table.Cell>
                    <Table.Cell>{tabulation.tabulationName}</Table.Cell>
                    {level === 'admin' && (
                      <Table.Cell>
                        {expandName
                          ? titleCase(tabulation.operatorName)
                          : getFirstAndLastWord(
                            titleCase(tabulation.operatorName)
                          )}
                      </Table.Cell>
                    )}
                    <Table.Cell>
                      <ClickToCall
                        label={formatPhone(tabulation.phone)}
                        to={tabulation.phone}
                      />
                    </Table.Cell>
                    <Table.Cell>{expandDate
                      ? toBrDisplay(tabulation.createdAt, true)
                      : dateToBrSmall(tabulation.createdAt)}</Table.Cell>
                    <Table.Cell>
                      {tabulation.annotation ? (
                        <Modal
                          trigger={<Link to='#'>Ver descrição</Link>}
                          header={tabulation.tabulationName}
                          content={tabulation.annotation}
                          actions={['Fechar']}
                        />
                      ) : (
                        <span className='unimportant-text'>Sem descrição</span>
                      )}
                    </Table.Cell>
                    <Table.Cell>{tabulation.patients ? titleCase(tabulation.patients.name) : <span className='unimportant-text'>Sem paciente</span> }</Table.Cell>
                    <Table.Cell>
                      <Dropdown text='Ações' button item>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            disabled={!tabulation.phone}
                            // FIXME: mudar id quando o backend for trocado
                            onClick={() => this.searchPhone(tabulation.phone)}>
                            Buscar Paciente
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <PaginationShort
              onPageChange={(e) => this.changePage(e)}
              totalPages={totalPages}
              activePage={activePage}
            />
          </>
        )}
      </Card>
    )
  }
}

ListTabulations.propTypes = {
  token: PropTypes.string,
  showAlert: PropTypes.func,
  showConfirmation: PropTypes.func
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  level: state.auth.user.level
})

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) =>
    dispatch(showAlert(type, title, message, details, func)),
  showConfirmation: (type, title, message, func) =>
    dispatch(showConfirmation(type, title, message, func)),
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal()),
  push: (value) => dispatch(push(value)),
  setPatient: (data) => dispatch(setPatient(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ListTabulations))
