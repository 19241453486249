import axios from 'axios'
import { servers } from 'utils/config'
import Swal from 'sweetalert2'

const ENV = process.env.REACT_APP_ENV
const baseUrl = servers[ENV] || servers.local

const api = axios.create({
  baseURL: baseUrl,
  timeout: 30000
})

const getToken = () => {
  const token = localStorage.getItem('@Agenda/accessToken')
  if (token && token !== null) return token
  return undefined
}

api.defaults.headers.post['Content-Type'] = 'application/json'

api.interceptors.request.use(config => {
  const token = getToken()
  if (
    (!config.url.endsWith('login') ||
    !config.url.endsWith('refresh') ||
    !config.url.endsWith('signup')) && token
  ) {
    config.headers.Authorization = token
  }
  return config
}, error => Promise.reject(error))

api.interceptors.request.use((config) => {
  var connection = navigator.onLine
  if (!connection) {
    const error = new Error('ERR_INTERNET_DISCONNECTED')
    error.name = 'ERR_INTERNET_DISCONNECTED'
    return Promise.reject(error)
  }
  return config
})
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      Swal.fire({
        icon: 'info',
        title: 'Sua sessão expirou!',
        text: 'Faça o login novamente.',
        onBeforeOpen: () => {
          document.location.href = '/'
        },
        onConfirm: () => {
          localStorage.clear()
        }
      })
    }
    return Promise.reject(error)
  }
)

export default api
