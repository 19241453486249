export const setPatient = (data) => ({
  type: 'SET_PATIENT',
  payload: { ...data }
})

export const clearPatient = () => ({
  type: 'CLEAR_PATIENT'
})

export const setPatientId = (id) => ({
  type: 'SET_PACIENT_ID',
  payload: { id }
})
export const setPatientmvId = (mvId) => ({
  type: 'SET_PACIENT_MVID',
  payload: { mvId }
})

export const setPatientCpf = (cpf) => ({
  type: 'SET_PATIENT_CPF',
  payload: { cpf }
})

export const setPatientName = (name) => ({
  type: 'SET_PATIENT_NAME',
  payload: { name }
})

export const setPatientBirthdate = (birthdate) => ({
  type: 'SET_PATIENT_BIRTHDATE',
  payload: { birthdate }
})

export const setPatientGender = (gender) => ({
  type: 'SET_PATIENT_GENDER',
  payload: { gender }
})

export const setPatientEmail = (email) => ({
  type: 'SET_PATIENT_EMAIL',
  payload: { email }
})

export const setPatientCellphone = (cellphone) => ({
  type: 'SET_PATIENT_CELLPHONE',
  payload: { cellphone }
})

export const setPatientSecondCellphone = (secondCellphone) => ({
  type: 'SET_PATIENT_SECOND_CELLPHONE',
  payload: { secondCellphone }
})

export const setPatientWhatsapp = (whatsapp) => ({
  type: 'SET_PATIENT_WHATSAPP',
  payload: { whatsapp }
})

export const setPatientTelephone = (telephone) => ({
  type: 'SET_PATIENT_TELEPHONE',
  payload: { telephone }
})

export const setPatientContactMethod = (contactMethod) => ({
  type: 'SET_PATIENT_CONTACTMETHOD',
  payload: { contactMethod }
})

export const setPatientRegister = (register) => ({
  type: 'SET_PATIENT_REGISTER',
  payload: { register }
})

export const setPatientMvId = (mvId) => ({
  type: 'SET_PACIENT_MVID',
  payload: { mvId }
})
