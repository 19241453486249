const consultOptions = [
  { value: '', label: 'Todas' },
  { value: 'new', label: 'Novo' },
  { value: 'emergency', label: 'Emergência' },
  { value: 'return', label: 'Retorno' },
  { value: 'routine', label: 'Rotina' }
]

const timeEntryOptions = [
  { value: '-timeEntry', label: 'Mais recente' },
  { value: 'timeEntry', label: 'Mais antigo' }
]

const situationOptions = [
  { value: '', label: 'Todas' },
  { value: 'pending', label: 'Aguardando' },
  { value: 'canceled', label: 'Cancelado' },
  { value: 'confirmed', label: 'Confirmado' }
]

const notificationOptions = [
  { value: ['master', 'admin', 'operator'], label: 'Todos' },
  { value: ['master'], label: 'Master' },
  { value: ['admin'], label: 'Administradores' },
  { value: ['operator'], label: 'Operadores' },
  { value: ['admin', 'operator'], label: 'Administradores/Operadores' },
  { value: ['admin', 'master'], label: 'Master/Administradores' }
]

const turnOptions = [
  { value: '', label: 'Selecione' },
  { value: 'morning', label: 'Manha' },
  { value: 'afternoon', label: 'Tarde' },
  { value: 'night', label: 'Noite' },
  { value: 'full-time', label: 'Integral' }
]

const hoursOptions = [
  { value: '00:00', label: '00:00' },
  { value: '01:00', label: '01:00' },
  { value: '02:00', label: '02:00' },
  { value: '03:00', label: '03:00' },
  { value: '04:00', label: '04:00' },
  { value: '05:00', label: '05:00' },
  { value: '06:00', label: '06:00' },
  { value: '07:00', label: '07:00' },
  { value: '08:00', label: '08:00' },
  { value: '09:00', label: '09:00' },
  { value: '10:00', label: '10:00' },
  { value: '11:00', label: '11:00' },
  { value: '12:00', label: '12:00' },
  { value: '13:00', label: '13:00' },
  { value: '14:00', label: '14:00' },
  { value: '15:00', label: '15:00' },
  { value: '16:00', label: '16:00' },
  { value: '17:00', label: '17:00' },
  { value: '18:00', label: '18:00' },
  { value: '19:00', label: '19:00' },
  { value: '20:00', label: '20:00' },
  { value: '21:00', label: '21:00' },
  { value: '22:00', label: '22:00' },
  { value: '23:00', label: '23:00' },
  { value: '23:59', label: '23:59' }
]

const sortOptions = [
  { value: '-createdAt', label: 'Criação mais recente' },
  { value: 'createdAt', label: 'Criação mais antiga' }
]

const consultOptionReport = [
  { ext: 'Nova', value: 'new' },
  { key: 2, text: 'Retorno', value: 'return' },
  { key: 3, text: 'Rotina', value: 'routine' }
]

const situationOptionReport = [
  { key: 1, text: 'Confirmado', value: 'confirmed' },
  { key: 2, text: 'Cancelado', value: 'canceled' },
  { key: 3, text: 'Aguardando', value: 'waiting' }
]

const periodOptions = [
  { value: 'day', label: 'Dia' },
  { value: 'week', label: 'Semana' },
  { value: 'month', label: 'Mês' }
]

const reportOptions = [
  { value: '', label: 'Escolher relatório' },
  { value: 'calls', label: 'Chamadas' },
  { value: 'pauses', label: 'Pausas' },
  { value: 'tabulation', label: 'Tabulação'},
  { value: 'schedule', label: 'Agendamentos' }
]

export { hoursOptions, situationOptions, consultOptions, sortOptions, periodOptions, reportOptions, consultOptionReport, situationOptionReport, notificationOptions, turnOptions, timeEntryOptions }
