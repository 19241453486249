import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Bounce } from 'react-activity'
import { connect } from 'react-redux'
import { passwordRequest, resetState } from 'store/modules/auth/actions'
import logo from '../../assets/logo.png'
import IconInput from '../../components/IconInput'
import { Button, PasswordLink, Logo } from './styled'
import { Link } from 'react-router-dom'
import user from 'consumers/user'
import { showAlert } from 'store/modules/alert/actions'

function ForgotPass ({ passwordRequest, resetState, loading, emailValidator, showAlert }) {
  const [email, setEmail] = useState('')
  useEffect(() => {
    resetState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const submit = async (email) => {
    try {
      const response = await user.forgotPassword({email})
      showAlert(response.code, 'Sucesso!', response.message)
    } catch (err) {
      if (!err.response) {
        return showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
      }
      showAlert(400, 'Atenção', err.message, err.technical)
    }
  }

  return (
    <>
      <Logo src={logo} width='160px' alt='UX Agenda Logo' />
      <IconInput
        onChange={(e) => setEmail(e.target.value)}
        placeholder='Email'
        type='email'
        unit
        icon='person-circle'
        error={emailValidator.message}
      />
      <PasswordLink>
        <Link to='/'>Voltar ao login</Link>
      </PasswordLink>
      <Button onClick={() => submit(email)}>
        {loading ? (
          <Bounce color='#FCFCFC' size={22} speed={1} animating={true} />
        ) : (
          'Enviar'
        )}
      </Button>
    </>
  )
}

ForgotPass.propTypes = {
  passwordRequest: PropTypes.func,
  resetState: PropTypes.func,
  loading: PropTypes.bool,
  emailValidator: PropTypes.exact({
    isValid: PropTypes.bool,
    message: PropTypes.string
  })
}

const mapStateToProps = (state) => ({
  emailValidator: state.auth.emailValidator,
  loading: state.auth.loading
})

const mapDispatchToProps = (dispatch) => ({
  passwordRequest: (email, password) => dispatch(passwordRequest(email, password)),
  resetState: () => dispatch(resetState()),
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPass)
