import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Routes from './routes'
import store, { history } from './store'
import { ConnectedRouter } from 'connected-react-router'
import GlobalStyles from './styles/global'
import 'react-activity/dist/react-activity.css'
import './assets/sass/main.scss'

import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <GlobalStyles />
      <Routes />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
