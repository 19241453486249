import React, { useState, useEffect } from 'react'
import { Modal, Table, Grid } from 'semantic-ui-react'
import ScheduleCalendar from './../ScheduleCalendar'
import RichText from 'components/Richtext'
import RichTextEditor from 'react-rte'
import Select from 'components/Select'
import Button from 'components/Button'
import SelectedSchedulesTable from 'components/SelectedSchedulesTable'
import ModalScheduleTable from 'components/ModalScheduleTable'
import schedulesConsumer from 'consumers/schedule'
import { push } from 'connected-react-router'
import { showAlert, setSwalLoading, closeAllSwal, showLoading } from 'store/modules/alert/actions'
import { connect } from 'react-redux'
import { FormatDateBr } from 'utils/formatDate'
import moment from 'moment'
import { validateData } from 'utils/validators'
import insurances from 'consumers/insurances'
import serviceTypes from 'consumers/serviceTypes'
import specialtiesConsumer from 'consumers/specialties'
import examsConsumer from 'consumers/exams'
import providersConsumer from 'consumers/providers'
import to from 'await-to-js'
import swal from 'sweetalert'
import Checkbox from 'components/Checkbox'
import Toggle from 'react-toggle'

// Core viewer
function TransferModal ({
  open,
  onClose,
  scheduleToTransferDescription,
  schedules,
  showAlert,
  setSwalLoading,
  closeAllSwal,
  patient,
  patientId,
  operatorId,
  mvId,
  scheduleToTransfer,
  closeTransferModal,
  updateSchedulesHistory

}) {
  const [description, setDescription] = useState(scheduleToTransferDescription ? RichTextEditor.createValueFromString(scheduleToTransferDescription, 'html') : RichTextEditor.createEmptyValue())
  const [openSelectOneSchedule, setOpenSelectOneSchedule] = useState(false)
  const [hourSchedules, setHourSchedules] = useState([])
  const [totalModalPages, setTotalModalPages] = useState(0)
  const [modalPage, setModalPage] = useState(0)
  const [selectedDate, setSelectedDate] = useState(0)
  const [selectedSchedules, setSelectedSchedules] = useState([])
  const [exam, setExam] = useState({ label: scheduleToTransfer.examName, code: scheduleToTransfer.examCode, value: scheduleToTransfer.examCode })
  const [specialty, setSpecialty] = useState({ label: scheduleToTransfer.specialtyName, code: scheduleToTransfer.specialtyCode, value: scheduleToTransfer.specialtyCode })
  const [date, setDate] = useState('')
  const [healthInsurance, setHealthInsurance] = useState({ label: scheduleToTransfer.insuranceName, code: scheduleToTransfer.insuranceCode, value: scheduleToTransfer.insuranceCode })
  const [healthInsurancePlan, setHealthInsurancePlan] = useState('')
  const [healthInsurances, setHealthInsurances] = useState('')
  const [healthInsurancePlans, setHealthInsurancePlans] = useState('')
  const [type, setType] = useState({ label: scheduleToTransfer.consultType, code: scheduleToTransfer.consultCode, value: scheduleToTransfer.consultCode })
  const [types, setTypes] = useState('')
  const [provider, setProvider] = useState({ label: scheduleToTransfer.providerName, code: scheduleToTransfer.providerCode, value: scheduleToTransfer.providerCode })
  const [providers, setProviders] = useState([])
  const [filteredProviders, setFilteredProviders] = useState([])
  const [exams, setExams] = useState([])
  const [specialties, setSpecialties] = useState([])
  const [wasInsuranceSet, setWasInsuranceSet] = useState(false)
  const [mvUser, setMvUser] = useState('')
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false)
  const [availableSchedules, setAvailableSchedules] = useState([])
  const [scheduleDescription, setScheduleDescription] = useState('')
  const [scheduleItemCode, setScheduleItemCode] = useState('')
  const [mainScheduleCode, setMainScheduleCode] = useState('')
  const [wasIndicated, setWasIndicated] = useState(false)
  const [toggleIndicationInput, setToggleIndicationInput] = useState(false)
  const [providerWhoIndicated, setProviderWhoIndicated] = useState(scheduleToTransfer.providerWhoIndicated ? providerWhoIndicated : '')
  const [allProviders, setAllProviders] = useState([])

  useEffect(() => {
    getHealthInsurances()
    getTypes()
    getExams()
    getSpecialties()
    getSchedules()
    getProviders()
    getMVUser()
  }, [])
  useEffect(() => {
    getProviders()
  }, [specialty])

  const daySchedulesQuery = async ({ date, page }) => {
    return {
      date,
      page,
      // provider,
      specialty: specialty.value
    }
  }

  const getMVUser = () => {
    const storageMVuser = localStorage.getItem('@Agenda/mvUser')

    if (mvUser !== 'undefined' && mvUser !== 'null' && mvUser !== null) {
      setMvUser(storageMVuser)
    }
  }

  const getSoulSchedulesDay = async (date, page) => {
    setSelectedDate(date)
    if (exam === null || exam === '') {
      return showAlert(422, 'Atenção', 'Selecione o item de agendamento.')
    }

    const fullQuery = await daySchedulesQuery({ date, page, specialty })
    setSwalLoading()

    console.log('FULL QUERY', fullQuery)
    try {
      const response = await schedulesConsumer.schedulesByDay(fullQuery)
      const hourSchedules = response.data
      setOpenSelectOneSchedule(true)
      setHourSchedules(hourSchedules.schedules)
      setTotalModalPages(hourSchedules.totalPages)
      setModalPage(hourSchedules.page)
      closeAllSwal()
    } catch (err) {
      closeAllSwal()
      if (err.code >= 400 && err.code <= 499) {
        showAlert(err.code, 'Atenção', err.message)
      }
    }
  }
  const selectSchedule = async (schedule) => {
    console.log('AGENDA A SER TRANSFERIDA', scheduleToTransfer)

    setDate(schedule.scheduleDate)
    setOpenSelectOneSchedule(false)
    setSelectedSchedules([{
      patientCode: Number(mvId),
      operatorId: operatorId,
      cellphone: patient.cellphone,
      email: patient.email,
      patientMongoId: patientId,
      mvUser: mvUser,
      scheduleItemCode: schedule.scheduleItemCode,
      mainScheduleCode: schedule.mainScheduleCode,
      scheduleDate: schedule.scheduleDate,
      providerName: schedule.providerName,
      providerType: schedule.providerType,
      attendanceUnit: schedule.attendanceUnit,
      specialtyCode: schedule.specialtyCode,
      insuranceCode: healthInsurance.code,
      providerCode: schedule.providerCode,
      insurancePlanCode: healthInsurancePlan,
      description: '',
      providerWhoIndicated: providerWhoIndicated
    }])
    setMainScheduleCode(schedule.mainScheduleCode)
    setScheduleItemCode(schedule.scheduleItemCode)
  }

  const getProviders = async () => {
    const providers = await providersConsumer.get({ specialtyCode: specialty.code })
    console.log('providers', providers)
    setProviders(providers)
  }
  const getAllProviders = async () => {
    setSwalLoading()
    const response = await providersConsumer.get()
    closeAllSwal()
    if (allProviders.length === 0) setAllProviders(response)
  }

  const getSpecialties = async (token) => {
    const specialties = await specialtiesConsumer.get()
    setSpecialties(specialties)
  }

  const getHealthInsurances = async (token) => {
    const healthInsurances = await insurances.getHealthInsurances()
    setHealthInsurances(healthInsurances)
  }

  const getHealthInsurancePlans = async (code) => {
    const healthInsurancePlans = await insurances.getInsurancePlans(code)
    console.log()
    setHealthInsurancePlans(healthInsurancePlans)
  }

  const handleInsuranceSelection = async (e) => {
    if (selectedSchedules.length) {
      const updatedSelectedSchedules = selectedSchedules.map((schedule) => ({ insuranceCode: e.value }))
      setSelectedSchedules(updatedSelectedSchedules)
    }
    setSwalLoading()
    setHealthInsurance(e)
    await getHealthInsurancePlans(e.value)
    setWasInsuranceSet(true)
    closeAllSwal()
  }

  const getExams = async (token) => {
    const exams = await examsConsumer.get(token)
    setExams(exams)
  }

  const getTypes = async () => {
    const response = await serviceTypes.getServiceTypes()
    setTypes(response)
  }
  const removeSchedule = async (selectedScheduleCode) => {
    const filteredSchedules = selectedSchedules.filter((schedule) => schedule.scheduleItemCode !== selectedScheduleCode)
    setSelectedSchedules(filteredSchedules)
  }

  const openScheduleDescriptionModal = async (schedule) => {
    setOpenDescriptionModal(true)
  }

  const getSchedules = async (date) => {
    const formattedDate = moment(date).format('DD-MM-YYYY')
    setSwalLoading()
    const [availableSchedulesResponseError, availableSchedulesResponse] = await to(schedulesConsumer.listOracle({
      month: formattedDate,
      specialty: specialty.code
    }))
    if (availableSchedulesResponseError) {
      showAlert('error', 'Houve um erro')
    }
    closeAllSwal()
    if (!availableSchedules) {
      showAlert('errro', 'Nenhuma agenda encontrada')
    }
    setAvailableSchedules(availableSchedulesResponse)
    closeAllSwal()
  }

  const getDescription = (description) => {
    setScheduleDescription(description)
    return description
  }
  const transferSchedule = async () => {
    swal({
      title: 'Realizar transferência ?',
      icon: 'warning',
      buttons: {
        cancel: 'Não',
        yes: {
          text: 'Sim',
          value: 'Sim'
        }
      },
      dangerMode: false
    })
      .then(async () => {
        setSwalLoading()
        const [responseError, response] = await to(schedulesConsumer.clear(
          {
            patientCode: scheduleToTransfer.patientCode,
            scheduleItemCode: scheduleToTransfer.scheduleCode,
            mainScheduleCode: scheduleToTransfer.mainScheduleCode
          }))
        if (responseError) return showAlert('error', 'Erro ao realizar transferência')

        const [insertionError, insertionResult] = await to(schedulesConsumer.insertPatient(
          [{
            patientCode: scheduleToTransfer.patientCode,
            operatorId: scheduleToTransfer.operator,
            cellphone: scheduleToTransfer.cellphone,
            email: scheduleToTransfer.email,
            patientMongoId: patientId,
            mvUser: mvUser,
            scheduleItemCode: scheduleItemCode,
            mainScheduleCode: mainScheduleCode,
            providerName: provider.label,
            specialtyCode: specialty.code,
            insuranceCode: healthInsurance.code,
            providerCode: provider.code,
            insurancePlanCode: healthInsurancePlan.code,
            description: scheduleDescription,
            attendanceCode: type.code,
            schedulingItemCode: exam.code,
            providerWhoIndicated: providerWhoIndicated
          }]
        ))

        if (insertionError) return showAlert('error', 'Erro ao realizar transferência')
        showAlert('success', 'Transferência realizada com sucesso', '', '', async () => {
          await updateSchedulesHistory()
          await closeTransferModal()
        })
      })
  }
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        size='large'
        closeIcon
        style={{ padding: 15, paddingBottom: 45 }}>
        <Modal.Header><h1 style={{ textAlign: 'center' }}>Transferir Agenda</h1></Modal.Header>

        <Modal.Content style={{ padding: 0 }}>
          <ModalScheduleTable
            open={openSelectOneSchedule}
            onClose={() => setOpenSelectOneSchedule(false)}
            hourSchedules={hourSchedules}
            getSoulSchedules={getSoulSchedulesDay}
            selectSchedule={selectSchedule}
            changePage={getSoulSchedulesDay}
            totalPages={totalModalPages}
            activePage={modalPage}
            selectedDate={selectedDate}
          />
          <Grid>
            <Grid.Row>
              <Grid.Column width={1} />

              <Grid.Column width={4}>

                <Select
                  label='Exame'
                  options={exams}
                  onChange={(value) => setExam(value)}
                  value={exam}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Select
                  label='Especialidade do prestador'
                  placeholder='Selecione... '
                  options={specialties}
                  onChange={(value) => {
                    setSpecialty(value)
                    setProvider('')
                  }}
                  value={specialty}
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <Select
                  label='Prestador'
                  placeholder='Selecione... '
                  options={['', ...providers]}
                  onChange={(value) => setProvider(value)}
                  value={provider}

                />
              </Grid.Column>
              <Grid.Column width={1} />

            </Grid.Row>
            {/* sgunda */}
            <Grid.Row>
              <Grid.Column width={1} />

              <Grid.Column width={4}>
                <Select
                  label='Tipo'
                  placeholder='Selecione... '
                  options={types}
                  onChange={(value) => setType(value)}
                  value={type}

                />
              </Grid.Column>

              <Grid.Column width={4}>
                <Select
                  label='Convênio'
                  placeholder='Selecione... '
                  options={healthInsurances}
                  onChange={(e) => {
                    handleInsuranceSelection(e)
                  }}
                  value={healthInsurance}
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <Select
                  label='Plano'
                  placeholder='Selecione ... '
                  options={healthInsurancePlans}
                  disabled={!wasInsuranceSet}
                  onChange={(value) => setHealthInsurancePlan(value)}

                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{ display: 'flex', alignItems: 'center' }}>
              <Grid.Column width={1} />
              <Grid.Column width={4}>

                <div className='align-toggle' style={{ marginBottom: toggleIndicationInput ? 30 : '' }}>
                  <Toggle
                    id='age-check'
                    defaultChecked={false}
                    onChange={async () => {
                      if (!wasIndicated) {
                        await getAllProviders()
                      }
                      setWasIndicated(true)
                      setToggleIndicationInput(!toggleIndicationInput)
                    }}
                  />
                  <label htmlFor="indication-check">Indicação</label>
                </div>
              </Grid.Column>

              { toggleIndicationInput ? <Grid.Column width={4}>
                <Select
                  label='Prestador Que Indicou'
                  options={allProviders}
                  onChange={(e) => {
                    setProviderWhoIndicated(e.value)
                  }}
                  placeholder='Selecione... '
                />
              </Grid.Column> : ''}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={13} textAlign='right'>
                <div className='uk-text-right uk-margin'>
                  <Button filled onClick={() => {
                    getSchedules(moment().toDate())
                  }}>
                    Filtrar
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={1} />
              <Grid.Column width={14}>
                <SelectedSchedulesTable single ={true}
                  removeSchedule={removeSchedule}
                  selectedSchedules={selectedSchedules}
                  openScheduleDescriptionModal={openScheduleDescriptionModal}
                  getDescription={getDescription}
                  initialDescription={scheduleToTransfer.description}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid.Row>
            {availableSchedules.length ? <ScheduleCalendar schedules={availableSchedules} size={250} onDateSelect={getSoulSchedulesDay} /> : ''}
          </Grid.Row>
          {selectedSchedules.length ? <Grid.Row style={{ marginTop: 20 }}>
            <Grid.Column width={14} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button filled onClick={() => { transferSchedule() }} >Transferir</Button>
            </Grid.Column>
          </Grid.Row> : ''}
        </Modal.Content>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  patientId: state.patient.id,
  mvId: state.patient.mvId,
  token: state.auth.token,
  router: state.router,
  patient: state.patient
})

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path)),
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal())
})

export default connect(mapStateToProps, mapDispatchToProps)(TransferModal)
