export const capitalize = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}

export const shortIfTooLong = (string) => {
  if (string.length <= 22) return string
  const splittedString = string.split(' ')
  if (splittedString.length < 2) return string
  splittedString[1] = splittedString[1].charAt(0).toUpperCase() + '.'
  const result = splittedString.join(' ')
  return result
}

export const getFirstAndLastWord = (value) => {
  if (!value) return ''
  const splittedString = value.split(' ')
  if (splittedString.length <= 1) return value.trim()
  return `${splittedString[0]} ${splittedString[splittedString.length - 1]}`
}

export const titleCase = text => {
  if (!text) return ''

  let words = text.toLowerCase().trim().split(' ')
  if (words[0].length <= 0) return words.join(' ')
  words = words.map(word => {
    if (!word || word[0].length <= 0) return ''
    return word[0].toUpperCase() + word.slice(1)
  }
  )
  return words.join(' ')
}

export const make24Hours = () => {
  let hours = []

  for (let i = 0; i < 24; i++) {
    let hour = ''

    if (i < 10) hour = `0${i}:00` 
    else hour = `${i}:00`

    hours.push(hour)
  }

  return hours
}
