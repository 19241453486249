const _initialState = {
  id: '',
  mvId: '',
  cpf: '',
  name: '',
  birthdate: '',
  gender: '',
  email: '',
  cellphone: '',
  secondCellphone: '',
  whatsapp: false,
  telephone: '',
  contactMethod: '',
  register: ''
}

const patient = (state = _initialState, action) => {
  switch (action.type) {
    case 'SET_PATIENT':
      return {
        ...state,
        ...action.payload
      }

    case 'CLEAR_PATIENT':
      return {
        ...state,
        ..._initialState
      }

    case 'SET_PACIENT_ID':
      return {
        ...state,
        id: action.payload.id
      }

    case 'SET_PATIENT_CPF':
      return {
        ...state,
        cpf: action.payload.cpf
      }

    case 'SET_PATIENT_NAME':
      return {
        ...state,
        name: action.payload.name
      }
    case 'SET_PACIENT_MVID':
      return {
        ...state,
        mvId: action.payload.mvId
      }

    case 'SET_PATIENT_BIRTHDATE':
      return {
        ...state,
        birthdate: action.payload.birthdate
      }

    case 'SET_PATIENT_GENDER':
      return {
        ...state,
        gender: action.payload.gender
      }

    case 'SET_PATIENT_EMAIL':
      return {
        ...state,
        email: action.payload.email
      }

    case 'SET_PATIENT_CELLPHONE':
      return {
        ...state,
        cellphone: action.payload.cellphone
      }

    case 'SET_PATIENT_SECOND_CELLPHONE':
      return {
        ...state,
        secondCellphone: action.payload.secondCellphone
      }

    case 'SET_PATIENT_WHATSAPP':
      return {
        ...state,
        whatsapp: action.payload.whatsapp
      }

    case 'SET_PATIENT_TELEPHONE':
      return {
        ...state,
        telephone: action.payload.telephone
      }

    case 'SET_PATIENT_CONTACTMETHOD':
      return {
        ...state,
        contactMethod: action.payload.contactMethod
      }

    case 'SET_PATIENT_REGISTER':
      return {
        ...state,
        register: action.payload.register
      }

    default:
      return state
  }
}

export default patient
