import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Input, ErrorMsg, Label } from './styled'

export default function IconInput ({ icon, error, unit, signup, label, ...rest }) {
  const hasError = !!(error && error.trim())

  return (
    <>
      <Label>{label}</Label>
      <Wrapper unit={unit} signup={signup}>
        <ion-icon name={icon} style={iconStyle} />
        <Input hasError={hasError} {...rest} hasIcon={!!(icon)} />
        {hasError && <ErrorMsg>{error}</ErrorMsg>}
      </Wrapper>
    </>
  )
}

const iconStyle = {
  position: 'absolute',
  height: '48px',
  paddingLeft: '10px',
  fontSize: '22px',
  color: 'rgba(255,255,255,0.95)'
}

IconInput.propTypes = {
  icon: PropTypes.string,
  error: PropTypes.string,
  unit: PropTypes.bool
}
