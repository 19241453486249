import api from 'services/api'

const telephony = {

  calls: async (token) => {
    try {
      const response = await api.post(
        '/calls',
        {},
        { headers: { Authorization: token } }
      )
      return response.data.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },

  client: async (token, phone) => {
    console.log(phone)
    try {
      const response = await api.get(
        `/clients/search/${phone}`,
        { headers: { Authorization: token } }
      )
      return response.data.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },

  makeCall: async (token, phone, missed) => {
    try {
      const response = await api.post(
        '/telephone/clicktocall',
        (missed) ? { phone, missed } : { phone },
        { headers: { Authorization: token } }
      )
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },
  tabulations: async (token, data) => {
    try {
      const response = await api.get(
        `/tabulations?page=${data.page}${data.ramal}${data.dateHourStart}${data.dateHourFinish}${data.typeTabulation}${data.sort}`,
        { headers: { Authorization: token } }
      )
      return response.data.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },
  recordings: async (token, data) => {
    try {
      const response = await api.get(
        `/calls?page=${data.page}${data.ramal}${data.phone}${data.dateHourStart}${data.dateHourEnd}`,
        { headers: { Authorization: token } }
      )
      return response.data.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },
  tabulate: async (token, text, tabulationId, callId, patientId) => {
    try {
      const response = await api.post(
        '/tabulations',
        {
          annotation: text,
          tabulationNameId: tabulationId,
          activeCallId: callId,
          patientId
        },
        { headers: { Authorization: token } }
      )
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },
  forceEnd: async (token) => {
    try {
      const response = await api.post('calls/forceend', {}, {
        headers: { Authorization: token }
      })
      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  },
  queued: async (token) => {
    try {
      const response = await api.get('telephone/queued', {
        headers: { Authorization: token }
      })
      return response.data.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  },
  ramals: async (token) => {
    try {
      const response = await api.get('telephone/ramals', {
        headers: { Authorization: token }
      })
      return response.data.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  },
  registerRamal: async (token) => {
    try {
      const response = await api.post(
        '/telephone/register',
        {},
        { headers: { Authorization: token } }
      )
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  }
}

export default telephony
