import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 70px 1fr 50px;
  width: 100%;
  height: 55vh;
  border: 1px solid rgba(0, 0, 0, 0.1);
`

export const TitleContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  grid-row: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
`

export const Title = styled.h1`
  margin: 0px 0px 0px 10px;
  font-size: 1.6em;
  color: #515151;
  font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
`

export const NavContainer = styled.div`
  width: 100%;
  height: 55vh;
  padding: 3px;
  grid-row: 2;
  background-color: #fff;
  overflow-y: scroll;
`

export const BodyContainer = styled.div`
  grid-row: 2;
  width: 100%;
  padding: 20px;
  background-color: #fbfbfb;
  overflow-y: auto;
  word-break: break-all;
  

  p {
    color: #313131;
    font-size: 1.2em;
    word-break: break-all;
    font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
  }
`

export const FooterContainer = styled.footer`
  grid-row: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;

  h1 {
    margin: 0px;
  }
`

export const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 0, 0, 0.7);
  font-size: 16px;
  margin-right: 10px;
  font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
  padding: 5px 10px;
  cursor: pointer;
  border: solid 2px rgba(255, 0, 0, 0.7);
  border-radius: 2px;
  font-weight: 400;
  transition: all 150ms linear;

  &:hover {
    background-color: rgba(255, 0, 0, 0.7);
    color: #fff
  }
`
