import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useEventListener from '@use-it/event-listener'
import { ExecuteIfSwalIsClosed } from 'utils/execute'

import { Bounce } from 'react-activity'
import { connect } from 'react-redux'
import { passwordRequest  } from 'store/modules/auth/actions'
import { Link } from 'react-router-dom'
import { showAlert } from 'store/modules/alert/actions'

import { Button, PasswordLink, Logo } from './styled'
import logo from '../../assets/logo.png'
import IconInput from '../../components/IconInput'
import user from 'consumers/user'

function ResetPass ({ passwordRequest, passwordValidator, showAlert, location }) {
  const [password, setPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const [loading, setLoading] = useState(false)

  const submit = async (password, confirmation) => {
    const passwordResetToken = location.search
    const data = { password, confirmation }
    
    setLoading(true) 
    try {
      const response = await user.resetPassword(passwordResetToken, data)
      showAlert(response.code, 'Sucesso!', response.message)
    } catch (err) {
      if (!err.response) {
        return showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
      }
      showAlert(400, 'Atenção', err.message, err.technical)
    }
    
    setLoading(false)
    setPassword('')
    setConfirmation('')
  }

  useEventListener('keypress', (e) => {
    ExecuteIfSwalIsClosed(e.keyCode, () => {
      submit()
    })
  })

  return (
    <>
    <form autoComplete='true' style={{ width: '100%', display: 'contents' }}></form>
      <Logo src={logo} width='160px' alt='UX Agenda Logo' />
      <IconInput
        placeholder='Senha'
        onChange={(e) => setPassword(e.target.value)}
        type='password'
        unit
        icon='lock-closed'
        error={passwordValidator.message}
      />
      <IconInput
        placeholder='Confirmar a senha'
        onChange={(e) => setConfirmation(e.target.value)}
        type='password'
        icon='lock-closed'
        error={passwordValidator.message}
      />
      <PasswordLink>
        <Link to='/'>Ir para o login</Link>
      </PasswordLink>
      <Button onClick={() => submit(password, confirmation)}>
        {loading ? (
          <Bounce color='#FCFCFC' size={22} speed={1} animating={true} />
        ) : (
          'Trocar senha'
        )}
      </Button>
    </>
  )
}

ResetPass.propTypes = {
  passwordRequest: PropTypes.func,
  loading: PropTypes.bool,
  passwordValidator: PropTypes.exact({
    isValid: PropTypes.bool,
    message: PropTypes.string
  })
}

const mapStateToProps = (state) => ({
  passwordValidator: state.auth.passwordValidator,
  loading: state.auth.loading
})

const mapDispatchToProps = (dispatch) => ({
  passwordRequest: (email, password) => dispatch(passwordRequest(email, password)),
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPass)
