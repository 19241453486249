import React from 'react'
import RichTextEditor from 'react-rte'

import { Label } from './styled'
import './styles.css'

export default function MyStatefulEditor ({ ref, label, ...rest }) {
  return (
    <>
      <Label>{label}</Label>
      <RichTextEditor
        className='min-height'
        ref={ref}
        {...rest}
      />
    </>
  )
}
