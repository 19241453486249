import api from 'services/api'
import mvApi from 'services/mvApi'

// TODO: Create default error message
const patient = {

  create: async (token, data) => {
    try {
      const response = await api.post(
        '/patients',
        data,
        { headers: { Authorization: token } }
      )
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },

  getByCpf: async (token, cpf) => {
    try {
      const response = await api.get(
          `/patients/search/cpf/${cpf}`,
          { headers: { Authorization: token } }
      )
      return response
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },
  getByCpfOracle: async (cpf) => {
    try {
      const response = await mvApi.get(`/v0/soul/patients/search/cpf/${cpf}`)
      console.log('patient Response', response.data.data.patients.data.patients[0])
      return response.data.data.patients.data.patients[0]
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },

  getById: async (token, id, query) => {
    const newQuery = query || ''
    try {
      const response = await api.get(
        `/patients/${id}${newQuery}`,
        { headers: { Authorization: token } }
      )
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },

  update: async (token, id, data) => {
    try {
      const response = await api.patch(
        `/patients/${id}`,
        data,
        { headers: { Authorization: token } }
      )
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }

      throw err.response.data
    }
  },

  search: async (token, data, query) => {
    try {
      const response = await api.post(
        `/patients/search?page=${query.page}`,
        data,
        { headers: { Authorization: token } }
      )
      return response.data.data.patients
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possivel se comunicar com o servidor!'
        throw error
      }
      throw e.response.data
    }
  }
}

export default patient
