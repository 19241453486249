import moment from 'moment'
import { translatePhone } from './translate'

export const validateData = (data) => {
  const keys = Object.keys(data)
  const invalid = {}
  keys.forEach((key) => {
    switch (key) {
      case 'date':
        if (!validateDateDays(data[key])) {
          invalid[key] = 'Data de agendamento inválida!'
        }
        break

      case 'hour':
        if (!validateHour(data[key])) {
          invalid[key] = 'Hora inválida!'
        }
        break

      case 'healthInsurance':
        if (!validateSelect(data[key])) {
          invalid[key] = 'Selecione o plano de saude!'
        }
        break

      case 'specialty':
        if (!validateSelect(data[key])) {
          invalid[key] = 'Selecione uma especialidade!'
        }
        break

      case 'unit':
        if (!validateSelect(data[key])) {
          invalid[key] = 'Selecione uma especialidade!'
        }
        break

      case 'type':
        if (!validateSelect(data[key])) {
          invalid[key] = 'Selecione um tipo de agendamento!'
        }
        break

      case 'contactMethod':
        if (!data[key]) break
        if (!validateSelect(data[key])) {
          invalid[key] = 'Selecione um método de contato!'
        }
        break

      case 'cpf':
        if (!validateCpf(data[key])) {
          if(!isTeenBirthdate(data['birthdate'])) {
            invalid[key] = 'CPF incompleto!'
          }
        }
        break

      case 'name':
        if (!validateName(data[key])) {
          invalid[key] = 'Digite um nome!'
        }
        break

      case 'gender':
        if (!validateSelect(data[key])) {
          invalid[key] = 'Escolha um gênero'
        }
        break

      case 'birthdate':
        if (!validateBirthdate(data[key])) {
          invalid[key] = 'Data inválida'
        }
        break

      case 'email':
        if (!data[key]) break
        if(!notRequiredEmail(data[key])) {
          invalid[key] = 'E-mail inválido'
        }
        break
      case 'confirmEmail':
        if (!validateEmail(data[key])) {
          invalid[key] = 'E-mail inválido'
        }
        break

        case 'cellphone':
        case 'secondCellphone':
        case 'telephone':
        if (!data['cellphone'] && !data['secondCellphone'] && !data['telephone']) {
          if (!validatePhone(data[key])) {
            invalid[key] = `${translatePhone(key)} inválido`
          }
        }
        else if(data[key]) {
          if (!validatePhone(data[key])) {
            invalid[key] = `${translatePhone(key)} inválido`
          }
        }
        break

      case 'password':
        if (!validatePassword(data[key])) {
          invalid[key] = 'Digite uma senha'
        }
        break

      case 'confirmPassword':
        if (!validatePassword(data[key])) {
          invalid[key] = 'Digite a confirmação de senha'
        }
        break

      default:
        if (isString(data[key]) && !data[key].trim()) {
          invalid[key] = 'Campo inválido!'
        }
        break
    }
  })

  return invalid
}

const validateDateDays = (value) => {
  return Boolean(moment().diff(moment(value, 'DD/MM/YYYY'), 'days') <= 0)
}

const validateHour = value => {
  const regexHour = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/
  const regexValidate = !!regexHour.test(value)
  return regexValidate
}

const validateSelect = value => {
  const validateSelect = !!((value !== null && value !== ''))
  return validateSelect
}

const isTeenBirthdate = value => {
  const validate = moment().diff(value, 'years') >= 0 && moment().diff(value, 'years') < 18
  return validate
}

const validateCpf = value => {
  if (value) {
    const newValue = value.replace(/\D/g, '')
    const validate = newValue.length === 11
    return validate
  }
}

const validatePassword = value => {
  const validate = !!((value !== null && value !== ''))
  return validate
}

const validateName = value => {
  const validate = !!((value !== null && value !== ''))
  return validate
}

const validatePhone = value => {
  if (value) {
    const newValue = value.replace(/\D/g, '')
    const validate = newValue.length >= 10
    return validate
  }
}

const validateBirthdate = value => {
  const validate = moment(value).isValid()
  return validate
}

const validateEmail = value => {
  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const regexValidate = !!regexEmail.test(value)
  return regexValidate
}

const notRequiredEmail = value => {  
  if(value.length < 1) return true;    
  
  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/    
  const regexValidate = !!regexEmail.test(value)    
  return regexValidate
  
}

export const validateDate = value => {
  // eslint-disable-next-line
  const regexDate = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  if (regexDate.test(value)) {
    return true
  } else {
    return false
  }
}

export const validateDateHour = value => {
  const newDate = moment(value).format('YYYY/MM/DD HH:mm')
  return moment(newDate).isValid()
}

const isString = (value) => typeof value === 'string'
