import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { connect } from 'react-redux'
import { showAlert, setSwalLoading, closeAllSwal } from 'store/modules/alert/actions'
import { setPatientId, clearPatient, setPatient, setPatientRegister } from 'store/modules/patient/actions'
import { titleCase } from 'utils/stringMethods'
import patient from 'consumers/patient'
import schedule from 'consumers/schedule'

import { validateData, validateDateHour } from 'utils/validators'
import Inputmask from 'inputmask'
import { scrollToRef } from 'utils/window'

import Card from 'components/Card'
import CreatePatient from 'components/CreatePatient'
import CreateSchudulling from 'components/CreateScheduling'
import { Tab, Grid } from 'semantic-ui-react'
import ListSchedules from 'components/ListSchedule'
import ListRecord from 'components/ListRecord'
import PaginationShort from 'components/Pagination'
import CalendarHoursInput from 'components/CalendarHoursInput'
import Button from 'components/Button'

import { Header, Title } from 'components/Card/styled'

class CreatePatientPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showScheduling: false,
      hasRegister: false,
      idPatient: null,
      title: 'Informações do paciente',
      activeIndex: 0,
      clearFileds: false,

      name: null,
      cpf: null,
      cellphone: null,
      telephone: null,
      whatsapp: false,
      contactMethod: 'whatsapp',
      gender: 'male',
      email: null,
      birthdate: null,
      invalidFields: {},
      errorsMessages: {},

      // gravações - paginação
      totalPages: null,
      activePage: null,
      page: 1,
      dateHourStart: '',
      dateHourEnd: '',
      dateStart: '',
      dateEnd: ''
    }

    this.dateStartRef = React.createRef()
    this.dateEndRef = React.createRef()
    this.newSchuduleRef = React.createRef()
  }

  createPatient = async (data) => {
    const { token, showAlert, setPatientId, setSwalLoading, setPatientRegister } = this.props
    const invalidFields = validateData(data)

    if (Object.keys(invalidFields).length === 0) {
      try {
        setSwalLoading()
        const response = await patient.create(token, data)

        showAlert(response.code, 'Sucesso!', response.message, '', () => {
          setPatientId(response.data.patientId)
          setPatientRegister(true)
          })
        this.setState({ patientId: response.patientId })
        this.showScheduleForm()
        this.cleanErrors()
      } catch (err) {
        if (!err.response) {
          showAlert(500, 'Atenção', 'Não foi possível se comunicar com o servidor!')
        }
        if (err.invalid) {
          const invalidFields = {}
          err.invalid.forEach((field) => {
            invalidFields[field.name] = field.message
          })
          this.setState({ invalidFields })
          showAlert(err.code, 'Atenção', err.message, err.technical)
        } else {
          showAlert(err.code, 'Atenção', err.message, err.technical)
        }
      }
    } else {
      this.setState({ invalidFields })
    }
  }

  updatePatient = async (data, id) => {
    const { token, showAlert, setSwalLoading } = this.props
    if (Object.keys(data).length <= 0) {
      this.showScheduleForm()
      return
    }
    const invalidFields = validateData(data)

    if (Object.keys(invalidFields).length === 0) {
      try {
        setSwalLoading()
        const response = await patient.update(token, id, data)

        showAlert(response.code, 'Sucesso!', response.message)
        this.setState({ patientId: response.patientId })
        this.showScheduleForm()
      } catch (err) {
        if (!err.response) {
          showAlert(500, 'Atenção', 'Não foi possível se comunicar com o servidor!')
        }

        if (err.invalid) {
          const invalidFields = {}
          err.invalid.forEach((field) => {
            invalidFields[field.name] = field.message
          })
          this.setState({ invalidFields })
          showAlert(400, 'Atenção', err.message, err.technical)
        }
      }
    } else {
      this.setState({ invalidFields })
    }
  }

  createSchudeling = async (data) => {
    const { token, showAlert, setSwalLoading } = this.props
    const invalidFields = validateData(data)
    if (Object.keys(invalidFields).length === 0) {
      try {
        setSwalLoading()
        const response = await schedule.create(token, data)

        showAlert(response.code, 'Sucesso!', response.message, '', () => {
          this.setState({
            activeIndex: 1,
            invalidFields: {}
          })
        })

        return true
      } catch (err) {
        if (err.invalid) {
          const invalidFields = {}
          err.invalid.forEach((field) => {
            invalidFields[field.name] = field.message
          })
          this.setState({ invalidFields })
          showAlert(400, 'Atenção', err.message, err.technical)
        }
        else {
          showAlert(err.code, 'Atenção', err.message)
        }
        return false
      }
    } else {
      this.setState({ invalidFields })
    }
  }

  changePage = (event) => {
    if (event) {
      this.setState(
        {
          page: event.target.getAttribute('value')
        }, () => { this.getPatient() }
      )
    }
  }

  clearFilters = () => {
    this.setState(
      {
        dateHourStart: '',
        dateHourEnd: '',
        tempDate: '',
        tempDateFinal: '',
        page: 1,
        activePage: 1
      }, () => { this.getPatient() }
    )
  }

  changeStartDate = (event) => {
    const inputFormatDate = moment(event).format('DD/MM/YYYY HH:mm')
    this.setState({ dateStart: inputFormatDate })

    const searchFormatDate = moment(event).format('YYYY-MM-DDTHH:mm')
    this.concatDate(searchFormatDate, event, 'start')
  }

  changeEndDate = (event) => {
    const inputFormatDate = moment(event).format('DD/MM/YYYY HH:mm')
    this.setState({ dateEnd: inputFormatDate })

    const searchFormatDate = moment(event).format('YYYY-MM-DDTHH:mm')
    this.concatDate(searchFormatDate, event, 'end')
  }

  concatDate = (date, event, type) => {
    if (type === 'start') {
      if (validateDateHour(event)) {
        this.setState(
          { page: 1, dateHourStart: `&start=${date}` },
          () => {
            this.setState({ activePage: 1 })
            this.getPatient()
          }
        )
      } else {
        this.setState({ dateHourStart: '' }, () => {
          this.getPatient()
        })
      }
    }

    if (type === 'end') {
      if (validateDateHour(event)) {
        this.setState(
          { page: 1, dateHourEnd: `&end=${date}` },
          () => {
            this.setState({ activePage: 1 })
            this.getPatient()
          }
        )
      } else {
        this.setState({ dateHourEnd: '' }, () => {
          this.getPatient()
        })
      }
    }
  }

  // TODO: move to redux
  cleanErrors = () => {
    this.setState({ invalidFields: {} })
  }

  getPatient = async (id) => {
    const { page, dateHourStart, dateHourEnd } = this.state
    const { token, setPatient, showAlert, patientId } = this.props
    try {
      const response = await patient.getById(
        token,
        id || patientId,
        `?page=${page}${dateHourStart ? `&start=${dateHourStart}` : ''}${dateHourEnd ? `&end=${dateHourEnd}` : ''}`)
      const user = response.data
      this.setState({
        totalPages: response.data.calls.totalPages,
        activePage: response.data.calls.page
      })

      setPatient({ ...user, name: titleCase(user.name), register: true, birthdate: new Date(user.birthdate) })
    } catch (e) {
      showAlert(e.code, e.message)
    }
  }

  componentDidMount () {
    const { state } = this.props.location
    if (state) this.getPatient(state)
    Inputmask().mask(document.querySelectorAll('input'))
  }

  showScheduleForm = () => {
    this.setState({ showScheduling: true })
    setTimeout(() => {
      if (this.newSchuduleRef.current) {
        scrollToRef(this.newSchuduleRef.current.offsetTop)
      }
    }, 100)
  }

  clear = () => {
    const { clearPatient } = this.props

    clearPatient()
    this.setState({ showScheduling: false })
  }

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex }, () => this.setTitle(activeIndex))
  }

  setTitle = (index) => {
    const { patientName } = this.props

    if (index === 0) {
      this.setState({ title: 'Informações do paciente' })
    }

    if (index === 1) {
      this.setState({ title: `Agendamentos de ${titleCase(patientName)}` })
    }

    if (index === 2) {
      this.setState({ title: `Novo agendamento para ${titleCase(patientName)}` })
    }

    if (index === 3) {
      this.setState({ title: `Gravações de ${titleCase(patientName)}` })
    }
  }

  render () {
    const { invalidFields, title, activeIndex, activePage, totalPages } = this.state
    const { token, patientId, patientCalls } = this.props

    const panes = [
      {
        key: 'Paciente',
        menuItem: 'Paciente',
        render: () => (
          <Tab.Pane className="hide-pane">
            <Card
              noPadding
              sideButtonPress={this.clear}
              sideButtonText="Novo Paciente">
              <CreatePatient
                hasNewButton
                cleanError={this.cleanErrors}
                onUpdate={this.updatePatient}
                onSubmit={this.createPatient}
                errorMessages={invalidFields}
              />
            </Card>
          </Tab.Pane>
        ) 
      },
      {},
      {},
      {}
    ]

    if (patientId) {
      panes[1] = {
        key: 'Agendamentos Passados',
        menuItem: 'Agendamentos Passados',
        render: () => (
          <Tab.Pane className="hide-pane">
            <Card noPadding>
              <ListSchedules
                patientId={patientId}
                hideControls={true}
                showButton={true} />
            </Card>
          </Tab.Pane>
        )
      }
      panes[2] = {
        key: 'Novo Agendamento',
        menuItem: 'Novo Agendamento',
        render: () => (
          <Tab.Pane className="hide-pane">
            <Card titleSize='1.9em' noPadding contentRef={this.newSchuduleRef}>
              <CreateSchudulling
                token={token}
                onSubmit={this.createSchudeling}
                errorMessages={invalidFields}
              />
            </Card>
          </Tab.Pane>
        ) 
      }
      if (patientCalls) {
        panes[3] = {
          key: 'Gravações',
          menuItem: 'Gravações',
          render: () => (
            <Tab.Pane className='hide-pane'>
              <Card>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={3}>
                      <CalendarHoursInput
                        inputRef={this.dateStartRef}
                        label='Data inicial'
                        onSelect={(e) => { this.setState({ tempDate: e }); this.changeStartDate(e) }}
                        selected={this.state.tempDate}
                      />
                    </Grid.Column>

                    <Grid.Column width={3}>
                      <CalendarHoursInput
                        inputRef={this.dateEndRef}
                        label='Data Final'
                        onSelect={(e) => { this.setState({ tempDateFinal: e }); this.changeEndDate(e) }}
                        selected={this.state.tempDateFinal}
                      />
                    </Grid.Column>

                    <Grid.Column width={2} style={{ marginTop: 36 }}>
                      <Button filled onClick={this.clearFilters}>
                        Limpar
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {patientCalls && patientCalls.docs
                  ? <ListRecord recordings={patientCalls.docs} />
                  : <Header><Title>Sem registros</Title></Header>
                }
                <PaginationShort
                  onPageChange={(e) => this.changePage(e)}
                  totalPages={totalPages || 1}
                  activePage={activePage || 1}
                />
              </Card>
            </Tab.Pane>
          ) 
        }
      }
    }
    return (
      <>
        <Card noBg>
          <Header><Title>{ title }</Title></Header>
          <Tab onTabChange={this.handleTabChange} menu={{ pointing: true }} activeIndex={activeIndex} panes={panes} renderActiveOnly={true} />
        </Card>
      </>
    )
  }
}

CreatePatientPage.propTypes = {
  token: PropTypes.string,
  showAlert: PropTypes.func
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  patientId: state.patient.id,
  patientName: state.patient.name,
  patientCalls: state.patient.calls
})

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
  setPatientId: (id) => dispatch(setPatientId(id)),
  setPatientRegister: (bool) => dispatch(setPatientRegister(bool)),
  clearPatient: () => dispatch(clearPatient()),
  setPatient: (data) => dispatch(setPatient(data)),
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal())
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatePatientPage)
