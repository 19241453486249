import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Dropdown, Icon } from 'semantic-ui-react'
import { Wrapper, Text } from './styled'
import { Link } from 'react-router-dom'

function RamalButton ({
  token,
  name,
  status,
  registerUser
}) {
  const DropdownTrigger = () => (
    <Wrapper>
      <Icon name='user'/>
      <Text>
        {name}
      </Text>
    </Wrapper>
  )

  return (
    <Dropdown trigger={<DropdownTrigger />} icon={null}>
      <Dropdown.Menu style={{width: '100%', height: '30px', alignItens: 'center'}}>
        <Link to="/admin/perfil">
          <Dropdown.Item  style={{color: '#000', width: '100%', textAlign: 'center', marginTop: '4px'}} text='Perfil'/>
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  )
}

RamalButton.propTypes = {
  token: PropTypes.string,
  ramal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.string,
  registerUser: PropTypes.func
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  ramal: state.auth.user.ramal,
  status: state.auth.user.status
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(RamalButton)
