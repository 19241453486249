import styled from 'styled-components'
import mountainBackground from 'assets/img/mountain-background.jpg'

export const Content = styled.div`
  background: #fcfcfc;
  height: 100%;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  background-image: url(${mountainBackground});
  background-position: center;
  background-size: cover;
`

export const Wrapper = styled.div`
  background: linear-gradient(127.59deg, #151515 -12.33%, #040404 113.59%);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 20px;
  width: ${props => props.signupWidth ? '56%' : '36%'};
  color: #fcfcfc;
  min-height:380px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 710px) {
     width: ${props => props.signupWidth ? '80%' : '60%'};
  }
  @media screen and (max-width: 700px){
    width: 95%
  }
  @media screen and (max-width: 400px){
    width: 100%
  }
`

export const Banner = styled.div`
  background: red;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 4px;
  width: 98%;
  color: #fcfcfc;
  min-height: 20px;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;


`
