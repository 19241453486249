import { call, put, all, takeLatest, select } from 'redux-saga/effects'
import resources from 'consumers/resources'

function * getPauseReasons (action) {
  const token = yield select((state) => state.auth.token)

  try {
    const pauseReasons = yield call(
      resources.pauseReasons,
      token
    )
    yield put({
      type: 'RESOURCE_SET_PAUSE_REASONS',
      data: {
        pauseReasons
      }
    })
  } catch (e) {
    getPauseReasons({ payload: { token } })
  }
}

export default all([
  takeLatest('GET_PAUSE_REASONS', getPauseReasons)
])
