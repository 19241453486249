const _initialState = {
  loading: false,
  emailValidator: {
    isValid: true,
    message: ''
  },
  passwordValidator: {
    isValid: true,
    message: ''
  },
  user: {
    name: null,
    status: 'unavailable',
    level: null,
    ramal: 0,
    clientName: null,
    clientNumber: null,
    customRoutes: []
  },
  pauseStartedAt: null,
  token: null,
  refreshToken: null,
  logged: false,
  sessionTimeout: null
}

const auth = (state = _initialState, action) => {
  switch (action.type) {
    case 'RESET_STATE': {
      return {
        ...state,
        emailValidator: _initialState.emailValidator,
        passwordValidator: _initialState.passwordValidator
      }
    }

    case 'SIGNIN_FAILED': {
      return {
        ...state,
        ...action.data,
        loading: false
      }
    }

    case 'SIGNIN_SUCCEEDED': {
      const user = action.data.user
      localStorage.setItem('@Agenda/userName', action.data.user.name)
      localStorage.setItem('@Agenda/userLevel', action.data.user.level)
      localStorage.setItem('@Agenda/mvUser', action.data.user.mvUser)
      localStorage.setItem('@Agenda/userStatus', action.data.user.status)
      localStorage.setItem('@Agenda/customRoutes', action.data.user.customRoutes)
      if (action.data.user.ramal) {
        localStorage.setItem('@Agenda/userRamal', action.data.user.ramal)
      }
      localStorage.setItem(
        '@Agenda/accessToken',
        'Bearer ' + action.data.accessToken
      )
      localStorage.setItem('@Agenda/refreshToken', action.data.refreshToken)
      localStorage.setItem('@Agenda/sessionTimeout', action.data.sessionTimeout)
      localStorage.setItem('@Agenda/lastCall', Date.now())
      localStorage.setItem('@Agenda/lastTokenRequest', Date.now())

      return {
        ...state,
        user: { ...state.user, ...user },
        emailValidator: _initialState.emailValidator,
        passwordValidator: _initialState.passwordValidator,
        token: 'Bearer ' + action.data.accessToken,
        refreshToken: action.data.refreshToken,
        sessionTimeout: action.data.sessionTimeout,
        logged: true,
        loading: false
      }
    }

    case 'SET_SESSION': {
      const name = localStorage.getItem('@Agenda/userName')
      const level = localStorage.getItem('@Agenda/userLevel')
      const ramal = localStorage.getItem('@Agenda/userRamal')
      const accessToken = localStorage.getItem('@Agenda/accessToken')
      const refreshToken = localStorage.getItem('@Agenda/refreshToken')
      const sessionTimeout = localStorage.getItem('@Agenda/sessionTimeout')
      const pauseStartedAt = parseInt(localStorage.getItem('@Agenda/pauseStartedAt'))
      const customRoutes = localStorage.getItem('@Agenda/customRoutes')
      const status = pauseStartedAt ? 'paused' : localStorage.getItem('@Agenda/userStatus')
      const customRoutesArray = customRoutes.split(',')

      const newState = {
        token: accessToken,
        user: {
          ...state.user,
          name,
          level,
          status,
          customRoutes: customRoutesArray
        },
        pauseStartedAt,
        refreshToken: refreshToken,
        sessionTimeout: sessionTimeout,
        logged: true
      }
      newState.user.ramal = ramal || undefined
      return {
        ...state,
        ...newState,
        loading: false
      }
    }

    case 'CLEAR_SESSION': {
      localStorage.clear()
      return _initialState
    }

    case 'SET_STATUS': {
      if (action.payload.status === state.user.status) return { ...state }
      let pauseStartedAt = null
      if (action.payload.status === 'paused') {
        const dateNow = Date.now()
        localStorage.setItem('@Agenda/pauseStartedAt', dateNow)
        pauseStartedAt = dateNow
      } else {
        localStorage.removeItem('@Agenda/pauseStartedAt')
      }
      return {
        ...state,
        pauseStartedAt,
        user: { ...state.user, status: action.payload.status }
      }
    }

    case 'SET_LOADING':
      return { ...state, loading: action.payload.loading }
    case 'RENEW_TOKEN_SUCCEEDED': {
      localStorage.setItem(
        '@Agenda/accessToken',
        'Bearer ' + action.payload.token
      )
      return { ...state, token: `Bearer ${action.payload.token}` }
    }
    default:
      return state
  }
}

export default auth
