import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-content: center;
`

export const Span = styled.span`
  color: #121212;
  font-size: 1.1em;
  font-family: Heebo, Roboto, Arial, Helvetica, sans-serif;
`

export const Notification = styled.div`  
  background-color: red;
  border-radius: 50%;
  padding: 15px;
  color: white;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid whitesmoke;
  position: absolute;
  right: -10px;
`