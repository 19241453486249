import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import createRootReducer from './modules/rootReducer'
import rootSaga from './modules/rootSaga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose

const store = createStore(createRootReducer(history), composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)))

sagaMiddleware.run(rootSaga)

export default store
