import styled from 'styled-components'

export const Input = styled.input`
  height: 48px;
  background: #262626;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  color: #fcfcfc;
  padding-left: ${props => props.icon ? '10px' : '40px'};
  padding-right: 12px;
  border: ${props => props.hasError ? '1px solid red' : '0'};
  &::placeholder{
    color: #A6A6A6;
  }
  `

export const Wrapper = styled.div`
  margin-bottom: ${props => props.unit ? '12px' : '0'};
  position: relative;
  width: ${props => props.signup ? '100%' : '76%'};
`

export const ErrorMsg = styled.span`
  font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
  color: red;
  margin-left: 12px;
`

export const Label = styled.label`
  font-weight: 600;
  color: #818181;
  margin-left: 4px;
  line-height: 38px;
`
