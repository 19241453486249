import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'

import AXCard from 'components/Card'
import { Grid, Form, Segment, Divider } from 'semantic-ui-react'

import reports from 'consumers/reports'

import saveSvgAsPng from 'save-svg-as-png'

import {
  FormatDateBr,
  calendarInputToUsFormat,
  FormatDateBrPlus3Hours,
  ToBrDisplayWithHour
} from 'utils/formatDate'

import {
  showAlert,
  setSwalLoading,
  closeAllSwal
} from 'store/modules/alert/actions'

import { translateReportType } from 'utils/translate'
import CalendarHoursInput from 'components/CalendarHoursInput'
import CardIcon from 'components/CardIcon'
import { Label } from 'components/Input/styled'

import logo from 'assets/img/logo-black.png'
import logoAgx from 'assets/img/AGX_Software.png'

import jsPDF from 'jspdf'
import 'jspdf-autotable'

import Chart from 'react-google-charts'

import downloadJs from 'downloadjs';

export class ListReports extends Component {
  constructor (props) {
    super(props)

    this.state = {
      startDate: null,
      finalDate: null,
      completeDate: null,
      rangeDate: null,
      PeriodFooterPdf: null,
      options: [],
      optionsChartSchedule: [],
      chartTitle: null
    }

    this.chartRef = createRef()
    this.chartScheduleRef = createRef()
  }

  changeStartDate = (event) => {
    const searchFormatDate = calendarInputToUsFormat(event)
    this.setState(
      {
        startDate: searchFormatDate,
        rangeDate: null
      },
      () => this.defineDate()
    )
  }

  changeEndDate = (event) => {
    const searchFormatDate = calendarInputToUsFormat(event)
    this.setState({ finalDate: searchFormatDate, rangeDate: null }, () =>
      this.defineDate()
    )
  }

  changeRangeDate = (e, { value }) => {
    this.setState(
      {
        rangeDate: value,
        startDate: null,
        finalDate: null,
        tempStartDate: null,
        tempFinalDate: null
      },
      () => this.defineDate()
    )
  }

  defineDate = () => {
    const { startDate, finalDate, rangeDate } = this.state

    if (startDate !== null && finalDate !== null) {
      this.setState({
        completeDate: `?start=${startDate}&end=${finalDate}`
      })
    }

    if (rangeDate !== null) {
      this.setState({
        completeDate: `?range=${rangeDate}`
      })
    }
  }

  footerDefineDate = (beginDate, endDate) => {
    const { startDate, finalDate, rangeDate } = this.state

    if (startDate !== null && finalDate !== null) {
      this.setState({
        PeriodFooterPdf: `${ToBrDisplayWithHour(beginDate)} até ${ToBrDisplayWithHour(
          endDate
        )}`
      })
    }

    if (rangeDate !== null) {
      switch (rangeDate) {
        case 'day':
          this.setState({ PeriodFooterPdf: `${FormatDateBr(new Date())}` })
          break
        case 'week':
          this.setState({
            PeriodFooterPdf: `${FormatDateBrPlus3Hours(
              beginDate
            )} até ${FormatDateBrPlus3Hours(endDate)}`
          })
          break
        case 'month':
          this.setState({
            PeriodFooterPdf: `${FormatDateBrPlus3Hours(
              beginDate
            )} até ${FormatDateBrPlus3Hours(endDate)}`
          })
          break
        default:
          break
      }
    }
  }

  headerAndFooterPdf = (doc, date, auth) => {
    const { PeriodFooterPdf } = this.state

    const headerImg = new Image()
    headerImg.src = logo
    // Header
    doc.setFontSize(10)
    doc.setTextColor(40)
    doc.setFontStyle('normal')
    doc.addImage(headerImg, 'PNG', 15, 15, 22, 10)
    // Footer
    const pageSize = doc.internal.pageSize
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    doc.text(
      `Gerado dia: ${date} | Período: ${PeriodFooterPdf} | Gerado por: ${auth.name} `,
      15,
      pageHeight - 10
    )
  }

  footerCallPdf = (doc, footer) => {
    const pageSize = doc.internal.pageSize
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()

    doc.text(
      `Tempo chamada curta: menor que ${footer.durationShortCall} segundos `,
      15,
      pageHeight - 17
    )
  }

  getHeader = (item, type) => {
    const array = []

    item.forEach((itens) => {
      if (itens[type]) {
        itens[type].forEach((names) => {
          if (!array.includes(names.initials)) {
            array.push(names.initials)
          }
        })
      }
    })
    return array
  }

  getLegendsInitials = (item, type) => {
    const array = []
    const tempArray = []

    item.forEach((itens) => {
      if (itens[type]) {
        itens[type].forEach((names) => {
          if (!tempArray.includes(names.name)) {
            tempArray.push(names.name)

            const objLegend = {
              name: names.name,
              initials: names.initials
            }
            array.push(objLegend)
          }
        })
      }
    })
    return array
  }

  getBody = (item, type, HeaderArray) => {
    const array = new Array(HeaderArray.length)
    array.fill(0)

    for (let i = 0; i < HeaderArray.length; i++) {
      if (item[type][i]) {
        const position = HeaderArray.indexOf(item[type][i].initials)

        if (position !== -1) {
          array[position] = item[type][i].count
        }
      }
    }

    return array
  }

  generateFooter = (fullHeader, footerInfo, type) => {
    const array = new Array(fullHeader.length)
    if (type === 'pause') {
      array[fullHeader.length - 1] = footerInfo.pause
      array[fullHeader.length - 2] = footerInfo.time
    } else if (type === 'tabulation') {
      array[fullHeader.length - 1] = footerInfo.tabulation
    }
    array[0] = 'Total'

    if (!footerInfo.totalInitials) return array

    for (let i = 0; i < footerInfo.totalInitials.length; i++) {
      if (footerInfo.totalInitials[i]) {
        const position = fullHeader.indexOf(
          Object.keys(footerInfo.totalInitials[i])[0]
        )

        if (position !== -1) {
          array[position] = Object.values(footerInfo.totalInitials[i])[0]
        }
      }
    }
    return array
  }

  paintLastLine = (doc, data) => {
    var rows = data.table.body
    if (data.row.index === rows.length - 1) {
      doc.setFillColor(239, 154, 154)
    }
  }

  paintPenultimateLine = (doc, data) => {
    var rows = data.table.body
    if (data.row.index === rows.length - 1) {
      doc.setFillColor(161, 53, 53)
      doc.setTextColor(255, 255, 255)
    }

    if (data.row.index === rows.length - 2) {
      doc.setFillColor(239, 154, 154)
    }
  }

  makeTotalLastCell = (fullHeader, lastCell) => {
    const footer = new Array(fullHeader.length)
    footer[fullHeader.length - 1] = lastCell
    footer[0] = 'Total'

    return footer
  }

  getScheduleType = (itens, situation, data) => {
    if (itens.situation === situation) {
      data.total = itens.count
      itens.types.forEach((value) => {
        if (value.consultType === 'new') data.new = value.count
        if (value.consultType === 'return') data.return = value.count
        if (value.consultType === 'routine') data.routine = value.count
        if (value.consultType === 'emergency') data.emergency = value.count
      })
    }

    return data
  }

  generateFirstPage = (doc, extractType) => {
    const { auth } = this.props
    const { PeriodFooterPdf } = this.state
    const date = FormatDateBr(new Date())

    console.log(extractType)

    const headerImg = new Image()
    headerImg.src = logo
    const width = doc.internal.pageSize.getWidth()
    const height = doc.internal.pageSize.getHeight()

    const agxImg = new Image()
    agxImg.src = logoAgx

    doc.autoTable({
      didDrawPage: function () {
        doc.addImage(agxImg, width / 2 - 15, 10, 30, 30)
        doc.addImage(headerImg, width / 2 - 75, height / 2 - 40, 150, 80)
        doc.text(`Relatório de ${extractType}`, 15, height - 40)
        doc.text(`Período: ${PeriodFooterPdf}`, 15, height - 30)
        doc.text(`Gerado por: ${auth.name}`, 15, height - 20)
        doc.text(`Data de geração: ${date}`, 15, height - 10)
        doc.addPage(1)
      }
    })
  }

  constructValuesChart = (value) => {
    const array = value.map(itens => {
      const data = [
        itens.name,
        (Object.values(itens)[0])
      ]
      return data
    })
    return array
  }

  makeChart = async (doc, type, values) => {
    const { auth } = this.props
    const date = FormatDateBr(new Date())

    await this.setState({
      options: [
        ['Task', 'Hours per Day'],
        ...values
      ],
      chartTitle: type
    })

    const chartArea = this.chartRef.current.getElementsByTagName('svg')[0]

    await saveSvgAsPng.svgAsPngUri(chartArea).then(uri => {
      doc.addImage(uri, 'JPEG', -15, 30)
      doc.autoTable({
        didDrawPage: () => this.headerAndFooterPdf(doc, date, auth)
      })
      doc.addPage(1)
    })
  }

  makeChartSchedule = async (doc, values) => {
    const { auth } = this.props
    const date = FormatDateBr(new Date())

    await this.setState({
      optionsChartSchedule: [
        ['Agendamento', 'Novo', 'Retorno', 'Rotina', 'Emergência'],
        ...values
      ]
    })

    const chartArea = this.chartScheduleRef.current.getElementsByTagName('svg')[0]

    await saveSvgAsPng.svgAsPngUri(chartArea).then(uri => {
      doc.addImage(uri, 'JPEG', -15, 30)
      doc.autoTable({
        didDrawPage: () => this.headerAndFooterPdf(doc, date, auth)
      })
      doc.addPage(1)
    })
  }

  getReports = async (typeReport) => {
    const { token, showAlert, auth, setSwalLoading, closeAllSwal } = this.props
    const { startDate, finalDate, completeDate } = this.state

    let extractType
    const date = FormatDateBr(new Date())
    const newArray = []
    let HeaderArray = []
    let fullHeader = []
    let legendsArray = []

    extractType = translateReportType(typeReport)

    const doc = new jsPDF({
      orientation: 'landscape'
    })

    switch (typeReport) {
      case 'calls':
        try {
          setSwalLoading()
          const response = await reports.calls(token, completeDate)
          const item = response.data.results
          const footerInfo = response.data.footer          

          if (footerInfo.call < 1) {
            return showAlert(
              403,
              'Atenção',
              'O período selecionado não possui dados!'
            )
          }

          this.footerDefineDate(footerInfo.begin, footerInfo.end)

          this.generateFirstPage(doc, extractType)

          item.forEach((item) => {
            const data = [
              item.name,
              item.ramal,
              item.total.avgTime,
              item.total.notShortCall,
              item.total.shortCall,
              item.total.total
            ]
            newArray.push(data)
          })

          fullHeader = [
            'Nome',
            'Ramal',
            'Tempo médio',
            'Chamadas longas',
            'Chamadas curtas',
            'Total chamadas'
          ]

          const footer = this.makeTotalLastCell(fullHeader, footerInfo.call)
          footer[4] = footerInfo.shortCall
          footer[3] = footerInfo.notShortCall

          doc.autoTable({
            head: [fullHeader],
            body: [...newArray, footer],
            willDrawCell: (data) => this.paintLastLine(doc, data),
            didDrawPage: () => {
              this.headerAndFooterPdf(doc, date, auth)
              this.footerCallPdf(doc, footerInfo)
            },
            margin: { top: 30 }
          })
          doc.save(`${extractType}_${date}.pdf`)

          closeAllSwal()
        } catch (err) {
          closeAllSwal()
          if (!err) {
            return showAlert(
              500,
              'Atenção',
              'Não foi possível se comunicar com o servidor!'
            )
          }
          showAlert(err.code, 'Atenção', err.message)
        }
        break
      case 'pauses':
        try {
          setSwalLoading()
          const response = await reports.pauses(token, completeDate)
          const item = response.data.results
          const footerInfo = response.data.footer

          if (footerInfo.pause < 1) {
            return showAlert(
              403,
              'Atenção',
              'O período selecionado não possui dados!'
            )
          }

          this.footerDefineDate(footerInfo.begin, footerInfo.end)
          this.generateFirstPage(doc, extractType)
          const values = await this.constructValuesChart(footerInfo.totalInitials)
          await this.makeChart(doc, 'Pausas', values)

          legendsArray = this.getLegendsInitials(item, 'pauseData')

          HeaderArray = this.getHeader(item, 'pauseData')
          fullHeader = ['Operador', ...HeaderArray, 'Tempo total', 'Total']

          const footer = this.generateFooter(fullHeader, footerInfo, 'pause')

          item.forEach((item) => {
            let values = []
            values = this.getBody(item, 'pauseData', HeaderArray)

            const data = [item.name, ...values, item.totalTime, item.total]
            newArray.push(data)
          })

          doc.autoTable({
            head: [fullHeader],
            body: [...newArray, footer],
            willDrawCell: (data) => this.paintLastLine(doc, data),
            didDrawPage: () => this.headerAndFooterPdf(doc, date, auth),
            margin: { top: 30 }
          })

          doc.addPage(1)

          doc.text('Legendas', 15, 33)
          doc.autoTable({
            head: [''],
            body: [...legendsArray],
            showHead: false,
            didDrawPage: () => this.headerAndFooterPdf(doc, date, auth),
            margin: { top: 40 }
          })

          doc.save(`${extractType}_${date}.pdf`)

          closeAllSwal()
        } catch (err) {
          closeAllSwal()
          if (!err) {
            return showAlert(
              500,
              'Atenção',
              'Não foi possível se comunicar com o servidor!'
            )
          }
          showAlert(err.code, 'Atenção', err.message)
        }
        break
      case 'tabulation':
        try {
          setSwalLoading()
          const response = await reports.tabulation(token, completeDate)
          const item = response.data.results
          const footerInfo = response.data.footer

          if (footerInfo.tabulation < 1) {
            return showAlert(
              403,
              'Atenção',
              'O período selecionado não possui dados!'
            )
          }

          this.footerDefineDate(footerInfo.begin, footerInfo.end)
          this.generateFirstPage(doc, extractType)
          const values = await this.constructValuesChart(footerInfo.totalInitials)
          await this.makeChart(doc, 'Tabulações', values)

          legendsArray = this.getLegendsInitials(item, 'tabulationData')
          HeaderArray = this.getHeader(item, 'tabulationData')
          fullHeader = ['Operador', ...HeaderArray, 'Total']

          // let footer = this.makeTotalLastCell(fullHeader, footerInfo.tabulation)
          const footer = this.generateFooter(fullHeader, footerInfo, 'tabulation')

          item.forEach((item) => {
            let values = []
            values = this.getBody(item, 'tabulationData', HeaderArray)

            const data = [item.name, ...values, item.total]
            newArray.push(data)
          })

          doc.autoTable({
            head: [fullHeader],
            body: [...newArray, footer],
            willDrawCell: (data) => this.paintLastLine(doc, data),
            didDrawPage: () => this.headerAndFooterPdf(doc, date, auth),
            margin: { top: 30 }
          })

          doc.addPage(1)

          doc.text('Legendas', 15, 33)
          doc.autoTable({
            head: [''],
            body: [...legendsArray],
            showHead: false,
            didDrawPage: () => this.headerAndFooterPdf(doc, date, auth),
            margin: { top: 40 }
          })

          doc.save(`${extractType}_${date}.pdf`)

          closeAllSwal()
        } catch (err) {
          closeAllSwal()
          if (!err) {
            return showAlert(
              500,
              'Atenção',
              'Não foi possível se comunicar com o servidor!'
            )
          }
          showAlert(err.code, 'Atenção', err.message)
        }
        break
      case 'schedule':
        try {
          setSwalLoading()
          const response = await reports.schedule(token, completeDate)
          const item = response.data.results
          const footerInfo = response.data.footer

          if (footerInfo.schedule < 1) {
            return showAlert(
              403,
              'Atenção',
              'O período selecionado não possui dados!'
            )
          }

          this.footerDefineDate(footerInfo.begin, footerInfo.end)
          this.generateFirstPage(doc, extractType)

          const confirmArray = []
          const pendingArray = []
          const canceledArray = []

          item.forEach((item) => {
            let dataConf = {}
            let dataCanceled = {}
            let dataPending = {}

            item.scheduleData.forEach((itens) => {
              dataConf = this.getScheduleType(itens, 'confirmed', dataConf)
              dataCanceled = this.getScheduleType(
                itens,
                'canceled',
                dataCanceled
              )
              dataPending = this.getScheduleType(itens, 'pending', dataPending)
            })

            const confirmData = {
              name: item.name,
              loggedTime: item.loggedTime,
              new: dataConf.new || 0,
              return: dataConf.return || 0,
              routine: dataConf.routine || 0,
              emergency: dataConf.emergency || 0,
              total: dataConf.total || 0
            }

            const canceledData = {
              name: item.name,
              loggedTime: item.loggedTime,
              new: dataCanceled.new || 0,
              return: dataCanceled.return || 0,
              routine: dataCanceled.routine || 0,
              emergency: dataCanceled.emergency || 0,
              total: dataCanceled.total || 0
            }

            const pendingData = {
              name: item.name,
              loggedTime: item.loggedTime,
              new: dataPending.new || 0,
              return: dataPending.return || 0,
              routine: dataPending.routine || 0,
              emergency: dataPending.emergency || 0,
              total: dataPending.total || 0
            }

            confirmArray.push(confirmData)
            canceledArray.push(canceledData)
            pendingArray.push(pendingData)
          })

          fullHeader = [
            {
              name: 'Nome',
              new: 'Novo',
              return: 'Retorno',
              emergency: 'Emergência',
              routine: 'Rotina',
              total: 'Total'
            }
          ]

          const values = []
          const headerSchedule = ['schedule', 'new', 'return', 'routine', 'emergency']
          const confirmed = new Array(5)
          confirmed.fill(0)
          const canceled = new Array(5)
          canceled.fill(0)
          const pending = new Array(5)
          pending.fill(0)

          const footerConfirmed = {
            name: 'Total',
            new: 0,
            return: 0,
            emergency: 0,
            routine: 0,
            total: 0
          }

          if (footerInfo.confirmed) {
            footerInfo.confirmed.forEach(itens => {
              const position = headerSchedule.indexOf(Object.keys(itens)[0])
              if (position !== -1) {
                confirmed[position] = Object.values(itens)[0]
              }
              Object.assign(footerConfirmed, itens)
            })

            footerConfirmed.total = footerInfo.totalconfirmed
          }

          const footerCanceled = {
            name: 'Total',
            new: 0,
            return: 0,
            emergency: 0,
            routine: 0,
            total: 0
          }

          if (footerInfo.canceled) {
            footerInfo.canceled.forEach(itens => {
              const position = headerSchedule.indexOf(Object.keys(itens)[0])
              if (position !== -1) {
                canceled[position] = Object.values(itens)[0]
              }
              Object.assign(footerCanceled, itens)
            })

            footerCanceled.total = footerInfo.totalcanceled
          }

          const footerPending = {
            name: 'Total',
            new: 0,
            return: 0,
            emergency: 0,
            routine: 0,
            total: 0
          }

          if (footerInfo.pending) {
            footerInfo.pending.forEach(itens => {
              const position = headerSchedule.indexOf(Object.keys(itens)[0])
              if (position !== -1) {
                pending[position] = Object.values(itens)[0]
              }
              Object.assign(footerPending, itens)
            })

            footerPending.total = footerInfo.totalpending
          }

          confirmed[0] = 'Confirmados'
          values.push(confirmed)

          canceled[0] = 'Cancelados'
          values.push(canceled)

          pending[0] = 'Pendente'
          values.push(pending)

          await this.makeChartSchedule(doc, values)

          doc.text('Agendamentos confirmados', 115, 20)
          doc.autoTable({
            startY: 30,
            head: fullHeader,
            body: [...confirmArray, footerConfirmed],
            willDrawCell: (data) => this.paintLastLine(doc, data),
            didDrawPage: () => this.headerAndFooterPdf(doc, date, auth),
            margin: { top: 30 }
          })

          doc.addPage(1)

          doc.text('Agendamentos cancelados', 115, 20)
          doc.autoTable({
            startY: 30,
            head: fullHeader,
            body: [...canceledArray, footerCanceled],
            willDrawCell: (data) => this.paintLastLine(doc, data),
            didDrawPage: () => this.headerAndFooterPdf(doc, date, auth),
            margin: { top: 30 }
          })

          doc.addPage(1)

          const footerAll = this.makeTotalLastCell(
            Object.keys(fullHeader[0]),
            footerInfo.schedule
          )

          footerAll[0] = 'Total geral de agendamentos'

          doc.text('Agendamentos pendentes', 115, 20)
          doc.autoTable({
            startY: 30,
            head: fullHeader,
            body: [...pendingArray, footerPending, footerAll],
            willDrawCell: (data) => {
              this.paintPenultimateLine(doc, data)
            },
            didDrawPage: () => this.headerAndFooterPdf(doc, date, auth),
            margin: { top: 30 }
          })

          doc.save(`${extractType}_${date}.pdf`)

          closeAllSwal()
        } catch (err) {
          closeAllSwal()
          if (!err) {
            return showAlert(
              500,
              'Atenção',
              'Não foi possível se comunicar com o servidor!'
            )
          }
          showAlert(err.code, 'Atenção', err.message)
        }
        break

      case 'actions': 
        try {
          setSwalLoading()
          const response = await reports.actions(token, completeDate)          
          
          const startDateNoTime = FormatDateBr(startDate)
          const finalDateNoTime = FormatDateBr(finalDate)
          
          downloadJs(response, `Acoes_${startDateNoTime}_${finalDateNoTime}.xlsx`, 'application/vnd.ms-excel');

          closeAllSwal()
        } catch (err) {
          closeAllSwal()
          if (!err) {
            return showAlert(
              500,
              'Atenção',
              'Não foi possível se comunicar com o servidor!'
            )
          }
          showAlert(err.code, 'Atenção', err.message)
        }
          break;

      default:
        break
    }
  }

  render () {
    const { startDate, finalDate, rangeDate, options, chartTitle, optionsChartSchedule } = this.state

    return (
      <AXCard noBg title='Relatórios'>
        <div ref={this.chartRef} style={{ visibility: 'hidden', position: 'absolute' }}>
          <Chart
            width={'1000px'}
            height={'600px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={options}
            options={{
              title: chartTitle
            }}
            rootProps={{ 'data-testid': '7' }}
          />
        </div>

        <div ref={this.chartScheduleRef} style={{ visibility: 'hidden', position: 'absolute' }}>
          <Chart
            width={'1000px'}
            height={'600px'}
            chartType="BarChart"
            loader={<div>Loading Chart</div>}
            data={optionsChartSchedule}
            options={{
              title: 'Agendamentos',
              chartArea: { width: '50%' },
              isStacked: true,
              hAxis: {
                title: 'Total Agendamentos',
                minValue: 0
              },
              vAxis: {
                title: 'Agendamentos'
              }
            }}
            // For tests
            rootProps={{ 'data-testid': '3' }}
          />
        </div>
        <Segment>
          <Grid style={{ marginTop: 10 }} relaxed='very' columns='equal'>
            <Grid.Column width='4'>
              <CalendarHoursInput
                label='Data inicial'
                onSelect={(e) => {
                  this.setState({ tempStartDate: e })
                  this.changeStartDate(e)
                }}
                selected={this.state.tempStartDate}
              />
            </Grid.Column>

            <Grid.Column width='4'>
              <CalendarHoursInput
                label='Data Final'
                inputRef={this.dateEndRef}
                onSelect={(e) => {
                  this.setState({ tempFinalDate: e })
                  this.changeEndDate(e)
                }}
                selected={this.state.tempFinalDate}
              />
            </Grid.Column>

            <Grid.Column>
              <Form style={{ marginTop: 8 }}>
                <Label>Tempo determinado:</Label>
                <Form.Group inline>
                  <Form.Radio
                    label='Hoje'
                    value='day'
                    checked={rangeDate === 'day'}
                    onChange={this.changeRangeDate}
                  />
                  <Form.Radio
                    label='Semana'
                    value='week'
                    checked={rangeDate === 'week'}
                    onChange={this.changeRangeDate}
                  />
                  <Form.Radio
                    label='Mês'
                    value='month'
                    checked={rangeDate === 'month'}
                    onChange={this.changeRangeDate}
                  />
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid>

          <Divider vertical>Ou</Divider>
        </Segment>

        {(startDate !== null && finalDate !== null) || rangeDate != null ? (
          <Grid columns={3} style={{ marginTop: 0 }}>
            <Grid.Column>
              <CardIcon
                icon='call-outline'
                header='Chamadas'
                description='Relatório de chamadas'
                textButton='Baixar'
                onClick={() => this.getReports('calls')}
              />
            </Grid.Column>

            <Grid.Column>
              <CardIcon
                icon='pause-outline'
                header='Pausas'
                description='Relatório de pausas'
                textButton='Baixar'
                onClick={() => this.getReports('pauses')}
              />
            </Grid.Column>

            <Grid.Column>
              <CardIcon
                icon='file-tray-full-outline'
                header='Tabulação'
                description='Relatório de tabulação'
                textButton='Baixar'
                onClick={() => this.getReports('tabulation')}
              />
            </Grid.Column>

            <Grid.Column>
              <CardIcon
                icon='calendar-outline'
                header='Agendamentos'
                description='Relatório de agendamentos'
                textButton='Baixar'
                onClick={() => this.getReports('schedule')}
              />
            </Grid.Column>
            
            <Grid.Column>
              <CardIcon
                icon='checkbox-outline'                
                header='Ações'
                description='Relatório de ações'
                textButton='Baixar'
                onClick={() => this.getReports('actions')}
              />
            </Grid.Column>
          </Grid>
        ) : (
          ''
        )}
      </AXCard>
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  auth: state.auth.user
})

const mapDispatchToProps = (dispatch) => ({
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal()),
  showAlert: (type, title, message, details, func) =>
    dispatch(showAlert(type, title, message, details, func))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListReports)
