import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Dot, Register } from './styled'
import { translateStatus } from 'utils/translate'
import { showAlert, showLoading } from 'store/modules/alert/actions'
import { connect } from 'react-redux'

class StatusIndicator extends Component {
  render () {
    const { status, registerUser, phone, clientName } = this.props
    const isOnPhone = !!(phone)
    return (
      <Container>
        <Dot status={status}>{translateStatus(status, false, isOnPhone, clientName)}</Dot>
        {status === 'unavailable'
          ? <Register onClick={() => { registerUser() }}>(Clique para registrar)</Register> : <></>}
      </Container>
    )
  }
}

StatusIndicator.propTypes = {
  status: PropTypes.oneOf(['available', 'paused', 'busy', 'unavailable', 'offline', 'online', 'ending', 'waiting']).isRequired

}

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
  showLoading: (type, title, message, func) => dispatch(showLoading(type, title, message, func))
})

const mapStateToProps = (state) => ({
  token: state.auth.token,
  phone: state.telephony.call.phone,
  clientName: state.telephony.client.name
})

export default connect(mapStateToProps, mapDispatchToProps)(StatusIndicator)
