import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: ${(props) => (props.unit ? '12px' : '0')};
  position: relative;    
`

export const ErrorMsg = styled.span`
  font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
  color: red;
  font-size: 11px;
  margin-left: 4px;
`

export const Label = styled.label`
  font-weight: 600;
  color: #818181;
  margin-left: 4px;
  line-height: 38px;
`

export const Spacer = styled.div`
  height: 17px;
  margin: 4px;
`
