import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, ErrorMsg, Label, Spacer } from './styled'
import { Checkbox } from 'semantic-ui-react'

export default function CheckboxComponent ({ icon, label, error, unit, containerClass, checkboxRef, checkboxClass, ...rest }) {
  const hasError = !!(error && error.trim())

  return (
    <>
      <Wrapper unit={unit} className={containerClass}>
        <ion-icon name={icon} style={iconStyle} />
        <Checkbox hasError={hasError} {...rest} ref={checkboxRef} className={checkboxClass} hasIcon={!!icon}/>
        <Label>{label}</Label><br/>
      </Wrapper>
      {hasError ? <ErrorMsg>{error}</ErrorMsg> : <Spacer />}
    </>

  )
}

const iconStyle = {
  position: 'absolute',
  height: '32px',
  paddingLeft: '10px',
  fontSize: '22px',
  color: 'rgba(0,0,0,1.0)'
}

CheckboxComponent.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  checkboxRef: PropTypes.object,
  unit: PropTypes.bool,
  checkboxClass: PropTypes.string,
  containerClass: PropTypes.string
}
