import { call, put, all, takeLatest, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import operator from 'consumers/operator'

function * login (action) {
  yield put({ type: 'SET_LOADING', payload: { loading: true } })
  yield put({ type: 'RESET_STATE' })
  const { email, password } = action.payload
  const emailValidator = {
    isValid: true,
    message: ''
  }
  const passwordValidator = {
    isValid: true,
    message: ''
  }
  if (!email || !email.trim()) {
    emailValidator.isValid = false
    emailValidator.message = 'Insira um email!'
  }
  if (!password || !password.trim()) {
    passwordValidator.isValid = false
    passwordValidator.message = 'Insira uma senha!'
  }
  const data = { passwordValidator, emailValidator }
  if (!passwordValidator.isValid || !emailValidator.isValid) {
    yield put({
      type: 'ALERT',
      data: {
        config: {
          type: 400,
          text: 'Email ou/e Senha incorretos.',
          title: 'Login Inválido'
        }
      }
    })
    yield put({ type: 'SIGNIN_FAILED', data })
    return
  }
  try {
    const response = yield call(
      operator.login,
      action.payload.email,
      action.payload.password
    )
    yield put({
      type: 'SIGNIN_SUCCEEDED',
      data: {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        sessionTimeout: response.data.accessTokenExpiresIn,
        user: response.data.user,
        logged: true
      }
    })
    yield put({
      type: 'TELEPHONY_SET_LAST_CALL',
      payload: { lastCall: Date.now() }
    })
    response.data.user.level === 'admin' ? yield put(push('/admin/operadores'))
      : yield put(push('/admin/home'))
  } catch (e) {
    const emailValidator = {
      isValid: true,
      message: ''
    }

    if (e.invalid && Array.isArray(e.invalid)) {
      e.invalid.forEach((field) => {
        if (field.name === 'email') {
          emailValidator.isValid = false
          emailValidator.message = 'Email inválido!'
        }
      })
      const data = { emailValidator }
      yield put({ type: 'SIGNIN_FAILED', data })
    }
    console.log('msg', e.message)
    if (e.message && e.message !== 'ERR_INTERNET_DISCONNECTED') {
      yield put({
        type: 'ALERT',
        data: {
          config: {
            type: 400,
            text: e.message,
            title: 'Problemas com o login'
          }
        }
      })
    }
    if (e.message && e.message === 'ERR_INTERNET_DISCONNECTED') {
      yield put({
        type: 'ALERT',
        data: {
          config: {
            type: 0,
            html: '<img src="imgs/no-connection.png" width="65%"/>',
            title: 'Você está desconectado(a)!'
          }
        }
      })
    }
    yield put({ type: 'SIGNIN_FAILED', message: e.message })
  }
}

function * resetPassword (action) {
  yield put({ type: 'SET_LOADING', payload: { loading: true } })
  const { email } = action.payload
  const emailValidator = {
    isValid: true,
    message: ''
  }

  if (!email || !email.trim()) {
    emailValidator.isValid = false
    emailValidator.message = 'Insira um email!'
  }

  const data = { emailValidator }
  if (!emailValidator.isValid) {
    yield put({
      type: 'ALERT',
      data: {
        config: {
          type: 'warning',
          text: 'Insira um email válido.',
          title: 'Email Inválido'
        }
      }
    })
    yield put({ type: 'SIGNIN_FAILED', data })
    return
  }
  try {
    const response = yield call(operator.resetPassword, action.payload.email)
    yield put({
      type: 'ALERT',
      data: {
        config: {
          type: 'success',
          text: response.message,
          title: 'Email enviado!'
        }
      }
    })
    yield put({ type: 'PWD_RESET_SUCCEEDED' })
  } catch (e) {
    if (e.message) {
      yield put({
        type: 'ALERT',
        data: {
          config: {
            type: 400,
            text: e.message,
            title: 'Problemas ao tentar resetar senha'
          }
        }
      })
    }

    yield put({ type: 'SIGNIN_FAILED', message: e.message })
  }
}

function * clearSession (action) {
  const token = yield select((state) => state.auth.token)

  yield put({ type: 'CLEAR_SESSION' })
  yield put({ type: 'CLEAR_PATIENT' })

  yield call(operator.logout, token)

  yield put(push('/'))
}

function * checkSession (action) {
  const resources = yield select((state) => state.resources)

  const token = yield select((state) => state.auth.token)
  const refreshToken = yield select((state) => state.auth.refreshToken)
  if (token === null) return
  const sessionTimeout =
    Number(yield select((state) => state.auth.sessionTimeout)) * 60000
  const lastTokenRequest =
    Number(localStorage.getItem('@Agenda/lastTokenRequest')) || 0
  const currentDate = Date.now()

  if (!resources.pauseReasons || resources.pauseReasons.length === 0) {
    yield put({ type: 'GET_PAUSE_REASONS' })
  }

  const lastCall = Number(localStorage.getItem('@Agenda/lastCall'))
  yield put({ type: 'TELEPHONY_SET_LAST_CALL', payload: { lastCall: lastCall } })
  const status = yield call(operator.status, token)
  yield put({ type: 'SET_STATUS', payload: { status } })
  if (lastTokenRequest <= 0 || sessionTimeout <= 0) {
    yield put({ type: 'CLEAR_AND_REDIRECT' })
    return
  }
  if (currentDate - lastTokenRequest >= sessionTimeout) {
    console.log(currentDate, lastTokenRequest, sessionTimeout)
    yield put({ type: 'CLEAR_AND_REDIRECT' })
    yield put({
      type: 'ALERT',
      data: {
        config: {
          type: 400,
          text: 'Sessão Expirada',
          title: 'Faça login novamente.'
        }
      }
    })
  } else if (currentDate - lastTokenRequest <= sessionTimeout * 0.8) {
  } else {
    try {
      const response = yield call(operator.renewToken, token, refreshToken)
      yield put({
        type: 'RENEW_TOKEN_SUCCEEDED',
        payload: { token: response.token }
      })
      localStorage.setItem('@Agenda/lastTokenRequest', Date.now())
    } catch (err) {
      if (!err.response) {
        return
      }
      if (err.code === 404 || err.code === 403) {
        yield put({ type: 'CLEAR_AND_REDIRECT' })
        yield put({
          type: 'ALERT',
          data: {
            config: {
              type: 400,
              text: 'Sessão Expirada',
              title: 'Faça login novamente.'
            }
          }
        })
      }
    }
  }
}

function * setPause (action) {
  const { token, pauseId } = action.payload
  try {
    yield call(operator.pause, pauseId, token)
    yield put({ type: 'SET_STATUS', payload: { status: 'paused' } })
  } catch (e) {
    yield put({
      type: 'ALERT',
      data: {
        config: {
          type: 400,
          title: 'Seu ramal não foi pausado!',
          text: e.message,
          details: e.technical
        }
      }
    })
  }
}

function * setResume (action) {
  const { token } = action.payload

  yield call(operator.resume, token)
  yield put({ type: 'SET_STATUS', payload: { status: 'unavailable' } })
  localStorage.setItem('@Agenda/lastCall', Date.now())
  yield put({
    type: 'TELEPHONY_SET_LAST_CALL',
    payload: { lastCall: Date.now() }
  })
}

export default all([
  takeLatest('SIGNIN_REQUESTED', login),
  takeLatest('FORGOT_PASSWORD', resetPassword),
  takeLatest('CLEAR_AND_REDIRECT', clearSession),
  takeLatest('CHECK_SESSION', checkSession),
  takeLatest('SET_PAUSE', setPause),
  takeLatest('SET_RESUME', setResume)
])
