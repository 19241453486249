import styled from 'styled-components'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(220px, 1fr) );
  justify-items: center;
  grid-gap: 1rem;

`

export const Loading = styled.div`
  min-height: 300px;
  align-items: center;

`

const subtitleColors = {
  online: 'green',
  paused: 'blue',
  offline: 'gray'
}

export const Subtitle = styled.div`
width: 13px;
height: 13px;
background-color: ${props => subtitleColors[props.type || 'offline']};
border-radius: 10px;
margin: 0px 10px;
`
