import React, { Component } from 'react'
import { Icon, Table, Button } from 'semantic-ui-react'
import PaginationShort from 'components/Pagination'

import { connect } from 'react-redux'
import { showAlert, closeAllSwal, setSwalLoading } from 'store/modules/alert/actions'
import PropTypes from 'prop-types'
import { dateToBrSmall } from 'utils/formatDate'

class TableClass extends Component {
  deleteItem = async (id) => {
    const { token, parentCallback, apiFunc, showAlert } = this.props
    parentCallback({ loadDelete: id })
    try {
      const response = await apiFunc.delete(id, token)
      showAlert(response.code, 'Sucesso!', response.message)
    } catch (err) {
      showAlert(err.code, 'Atenção', err.message)
    }    
    parentCallback({ loadDelete: false })
  }

  updateItem = async (id, value) => {
    const { token, parentCallback, apiFunc, showAlert } = this.props    

    parentCallback({ loadUpdate: id })
    try {      
      const response = await apiFunc.update(id, token, {
        active: !value
      })
      showAlert(response.code, 'Sucesso!', response.message, '', () => parentCallback({ loadUpdate: false }))
    } catch (err) {
      showAlert('warning', 'Atenção', err.message, '', () => parentCallback({ loadUpdate: false }))
    }
    parentCallback({ loadUpdate: false })           
  }

  changePage = (event) => {
    const { changePage, body } = this.props
    const page = event.target.getAttribute('value')
    if (event && (page <= body.totalPages || page === body.totalPages)) {
      changePage(page)
    }
  }

  render () {
    const { loadDelete, loadUpdate, body, cantDelete } = this.props
    
    return (
      <>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Ativo</Table.HeaderCell>
              <Table.HeaderCell>Ultima modificação</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {body ? body.map(item => {
              return (
                <Table.Row key={item._id}>
                  <Table.Cell width='4'>{item.name}</Table.Cell>
                  <Table.Cell
                    width='1'
                    textAlign='center'
                  >
                    <Icon
                      name='circle'
                      color={(item.active === true) ? 'green' : 'red'}
                    />
                    {(item.active === true) ? 'Ativo' : 'Inativo'}
                  </Table.Cell>
                  <Table.Cell width='1'>{dateToBrSmall(item.updatedAt)}</Table.Cell>
                  <Table.Cell width='2'>
                    <Button
                      loading={(loadUpdate === item._id)}
                      onClick={() => this.updateItem(item._id, item.active)}>
                      {(item.active === true) ? 'Desativar' : 'Ativar'}
                    </Button>
                    <Button
                      loading={(loadDelete === item._id)}
                      disabled={cantDelete}
                      onClick={() => this.deleteItem(item._id)}
                    >
                    Deletar
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )
            }) : <></>}
          </Table.Body>
        </Table>
        <PaginationShort
          onPageChange={(e) => this.changePage(e)}
          totalPages={body ? parseInt(body.totalPages) : 1}
          activePage={body ? parseInt(body.page) : 1}
        />
      </>
    )
  }
}

TableClass.propTypes = {
  token: PropTypes.string,
  showAlert: PropTypes.func
}

const mapStateToProps = (state) => ({
  token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
  setSwalLoading: () => dispatch(setSwalLoading()) ,
  closeAllSwal: () => dispatch(closeAllSwal())
})

export default connect(mapStateToProps, mapDispatchToProps)(TableClass)
