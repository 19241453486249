import React from 'react'
import { Card, Popup } from 'semantic-ui-react'
import propTypes from 'prop-types'
import { WrapperContent, Header, Content, Item, Totals, ContentTotal, ContentTotalBody, SubTotals, Border, LineBody, TotalsNumber, ContentChannel, ItemIcon, LineHeaderBody } from './styled'

import { jeielColors } from 'utils/colors'

export default function CardOmniValues ({
  description,
  header,
  meta,
  textButton,
  data,
  active,
  dashClick,
  total,
  totalConfirmed,
  totalNotConfirmed
}) {
  const icons = [
    <Popup
      content="URA"
      key={1}
      trigger={<ItemIcon><ion-icon name="logo-reddit" style={{ color: 'black' }} /></ItemIcon>}
    />,
    <Popup
      content="WHATSAPP"
      key={2}
      trigger={<ItemIcon><ion-icon name="logo-whatsapp" style={{ color: jeielColors.musgo }} /></ItemIcon>}
    />,
    <Popup
      content="EMAIL"
      key={3}
      trigger={<ItemIcon><ion-icon icon="mail-sharp" style={{ color: jeielColors.orange }} /></ItemIcon>}
    />,
    <Popup
      content="OPERADOR"
      key={4}
      trigger={<ItemIcon><ion-icon icon="headset-outline" style={{ color: jeielColors.pink }} /></ItemIcon>}
    />
  ]

  const sums = {
    percentage: 0,
    total: 0,
    yes: 0,
    no: 0
  }

  return (
    <>
      <Card fluid style={cardStyle} active={active}>

        <WrapperContent>
          <Header><strong>{header}</strong></Header>
          <Card.Meta>{meta}</Card.Meta>

          <Content>

            <ContentTotal>
              <Border>
                <Totals>Total</Totals>
                <TotalsNumber>{total}</TotalsNumber>
              </Border>

              <ContentTotalBody>

                <LineBody>
                  <SubTotals>Confirmados </SubTotals>
                  <SubTotals> {totalConfirmed}</SubTotals>
                </LineBody>

                <LineBody>
                  <SubTotals>Sem confirmação</SubTotals>
                  <SubTotals>{totalNotConfirmed}</SubTotals>
                </LineBody>
              </ContentTotalBody>
            </ContentTotal>

            <ContentChannel>
              <LineHeaderBody>
                <ItemIcon></ItemIcon>
                <Item>Total</Item>
                <Item>%</Item>
                <Item><ion-icon name={'checkmark-circle-outline'} style={{ color: 'darkgreen' }}/>
                </Item><Item><ion-icon name={'close-circle-outline'} style={{ color: 'red' }} /></Item>
              </LineHeaderBody>

              {data.map((item, index) => {
                const auxPercentage = Math.round(((item.yes + item.no) * 100) / (total || 1))
                const auxTotal = item.yes + item.no
                sums.yes += item.yes
                sums.no += item.no
                sums.percentage += auxPercentage
                sums.total += auxTotal

                return (
                  <LineBody key={index}>
                    {icons[index]}
                    <Item>{auxTotal}</Item>
                    <Item>{auxPercentage}</Item>
                    <Item>{item.yes}</Item>
                    <Item>{item.no}</Item>
                  </LineBody>
                )
              })}

              <LineHeaderBody>
                <ItemIcon></ItemIcon>
                <Item><strong>{sums.total}</strong></Item>
                <Item>{sums.percentage}</Item>
                <Item>{sums.yes}</Item>
                <Item>{sums.no}</Item>
              </LineHeaderBody>
            </ContentChannel>
          </Content>
        </WrapperContent>
      </Card>
    </>
  )
}

CardOmniValues.propTypes = {
  description: propTypes.string,
  header: propTypes.string,
  meta: propTypes.string,
  textButton: propTypes.string,
  onClick: propTypes.func
}

const iconStyle = {
  height: '100%',
  fontSize: '70px',
  color: 'black'
}

const cardStyle = {
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  height: '100%'
}
