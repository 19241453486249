import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Input, ErrorMsg, Label, Spacer } from './styled'

export default function IconInput ({ icon, label, error, unit, containerClass, inputRef, inputClass, ...rest }) {
  const hasError = !!(error && error.trim())

  return (
    <Wrapper unit={unit} className={containerClass}>
      <Label>{label}</Label><br/>
      <ion-icon name={icon} style={iconStyle} />
      <Input hasError={hasError} {...rest} ref={inputRef} className={inputClass} hasIcon={!!icon}/>
      {hasError ? <ErrorMsg>{error}</ErrorMsg> : <Spacer />}
    </Wrapper>
  )
}

const iconStyle = {
  position: 'absolute',
  height: '32px',
  paddingLeft: '10px',
  fontSize: '22px',
  color: 'rgba(0,0,0,1.0)'
}

IconInput.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  inputRef: PropTypes.object,
  unit: PropTypes.bool,
  inputClass: PropTypes.string,
  containerClass: PropTypes.string
}
