import React, { Component } from 'react'
import 'uikit'

import { connect } from 'react-redux'
import { showAlert } from 'store/modules/alert/actions'
import PropTypes from 'prop-types'

import Card from 'components/Card'
import operator from 'consumers/operator'
import CreatePerson from 'components/CreatePerson'
import OperatorsList from 'components/OperatorsList'
import OperatorDetails from 'components/OperatorDetails'
import { Switch, Route } from 'react-router-dom'
import { push } from 'connected-react-router'

class Operators extends Component {
  constructor (props) {
    super(props)
    this.state = {
      errorMessages: {},
      loading: false
    }
  }

  createOperator = async (data) => {
    const { token, showAlert } = this.props
    this.setState({ loading: true })
    try {
      const response = await operator.create(token, data)
      showAlert(response.code, 'Sucesso!', 'O operador foi criado.')
    } catch (err) {
      if (err.invalid) {
        const errorMessages = {}
        err.invalid.forEach((field) => {
          errorMessages[field.name] = field.message
        })
        this.setState({ errorMessages })
        showAlert(400, 'Atenção', err.message, err.technical)
      }
    }

    this.setState({ loading: false })
  }

  // updateOperator = async (id, data) => {
  //   const { token, showAlert, push } = this.props

  //   this.setState({ loading: true })
  //   try {
  //     const response = await operator.update(token, id, data)
  //     showAlert(response.code, 'Sucesso!', response.message, () => {
  //       push('/admin/operadores/listar')
  //     })
  //   } catch (err) {
  //     if (err.invalid) {
  //       const errorMessages = {}
  //       err.invalid.forEach((field) => {
  //         errorMessages[field.name] = field.message
  //       })
  //       this.setState({ errorMessages })
  //       showAlert('warning', 'Atenção', err.message)
  //     }
  //   }
  //   this.setState({ loading: false })
  // }

  toggleCreate = () => {
    if (this.props.router.location.pathname !== '/admin/operadores/listar') {
      this.props.push('/admin/operadores/listar')
    } else {
      this.props.push('/admin/operadores/criar')
    }
  }

  render () {
    const { loading } = this.state
    const { pathname } = this.props.router.location
    const hideCreate = (pathname === '/admin/operadores/listar')
    const isRoot = pathname === '/admin/operadores'
    if (isRoot) {
      this.props.push('/admin/operadores/listar')
    }
    return (
      <>
        <Switch>
          <Route
            path={'/admin/operadores/criar'}
            render={(props) => (
              <Card
                title='Operadores'
                sideButtonPress={this.toggleCreate}
                sideButtonText={hideCreate ? 'Cadastrar Novo Operador' : 'Voltar'}>
                <CreatePerson
                  onSubmit={this.createOperator}
                  invalidFields={this.state.errorMessages}
                  type='operator'
                  loading={loading}
                  {...props}
                />
              </Card>
            )}
            key='opCreate'
          />
          <Route
            path={'/admin/operadores/listar'}
            render={(props) => (
              <Card
                title='Operadores'
                sideButtonPress={this.toggleCreate}
                sideButtonText={hideCreate ? 'Cadastrar Novo Operador' : 'Voltar'}>
                <OperatorsList />
              </Card>
            )}
            key='opList'
          />
          {<Route
            path={'/admin/operadores/:id'}
            component={OperatorDetails}
            key='opGet'
          />}
        </Switch>
      </>
    )
  }
}

Operators.propTypes = {
  token: PropTypes.string,
  showAlert: PropTypes.func,
  push: PropTypes.func,
  router: PropTypes.object
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  router: state.router
})

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
  push: (path) => dispatch(push(path))
})

export default connect(mapStateToProps, mapDispatchToProps)(Operators)
