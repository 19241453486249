import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { getTabulations, tabulateCall } from 'store/modules/telephony/actions'
import { connect } from 'react-redux'
import { Dropdown, Icon } from 'semantic-ui-react'
import { showInput, showConfirmation, showAlert } from 'store/modules/alert/actions'
import { Wrapper, TabulationText } from './styled'
import missedCalls from 'consumers/missedCalls'

const DropdownTrigger = (
  <Wrapper>
    <Icon name='table'/>
    <TabulationText>Tabular</TabulationText>
  </Wrapper>
)

const Tabulation = ({
  call,
  tabulations,
  logged,
  getTabulations,
  tabulateCall,
  showInput,
  showAlert,
  showConfirmation,
  token,
  status,
  patientId
}) => {
  useEffect(() => {
    if (Array.isArray(tabulations) && tabulations.length <= 0) {
      getTabulations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged])

  const tabulate = (text, tabulationId, callId) => {
    tabulateCall(text, tabulationId, callId, patientId)
    if (call.missedCallId) {
      showConfirmation(20, 'Finalizar',
        'Deseja finalizar o atendimento para esta chamada perdida?', () => {
          missedCalls.finish(token, call.missedCallId)
        })
    }
  }

  if ((status !== 'ending')) return <div />
  return (
    <Dropdown trigger={DropdownTrigger} icon={null}>
      <Dropdown.Menu>
        {tabulations &&
          tabulations.map((tabulation) => (
            <Dropdown.Item
              key={tabulation._id}
              onClick={() => {
                console.log(tabulation)
                if(tabulation.patient && !patientId){
                  showAlert('warning', "Paciente não informado!", "Cadastre ou selecione um paciente.")
                }else{
                  showInput('', 'Comentário', 'Insira um comentário para a tabulação.', (text) => tabulate(text, tabulation._id, call.id))
                }
              }}>
              {tabulation.name}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

Tabulation.propTypes = {
  call: PropTypes.object,
  tabulations: PropTypes.arrayOf(PropTypes.object),
  logged: PropTypes.bool,
  tabulateCall: PropTypes.func,
  getTabulations: PropTypes.func

}

const mapStateToProps = (state) => {
  return {
    logged: state.auth.logged,
    status: state.auth.user.status,
    call: state.telephony.call,
    patient: state.telephony.patient,
    sessionTimeout: state.telephony.sessionTimeout,
    tabulations: state.telephony.tabulations,
    token: state.auth.token,
    patientId: state.patient.id
  }
}

const mapDispatchToProps = (dispatch) => ({
  getTabulations: (type, title, text, func) =>
    dispatch(getTabulations(type, title, text, func)),
  tabulateCall: (text, tabulationId, callId, patientId) => dispatch(tabulateCall(text, tabulationId, callId, patientId)),
  showInput: (type, title, text, func) => dispatch(showInput(type, title, text, func)),
  showConfirmation: (type, title, text, func) => dispatch(showConfirmation(type, title, text, func)),
  showAlert: (type, title, text, func) => dispatch(showAlert(type, title, text, func))
})

export default connect(mapStateToProps, mapDispatchToProps)(Tabulation)
