import { call, put, all, takeLatest, select, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import resources from 'consumers/resources'
import telephony from 'consumers/telephony'
import patient from 'consumers/patient'

function * getTabulations (action) {
  const token = yield select((state) => state.auth.token)
  try {
    const tabulations = yield call(
      resources.tabulations,
      token
    )
    yield put({
      type: 'TELEPHONY_SET_TABULATIONS',
      payload: {
        tabulations
      }
    })
  } catch (e) {
    yield put({
      type: 'TELEPHONY_CHECK_FAILED'
    })
  }
}

function * checkTelephony (action) {
  const token = yield select((state) => state.auth.token)
  const status = yield select((state) => state.auth.user.status)
  const clientWasRequested = yield select((state) => state.telephony.client.wasRequested)
  const fetchingTelephony = yield select((state) => state.telephony.fetching)

  if (token === null || fetchingTelephony) return
  yield put({
    type: 'TELEPHONY_SET_FETCHING',
    payload: { fetching: true }
  })
  try {
    const calls = yield call(
      telephony.calls,
      token
    )
    if (calls.call) {
      if (status !== 'available') {
        yield put({
          type: 'TELEPHONY_SET_LAST_CALL',
          payload: { lastCall: Date.now() }
        })
      }
      yield put({
        type: 'TELEPHONY_CALL_SUCCEEDED',
        payload: {
          activeCall: calls.activeCall
        }
      })
      if (!clientWasRequested) {
        try {
          const register = yield call(
            telephony.client,
            token,
            calls.activeCall.phone
          )
          if (register) {
            yield put({
              type: 'TELEPHONY_SET_CLIENT',
              payload: {
                client: { name: register.client.name, cpf: register.client.cpf, wasRequested: true }
              }
            })
            yield put({
              type: 'CLEAR_PATIENT'
            })

            try {
              const response = yield call(
                patient.getByCpf,
                token,
                register.client.cpf
              )

              const user = response.data.data
              const data = {
                id: user.id,
                cpf: user.cpf,
                name: user.name,
                birthdate: new Date(user.birthdate),
                gender: user.gender,
                email: user.email,
                cellphone: user.cellphone,
                secondCellphone: user.secondCellphone,
                whatsapp: user.whatsapp,
                telephone: user.telephone,
                contactMethod: user.contactMethod,
                register: true
              }
              yield put({
                type: 'TELEPHONY_SET_FETCHING',
                payload: { fetching: false }
              })
              yield put({ type: 'SET_PATIENT', payload: data })
            } catch (err) {
              const data = {
                cpf: register.client.cpf,
                name: register.client.name,
                register: false
              }
              if (String(register.number).length === 10) {
                data.telephone = register.number
              } else {
                data.cellphone = register.number
              }
              yield put({ type: 'SET_PATIENT', payload: data })
              yield put({
                type: 'ALERT',
                data: {
                  config: {
                    icon: 'info',
                    text: 'Clique em "OK" para prosseguir ao cadastro.',
                    title: 'Paciente não encontrado'
                  }
                }
              })
              yield put({
                type: 'TELEPHONY_SET_FETCHING',
                payload: { fetching: false }
              })
            }
            yield put({
              type: 'TELEPHONY_SET_FETCHING',
              payload: { fetching: false }
            })
          }
        } catch (err) {
          if (err.message && err.code === 404) {
            yield put({
              type: 'TELEPHONY_SET_CLIENT',
              payload: {
                client: { name: null, cpf: null, wasRequested: true }
              }
            })
            yield put({
              type: 'CLEAR_PATIENT'
            })
            yield put({
              type: 'TELEPHONY_SET_FETCHING',
              payload: { fetching: false }
            })
            const data = {
              register: false
            }
            if (String(calls.activeCall.phone).length === 10) {
              data.telephone = calls.activeCall.phone
            } else if (String(calls.activeCall.phone).length > 10) {
              const cellphone = String(calls.activeCall.phone)
              data.cellphone = cellphone.length > 11 ? cellphone.slice(0, 11) : cellphone
            }
            yield put({ type: 'SET_PATIENT', payload: data })
            yield put({
              type: 'ALERT',
              data: {
                config: {
                  icon: 'info',
                  text: 'Este telefone ainda não cadastrado no sistema, clique em "OK" para prosseguir ao cadastro.',
                  title: 'Paciente não encontrado'
                }
              }
            })
          } else {
            yield put({
              type: 'TELEPHONY_SET_CLIENT',
              payload: {
                client: { name: null, cpf: null, wasRequested: false }
              }
            })
            yield put({
              type: 'TELEPHONY_SET_FETCHING',
              payload: { fetching: false }
            })
          }
        }        
      }
      yield put({
        type: 'TELEPHONY_SET_FETCHING',
        payload: { fetching: false }
      })
    } else {
      yield put({
        type: 'TELEPHONY_CALL_FAILED'
      })
      yield put({
        type: 'TELEPHONY_SET_FETCHING',
        payload: { fetching: false }
      })
    }
  } catch (e) {
    yield put({
      type: 'TELEPHONY_CALL_FAILED'
    })
    yield put({
      type: 'TELEPHONY_SET_FETCHING',
      payload: { fetching: false }
    })
  }
}

function * tabulateCall (action) {
  const token = yield select((state) => state.auth.token)
  const { text, tabulationId, callId, patientId } = action.payload
  try {
    yield call(
      telephony.tabulate,
      token,
      text,
      tabulationId,
      callId,
      patientId || undefined
    )
    yield put({
      type: 'CHECK_SESSION'
    })
    yield put({
      type: 'TELEPHONY_TABULATION_SUCCEEDED'
    })
  } catch (e) {
    yield put({
      type: 'ALERT',
      data: {
        config: {
          type: e.code,
          text: e.message,
          title: 'Paciente não informado!'
        }
      }
    })
  }
}

function * makeCall (action) {
  const token = yield select((state) => state.auth.token)
  const { phone } = action.payload
  try {
    yield call(
      telephony.makeCall,
      token,
      phone
    )
    yield put({
      type: 'CHECK_SESSION'
    })
  } catch (e) {

  }
}

export default all([
  takeLatest('GET_TABULATIONS', getTabulations),
  takeEvery('CHECK_TELEPHONY', checkTelephony),
  takeLatest('TABULATE_CALL', tabulateCall),
  takeLatest('TELEPHONY_MAKE_CALL', makeCall)
])
