import api from 'services/api'

const operator = {
  login: async (email, password) => {
    try {
      const response = await api.post('login', { email, password })
      return response.data
    } catch (e) {
      if (e.name === 'ERR_INTERNET_DISCONNECTED') {
        throw e
      }
      if (!e.response) {
        const error = new Error('CONNECTION_FAILED')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  },
  logout: async (token) => {
    try {
      const response = await api.post(
        'logout',
        {},
        { headers: { Authorization: token } }
      )
      return response.data
    } catch (e) {
      if (!e.response) {
        console.log('Erro ao deslogar')
        return { message: 'Logout Error' }
      }
    }
  },
  create: async (token, data) => {
    try {
      const response = await api.post('operators', data, {
        headers: { Authorization: token }
      })
      return response.data
    } catch (e) {
      throw e.response.data
    }
  },
  update: async (token, id, data) => {
    try {
      const response = await api.patch(`operators/${id}`, data, {
        headers: { Authorization: token }
      })
      return response.data
    } catch (e) {
      throw e.response.data
    }
  },
  getById: async (token, id, range) => {
    if (!range) range = 'day'
    try {
      const response = await api.get(`operators/${id}/?range=${range}`, {
        headers: { Authorization: token }
      })
      return response.data
    } catch (e) {
      throw e.response.data
    }
  },
  resetPassword: async (email) => {
    try {
      const response = {
        data: { message: '_TEST_ Um email foi enviado para ' + email }
      }
      return response.data
    } catch (e) {
      throw e.response.data
    }
  },
  list: async (token) => {
    try {
      const response = await api.get('operators', {
        headers: { Authorization: token }
      })
      const ordered = response.data.data.docs.sort((a, b) => {
        if (Number(a.ramal) > Number(b.ramal)) {
          return 1
        }
        if (Number(a.ramal) < Number(b.ramal)) {
          return -1
        }
        // a must be equal to b
        return 0
      })
      response.data.data.docs = ordered
      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  },
  pause: async (pauseReasonId, token) => {
    try {
      const response = await api.post(
        'pauses',
        { pauseReasonId },
        {
          headers: { Authorization: token }
        }
      )
      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  },
  resume: async (token) => {
    try {
      const response = await api.patch(
        'pauses',
        {},
        {
          headers: { Authorization: token }
        }
      )
      return response.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  },
  renewToken: async (token, refreshToken) => {
    try {
      const response = await api.post(
        'tokens',
        { token: refreshToken },
        {
          headers: { Authorization: token }
        }
      )
      return response.data.data
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  },
  status: async (token) => {
    try {
      const response = await api.get(
        'status',
        {
          headers: { Authorization: token }
        }
      )
      const status = response.data.data.status
      return status
    } catch (e) {
      return 'offline'
    }
  },
  report: async (token, range) => {
    try {
      const response = await api.get(
        `operators?report&range=${range}`,
        { headers: { Authorization: token } }
      )
      return response.data
    } catch (err) {
      if (!err) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw err.response.data
      }
    }
  },
  activate: async (token, userId) => {
    try {
      const response = await api.patch(
        'users/activate', {
          userId
        },
        {
          headers: { Authorization: token }
        }
      )
      return response.data
    } catch (err) {
      if (!err) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw err.response.data
      }
    }
  },
  deactivate: async (token, userId) => {
    try {
      const response = await api.patch(
        'users/deactivate', {
          userId
        },
        {
          headers: { Authorization: token }
        }
      )
      return response.data
    } catch (err) {
      if (!err) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw err.response.data
      }
    }
  }
}

export default operator
