import styled from 'styled-components'

export const EasyPie = styled.div`
 
  border: 16px solid #f3f3f3; /* Light grey */
   /* Blue */]
  border-left: 18px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
`

export const Button = styled.h2`
  padding: 5px;
  background: none;
  font-size: 21px;
  color: #010101;
  font-weight: bold;
  /* border: 2px solid #d1d1d1;
  border-radius: 10px; */

  ${props => props.active ? `
    color: #fff;
  ` : ''}

`
