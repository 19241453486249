import api from 'services/api'

const notification = {
  list: async (token) => {
    try {
      const response = await api.get('notifications', {
        headers: { Authorization: token }
      })
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },

  create: async (token, data) => {
    try {
      const response = await api.post('notifications', data, {
        headers: { Authorization: token }
      })
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },

  readConfirm: async (token, id) => {
    try {
      const response = await api.patch('notifications/read/confirm',
        {notificationId: id},
        {
          headers: { Authorization: token }
        })
      return response.data
    } catch (err) {
      const error = new Error('CONNECTION_ERROR')
      error.message = 'Não foi possível se comunicar com o servidor'
      throw error
    }
  },

  deleteAll: async (token) => {
    try {
      const response = await api.delete('notifications',
        {
          headers: { Authorization: token }
        })
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.massage = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err
    }
  },

  delete: async (token, id) => {
    try {
      const response = await api.delete(`notifications/delete/${id}`,
        {
          headers: { Authorization: token }
        })
      return response.data
    } catch (err) {
      if (!err.response) {
        const error = new Error('CONNECTION_ERROR')
        error.massage = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err
    }
  }
}

export default notification
