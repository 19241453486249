import styled from 'styled-components'

export const Wrapper = styled.div`            
  border-left: 15px solid ${props => props.color ? props.color : '#dfdfdf'}; 
  padding-left: 15px;
`

export const Title = styled.h4`            
  font-size: 17px;
  font-weight: bold;
`

export const Description = styled.p`            
  font-size: 15px;
`
