import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Notification from 'consumers/notification'
import NotificationModal from 'components/NotificationModal'
import NotificationButton from 'components/NotificationButton'
import { setNotification } from 'store/modules/notification/actions'

import { Wrapper, Text, CircleInfo, Container, Title, ShowMore } from './styled'
import { showConfirmation, showAlert, showLoading } from 'store/modules/alert/actions'
import { Dropdown, Icon } from 'semantic-ui-react'

function BellButon ({ notifications, token, setNotification }) {
  const [open, setOpen] = useState(false)
  const [indexState, setIndexState] = useState(0)

  const newNotification = () => {
    if (!notifications) return 0
    const countNotify = notifications.filter(element => element.read === false)
    return countNotify.length
  }

  const close = () => setOpen(false)

  const show = (newIndexState) => () => {
    setOpen(true)
    setIndexState(newIndexState)
  }

  const handleReadConfirm = async (index) => {
    const notification = notifications[index]
    try {
      await Notification.readConfirm(token, notification._id)
      setNotification(token)
    } catch (err) {
      if (!err.message) {
        showAlert(500, 'Atenção', 'Falha ao se comunicar com o servidor!')
      } else {
        showAlert(400, 'Atenção', err.message, err.technical)
      }
    }
  }

  const DropdownTrigger = () => (
    <Wrapper>
      <Icon name='bell outline' size='large' style={{ gridColumn: '1/5', gridRow: '1' }}/>
      { newNotification() > 0
        ? <CircleInfo>
          <Text>{newNotification()}</Text>
        </CircleInfo> : ''}
    </Wrapper>
  )

  return (
    <>
      <Dropdown trigger={<DropdownTrigger />} icon={null}>
        <Dropdown.Menu style={{}}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '5px', backgroundColor: '#f6f6f6' }}>
            <Title>Notificações</Title>
            <Container>
              {notifications
                ? notifications.map((notification, index) => {
                  if (index < 5) {
                    return (
                      <NotificationButton
                        body={notification.body}
                        title={notification.title}
                        key={index}
                        read={notification.read}
                        onClick={async () => {
                          if (notification.read === false) {
                            handleReadConfirm(index)
                          }
                          setIndexState(index)
                          setOpen(true)
                        }}
                      />
                    )
                  }
                  return ''
                })
                : ''
              }
            </Container>
            <ShowMore onClick={show()}>Mostrar Todas</ShowMore>
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <NotificationModal open={open} onClose={close} indexActive={indexState} handleReadConfirm={handleReadConfirm} />

    </>
  )
}

BellButon.propTypes = {
  notifications: PropTypes.array,
  token: PropTypes.string,
  setNotification: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notification.notifications,
    token: state.auth.token
  }
}

const mapDispatchToProps = (dispatch) => ({
  showConfirmation: (type, title, text, func) =>
    dispatch(showConfirmation(type, title, text, func)),
  showAlert: (type, title, message, details, func) =>
    dispatch(showAlert(type, title, message, details, func)),
  showLoading: (type, title, message, func) =>
    dispatch(showLoading(type, title, message, func)),
  setNotification: (token) => dispatch(setNotification(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(BellButon)
