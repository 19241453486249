import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { showAlert, setSwalLoading, closeAllSwal } from 'store/modules/alert/actions'

import CreateScheduling from 'components/CreateScheduling'

import schedule from 'consumers/schedule'
import ListRecord from 'components/ListRecord'

import Card from 'components/Card'

export class ScheduleDetails extends Component {
  constructor (props) {
    super(props)

    this.state = {
      errorMessages: {},
      calls: null
    }
  }

  getSchedule = async () => {
    const { token, showAlert } = this.props
    const { id } = this.props.match.params

    try {
      const response = await schedule.getById(id, token)
      const scheduling = response.data

      this.setState({ calls: scheduling.calls })
    } catch (err) {
      if (!err.response) {
        return showAlert(500, 'Atenção!', 'Falha ao se comunicar com o servdor!')
      }

      showAlert(err.code, 'Atenção!', err.message)
    }
  }

  updateSchedule = async (data) => {
    const { token, showAlert, push, setSwalLoading } = this.props
    const { id } = this.props.match.params

    try {
      setSwalLoading()
      const response = await schedule.update(id, data, token)
      showAlert(response.code, 'Sucesso!', response.message, () => {
        push('/admin/agendamentos/listar/')
      })
    } catch (err) {
      if (!err) {
        showAlert(503, 'Atenção', 'Não é possível se conectar com o servidor')
      } else {
        if (err.invalid) {
          const errorMessages = {}
          err.invalid.forEach((field) => {
            errorMessages[field.name] = field.message
          })
          this.setState({ errorMessages })
          showAlert('warning', 'Atenção', err.message)
        }
        showAlert(err.code, 'Atenção', err.message)
      }
    }
  }

  componentDidMount () {
    this.getSchedule()
  }

  render () {
    const { calls } = this.state

    return (
      <>
        <Card
          title={'Editar agendamento'}>
          <CreateScheduling
            onUpdate={this.updateSchedule}
            errorMessages={this.state.errorMessages}

            {...this.props}
          />
        </Card>

        {calls === null || calls.length > 0
          ? <Card
            title={'Gravações'}>
            <ListRecord recordings={calls} />
          </Card> : ''
        }
      </>
    )
  }
}

ScheduleDetails.propTypes = {
  showAlert: PropTypes.func,
  push: PropTypes.func,
  setSwalLoading: PropTypes.func,
  closeAllSwal: PropTypes.func,
  token: PropTypes.string,
  match: PropTypes.object

}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  router: state.router

})

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path)),
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
  setSwalLoading: () => dispatch(setSwalLoading()),
  closeAllSwal: () => dispatch(closeAllSwal())
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDetails)
