import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Notification from 'consumers/notification'
import Card from 'components/Card'
import Button from 'components/Button'
import Input from 'components/Input'
import Select from 'components/Select'
import RichText from 'components/Richtext'
import { notificationOptions } from 'utils/resources'

import { showAlert } from 'store/modules/alert/actions'
import { Bounce } from 'react-activity'
import { Grid } from 'semantic-ui-react'
import RichTextEditor from 'react-rte'

function CreateNotification ({ showAlert, token }) {
  const [body, setBody] = useState(RichTextEditor.createEmptyValue())
  const [title, setTitle] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [level, setLevel] = useState(notificationOptions[0].value)
  const [errorMessages, setErrorMessages] = useState({})

  const clearInputs = async () => {
    setBody(RichTextEditor.createEmptyValue())
    setTitle('')
    setLevel(notificationOptions[0].value)
    setLoading(false)
  }

  const submit = async () => {
    setLoading(true)
    const data = {
      title,
      body: body.toString('html'),
      level
    }

    try {
      const response = await Notification.create(token, data)
      clearInputs()
      showAlert(200, 'Sucesso', response.message)
    } catch (err) {
      if (!err.message) {
        showAlert(500, 'Atenção', 'Falha ao se comunicar com o servidor!')
      } else {
        showAlert(400, 'Atenção', err.message, err.technical)
        if (err.invalid) {
          const messages = {}
          err.invalid.forEach(field => {
            messages[field.name] = field.message
          })
          setErrorMessages(messages)
        }
      }
    }
    clearInputs()
  }

  return (
    <Card title={'Notificações'}>
      <Grid columns='equal'>
        <Grid.Row>
          <Grid.Column width={6}>
            <Input
              label='Titulo'
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              placeholder='Titulo da notificação'
              error={errorMessages.title}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Select
              // inputRef={this.typeRef}
              label='Nivel'
              options={notificationOptions}
              onChange={(e) => setLevel(e.value)}
              placeholder='Selecione... '
              error={errorMessages.level}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column >
            <RichText
              label="Descrição"
              onChange={(event) => setBody(event)}
              value={body}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16} textAlign='right'>
            <div className='uk-text-right uk-margin'>
              <Button filled onClick={submit} disabled={loading}>
                {loading ? (
                  <Bounce
                    className='bounce-margin'
                    color='#FCFCFC'
                    size={22}
                    speed={1}
                    animating={true}
                  />
                ) : (
                  'Enviar'
                )}
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

CreateNotification.propTypes = {
  token: PropTypes.string,
  showAlert: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token
  }
}

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) =>
    dispatch(showAlert(type, title, message, details, func))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateNotification)
