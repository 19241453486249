import styled, { keyframes } from 'styled-components'
import mountainBackground from 'assets/img/hospital_fachada.jpg'

export const Content = styled.div`
  background: #fcfcfc;
  height: 100%;
  width: 100%;
  display: flex;
  background-image: url(${mountainBackground});
  background-position: center;
  background-size: cover;
`

export const wrapperAppear = keyframes`
  0% {
    transform: translateY(-500px);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
`

export const Wrapper = styled.div`
  background: linear-gradient(to bottom right, rgba(140, 140, 140, 0.8), rgba(30, 30, 30, 0.8));
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 5px;
  width: ${props => props.signupWidth ? '56%' : '36%'};
  color: #fcfcfc;
  min-height:380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${wrapperAppear} 0.9s both;

  @media screen and (max-width: 710px) {
     width: ${props => props.signupWidth ? '80%' : '60%'};
  }
  @media screen and (max-width: 1250px){
    width: 60%
  }
  @media screen and (max-width: 700px){
    width: 95%
  }
  @media screen and (max-width: 400px){
    width: 100%
  }
`

export const Gradient = styled.div`
  background: #fcfcfc;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.59);
`
