import styled from 'styled-components'

export const Container = styled.div`
  background-color: #fff;
  padding: 10px 0px;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.09);
  overflow: hidden;
  height: 100%;
  ul {
    padding-left: 10px;
  }
`
