import styled from 'styled-components'
import { getStatusColor } from 'utils/colors'

export const Circle = styled.div`
  height: 256px;
  width: 256px;
  margin: 18px;
  border-radius: 134px;
  border: #414141 solid 8px;
  border-color: ${props => getStatusColor(props.typeColor)};
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    color: ${props => getStatusColor(props.typeColor)};
  }
`

export const Time = styled.div`    
  ${props => props.nav ?
   `font-weight: bold;
    font-size: 16px;
    margin-left: 10px;`
    :
   `font-size: 30px;
    font-weight: 600;`
  }
`
