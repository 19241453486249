import api from 'services/api'
import mvApi from 'services/mvApi'
const insurances = {

  update: async (id, token, data) => {
    try {
      const response = await api.patch(
        `insurances/${id}`, data,
        { headers: { Authorization: token } }
      )
      return response.data
    } catch (e) {
      throw e.response.data
    }
  },

  getInsurancePlans: async (code) => {
    try {
      const response = await mvApi.get(
        '/v0/soul/insurances/plans', { params: { code: code, active: 'S' } })
      if (!response.data.result) return []
      const plans = response.data.result.data.plans
      return plans
    } catch (e) {
      throw e.response.data
    }
  },
  getHealthInsurances: async () => {
    try {
      const response = await mvApi.get('/v0/soul/insurances')
      const insurances = response.data.result.data.insurances
      return insurances
    } catch (e) {
      if (!e.response) {
        const error = new Error('CONNECTION_ERROR')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw e.response.data
    }
  }

}

export default insurances
