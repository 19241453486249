import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth/reducer'
import alert from './alert/reducer'
import telephony from './telephony/reducer'
import patient from './patient/reducer'
import resources from './resources/reducer'
import notification from './notification/reducer'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  alert,
  telephony,
  patient,
  resources,
  notification  
})

export default createRootReducer
