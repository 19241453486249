import styled from 'styled-components'

export const Content = styled.div`
  background: #F6F7FA;
  height: 100%;
  display: grid;
  grid-template-rows: 82px 1fr 82px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

`

export const Container = styled.div`
  background: #F6F7FA;
`

export const Aside = styled.aside`
  position: relative;
  float:left;
  top: 0px;
  bottom: 0px;
  background: #fcfcfc;
  grid-area: 2 / 1 / 6 / 2;
`

export const Footer = styled.footer`
  display: flex; 
  justify-content: flex-end; 
  align-items: center;
  background-color: #fcfcfc;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.18); 
  padding: 0px 30px;
`

export const Text = styled.p`
  font-size: 1.2em;

  strong {
    padding: 0px 2px;
  }
`