import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, ErrorMsg, Label, Spacer } from './styled'
import ReactSelect from 'react-select'

export default function Select ({ error, unit, options, label, inputRef, disabled, ...rest }) {
  const hasError = !!(error && error.trim())

  const customStyles = {

    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      borderBottom: hasError ? '1px solid red' : '1px solid #919191',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.18)',
      zIndex: 20,
      color: state.selectProps.menuColor
    }),

    control: (_, { selectProps: { width }, isDisabled }) => ({
      display: 'flex',
      width: width,
      boxShadow: isDisabled ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.18)',
      background: isDisabled ? '#FAFAFA' : '#FFF',
      borderBottom: hasError ? '1px solid red' : '1px solid #919191',
      fontSize: '14px',
      fontFamily: 'Heebo, Roboto, Arial, Helvetica, sans-serif',
      height: '36px !important',
      '&:hover': {
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.25)'
      }
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'
      return { ...provided, opacity, transition }
    },

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#212121'
    })
  }

  return (
    <Wrapper unit={unit}>
      <Label>{label}</Label><br/>
      <ReactSelect
        ref={inputRef}
        defaultValue={options[0]}
        label="Single select"
        options={options}
        styles={customStyles}
        width="100%"
        isDisabled={disabled}
        components={{
          IndicatorSeparator: () => null
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#a1a1a1',
            primary: 'black'
          }
        })}
        {... rest}
      />
      {hasError ? <ErrorMsg>{error}</ErrorMsg> : <Spacer />}
    </Wrapper>
  )
}

Select.propTypes = {
  error: PropTypes.string,
  unit: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ])
  })).isRequired,
  disabled: PropTypes.bool
}
