import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Modal } from 'semantic-ui-react'
import { showAlert, showLoading } from 'store/modules/alert/actions'

import user from 'consumers/user'

import Button from 'components/Button'
import Input from 'components/Input'

function EmailModal ({ loading, showAlert, token, showLoading }) {
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [emailConfirm, setEmailConfirm] = useState('')
  const [errorMessages, setErrorMessages] = useState({})

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  const submit = async () => {
    const data = {
      email,
      newEmail,
      emailConfirm
    }
    showLoading('Enviando...')
    try {
      const response = await user.changeEmail(token, data)
      showAlert(response.code, 'Sucesso!', response.message)
    } catch (err) {
      if (!err.message) {
        return showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
      }
      if (err.invalid) {
        const messages = {}
        err.invalid.forEach((field) => {
          messages[field.name] = field.message
        })
        setErrorMessages(messages)
      }
      return showAlert(400, 'Atenção', err.message, err.technical)
    }

    setOpen(false)
  } 
  
  return (
    <Modal 
      size='tiny'
      open={open}
      onOpen={openModal}
      onClose={closeModal} 
      trigger={
      <Button filled 
          style={{paddingLeft: '80px', paddingRight: '80px'}} 
          disabled={loading}
        >
          Alterar email
        </Button>
    }>
      <Modal.Header >Trocar email</Modal.Header>
        <Modal.Content>
          <Input 
            label='Email'
            placeholder='Insira o email antigo'
            onChange={(e) => setEmail(e.target.value)}
            error={errorMessages.email}
          />
          <Input 
            label='Novo email'
            placeholder='Insira o novo email'
            onChange={(e) => setNewEmail(e.target.value)}
            error={errorMessages.newEmail}
          />
          <Input 
            label='Confirmação'
            placeholder='Confirme o novo email'
            onChange={(e) => setEmailConfirm(e.target.value)}
            error={errorMessages.emailConfirm}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal}>Sair</Button>
          <Button filled
           style={{marginLeft: '20px'}}
           onClick={() => submit()}
          >
            Alterar
          </Button>
        </Modal.Actions>
    </Modal>
  )
}

EmailModal.propTypes = {
  loading: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  token: state.auth.token,
})

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func)),
  showLoading: (type, title, message, func) => dispatch(showLoading(type, title, message, func))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailModal)