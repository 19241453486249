import moment from 'moment'

const _initialState = {
  tabulations: [],
  call: {
    name: null,
    phone: null,
    id: null,
    createdAt: null,
    finishedAt: null,
    missedCallId: null
  },
  client: {
    name: null,
    cpf: null,
    wasRequested: false
  },
  lastCall: null,
  fetching: false,
  confirmation: {
    id: null,
    token: null,
    retention: false,
    name: null,
    phone: null
  }
}

const resources = (state = _initialState, action) => {
  switch (action.type) {
    case 'TELEPHONY_SET_TABULATIONS': {
      return {
        ...state,
        tabulations: action.payload.tabulations
      }
    }
    case 'TELEPHONY_SET_FETCHING': {
      return {
        ...state,
        fetching: action.payload.fetching
      }
    }
    case 'TELEPHONY_CALL_SUCCEEDED': {
      const { activeCall } = action.payload
      return {
        ...state,
        call: {
          id: activeCall._id,
          phone: activeCall.phone,
          createdAt: activeCall.createdAt,
          finishedAt: activeCall.finishedAt,
          missedCallId: activeCall.missedCallId,
          duration: moment().diff(moment(activeCall.createdAt), 'seconds')
        }
      }
    }
    case 'TELEPHONY_SET_CLIENT': {
      const { client } = action.payload
      return {
        ...state,
        client: {
          name: client.name,
          cpf: client.cpf,
          wasRequested: client.wasRequested
        }
      }
    }
    case 'TELEPHONY_TABULATION_SUCCEEDED':
      return {
        ...state,
        call: _initialState.call,
        client: _initialState.client,
        lastCall: Date.now()
      }
    case 'TELEPHONY_CALL_FAILED': {
      return {
        ...state,
        call: _initialState.call
      }
    }
    case 'TELEPHONY_SET_LAST_CALL': {
      localStorage.setItem('@Agenda/lastCall', action.payload.lastCall)
      return {
        ...state,
        lastCall: action.payload.lastCall
      }
    }

    case 'TELEPHONY_SET_CONFIRMATION': {
      console.log(action.payload)

      return {
        ...state,
        confirmation: {
          id: action.payload.id,
          token: action.payload.token,
          retention: action.payload.retention,
          name: action.payload.name,
          phone: action.payload.phone
        }
      }
    }

    case 'TELEPHONY_CLEAN_CONFIRMATION': {
      return {
        ...state,
        confirmation: {
          id: null,
          token: null,
          retention: false,
          name: null,
          phone: null
        }
      }
    }

    default:
      return state
  }
}

export default resources
