import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bounce } from 'react-activity'
import { connect } from 'react-redux'
import { showAlert } from 'store/modules/alert/actions'
import OperatorCard from 'components/OperatorCard'
import { Grid, Label, Tab } from 'semantic-ui-react'
import { Grid as Opgrid, Subtitle } from './styled'
import operator from 'consumers/operator'
import telephone from 'consumers/telephony'
import moment from 'moment'
import { env } from 'utils/config'

class OperatorsList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      operators: undefined,
      ramals: undefined,
      activeIndex: 0
    }
  }

  getOperators = async () => {
    const { token, showAlert } = this.props
    const { operators } = this.state

    try {
      const response = await operator.list(token)
      if (
        JSON.stringify(operators) !==
        JSON.stringify(response.data.docs)
      ) {
        this.setState({ operators: response.data.docs })
      }
    } catch (err) {
      if (err.code >= 400 && err.code <= 499) {
        showAlert(err.code, 'Atenção', err.message, err.technical)
      }
      this.setState({ operators: undefined })
    }
  }

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex })
  }

  getRamals = async () => {
    const { token } = this.props

    try {
      const response = await telephone.ramals(token)
      this.setState({ ramals: response })
    } catch (err) {
      this.setState({ ramals: [] })
    }
  }

  componentDidMount () {
    this.getOperators()
    this.getRamals()
    this.interval = setInterval(() => {
      this.getOperators()
      this.getRamals()
    }, 5000)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  render () {
    const ramalsColor = (ramal) => {
      const { ramals } = this.state
      let newColor
      if (ramals) {
        if (ramals.registered[0]) newColor = ramals.registered.includes(ramal) ? 'green' : 'grey'
        if (ramals.paused[0]) newColor = ramals.paused.includes(ramal) ? 'blue' : newColor
      }
      return newColor
    }

    const { operators: allOperators, ramals } = this.state

    if (!allOperators) {
      return (
        <Grid centered>
          <Grid.Row>
            <Bounce className="bounce-margin" color="#212121" size={22} speed={1} animating={true} />
          </Grid.Row>
        </Grid>
      )
    }
    const operators = allOperators.filter(operator => {
      if (env !== 'prod') {
        return operator.active
      } else {
        return operator.active && operator.ramal !== '7000'
      }
    })

    const inactiveOperators = allOperators.filter(operator => !operator.active)

    const panes = [
      {
        key: 'Todos',
        menuItem: 'Todos os Turnos',
        render: () => (<Tab.Pane className="hide-pane">
          <Opgrid
            noPadding>
            {operators.map((operator) => (
              <OperatorCard
                operator={operator}
                period={operator.period}
                key={operator._id}
                timeFinished={moment(operator.finishedAt).fromNow()}
                timeBusy={moment(operator.lastBusyAt).fromNow()}
                timePaused={moment(operator.pausedAt).fromNow()}/>
            ))}
          </Opgrid></Tab.Pane>)
      },
      {
        key: 'Manha',
        menuItem: 'Manhã',
        render: () => (<Tab.Pane className="hide-pane">
          <Opgrid
            noPadding
            sideButtonPress={this.clear}
            sideButtonText="Novo Paciente">
            {operators.map((operator) => (
              operator.period === 'morning' ? <OperatorCard
                operator={operator}
                period={operator.period}
                key={operator._id}
                timeFinished={moment(operator.finishedAt).fromNow()}
                timeBusy={moment(operator.lastBusyAt).fromNow()}
                timePaused={moment(operator.pausedAt).fromNow()}/> : ''
            ))}
          </Opgrid></Tab.Pane>)
      },
      {
        key: 'Tarde',
        menuItem: 'Tarde',
        render: () => (<Tab.Pane className="hide-pane">
          <Opgrid
            noPadding
            sideButtonPress={this.clear}
            sideButtonText="Novo Paciente">
            {operators.map((operator) => (
              operator.period === 'afternoon' ? <OperatorCard
                operator={operator}
                period={operator.period}
                key={operator._id}
                timeFinished={moment(operator.finishedAt).fromNow()}
                timeBusy={moment(operator.lastBusyAt).fromNow()}
                timePaused={moment(operator.pausedAt).fromNow()}/> : ''
            ))}
          </Opgrid></Tab.Pane>)
      },
      {
        key: 'Noite',
        menuItem: 'Noite',
        render: () => (<Tab.Pane className="hide-pane">
          <Opgrid
            noPadding
            sideButtonPress={this.clear}
            sideButtonText="Novo Paciente">
            {operators.map((operator) => (
              operator.period === 'night' ? <OperatorCard
                operator={operator}
                period={operator.period}
                key={operator._id}
                timeFinished={moment(operator.finishedAt).fromNow()}
                timeBusy={moment(operator.lastBusyAt).fromNow()}
                timePaused={moment(operator.pausedAt).fromNow()}/> : ''
            ))}
          </Opgrid></Tab.Pane>)
      },
      {
        key: 'Integral',
        menuItem: 'Integral',
        render: () => (<Tab.Pane className="hide-pane">
          <Opgrid
            noPadding
            sideButtonPress={this.clear}
            sideButtonText="Novo Paciente">
            {operators.map((operator) => (
              operator.period === 'full-time' ? <OperatorCard
                operator={operator}
                period={operator.period}
                key={operator._id}
                timeFinished={moment(operator.finishedAt).fromNow()}
                timeBusy={moment(operator.lastBusyAt).fromNow()}
                timePaused={moment(operator.pausedAt).fromNow()}/> : ''
            ))}
          </Opgrid></Tab.Pane>)
      },
      {
        key: 'Inativos',
        menuItem: 'Inativos',
        render: () => (<Tab.Pane className="hide-pane">
          <Opgrid
            noPadding>
            {inactiveOperators.map((operator) => (
              operator.period === 'full-time' ? <OperatorCard
                operator={operator}
                period={operator.period}
                key={operator._id}
                timeFinished={moment(operator.finishedAt).fromNow()}
                timeBusy={moment(operator.lastBusyAt).fromNow()}
                timePaused={moment(operator.pausedAt).fromNow()}/> : ''
            ))}
          </Opgrid></Tab.Pane>)
      }
    ]
    return (
      <>
        <div style={{ margin: '30px' }}>
          <Grid padded textAlign='center' >
            <Grid.Row>
              <h2 style={{ color: '#515151' }}>Ramais</h2>
            </Grid.Row>
            { ramals
              ? ramals.ramals?.map((ramal, index) => (
                <Label key={index} color={ramalsColor(ramal)} size='big'>{ramal}</Label>
              )) : <Label size='big'>Sem ramal</Label>}

            <Grid.Row style={{ marginTop: '10px' }} >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Subtitle type='online'/>
                <p>Disponível</p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Subtitle type='paused'/>
                <p>Pausado</p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Subtitle type='offline'/>
                <p>Offline</p>
              </div>
            </Grid.Row>

          </Grid>
        </div>
        <br/>
        <Tab onTabChange={this.handleTabChange} menu={{ pointing: true }} activeIndex={this.state.activeIndex} panes={panes} renderActiveOnly={true} />
      </>
    )
  }
}

OperatorsList.propTypes = {
  token: PropTypes.string,
  showAlert: PropTypes.func
}

const mapStateToProps = (state) => ({
  token: state.auth.token
})

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func))
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatorsList)
