import React, { Component } from 'react'
import { translateSituation, translateConsultType } from 'utils/translate'
import { Table } from 'semantic-ui-react'

class OperatorTable extends Component {
  render () {
    const { content, objectContent, header } = this.props
    let index = -1

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{header}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {content.map((item) =>
            <Table.Row key={item.title}>
              <Table.Cell><h4>{item.title}</h4></Table.Cell>
              <Table.Cell>{item.value}</Table.Cell>
            </Table.Row>
          )}
          {objectContent.map((item) => {

            if (item.length) {              
              var objectDataAux = []              
              for (const object in item) {
                objectDataAux.push({
                  name: Object.keys(item[object])[0],
                  value: Object.values(item[object])[0]
                })
              }              
              index++
              return objectDataAux.map((data) => {
                if (this.props.translate[index] === 'situation') {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        <h4>{translateSituation(data.name)}</h4>
                      </Table.Cell>
                      <Table.Cell>{data.value}</Table.Cell>
                    </Table.Row>
                  )
                } else if (this.props.translate[index] === 'consultType') {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        <h4>{translateConsultType(data.name)}</h4>
                      </Table.Cell>
                      <Table.Cell>{data.value}</Table.Cell>
                    </Table.Row>
                  )
                }
                return ''
              })
            } else {              
              return (     
                <>                                
                {item.length !== 0 ? <Table.Row>
                    <Table.Cell>
                      <h4>{Object.keys(item)}</h4>               
                    </Table.Cell>
                    <Table.Cell>{Object.values(item)}</Table.Cell>
                  </Table.Row> : ''
                }
                </>           
              )
            }
          })}
        </Table.Body>
      </Table>
    )
  }
}

export default OperatorTable
