import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  cursor: pointer;
  transition: all 300ms linear;
  
  img {
    object-fit: fill;
    grid-column: 1;
    grid-row: 1/3;
    align-self: end;
    z-index: 1;
    width: 170px;
    height: 170px;
  }
  
  p {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;
    color: white;
    grid-column: 1;
    grid-row: 2;
    align-self: end;
    z-index: 2;
  }

  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
  }
`