import React from 'react'
import PropTypes from 'prop-types'
import { Button } from './styled'

export default function IconButton ({ icon, ...rest }) {
  return (
    <Button type='button' {...rest}>
      <ion-icon name={icon} style={{ verticalAlign: 'middle' }}/>
    </Button>
  )
}

IconButton.propTypes = {
  icon: PropTypes.string
}
