export const showAlert = (type, title, text, details, func) => ({
  type: 'ALERT',
  data: {
    config: {
      type,
      title,
      text,
      details,
      func
    }
  }
})

export const showConfirmation = (type, title, text, func) => ({
  type: 'CONFIRM',
  data: {
    config: {
      type,
      title,
      text,
      func
    }
  }
})

export const showApproveNegate = (type, title, text, func, negatefunc, close) => ({
  type: 'CONFIRM_NEGATE',
  data: {
    config: {
      type,
      title,
      text,
      func,
      negatefunc,
      close
    }
  }
})

export const showLoading = (title) => ({
  type: 'LOADING',
  data: {
    title
  }
})

export const setSwalLoading = () => ({
  type: 'SET_LOADING_SWAL'
})

export const closeAllSwal = () => ({
  type: 'CLOSE_ALL_SWAL'
})

export const showInput = (type, title, text, func) => ({
  type: 'SWAL_INPUT',
  payload: {
    config: {
      type,
      title,
      text,
      func
    }
  }
})

export const showOptionSelect = (title, options, func) => ({
  type: 'SWAL_OPTION_SELECT',
  payload: {
    config: {
      title,
      options,
      func
    }
  }
})

export const showDialling = (text) => ({
  type: 'DIAL',
  payload: {
    config: {
      text
    }
  }
})
