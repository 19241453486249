import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import AdminLayout from '../pages/_layouts/Admin'

export default function AdminRoute ({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AdminLayout>
          {Component && <Component {...props} />}
        </AdminLayout>
      )}
    />
  )
}

AdminRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}
