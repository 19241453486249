import styled from 'styled-components'

export const FullContainer = styled.div `  
  ${props => props.noPadding ? '' : 'padding: 15px;' }  
`

export const Container = styled.div`  
  height: auto;  
  ${props => props.noPadding ? '' : `
    width: calc(100% - 13px);
    margin: 15px auto; 
    width: calc(100% - 13px);
    `}  
    border-radius: 3px;
    margin-top: 0;
    ${props => props.noBg ? ''
    : `
    padding: 8px;
    background-color: #fff;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.18);    
  `
  }
  
  ${props => props.center ? `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  ` : ''}
`

export const Header = styled.div`  
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;    
  padding-left: 8px;
`

export const Title = styled.h1`
  color: #515151;
  font-size: ${props => props.titleSize ? props.titleSize : '2.3em'};    
  margin-bottom: 0;  
  
`
