export const getTabulations = () => ({
  type: 'GET_TABULATIONS'
})

export const checkTelephony = () => ({
  type: 'CHECK_TELEPHONY'
})

export const tabulateCall = (text, tabulationId, callId, patientId) => ({
  type: 'TABULATE_CALL',
  payload: {
    text,
    tabulationId,
    callId,
    patientId
  }
})

export const makeCall = (phone) => ({
  type: 'TELEPHONY_MAKE_CALL',
  payload: {
    phone
  }
})

export const setConfirmation = (id, token, retention, name, phone) => ({
  type: 'TELEPHONY_SET_CONFIRMATION',
  payload: {
    id,
    token,
    retention,
    name,
    phone
  }
})

export const cleanConfirmation = () => ({
  type: 'TELEPHONY_CLEAN_CONFIRMATION'
})