import styled from 'styled-components'

export const Circle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  height: 28px;
  width: 28px;
  border-radius: 18px;
  background: green;
  margin-left: 4px;
  i {
    margin: 0;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => !props.whats ? `
    &:before {
      content: '';
      margin: 0 0.25rem 0 0;
      width: 1.18em;
      height: 1em;
     }
  ` : ''}
`
