import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import logo from '../../assets/logo.png'
import { LoginLink, Logo } from './styled'
import { Link } from 'react-router-dom'
import user from 'consumers/user'
import { showAlert } from 'store/modules/alert/actions'

function ConfirmEmail ({ showAlert, location }) {
  const confirm = async () => {
    const changeEmailToken = location.search

    try {
      const response = await user.emailConfirm(changeEmailToken)
      showAlert(response.code, 'Sucesso!', response.message)
    } catch (err) {
      if (!err.response) {
        return showAlert(500, 'Atenção', 'Erro ao se comunicar com o servidor')
      }
      showAlert(400, 'Atenção', err.message, err.technical)
    }
  }

  useEffect(() => {
    confirm()
  })

  return (
    <>
      <Logo src={logo} width='160px' alt='UX Agenda Logo' />
      <LoginLink>
        <Link to='/'>
          Ir para o login
        </Link>
      </LoginLink>
    </>
  )
}

ConfirmEmail.propTypes = {
  loading: PropTypes.bool
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading
})

const mapDispatchToProps = (dispatch) => ({
  showAlert: (type, title, message, details, func) => dispatch(showAlert(type, title, message, details, func))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail)
