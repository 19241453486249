import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { convertMessageByCode } from 'utils/translate'

import AccordionButton from 'components/Accordion'
import { Bounce } from 'react-activity'
import React from 'react'

const ReactSwal = withReactContent(Swal)

const alert = (state = {}, action) => {
  switch (action.type) {
    case 'ALERT': {
      const { config } = action.data
      ReactSwal.fire({
        icon: convertMessageByCode(config.type),
        title: config.title,
        text: config.text,
        html: config.html,
        heightAuto: false,
        footer: config.type >= 400 && config.details
          ? <AccordionButton title='Detalhes técnicos' details={config.details}/> : ''
      }).then((result) => {
        if (result.value) {
          if (config.func && typeof config.func === 'function') {
            config.func()
          }
        }
      })
      return state
    }
    case 'SWAL_LOADING': {
      ReactSwal.fire({
        heightAuto: false,
        html: <Bounce
          className='bounce-margin'
          color='#FCFCFC'
          size={22}
          speed={1}
          animating={true}
        ></Bounce>
      })
      return state
    }
    case 'CONFIRM': {
      const { config } = action.data
      ReactSwal.fire({
        icon: convertMessageByCode(config.type),
        title: config.title,
        text: config.text,
        showCancelButton: true,
        heightAuto: false
      }).then((result) => {
        if (result.value) {
          config.func()
        }
      })
      return state
    }
    case 'CONFIRM_NEGATE': {
      const { config } = action.data

      ReactSwal.fire({
        icon: 'warning',
        title: config.title,
        html: config.text,   
        showCancelButton: true,        
        confirmButtonText: 'Confirmou.',
        cancelButtonText: 'Negou.',
        showCloseButton: true,
        allowOutsideClick: false,
        customClass: {                    
          cancelButton: 'btn-danger',
          closeButton: 'color-black-close-sweet'
        },
        heightAuto: false,
        onClose: () => config.close()        
      }).then((result) => {
        if (result.value) {
          config.func()          
        } else if (                    
          result.dismiss === Swal.DismissReason.cancel
        ) {
          config.negatefunc()          
        }
      })
      return state
    }
    case 'LOADING': {
      const { title } = action.data
      ReactSwal.fire({
        heightAuto: false,
        allowOutsideClick: false,
        title: title,
        onBeforeOpen: () => {
          ReactSwal.showLoading()
        }
      })
      return state
    }
    case 'SET_LOADING_SWAL': {
      ReactSwal.fire({
        heightAuto: false,
        allowOutsideClick: false,
        customClass: { popup: 'containerClass' },
        onBeforeOpen: () => {
          ReactSwal.showLoading()
        }
      })

      return state
    }
    case 'SWAL_INPUT': {
      const { config } = action.payload
      ReactSwal.fire({
        icon: convertMessageByCode(config.type),
        title: config.title,
        text: config.text,
        input: 'textarea',
        inputPlaceholder: 'Insira sua mensagem aqui...',
        inputAttributes: {
          'aria-label': 'Insira sua mensagem aqui'
        },
        showCancelButton: true,
        heightAuto: false
      }).then((res) => {
        if (!res.dismiss) {
          config.func(res.value)
        }
      })

      return state
    }

    case 'SWAL_OPTION_SELECT': {
      const { config } = action.payload
      ReactSwal.fire({
        title: config.title,
        input: 'radio',
        inputOptions: config.options,
        inputValidator: (value) => {
          if (!value) {
            return 'Você precisa selecionar um número!'
          }
        },
        customClass: {
          content: 'option-select-swal'
        },
        showCancelButton: true,
        heightAuto: false
      }).then((res) => {
        if (!res.dismiss) {
          config.func(res.value)
        }
      })

      return state
    }

    case 'CLOSE_ALL_SWAL': {
      ReactSwal.close()

      return state
    }

    case 'DIAL': {
      const { config } = action.payload
      ReactSwal.fire({
        heightAuto: false,
        icon: 'info',
        title: 'DISCANDO...',
        text: `Ligando para o número ${config.text}`
      })
      return state
    }

    default:
      return state
  }
}

export default alert
