import React, { Component } from 'react'
import Counter from 'components/Counter'
import { Grid } from 'semantic-ui-react'
import Card from 'components/Card'

class Home extends Component {
  render () {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Card center>
              <Counter/>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default Home
