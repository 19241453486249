import React from 'react'

import { Wrapper, Icon, Content, Text, Number } from './styled'

export default function CardIconDash ({ color, number, icon, description }) {
  const iconStyle = {
    fontSize: 45,
    color: color
  }
  return (
    <Wrapper>
      <Content>
        <Number>{number}</Number>

        <Text>{description}</Text>
      </Content>

      <Icon color={color}>
        <ion-icon name={icon} style={iconStyle} />
      </Icon>
    </Wrapper>
  )
}
